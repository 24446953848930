import { RouteLocationNormalized, RouteLocationRaw } from 'vue-router'

import useErrorModal from '@/composables/useErrorModal'
import { Quote } from '@/domain/Quote/Quote'
import { waitUntilLaunchDarklyAndConfigInitialized } from '@/lib/appConfig'
import { isNotNullOrUndefined } from '@/lib/utils/arrays'
import BasketsV1Service from '@/services/BasketsV1Service'
import CustomersService from '@/services/CustomersService'
import QuotesService from '@/services/QuotesService'

export const quoteWithoutAnOwnerButWithExistingCustomerEmailGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: Function
): Promise<RouteLocationRaw | void> => {
  const { showError } = useErrorModal()
  if (from.name !== 'quote-list' && from.name !== undefined) {
    next()
    return
  }

  if (to.name === 'quote-view' || to.name === 'order-select-products') {
    let customerEmail: string = ''
    let quotes: Quote[] = []

    await waitUntilLaunchDarklyAndConfigInitialized()

    // Getting either basketId or uuid from the quote params
    if (to.params.basketId) {
      const identifier = Array.isArray(to.params.basketId)
        ? to.params.basketId[0]
        : to.params.basketId

      // Getting the basket and handling possible errors
      // with redirecting to dashboard
      try {
        const basket = await BasketsV1Service.get(identifier)
        if (basket?.items.length === 0) {
          showError('Basket has no items', ['App Error', 'CSE'], true)
          next({ name: from.name ?? 'dashboard' })
          return
        }
        const quoteIdsToFetch = basket.items.map((item) => item.external_id)

        const requests = quoteIdsToFetch.map((quoteId: string) =>
          QuotesService.get(quoteId)
        )
        quotes = await Promise.all(requests)

        customerEmail =
          quotes.find((quote) => quote.data?.policy_holders?.[0].email)?.data
            ?.policy_holders?.[0].email ?? ''
      } catch (error) {
        showError(error, ['App Error', 'CSE'], true)
        next({ name: from.name ?? 'dashboard' })
        return
      }
    } else {
      const identifier = Array.isArray(to.params.uuid)
        ? to.params.uuid[0]
        : to.params.uuid

      const quote = await QuotesService.get(identifier)
      quotes = [quote]
      customerEmail = quote.data?.policy_holders?.[0].email ?? ''
    }

    // Checking if the quote is invalid
    // If it is, we set the owner of the quote to the existing customer
    // we found in the database

    const anyUnownedQuptes = quotes.some(
      (quote) => quote?.status === 'IN_PROGRESS' && !quote?.owner
    )

    if (anyUnownedQuptes && customerEmail) {
      const customer = await CustomersService.getCustomerByEmail(customerEmail)
      const syncQuotesPromises = quotes
        .map(async (quote) => {
          if (
            quote?.status === 'IN_PROGRESS' &&
            !quote?.owner &&
            customer?.uuid
          ) {
            return QuotesService.setQuoteOwner(quote.uuid, {
              ownerId: customer.uuid,
            })
          }
        })
        .filter(isNotNullOrUndefined)

      await Promise.all(syncQuotesPromises)
    }

    next()
  } else {
    next()
  }
}
