import { ProductLineConfig } from '@/@types/Config'
import deepFreeze from '@/lib/utils/deepFreeze'

const productLineConfig: ProductLineConfig = {
  claims: {
    minWarningTimespan: {
      duration: 'P14D',
      format: 'days',
    },
    approveLimitPermissionPrefix: 'claims:insurance_gbp:',
    algoliaVetsConfig: {
      api_key: '739bfa3f94403717fec527eeca3ee526',
      app_id: 'ORKMXLHYI4',
      index: 'dev_vet_addresses',
    },
  },
  flags: {
    allowViewingPolicyLimits: true,
  },
}

export default deepFreeze(productLineConfig)
