import { DeepPartial } from '@/@types/general'
import { BasketV1 } from '@/domain/BasketV1'
import ErrorResponseFactory from '@/domain/Error/ErrorResponseFactory'
import { Quote } from '@/domain/Quote/Quote'
import { QuoteData } from '@/domain/Quote/QuoteData'

import ApiService from './ApiService'
import BasketV1Parser from './parsers/BasketV1Parser'

const BASKETS_PATH = '/baskets'

export default class BasketsV1Service {
  public static async get(basketId: string): Promise<BasketV1> {
    return ApiService.get({
      path: `${BASKETS_PATH}/${basketId}`,
      parse: BasketV1Parser.parse,
    })
  }

  public static async setBasketEmail(
    basketId: string,
    params: {
      email: string
    }
  ): Promise<BasketV1> {
    const { email } = params

    try {
      return await ApiService.post({
        path: `${BASKETS_PATH}/${basketId}/set-email`,
        params: {
          json: { email },
        },
        parse: BasketV1Parser.parse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error setting basket email',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }

  public static async sendBasketChaserEmail(
    basketId: string,
    params: {
      email: string
    }
  ): Promise<void> {
    const { email } = params
    try {
      await ApiService.post({
        path: `${BASKETS_PATH}/${basketId}/email-chaser`,
        params: {
          json: { email },
        },
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error sending basket chaser email',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }

  public static async createQuickQuoteInBasket(
    basketId: string,
    params: {
      productLineKey: string
      agency: string
      data: DeepPartial<QuoteData>
      owner?: string
      // TODO: add proper TS type instead of Promise<any>
    }
  ): Promise<Quote> {
    const { productLineKey, agency, data, owner } = params
    try {
      return await ApiService.post({
        path: `${BASKETS_PATH}/${basketId}/quick-quote`,
        params: {
          json: {
            product_line_key: productLineKey,
            agency: agency,
            data: data,
            owner,
            source: 'app-io-pas',
          },
        },
        parse: (data: Quote) => data,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error creating quick quote in basket',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }

  public static async removeBasketItem(
    basketId: string,
    params: {
      itemId: string
    }
  ): Promise<BasketV1> {
    const { itemId } = params
    try {
      return await ApiService.delete({
        path: `${BASKETS_PATH}/${basketId}/items/${itemId}`,
        parse: BasketV1Parser.parse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error removing basket item',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }
}
