import type {
  BasketChange,
  BasketCustomerChange,
  BasketPetChange,
  BasketPolicyChange,
} from '@/@types/Basket'
import { DeepPartial } from '@/@types/general'
import type { Pet } from '@/@types/Pet'
import type { Customer } from '@/domain/PolicyV2'
import { shortUuid } from '@/lib/utils'

/**
 * Creates a basket change for an existing customer and returns the updated basket.
 *
 * @param {object} params The data to send with the request.
 * @param {object} params.customerId The id of the customer to update.
 * @param {object} params.data Key value pairs of fields to update.
 *
 * @returns {object} The basket with the new change and any side effects in it.
 */
export function createCustomerBasketChange({
  customerId,
  data,
}: {
  customerId: string
  data: Partial<Customer>
}): BasketCustomerChange {
  return {
    type: 'SET',
    entity: 'CUSTOMER',
    entity_id: customerId,
    data,
  }
}

/**
 * Creates a basket change for an existing pet and returns the updated basket.
 *
 * @param {object} params The data to send with the request.
 * @param {string} params.petId The id of the pet to update.
 * @param {object} params.data Key value pairs of fields to update.
 *
 * @returns {object} The basket with the new change and any side effects in it.
 */
export function createPetBasketChange({
  petId,
  data,
}: {
  petId: string
  data: Partial<Pet>
}): BasketPetChange {
  return {
    type: 'SET',
    entity: 'PET',
    entity_id: petId,
    data,
  }
}

/**
 * Creates a basket change for an existing policy to lapse the upcoming renewal.
 *
 * @param {object} params The data to send with the request.
 * @param {string} params.entity_id The policy ID to lapse.
 * @param {string} params.reason The reason of lapse.
 * @returns {BasketPolicyChange} A basket change object.
 */
export function createPolicyLapseChange({
  entity_id,
  reason,
}: {
  entity_id: string
  reason: string
}): BasketPolicyChange {
  return {
    type: 'LAPSE',
    entity: 'POLICY',
    entity_id,
    data: {
      cancellation_reason: reason,
    },
  }
}

/**
 * Creates a basket change for an existing policy that is renewing.
 *
 * @param {object} params The data to send with the request.
 * @param {string} params.entity_id The policy ID from the renewal.
 *
 * @returns {BasketChange} A basket change object.
 */
export function createPolicySetChange({
  entity_id,
}: {
  entity_id: string
}): BasketPolicyChange {
  return {
    type: 'SET',
    entity: 'POLICY',
    entity_id,
    data: {},
  }
}

/**
 * Creates a basket change for an existing policy to renew.
 *
 * @param {object} params The data to send with the request.
 * @param {string} params.entity_id The policy ID to renew.
 *
 * @returns {BasketChange} A basket change object.
 */
export function createPolicyRenewChange({
  entity_id,
}: {
  entity_id: string
}): BasketPolicyChange {
  return {
    type: 'RENEW',
    entity: 'POLICY',
    entity_id,
    data: {},
  }
}

/**
 * Creates basket changes for existing pet.
 *
 * @param {object} data The data to send with the request.
 * @param {object} data.pet The pet data.
 * @param {string[]} data.productLines The product lines to create policy create changes for.
 * @returns {object[]} The basket changes.
 */
export function createExistingPetBasketChanges({
  pet,
  productLines,
}: {
  pet: DeepPartial<Pet>
  productLines: string[]
}): BasketChange[] {
  return productLines.map(
    (productLine) =>
      <BasketPolicyChange>{
        type: 'CREATE',
        entity: 'POLICY',
        data: {
          product_line: productLine,
          pet: {
            id: pet.id,
          },
        },
      }
  )
}

/**
 * Creates basket changes for new pet.
 *
 * @param {object} data The data to send with the request.
 * @param {object} data.pet The pet data.
 * @param {string[]} data.productLines The product lines to create policy create changes for.
 * @returns {object[]} The basket changes.
 */
export function createNewPetBasketChanges({
  pet,
  productLines,
}: {
  pet: DeepPartial<Pet>
  productLines: string[]
}): BasketChange[] {
  const petCorrelationId = `cor_${shortUuid.new()}`

  return [
    {
      type: 'CREATE',
      entity: 'PET',
      correlation_id: petCorrelationId,
      data: pet,
    },
    ...productLines.map(
      (productLine) =>
        <BasketPolicyChange>{
          type: 'CREATE',
          entity: 'POLICY',
          data: {
            product_line: productLine,
            pet: {
              id: petCorrelationId,
            },
          },
        }
    ),
  ]
}
