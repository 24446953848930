import {
  FinancialOverview,
  FinancialOverviewLine,
  FinancialOverviewPolicy,
  FinancialOverviewTransaction,
} from '@/domain/Account/FinancialOverview'

export interface FinancialOverviewTransactionJson {
  due: string
  amount: number
  cumulative: number | null
  remaining: number | null
  status: string
}

export interface FinancialOverviewLineJson {
  transaction_type: string
  start_date: string
  gross_price: number
  gross_price_delta: number
  transactions: FinancialOverviewTransactionJson[]
  bdx_source: string
}

export interface FinancialOverviewPolicyJson {
  policy_id: string
  bdx_lines: FinancialOverviewLineJson[]
}

export type FinancialOverviewJson = FinancialOverviewPolicyJson[]

export default class FinancialOverviewParser {
  public static parseFinancialOverview(
    data: FinancialOverviewJson
  ): FinancialOverview {
    return data.map(
      (policy): FinancialOverviewPolicy => ({
        policyId: policy.policy_id,
        bdxLines: policy.bdx_lines.map(
          (line): FinancialOverviewLine => ({
            transactionType: line.transaction_type,
            startDate: line.start_date,
            grossPrice: line.gross_price,
            grossPriceDelta: line.gross_price_delta,
            bdxSource: line.bdx_source,
            transactions: line.transactions.map(
              (transaction): FinancialOverviewTransaction => ({
                due: transaction.due,
                amount: transaction.amount,
                cumulative: transaction.cumulative,
                remaining: transaction.remaining,
                status: transaction.status,
              })
            ),
          })
        ),
      })
    )
  }
}
