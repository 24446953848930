import type { GlobalConfig } from '@/@types/Config'
import deepFreeze from '@/lib/utils/deepFreeze'

const globalConfig: GlobalConfig = {
  shouldGetLossSchemasAtTime: false,
  phoneIntegrationEnabled: true,
  phoneIntegrationShowActions: false,
}

export default deepFreeze(globalConfig)
