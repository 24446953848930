import { forOwn, isObjectLike } from 'lodash-es'

const deepFreeze = <T>(object: T): DeepReadonly<T> => {
  if (!isObjectLike(object)) {
    return object
  }

  const frozen = Object.freeze(object)

  forOwn(object, (value) => {
    if (!isObjectLike(value) || Object.isFrozen(value)) {
      return value
    }

    return deepFreeze(value)
  })

  return frozen
}

export default deepFreeze
