<template>
  <i18n-d v-if="date" :value="date" :format="format" :timezone="timezone">
    <template v-for="(index, name) in $slots" #[name]="data">
      <slot :name="name" v-bind="data" />
    </template>

    <template v-if="showTimezone">&nbsp;{{ timezone }}</template>
  </i18n-d>
</template>

<script lang="ts" setup>
import { computed, toRefs } from 'vue'

import dayjs, { DayJsParsable } from '@/lib/dayjs'

interface Props {
  value: DayJsParsable
  format: string
  timezone?: string
  showTimezone?: boolean
  forceNoTimezone?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showTimezone: false,
  timezone: undefined,
  forceNoTimezone: false,
})

const { value, timezone, forceNoTimezone } = toRefs(props)

// Passing undefined to dayjs will return the current date so instead we are passing null when date is undefined
const parsedDate = computed(() => dayjs(value.value ?? null))
const date = computed(() => {
  if (forceNoTimezone.value) {
    return parsedDate.value.isValid()
      ? //By removing 'Z' from the ISO string, Date object doesn't timezone the date
        //according to the system's date
        new Date(parsedDate.value.toISOString().replace('Z', ''))
      : null
  }

  return parsedDate.value.isValid() ? parsedDate.value.toDate() : null
})
</script>
