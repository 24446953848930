/* eslint-disable @typescript-eslint/explicit-function-return-type */

import type { RouteLocationNormalized } from 'vue-router'

export default [
  {
    name: 'quote-view',
    path: '/quotes/view/:uuid',
    component: () => import('@/views/quotes/QuoteView.vue'),
    props: (to: RouteLocationNormalized) => ({
      quoteUuid: to.params.uuid,
    }),
    meta: {
      title: 'View Quote',
      legacy: true,
    },
  },
  {
    name: 'quote-list',
    path: '/quotes',
    component: () => import('@/views/quotes/QuoteList.vue'),
    props: (to: RouteLocationNormalized) => ({
      page: to.query.page,
      search: to.query.search,
      productline: to.query.productline,
      objectType: 'quotes',
    }),
    meta: {
      title: 'Quotes',
      legacy: false,
    },
  },

  {
    name: 'quote-add',
    path: '/quotes/add',
    component: () => import('@/views/quotes/QuoteCreate.vue'),
    props: (to: RouteLocationNormalized) => {
      return {
        initialCustomerUuid: to.query.customer,
        initialProductLine: to.query.pl,
      }
    },
    meta: {
      title: 'Create Quote',
      legacy: true,
    },
  },
  {
    name: 'quote-update',
    path: '/quotes/update/:quote?',
    component: () => import('@/views/quotes/QuoteUpdate.vue'),
    props: (to: RouteLocationNormalized) => {
      return {
        quoteUuid: to.params.quote,
        email: to.query.email,
      }
    },
    meta: {
      title: 'Update Quote',
      legacy: true,
    },
  },
  {
    name: 'quote-create-customer',
    path: '/quotes/create/:customerId?',
    component: () => import('@/views/quotes/QuoteUpdate.vue'),
    props: (to: RouteLocationNormalized) => {
      return {
        customerId: to.params.customerId,
        email: to.query.email,
      }
    },
    meta: {
      title: 'Update Quote',
      legacy: true,
    },
  },
  {
    name: 'quick-quote-create',
    path: '/quick-quotes/create',
    component: () => import('@/views/quotes/QuickQuoteCreate.vue'),
    props: (to: RouteLocationNormalized) => {
      return {
        initialProductLine: to.query.pl,
      }
    },
    meta: {
      title: 'Create Quote',
      legacy: true,
    },
  },
  {
    name: 'quick-quote-update',
    path: '/quick-quotes/:quote/update',
    component: () => import('@/views/quotes/QuickQuoteUpdate.vue'),
    props: (to: RouteLocationNormalized) => ({
      quoteUuid: to.params.quote,
    }),
    meta: {
      title: 'Update Quote',
      legacy: true,
    },
  },
]
