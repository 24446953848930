{
  "postcode": "Postcode",
  "policy": {
    "co-payment": "Co-payment",
    "co_payment": "Co-payment",
    "reimbursement": "Reimbursement",
    "excess": "Excess",
    "deductible": "Deductible",
    "deducted": "Deducted",
    "claimed": "Claimed",
    "cooling-off-warning": "Policy is in waiting period"
  }
}
