import { ElMessageBox } from 'element-plus'
import type { ElMessageBoxOptions } from 'element-plus'
import { HTTPError } from 'ky'
import { h } from 'vue'

import ErrorDialog from '@/components/ErrorDialog'
import { getBuildTime, getBuildVersion } from '@/lib/build'
import dayjs from '@/lib/dayjs'
import { instance, agency } from '@/plrn'

interface ShowErrorOptions extends ElMessageBoxOptions {
  title?: string
  message?: string
  error?: any // we need any since if we catch an error in TRY/CATCH block, it will require to be UKNOWN or ANY
  extra?: Record<string, string>
}

/**
 * Shows error dialog.
 * If called with "await" it will stop further code execution until the dialog is closed.
 *
 * @async
 * @param {object} params Params object
 * @param {string} params.title Title section of the error dialog
 * @param {string} [params.message] Message section of the error dialog
 * @param {Error} [params.error] Error instance
 * @param {object} [params.extra] Object containig extra key/value params to be displayed
 *
 * @example Asynchronous(non-blocking) call with extra policyId information
 * this.$error({
 *  title: 'Could not load policy',
 *  error: new Error('Some Error/HTTPError instance'),
 *  extra: {
 *    policyId: 'pol_12312312313',
 *    anotherInfo: 'Some more info',
 *  },
 * })
 *
 * @example Await the dialog to be closed in order to proceed with code execution
 * await this.$error({
 *  title: 'Could not do the thing',
 *  message: 'Please check the other thing first',
 *  error: new Error('Some error instace'),
 * })
 */
export default async function showError({
  title,
  message,
  error,
  extra,
  ...msgboxOptions
}: ShowErrorOptions): Promise<void> {
  if (error) {
    console.error(error)
  }

  const buildTime = getBuildTime()
  const buildVersion = getBuildVersion()

  let details
  let requestUrl
  let requestMethod
  if (error instanceof HTTPError) {
    const errorJson = await error.response.json()
    details = errorJson?.detail
    requestUrl = error.request?.url
    requestMethod = error.request?.method
  }

  if (details == null) {
    details = error?.toString() ?? ''
  }

  try {
    await ElMessageBox({
      title,
      closeOnClickModal: false,
      confirmButtonText: 'Close',
      customClass: 'error-dialog',
      message: h(ErrorDialog, {
        buildVersion,
        buildTime,
        title,
        message: message ?? error?.toString(),
        details,
        requestUrl,
        requestMethod,
        timestamp: dayjs.utc().toDate(),
        extra: {
          agency,
          instance,
          ...extra,
          url: window.location.href,
        },
      }),
      ...msgboxOptions,
    })
  } catch (action) {
    // When $msgbox() is close/cancelled, it rejectes the promise and passes the action("close"/"cancel").
    // We still want to expose unexpected real errors.
    if (action instanceof Error) {
      throw action
    }
  }
}
