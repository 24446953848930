import type {
  GlobalConfig,
  InstanceConfig,
  AgencyConfig,
  ProductLineConfig,
  AgencyName,
  InstanceName,
  ProductLineName,
} from '@/@types/Config'
import globalConfig from '@/config/globalConfig'
import BBMSEProdConfig from '@/config/policies.io/BBM-SE/agencyConfig'
import BBMSEProdKattHundConfig from '@/config/policies.io/BBM-SE/productLine/katt-hund'
import BBMProdConfig from '@/config/policies.io/BBM/agencyConfig'
import BBMProdCatDogConfig from '@/config/policies.io/BBM/productLine/cat-dog'
import BBMProdCatDogPioConfig from '@/config/policies.io/BBM/productLine/cat-dog-pio'
import BBMProdPetConfig from '@/config/policies.io/BBM/productLine/pet'
import BBMProdPetInsuranceUkConfig from '@/config/policies.io/BBM/productLine/pet-insurance-uk'
import PoliciesConfig from '@/config/policies.io/instanceConfig'
import BBMSEStagConfig from '@/config/stagpolicies.io/BBM-SE/agencyConfig'
import BBMSEStagKattHundConfig from '@/config/stagpolicies.io/BBM-SE/productLine/katt-hund'
import BBMStagConfig from '@/config/stagpolicies.io/BBM/agencyConfig'
import BBMStagCatDogConfig from '@/config/stagpolicies.io/BBM/productLine/cat-dog'
import BBMStagCatDogPioConfig from '@/config/stagpolicies.io/BBM/productLine/cat-dog-pio'
import BBMStagPetConfig from '@/config/stagpolicies.io/BBM/productLine/pet'
import BBMStagPetInsuranceUkConfig from '@/config/stagpolicies.io/BBM/productLine/pet-insurance-uk'
import StagPoliciesConfig from '@/config/stagpolicies.io/instanceConfig'
import UsPoliciesConfig from '@/config/us.policies.io/instanceConfig'
import MPUSProdConfig from '@/config/us.policies.io/MP-US/agencyConfig'
import USProdAccidentIlnessConfig from '@/config/us.policies.io/MP-US/productLine/accident-illness'
import USProdWellnessConfig from '@/config/us.policies.io/MP-US/productLine/wellness'
import UsStagPoliciesConfig from '@/config/us.stagpolicies.io/instanceConfig'
import MPUSStagConfig from '@/config/us.stagpolicies.io/MP-US/agencyConfig'
import USStagAccidentIlnessConfig from '@/config/us.stagpolicies.io/MP-US/productLine/accident-illness'
import USStagWellnessConfig from '@/config/us.stagpolicies.io/MP-US/productLine/wellness'

const instanceConfigs: Record<InstanceName, InstanceConfig> = {
  'stagpolicies.io': StagPoliciesConfig,
  'us.stagpolicies.io': UsStagPoliciesConfig,
  'policies.io': PoliciesConfig,
  'us.policies.io': UsPoliciesConfig,
}

const agencyConfigs: Record<
  InstanceName,
  Partial<Record<AgencyName, Nullable<AgencyConfig>>>
> = {
  'stagpolicies.io': {
    BBM: BBMStagConfig,
    'BBM-SE': BBMSEStagConfig,
  },
  'us.stagpolicies.io': {
    'MP-US': MPUSStagConfig,
  },
  'policies.io': {
    BBM: BBMProdConfig,
    'BBM-SE': BBMSEProdConfig,
  },
  'us.policies.io': {
    'MP-US': MPUSProdConfig,
  },
}

export const isValidInstance = (instance: string): instance is InstanceName =>
  Boolean(instance in agencyConfigs)

export const isValidInstanceAndAgencyCombination = (
  instance: InstanceName,
  agency: AgencyName
): boolean => Boolean(agencyConfigs[instance]?.[agency])

const productLineConfigs: Record<
  InstanceName,
  Partial<
    Record<AgencyName, Partial<Record<ProductLineName, ProductLineConfig>>>
  >
> = {
  'stagpolicies.io': {
    BBM: {
      'cat-dog': BBMStagCatDogConfig,
      'cat-dog-pio': BBMStagCatDogPioConfig,
      'pet-insurance-uk': BBMStagPetInsuranceUkConfig,
      pet: BBMStagPetConfig,
    },
    'BBM-SE': {
      'katt-hund': BBMSEStagKattHundConfig,
    },
  },
  'us.stagpolicies.io': {
    'MP-US': {
      'accident-illness': USStagAccidentIlnessConfig,
      wellness: USStagWellnessConfig,
    },
  },
  'policies.io': {
    BBM: {
      'cat-dog': BBMProdCatDogConfig,
      'cat-dog-pio': BBMProdCatDogPioConfig,
      'pet-insurance-uk': BBMProdPetInsuranceUkConfig,
      pet: BBMProdPetConfig,
    },
    'BBM-SE': {
      'katt-hund': BBMSEProdKattHundConfig,
    },
  },
  'us.policies.io': {
    'MP-US': {
      'accident-illness': USProdAccidentIlnessConfig,
      wellness: USProdWellnessConfig,
    },
  },
}

export const loadGlobalConfig = (): GlobalConfig => {
  return globalConfig
}

export const loadInstanceConfig = (instance: InstanceName): InstanceConfig => {
  const config = instanceConfigs[instance]
  if (!config) {
    throw new Error(`Unknown instance (${instance})`)
  }
  return config
}

export const loadAgencyConfig = (
  instance: InstanceName,
  agency: AgencyName
): AgencyConfig => {
  const config = agencyConfigs[instance]?.[agency]
  if (!config) {
    throw new Error(
      `Unknown agency and instance combination (${instance}/${agency})`
    )
  }
  return config
}

export const loadProductLineConfig = (
  instance: InstanceName,
  agency: AgencyName
): Partial<Record<ProductLineName, ProductLineConfig>> => {
  return productLineConfigs[instance]?.[agency] ?? {}
}
