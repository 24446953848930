import type { ApiListResponse } from '@/@types/ApiResponse'
import type {
  Basket,
  BasketChange,
  BasketPricingBreakdown,
} from '@/@types/Basket'
import { apiClient } from '@/lib/api/ApiClient'

/**
 * Fetch Baskets.
 *
 * @param {object} [searchParams] The searchParams to send with the request
 * @param {string} [searchParams.checkout] The checkout status
 * @param {string} [searchParams.owner] The basket owner
 * @param {number} [searchParams.page_number] The page number
 * @param {number} [searchParams.per_page] Results per page
 * @returns {Array} The basket objects
 */
export async function fetchBaskets(searchParams?: {
  checkout?: string
  owner?: string
  page_number?: number
  per_page?: number
}): Promise<ApiListResponse<Basket>> {
  return await apiClient.get('/v2/baskets', { searchParams }).json()
}

/**
 * Creates a new Global API Basket with the payload provided.
 *
 * @param {object} data The basket payload.
 * @returns {object} The created basket object.
 */
export async function createBasket(data: Partial<Basket>): Promise<Basket> {
  return await apiClient.post('/v2/baskets', { json: data }).json()
}

/**
 * Fetches an existing Global API Basket with the id provided.
 *
 * @param {string} basketId The id of the basket to fetch
 * @returns {object} The basket object.
 */
export async function getBasket(basketId: string): Promise<Basket> {
  return await apiClient.get(`/v2/baskets/${basketId}`).json()
}

/**
 * Add provided changes to an existing Global API basket.
 *
 * @param {object} params The params to send with the request.
 * @param {string} params.basket The id of the basket.
 * @param {object[]} params.changes Array of basket changes to create the basket with.
 * @returns {object} The updated basket object.
 */
export async function addChangesToBasket({
  basket,
  changes,
}: {
  basket: string
  changes: Partial<BasketChange>[]
}): Promise<Basket> {
  return await apiClient
    .post(`/v2/baskets/${basket}/changes`, {
      json: { changes },
    })
    .json()
}

/**
 * Patch a change of an existing Global API basket.
 *
 * @param {object} params The params to send with the request.
 * @param {string} params.basket The id of the basket.
 * @param {string} params.change The id of the basket change.
 * @param {object} params.data The new change data.
 * @returns {object} The updated basket object.
 */
export async function patchBasketChange({
  basket,
  change,
  data,
}: {
  basket: string
  change: string
  data: any // This will be any until we are ready to do the selection of a product
}): Promise<Basket> {
  return await apiClient
    .patch(`/v2/baskets/${basket}/changes/${change}`, {
      json: data,
    })
    .json()
}

export async function patchBasketEffectiveDate(
  basketId: string,
  { effectiveDate }: { effectiveDate: string }
): Promise<Basket> {
  return await apiClient
    .patch(`/v2/baskets/${basketId}`, {
      json: [
        {
          op: 'replace',
          path: '/effective_date',
          value: effectiveDate,
        },
      ],
    })
    .json()
}

/**
 * Fetch the pricing breakdown for a basket.
 *
 * @param {object} params The params to send with the request.
 * @param {string} params.basket The id of the basket.
 * @returns {object} The basket's pricing breakdown object.
 */
export async function fetchBasketPricingBreakdown({
  basket,
}: {
  basket: string
}): Promise<BasketPricingBreakdown> {
  return await apiClient.get(`/v2/baskets/${basket}/pricing-breakdown`).json()
}
