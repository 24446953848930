<template>
  <div v-if="field" :class="getFieldWrapperClass()">
    <LabelWidget :field="field" :field-id="getFieldId()" />
    <ReadonlyWidget v-if="isReadOnly" :value="formattedValue" />
    <span v-if="!isReadOnly" class="mutt-field-wrapper-age">
      <Dropdown
        ref="years"
        :value="years"
        :empty-label="$t('Years')"
        :options="availableYears"
        class="mutt-dateinput mutt-dateinput--year mutt-field"
        @input="onYearsInput"
      />

      <span class="mutt-dateinput-separator" v-html="dateFieldSeparator" />

      <Dropdown
        ref="months"
        :value="months"
        :empty-label="$t('Months')"
        :options="availableMonths"
        class="mutt-dateinput mutt-dateinput--month mutt-field"
        @input="onMonthsInput"
      />
    </span>
    <HelpWidget :field="field" />
    <ErrorWidget
      v-if="!isReadOnly"
      :field="field"
      :errors="errors"
      :error-class="getErrorClass()"
    />
  </div>
</template>

<script>
import MuttVue from '@mutt/widgets-vue'

import { DayjsDateValidator } from '@/legacy/lib/Validators'
import dayjs from '@/lib/dayjs'

import Dropdown from './MuttAgeDropdown.vue'

export default {
  name: 'MuttAge',
  for: 'age',
  components: {
    Dropdown,
  },
  mixins: [MuttVue.mixin],
  emits: ['callback'],
  data() {
    return {
      min: null,
      max: null,
      months: null,
      years: null,
      dateFieldSeparator: null,
      required: null,
    }
  },
  computed: {
    availableYears() {
      const yearsLabel = this.$t('years')
      const yearLabel = this.$t('year')
      const maxYears = this.min ? dayjs.utc().diff(this.min, 'year') + 1 : 40
      return this.fillArray(maxYears, (index) => ({
        label: `${index} ${index === 1 ? yearLabel : yearsLabel}`,
        value: index,
      }))
    },
    availableMonths() {
      const monthsLabel = this.$t('months')
      const monthLabel = this.$t('month')
      if (this.years === null || this.years === 0) {
        return this.fillArray(11, (index) => ({
          label: `${index + 1} ${index === 0 ? monthLabel : monthsLabel}`,
          value: index + 1,
        }))
      }

      return this.fillArray(12, (index) => ({
        label: `${index} ${index === 1 ? monthLabel : monthsLabel}`,
        value: index,
      }))
    },
    formattedValue() {
      const fieldValueAtMonthStart = dayjs
        .utc(this.field.value)
        .startOf('month')
      const { months, years } = this.getYearsAndMonthsFromDate(
        fieldValueAtMonthStart
      )

      if (years && !months) {
        return `${years} ${this.$t('years')}`
      }
      if (months && !years) {
        return `${months} ${this.$t('months')}`
      }
      return `${years} ${this.$t('years')} ${this.$t(
        'and'
      )} ${months} ${this.$t('months')}`
    },
  },
  created() {
    if (!this.$t) {
      this.$t = (str) => str
    }
    // Field is only not required if options.required is explicitly false
    this.required = this.field.options.required !== false
  },
  mounted() {
    if (this.field.options.dateFieldSeparator) {
      this.dateFieldSeparator = this.field.options.dateFieldSeparator
    }

    if (this.field.options.min) {
      // Check for ISO-8601 duration string - i.e P1Y
      if (this.field.options.min.startsWith('P')) {
        this.min = dayjs
          .utc()
          .add(dayjs.duration(this.field.options.min))
          .startOf('month')
      } else {
        this.min = dayjs.utc(this.field.options.min)
      }
    }

    if (this.field.options.max) {
      // Check for ISO-8601 duration string - i.e P1Y
      if (this.field.options.max.startsWith('P')) {
        this.max = dayjs
          .utc()
          .add(dayjs.duration(this.field.options.max))
          .startOf('month')
      } else {
        this.max = dayjs.utc(this.field.options.max)
      }
    }

    this.field.validators.push(
      new DayjsDateValidator({
        min: this.min,
        max: this.max,
        messages: this.field.options.messages || {
          min: 'Pet is too old',
          max: 'Pet is too young',
        },
        required: this.required,
      })
    )

    this.initialised = true

    this.setInitialValue(this.field.value, null)

    if (this.field.value && this.field.validate()) {
      // Emit that the field has been populated
      this.$emit('callback', {
        key: this.field.name,
        value: this.field.value,
        action: 'populated',
        validated: true,
        bubble: true,
      })
    }
  },
  methods: {
    async focus() {
      // Focus on first field for the first time
      await this.$nextTick()
      this.$refs.years.focus()
    },
    fillArray(numberOfItems, itemGeneratorFunction) {
      const newArray = []
      for (let i = 0; i < numberOfItems; i++) {
        newArray.push(itemGeneratorFunction(i))
      }
      return newArray
    },
    getFieldClass() {
      return 'mutt-field mutt-field-choice mutt-field-dateinput'
    },
    getYearsAndMonthsFromDate(date) {
      const startOfMonth = dayjs.utc().startOf('day').startOf('month')

      return {
        months: startOfMonth.diff(date, 'months') % 12,
        years: startOfMonth.diff(date, 'years'),
      }
    },
    setInitialValue(newValue, oldValue) {
      let initialValue = oldValue
      if (newValue) {
        initialValue = dayjs.utc(newValue)
      } else if (this.field.options.default) {
        initialValue = dayjs.utc(this.field.options.default)
      }
      if (initialValue) {
        const { months, years } = this.getYearsAndMonthsFromDate(initialValue)
        this.months = months
        this.years = years
        this.buildValue()
      }
    },
    resetInputs() {
      this.years = null
      this.months = null
    },
    refresh() {
      this.initialised = false
      const value = dayjs.isDayjs(this.field.value)
        ? this.field.value
        : dayjs.utc(this.field.value)
      if (value.isValid()) {
        const { months, years } = this.getYearsAndMonthsFromDate(value)
        this.months = months
        this.years = years
      } else {
        this.resetInputs()
      }
      this.initialised = true
    },
    onYearsInput(value) {
      this.years = value

      if (this.months == null) {
        this.months = 0
      }

      this.buildValue()
    },
    onMonthsInput(value) {
      this.months = value
      this.buildValue()
    },
    buildValue() {
      if (!this.initialised) {
        return
      }

      const reset = () => {
        this.field.refreshValidationState(false)
        this.field.value = null
      }

      if (
        (!this.years && this.years !== 0) ||
        (!this.months && this.months !== 0)
      ) {
        // Not completed all fields, don't validate
        return reset()
      }

      const value = dayjs
        .utc()
        .startOf('day')
        .startOf('month')
        .subtract(this.years, 'years')
        .subtract(this.months, 'months')

      if (value.isValid()) {
        this.field.value = value
        const validated = this.field.validate()
        this.$emit('callback', {
          key: this.field.name,
          value: this.field.value,
          action: 'populated',
          validated,
          bubble: true,
        })
      } else {
        reset()
      }
    },
  },
}
</script>
