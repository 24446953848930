import { defineStore } from 'pinia'

import type { BasketV1, BasketV1Item } from '@/domain/BasketV1'
import type { Customer } from '@/domain/Customer'
import { PolicyHolderAddress } from '@/domain/Quote/PolicyHolder'
import type { Quote } from '@/domain/Quote/Quote'
import type { Subscription, SubscriptionItem } from '@/domain/Subscription'
import { isNotNullOrUndefined } from '@/lib/utils/arrays'
import { subscriptionMonthlyCost } from '@/lib/utils/subscriptions'
import BasketsV1Service from '@/services/BasketsV1Service'
import SubscriptionsService from '@/services/SubscriptionsService'

import { curry, loadExternalItems } from './utils'

interface QuoteAndBuyState {
  _subscription: Nullable<Subscription>
  _basket: Nullable<BasketV1>
  _customer: Nullable<Customer>
  _customerAddress: Nullable<PolicyHolderAddress>
  _quote: Nullable<Quote>
  _externalBasketItems: Record<string, BasketV1Item> | {}
  _externalSubscriptionItems: Record<string, SubscriptionItem> | {}
}

/**
 * Get the default module state.
 *
 * @returns {object} The default state of the module.
 */
const getDefaultState = (): QuoteAndBuyState => {
  return {
    _subscription: null,
    _basket: null,
    _customer: null,
    _customerAddress: null,
    _quote: null,
    _externalBasketItems: {},
    _externalSubscriptionItems: {},
  }
}

export const useQuoteAndBuy = defineStore('quoteAndBuy', {
  state: (): QuoteAndBuyState => getDefaultState(),
  getters: {
    subscription: (state: QuoteAndBuyState): Nullable<Subscription> =>
      state._subscription,
    subscriptionId: (state: QuoteAndBuyState): Maybe<string> =>
      state._subscription?.id,
    subscriptionItems: (state: QuoteAndBuyState): SubscriptionItem[] =>
      state._subscription?.items ?? [],
    subscriptionMonthlyCost: () => {
      return ({
        subscriptionItems,
      }: {
        subscriptionItems: SubscriptionItem[]
      }): number => subscriptionMonthlyCost(subscriptionItems)
    },
    basket: (state: QuoteAndBuyState): Nullable<BasketV1> => state._basket,
    basketId: (state: QuoteAndBuyState): Maybe<string> => state._basket?.id,
    basketItems: (state: QuoteAndBuyState): BasketV1Item[] =>
      state._basket?.items ?? [],
    basketTotal:
      () =>
      ({ basketItems }: { basketItems: BasketV1Item[] }): number =>
        basketItems.reduce(
          (total, item) => total + (item.monthly_cost || 0),
          0
        ),
    customer: (state: QuoteAndBuyState): Nullable<Customer> => state._customer,
    customerId: (state: QuoteAndBuyState): Maybe<string> =>
      state._customer?.uuid,
    quote: (state: QuoteAndBuyState): Nullable<Quote> => state._quote,
    quoteTotal:
      () =>
      ({ quote }: { quote: Nullable<Quote> }): Nullable<number> => {
        switch (quote?.type) {
          case 'MTA':
            return 0 // TODO: get price from quote.

          case 'CANCELLATION':
            return 0
        }
        return null
      },
    externalSubscriptionItems: (
      state: QuoteAndBuyState
    ): Record<string, SubscriptionItem> => state._externalSubscriptionItems,
    externalBasketItems: (
      state: QuoteAndBuyState
    ): Record<string, BasketV1Item> => state._externalBasketItems,
    email: (state: QuoteAndBuyState): Maybe<string> => {
      return state._customer?.email ?? state._basket?.email
    },
    customerDateOfBirth: (state: QuoteAndBuyState): Maybe<string> => {
      const quotes = [
        ...Object.values(state._externalBasketItems ?? {}),
      ] as unknown as Quote[]

      const dobInQuotes = quotes
        .map((quote: Quote) => quote?.data?.policy_holders?.[0]?.dob)
        .filter(isNotNullOrUndefined)?.[0]

      return state._customer?.date_of_birth ?? dobInQuotes
    },
    customerAddress: (state: QuoteAndBuyState): Maybe<PolicyHolderAddress> => {
      const quotes = [
        ...Object.values(state._externalBasketItems ?? {}),
      ] as unknown as Quote[]

      const addressInQuotes = quotes
        .map((quote: Quote) => quote?.data?.policy_holders?.[0]?.address)
        .filter(isNotNullOrUndefined)?.[0]

      return state._customerAddress ?? addressInQuotes
    },
    quoteUuid: (state: QuoteAndBuyState): Maybe<string> => {
      const quotes = [
        ...Object.values(state._externalBasketItems ?? {}),
      ] as unknown as Quote[]

      const quoteId = quotes
        .map((quote: Quote) => quote?.uuid)
        .filter(isNotNullOrUndefined)?.[0]

      return state._quote?.uuid ?? quoteId
    },
    quotesInBasket: (state: QuoteAndBuyState): Quote[] => {
      return [
        ...Object.values(state._externalBasketItems ?? {}),
      ] as unknown as Quote[]
    },
  },
  actions: {
    clearExternalSubscriptionItems(): void {
      this._externalSubscriptionItems = {}
    },
    clearExternalBasketItems(): void {
      this._externalBasketItems = {}
    },
    setSubscription(subscription: Nullable<Subscription>): void {
      // If the subscription's owner is not the same as the stored customer, invalidate the customer
      if (this.customerId && this.customerId !== subscription?.owner) {
        this.setCustomer(null)
      }

      this._subscription = subscription
    },
    setBasket(basket: BasketV1): void {
      this._basket = basket
    },
    setCustomer(customer: Nullable<Customer>): void {
      // If stored subscription's owner is not the customer we're setting, invalidate it
      const subscription = this.subscription

      if (subscription && subscription?.owner !== customer?.uuid) {
        this._subscription = null
      }

      this._customer = customer
    },
    setCustomerAddress(address: Nullable<PolicyHolderAddress>): void {
      this._customerAddress = address
    },
    setQuote(quote: Quote): void {
      this._quote = quote
    },
    setExternalSubscriptionItem({
      id,
      item,
    }: {
      id: string
      item: SubscriptionItem
    }): void {
      this._externalSubscriptionItems = {
        ...this._externalSubscriptionItems,
        [id]: item,
      }
    },
    setExternalBasketItem({
      id,
      item,
    }: {
      id: string
      item: BasketV1Item
    }): void {
      this._externalBasketItems = {
        ...this._externalBasketItems,
        [id]: item,
      }
    },
    startNewJourney({
      customer,
      subscription,
      quote,
    }: Partial<{
      customer: Customer
      subscription: Subscription
      quote: Quote
    }> = {}): void {
      this.destroyJourney()

      // If we were passed both a subscription and a customer, ensure that the
      // customer owns the subscription, otherwise don't set either.
      if (subscription && customer && subscription.owner !== customer.uuid) {
        return
      }

      if (subscription) {
        this.setSubscription(subscription)
      }

      if (customer) {
        this.setCustomer(customer)
      }

      if (quote) {
        this.setQuote(quote)
      }
    },
    destroyJourney(): void {
      this.$reset()
    },
    async loadSubscription({
      subscriptionId,
      force = false,
    }: Partial<{
      subscriptionId: string
      force: boolean
    }> = {}): Promise<void> {
      if (force || subscriptionId !== this.subscriptionId) {
        if (subscriptionId) {
          const subscription = await SubscriptionsService.get(subscriptionId)
          this.setSubscription(subscription)
        }
      }

      await this.loadSubscriptionItems()
    },
    async loadSubscriptionItems(): Promise<void> {
      this.clearExternalSubscriptionItems()

      return loadExternalItems({
        items: this.subscriptionItems as Array<
          SubscriptionItem & {
            type: 'QUOTE' | 'POLICY'
          }
        >,
        mutation: curry(this.setExternalSubscriptionItem),
      })
    },
    async loadBasket({
      basketId,
      force = false,
    }: {
      basketId: string
      force: boolean
    }): Promise<void> {
      if (force || basketId !== this.basketId) {
        const basket = await BasketsV1Service.get(basketId)
        this.setBasket(basket)
      }
      await this.loadBasketItems()
    },
    async loadBasketItems(): Promise<void> {
      this.clearExternalBasketItems()

      return loadExternalItems({
        items: this.basketItems as Array<
          BasketV1Item & {
            type: 'QUOTE' | 'POLICY'
          }
        >,
        mutation: curry(this.setExternalBasketItem),
      })
    },
  },
  persist: true,
})
