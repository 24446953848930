<template>
  <div>
    <p v-if="message">{{ message }}</p>
    <div v-if="details || requestUrl" class="error-box">
      <pre class="details">{{ details }}</pre>
      <div v-if="requestUrl" class="request">
        <code class="request-method">{{ requestMethod }}</code>
        <span class="request-url">
          <template v-for="(urlPart, idx) in requestUrlParts" :key="idx">
            <span>{{ urlPart }}</span>
            <wbr />
          </template>
        </span>
      </div>
    </div>
    <div class="timestamp">
      <span>{{ timestampFormatted }}</span>
      <ElButton size="default" :icon="CopyDocument" @click="copyErrorText">
        Copy error text
      </ElButton>
    </div>
    <table>
      <tbody>
        <tr>
          <th>Version</th>
          <td>{{ version }}</td>
        </tr>
        <tr v-for="(value, label) in extra" :key="label">
          <th>{{ startCase(label) }}</th>
          <td>{{ value }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
// We need to import the ElementPlus components manually because we render this
// component using h() in showError.ts
import { CopyDocument } from '@element-plus/icons-vue'
import { useClipboard } from '@vueuse/core'
import { ElButton, ElMessage } from 'element-plus'
import { startCase } from 'lodash-es'
import { computed } from 'vue'

import dayjs from '@/lib/dayjs'

interface Props {
  title?: string
  message?: string
  timestamp?: Date
  details?: string
  requestUrl?: string
  requestMethod?: string
  buildVersion: string
  buildTime: Date
  extra?: Record<string, any>
}

const props = withDefaults(defineProps<Props>(), {
  title: 'Error',
  message: '',
  timestamp: undefined,
  details: '',
  requestUrl: '',
  requestMethod: '',
  extra: () => ({}),
})

const { copy } = useClipboard()

// We are not using any reactivity(ref/computed) because we are not expecting
// this component to be used with reacive props.
const dateFormat = 'D MMM YYYY, HH:mm:ss [UTC]'
const timestampFormatted = dayjs.utc(props.timestamp).format(dateFormat)
const buildTimeFormatted = dayjs.utc(props.buildTime).format(dateFormat)

const version = `${props.buildVersion} (built on ${buildTimeFormatted})`

// Splits the main url in parts in order to add <wbr> for better readability in the UI
const requestUrlParts = computed(() => {
  if (!props.requestUrl) {
    return []
  }

  const { origin, pathname, search } = new URL(props.requestUrl)
  return [origin, pathname, search].filter(Boolean)
})

const copyErrorText = () => {
  const extraLines = Object.entries(props.extra).map(
    ([label, value]) => `${startCase(label)}: ${value}`
  )

  if (props.requestUrl) {
    extraLines.push(`Request: ${props.requestMethod} ${props.requestUrl}`)
  }

  const errorText = [
    `Error: ${props.title}`,
    `Message: ${props.message}`,
    `Timestamp: ${timestampFormatted}`,
    `Version: ${version}`,
    ...extraLines,
    '----------',
    props.details,
    '----------',
  ].join('\n')

  copy(errorText)
  ElMessage.success('Error text copied')
}
</script>

<style lang="scss">
// This class is set in showError
.el-message-box.error-dialog {
  width: 500px;

  .error-box {
    margin: 8px 0 12px 0;
    border: 1px solid var(--el-border-color);
    border-radius: var(--el-border-radius-base);

    .request {
      font-size: 12px;
      color: var(--el-color-info);
      background: var(--el-fill-color-lighter);
      border-top: 1px solid var(--el-border-color-lighter);
      font-family: monospace;
      padding: 6px 8px;
      line-height: 1.2;
      display: flex;
      align-items: flex-start;
      gap: 20px;

      code.request-method {
        padding: 0 5px;
        border-radius: var(--el-border-radius-small);
        background: #bdbec1;
        color: #fff;
      }

      span.request-url {
        word-break: break-word;
      }
    }

    pre.details {
      padding: 6px 8px;
      min-height: 100px;
      max-height: 300px;
      white-space: pre-wrap;
      line-height: 16px;
      overflow: auto;
      margin: 0;
    }
  }

  .timestamp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
    word-break: break-word;
    font-size: 14px;
    line-height: 1.4;

    th {
      font-weight: bold;
      min-width: 80px;
      padding-right: 10px;
    }
  }
}
</style>
