{
  "postcode": "Zip",
  "county": "State",
  "policy": {
    "co-payment": "Co-payment",
    "co_payment": "Co-payment",
    "reimbursement": "Reimbursement",
    "excess": "Excess",
    "deductible": "Annual Deductible",
    "deducted": "Deducted",
    "claimed": "Claimed",
    "cooling-off-warning": "Policy is in free look",
    "limits": {
      "amountClaimed": "{amount} paid out"
    }
  },
  "claim": {
    "document": {
      "type": {
        "MEDICAL_HISTORY": "Medical records - DOS",
        "MEDICAL_HISTORY_18_MONTHS": "Medical records - MRR",
        "INVOICE": "Invoice",
        "DOCTOR_OF_VET_MEDICINE_LETTER": "DVM",
        "REFERRAL_REPORT": "Referral report",
        "TREATMENT_NOTE" : "Treatment note",
        "PRESCRIPTION": "Prescription",
        "ESTIMATE": "Estimate",
        "RECEIPT": "Receipt",
        "OTHER": "Other"
      }
    },
    "duplicateLoss": {
      "reasons": {
        "INVOICE_FROM_PREVIOUSLY_PAID_CLAIM": "Invoice from previously paid claim",
        "TREATMENT_DATE_OVERLAP": "Treatment date overlap"
      }
    },
    "maxWarningMessage": "Claim date is {duration} past the date of loss",
    "viewAlgoliaVet": "Get zip code"
  }
}
