import dayjs, { DayJsParsable } from '@/lib/dayjs'

export interface PetAge {
  months: number
  years: number
}

/**
 * @param {string | Date} dateOfBirth date of birth of pet, can be anything that can be used by dayjs
 * @returns {object} Object containing 'months' and 'years' indicating how old the pet is
 */
export function petAgeFromDateOfBirth(
  dateOfBirth: DayJsParsable
): Nullable<PetAge> {
  return petAgeAtDate(dateOfBirth, dayjs())
}

/**
 * @param {string | Date} dateOfBirth date of birth of pet, can be anything that can be used by dayjs
 * @param {string | Date} dateOfInterest We want the pet's age as of this date
 * @returns {object} Object containing 'months' and 'years' indicating how old the pet is
 */
export function petAgeAtDate(
  dateOfBirth: DayJsParsable,
  dateOfInterest: DayJsParsable
): Nullable<PetAge> {
  if (!dateOfBirth) {
    return null
  }

  const monthEnd = dayjs(dateOfInterest).endOf('month')

  return {
    months: monthEnd.diff(dayjs(dateOfBirth), 'month') % 12,
    years: monthEnd.diff(dayjs(dateOfBirth), 'year'),
  }
}
