import { useScriptTag } from '@vueuse/core'
import type { BillingRequest, BillingRequestFlow } from 'gocardless-nodejs'
import { ref } from 'vue'

import { getConfigForFeature } from '@/lib/appConfig'

interface UseGoCardlessResultResult {
  openGoCardlessModal: (billingRequestFlowID: string) => Promise<{
    billingRequest: BillingRequest
    billingRequestFlow: BillingRequestFlow
  }>
  closeGoCardlessModal: () => void
}

export const useGoCardless = (): UseGoCardlessResultResult => {
  const gcModal = ref<any>(null)

  const loadGoCardless = async (): Promise<void> => {
    const { load } = useScriptTag(
      'https://pay.gocardless.com/billing/static/dropin/v2/initialise.js',
      undefined,
      {
        manual: true,
      }
    )
    await load()

    if (!(window as any).GoCardlessDropin) {
      throw new Error('Failed to load GoCardless Drop-In SDK')
    }

    return
  }

  const openGoCardlessModal = async (
    billingRequestFlowID: string
  ): Promise<{
    billingRequest: BillingRequest
    billingRequestFlow: BillingRequestFlow
  }> => {
    if (!(window as any).GoCardlessDropin) {
      await loadGoCardless()
    }
    return new Promise((resolve, reject) => {
      try {
        gcModal.value = (window as any).GoCardlessDropin.create({
          billingRequestFlowID: billingRequestFlowID,
          environment: getConfigForFeature('isProductionInstance')
            ? 'live'
            : 'sandbox', // either live or sandbox
          onSuccess: (
            billingRequest: BillingRequest,
            billingRequestFlow: BillingRequestFlow
          ) => {
            resolve({ billingRequest, billingRequestFlow })
          },
          onExit: (error: object | null) => {
            reject(error)
          },
        })
        gcModal.value.open()
      } catch (goCardlessInitialisationError) {
        return reject(goCardlessInitialisationError)
      }
    })
  }

  const closeGoCardlessModal = (): void => {
    gcModal.value?.exit?.()
  }

  return {
    openGoCardlessModal,
    closeGoCardlessModal,
  }
}
