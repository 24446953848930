import ErrorResponseFactory from '@/domain/Error/ErrorResponseFactory'
import { ProductCatalog } from '@/domain/Shop/ProductCatalog'

import { ShopApiServiceNoAuth } from '../ApiService'
import ProductCatalogParser from '../parsers/Shop/ProductCatalogParser'

export default class ProductCatalogService {
  public static async getCatalog(): Promise<ProductCatalog> {
    try {
      return ShopApiServiceNoAuth.get({
        path: `/v2/products`,
        parse: ProductCatalogParser.parse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to get products',
        originalError: error,
        tags: ['Shop', 'API'],
      })
      throw errorClass
    }
  }
}
