/* eslint-disable @typescript-eslint/explicit-function-return-type */

import type {
  RouteLocationNormalized,
  RouteLocationRaw,
  NavigationGuardNext,
} from 'vue-router'

import { waitUntilLaunchDarklyAndConfigInitialized } from '@/lib/appConfig'
import showError from '@/lib/showError'
import * as api from '@/novelClaims/api'

export const novelClaimsRoutes = [
  {
    name: 'claim-list',
    path: '/claims',
    component: () => import('@/novelClaims/views/ClaimList/ClaimList.vue'),
    meta: {
      title: 'Claims',
    },
  },
  {
    name: 'claim-overview',
    path: '/pet-claims/:petUuid',
    component: () =>
      import('@/novelClaims/views/ClaimOverview/ClaimOverview.vue'),
    props: (to: RouteLocationNormalized) => ({
      petUuid: to.params.petUuid,
      claimId: to.params.claimId,
      policyId: to.query.policyId,
    }),
    meta: {
      title: 'View Pet Claims',
    },
    children: [
      {
        name: 'claim-overview-new',
        path: 'new',
        component: () =>
          import('@/novelClaims/views/ClaimOverview/ClaimCreate.vue'),
        props: (to: RouteLocationNormalized) => ({
          petUuid: to.params.petUuid,
          policyId: to.query.policyId,
          dateOfLoss: to.query.dateOfLoss,
          continuationId: to.query.continuationId,
        }),
        meta: {
          title: 'Add Pet Claim',
        },
      },
      {
        name: 'claim-overview-view',
        path: ':claimId',
        component: () =>
          import('@/novelClaims/views/ClaimOverview/ClaimView.vue'),
        props: (to: RouteLocationNormalized) => ({
          petUuid: to.params.petUuid,
          claimId: to.params.claimId,
        }),
        meta: {
          title: 'View Pet Claim',
        },
      },
    ],
  },
  {
    name: 'vet-practice-list',
    path: '/vet-practices',
    component: () =>
      import('@/novelClaims/views/VetPracticeList/VetPracticeList.vue'),
    meta: {
      title: 'Vet Practices',
    },
  },
  {
    name: 'vet-practice-view',
    path: '/vet-practices/:id',
    component: () =>
      import('@/novelClaims/views/VetPracticeView/VetPracticeView.vue'),
    props: (to: RouteLocationNormalized) => ({
      vetPracticeId: to.params.id,
    }),
    meta: {
      title: 'View Vet Practice',
      breadcrumbs: [{ name: 'vet-practice-list' }],
    },
  },

  // Redirect to document (after authentication)
  {
    name: 'claim-document-view',
    path: '/claim-documents/:id',
    component: () => null,
    beforeEnter: async (to: RouteLocationNormalized) => {
      await waitUntilLaunchDarklyAndConfigInitialized()

      try {
        const doc = await api.getDocument(to.params.id as string)
        window.location.href = doc.url
      } catch (error) {
        showError({
          title: 'Error',
          message: 'Could not open document',
        })
      }
    },
  },

  // Legacy routes for backwards compatibility (ZenDesk links pointing to old routes)
  {
    name: 'legacy-claim-view',
    path: '/claims/view/:id', // cover the old claim view route
    alias: '/cases/view/:id', // cover the oldest case view route
    component: () => null,
    beforeEnter: async (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => {
      await waitUntilLaunchDarklyAndConfigInitialized()

      const claim = await api.getClaim(to.params.id as string)

      next({
        name: 'claim-overview-view',
        params: {
          petUuid: claim.insured_entity_id,
          claimId: claim.id,
        },
      })
    },
  },
  {
    name: 'legacy-loss-view',
    path: '/losses/view/:id',
    component: () => null,
    beforeEnter: async (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => {
      await waitUntilLaunchDarklyAndConfigInitialized()

      // Search by loss ref
      const claims = await api.searchClaims({
        q: to.params.id as string,
        per_page: 2,
      })

      if (claims._embedded.items.length === 1) {
        // If we find exactly 1 claim then we redirect to it
        const claim = claims._embedded.items[0]

        next({
          name: 'claim-overview-view',
          params: {
            petUuid: claim.insured_entity_id,
            claimId: claim.id,
          },
          // TODO: check if we should deep link to the loss
          // query: { lossId: to.params.id },
        })
      } else {
        // If we find 0 or more than 1 claim then we redirect to the claim list
        next({
          name: 'claim-list',
          query: { q: to.params.id },
        })
      }
    },
  },
]

interface ClaimRouteParams {
  id: string
  insured_entity_id?: string
}

export function getClaimRoute(
  claim: ClaimRouteParams,
  query: Record<string, string> = {}
): RouteLocationRaw {
  if (claim.insured_entity_id) {
    return {
      name: 'claim-overview-view',
      params: {
        petUuid: claim.insured_entity_id,
        claimId: claim.id,
      },
      query,
    }
  } else {
    return {
      name: 'legacy-claim-view',
      params: {
        id: claim.id,
      },
      query,
    }
  }
}
