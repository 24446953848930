<template>
  <Component
    :is="tag"
    class="heading"
    :class="{
      'heading--bold': bold,
      [`heading--${size}`]: size,
      'heading--uppercase': uppercase,
      'heading--secondary': secondary,
    }"
  >
    <slot />
  </Component>
</template>

<script>
export const elements = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']
export const sizes = [
  'hero',
  'large',
  'medium',
  'lead',
  'normal',
  'small',
  'micro',
  'nano',
]

export default {
  name: 'Heading',
  props: {
    tag: {
      type: String,
      validator: (value) => elements.includes(value),
      required: true,
    },
    size: {
      type: String,
      validator: (value) => sizes.includes(value),
      required: false,
      default: 'normal',
    },
    bold: {
      type: Boolean,
      required: false,
      default: false,
    },
    uppercase: {
      type: Boolean,
      required: false,
      default: false,
    },
    secondary: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.heading {
  color: var(--el-text-color-primary);
  font-weight: 400;
  line-height: 1;
  margin: var(--app-spacing-1) 0;

  &--bold {
    font-weight: 700;
  }

  &--secondary {
    color: var(--el-text-color-secondary);
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--hero {
    font-size: 46px;
  }

  &--large {
    font-size: 36px;
  }

  &--medium {
    font-size: 28px;
  }

  &--lead {
    font-size: 22px;
  }

  &--normal {
    font-size: 18px;
  }

  &--small {
    font-size: 16px;
  }

  &--micro {
    font-size: 14px;
  }

  &--nano {
    font-size: 12px;
  }
}
</style>
