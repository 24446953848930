// This mock of 'auth0/auth0-vue' is used for component tests
import type { Auth0VueClient } from '@auth0/auth0-vue'
import jwt_decode from 'jwt-decode'
import { readonly, ref } from 'vue'
import type { Plugin, App } from 'vue'

const isLoading = ref(true)
const isAuthenticated = ref(false)
const user = ref()
const error = ref(null)
const idToken = ref('')
const accessToken = ref('')

const auth0Instance: Partial<Auth0VueClient> = {
  isAuthenticated: readonly(isAuthenticated),
  isLoading: readonly(isLoading),
  user: readonly(user),
  error: readonly(error),
  async getAccessTokenSilently(): Promise<any> {
    return isAuthenticated.value ? accessToken.value : ''
  },
  async logout(): Promise<void> {
    isAuthenticated.value = false
    return
  },
}

export const createAuth0 = (): Plugin => {
  const init = async (): Promise<void> => {
    idToken.value = window.sessionStorage.getItem('mock-id-token') ?? ''
    accessToken.value = window.sessionStorage.getItem('mock-access-token') ?? ''
    user.value = jwt_decode(idToken.value)
    isAuthenticated.value = true
    isLoading.value = false
  }

  // Initialise asynchronously to mimic how the original one works
  init()

  return {
    install(app: App): void {
      app.config.globalProperties.$auth0 = auth0Instance as Auth0VueClient
    },
    ...auth0Instance,
  }
}

export const useAuth0 = (): Partial<Auth0VueClient> => auth0Instance
export const authGuard = (): boolean => isAuthenticated.value
