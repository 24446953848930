import { ref, Ref } from 'vue'

import AppError from '@/domain/Error/AppError'
import BaseError from '@/domain/Error/BaseError'
import { Tag } from '@/domain/Error/Tag'

const globalError = ref<Nullable<BaseError>>(null)

export interface UseErrorModalResult {
  error: Ref<Nullable<BaseError>>
  showError: (
    error: BaseError | unknown,
    tags?: Tag[],
    logError?: boolean
  ) => void
  hideError: () => void
}

export default function useErrorModal(): UseErrorModalResult {
  return {
    error: globalError,
    showError(
      _error: BaseError | unknown,
      tags: Tag[] = [],
      logError: boolean = false
    ): void {
      if (_error instanceof BaseError) {
        globalError.value = _error
      } else {
        // If we get here it means we did not handle this error properly
        globalError.value = new AppError({
          title: _error?.toString() ?? 'Error',
          originalError: _error,
          tags: ['unhandled'],
        })
      }

      globalError.value.addTags(tags)

      if (logError) {
        globalError.value.logError()
      }
    },
    hideError(): void {
      globalError.value = null
    },
  }
}
