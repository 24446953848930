<template>
  <div v-if="field" class="breed-selector" :class="getFieldWrapperClass()">
    <h2 v-if="isReadOnly" class="species-type-heading">Pet species</h2>

    <div
      class="mutt-field-wrapper mutt-field-wrapper mutt-field-wrapper--checkbox mutt-field-wrapper--radio"
    >
      <ReadonlyWidget v-if="isReadOnly" :value="speciesField.value" />

      <div
        v-if="speciesField.errors && !speciesField.value"
        class="mutt-error-wrapper mutt-error-wrapper--species"
      >
        <ErrorWidget
          v-if="!isReadOnly"
          :field="speciesField"
          :errors="speciesField.errors"
          :error-class="getErrorClass()"
        />
      </div>
    </div>

    <h2 class="breed-type-heading">Breed type</h2>

    <div
      class="mutt-field-wrapper mutt-field-wrapper mutt-field-wrapper--checkbox mutt-field-wrapper--radio"
    >
      <ReadonlyWidget v-if="isReadOnly" :value="pedigreeType" />
      <ul v-if="!isReadOnly" class="breed-type-list">
        <li
          v-for="pedigreeTypeOption in pedigreeTypeOptions"
          :key="pedigreeTypeOption.value"
          class="mutt-field-radio-item"
        >
          <input
            :id="
              generateElementId(
                `qa-pet-details-breed-type-btn-${pedigreeTypeOption.value
                  .split('_')
                  .join('')
                  .toLowerCase()}`
              )
            "
            :ref="`radio${pedigreeTypeOption.label.split(' ').join('')}`"
            v-model="pedigreeType"
            :value="pedigreeTypeOption.value"
            type="radio"
            :name="generateElementId('breed-type')"
            class="mutt-field mutt-field-radio"
            @change="selectPedigreeType"
          />
          <label
            class="mutt-label"
            :for="
              generateElementId(
                `qa-pet-details-breed-type-btn-${pedigreeTypeOption.value
                  .split('_')
                  .join('')
                  .toLowerCase()}`
              )
            "
            >{{ pedigreeTypeOption.label }}</label
          >
        </li>
      </ul>

      <div
        v-if="
          pedigreeField.errors &&
          pedigreeField.errors.length > 0 &&
          !pedigreeField.value
        "
        class="mutt-error-wrapper mutt-error-wrapper--pedigree-type"
      >
        <ErrorWidget
          v-if="!isReadOnly"
          :field="pedigreeField"
          :errors="pedigreeField.errors"
          :error-class="getErrorClass()"
        />
      </div>
    </div>

    <div class="mutt-help-container">
      <div class="mutt-help">
        <p class="notification--title">So what's the difference?</p>
        <ul class="styled-list">
          <li><em>A pedigree</em> - parents are of the same breed</li>
          <li>
            <em>A mixed breed</em> - parents are of three or more different
            breeds
          </li>
        </ul>
      </div>
    </div>

    <div v-show="showBreedSelector" class="breed-select">
      <MuttAlgolia
        v-if="breedNameField"
        ref="breedAutocomplete"
        :class="{
          'mutt-error-wrapper':
            breedField.errors && breedField.errors.length > 0 && hasNoBreedName,
        }"
        :field="breedNameField"
        :clear-on-select="true"
        :loading-message="loadingMessage"
        :zero-results-message="zeroResultsMessage"
        :readonly="isReadOnly"
        @select="setBreed"
      />

      <div
        v-if="hasNoBreedName"
        class="mutt-error-wrapper mutt-error-wrapper--breed"
      >
        <ul class="mutt-error">
          <li role="alert">Please enter a breed</li>
        </ul>
      </div>
    </div>

    <div v-if="showSizeSelector" class="size-select">
      <h2>How big is the dog?</h2>

      <div
        class="mutt-field-wrapper mutt-field-wrapper--checkbox mutt-field-wrapper--radio"
      >
        <ReadonlyWidget
          v-if="isReadOnly"
          :value="breedSizeOptions.find((o) => o.value === breedSize).label"
        />
        <ul v-if="!isReadOnly" class="size-list">
          <li
            v-for="breedSizeOption in breedSizeOptions"
            :key="breedSizeOption.value"
            class="mutt-field-radio-item"
          >
            <input
              :id="
                generateElementId(
                  `qa-pet-details-breed-size-btn-${breedSizeOption.value}`
                )
              "
              :ref="`radioBreedSize${breedSizeOption.label.split(':')[0]}`"
              v-model="breedSize"
              :value="breedSizeOption.value"
              type="radio"
              :name="generateElementId('breed-size')"
              class="mutt-field mutt-field-radio"
              @change="setMixedBreed"
            />
            <label
              class="formatted mutt-label"
              :for="
                generateElementId(
                  `qa-pet-details-breed-size-btn-${breedSizeOption.value}`
                )
              "
            >
              {{ breedSizeOption.label.split(':')[0] }}
              <span class="small-text">{{
                breedSizeOption.label.split(':')[1]
              }}</span>
            </label>
          </li>
        </ul>

        <div
          v-if="breedField.errors && !breedSize"
          class="mutt-error-wrapper mutt-error-wrapper--breed-size"
        >
          <ul class="mutt-error">
            <li role="alert">Please choose a size</li>
          </ul>
        </div>
      </div>
    </div>

    <HelpWidget :field="field" />
  </div>
</template>

<script>
import Mutt from '@mutt/forms'
import MuttVue from '@mutt/widgets-vue'

import MuttAlgolia from '@/widgets/mutt/MuttAlgolia.vue'

export default {
  name: 'MuttBreed',
  for: 'breed',
  components: {
    MuttAlgolia,
  },
  mixins: [MuttVue.mixin],
  data() {
    return {
      pedigreeType: null,

      breedField: this.field,
      breedFriendlyField: this.field.parent.getFieldByPath('breed_friendly'),
      speciesField: this.field.parent.getFieldByPath('species'),
      pedigreeField: this.field.parent.getFieldByPath('pedigree'),
      pedigreeTypeField: this.field.parent.getFieldByPath('pedigree_type'),

      breedSize: null,

      // The field populated by the autocomplete
      breedNameField: null,
      breedAutocompleteInput: null,
      showCrossBreedOption: false,
      showSizeSelector: false,
      showBreedSelector: false,
      input: '',
      // Replace the default loading message in MuttAutoComplete
      loadingMessage: 'Loading breeds...',
      // Replace the default zero results message in MuttAutoComplete
      zeroResultsMessage: `If it's not on our list, please select the
                most dominant breed.`,

      petIndex: 0,

      // Used to set the initial mixed breed radio from the saved quote values
      mixedBreedSizeMappings: {
        dog: {
          'SMALL_MONGREL_(UP_TO_10KG)': 'small',
          'MEDIUM_MONGREL_(10KG-20KG)': 'medium',
          'LARGE_MONGREL_(MORE_THAN_20KG)': 'large',
        },

        cat: {
          DOMESTIC_SHORTHAIR: 'noSize',
          MOGGY: 'noSize',
        },
      },
    }
  },

  computed: {
    backendValues() {
      const baseValues = {
        'mixedBreedSizeNames.cat.noSize.system': 'DOMESTIC_SHORTHAIR',
        'mixedBreedSizeNames.cat.noSize.type': 'CROSS_BREED',
        'mixedBreedSizeNames.dog.small.system': 'SMALL_MONGREL_(UP_TO_10KG)',
        'mixedBreedSizeNames.dog.small.type': 'MIXED_BREED_SMALL',
        'mixedBreedSizeNames.dog.medium.system': 'MEDIUM_MONGREL_(10KG-20KG)',
        'mixedBreedSizeNames.dog.medium.type': 'MIXED_BREED_MEDIUM',
        'mixedBreedSizeNames.dog.large.system':
          'LARGE_MONGREL_(MORE_THAN_20KG)',
        'mixedBreedSizeNames.dog.large.type': 'MIXED_BREED_LARGE',
        'mixedBreedSizeNames.dog.noSize.system': 'MEDIUM_MONGREL_(10KG-20KG)',
        'mixedBreedSizeNames.dog.noSize.type': 'MIXED_BREED_MEDIUM',
      }

      return this.isUk
        ? {
            ...baseValues,
            'mixedBreedSizeNames.cat.noSize.friendly': 'Mixed Breed',
            'mixedBreedSizeNames.dog.small.friendly': 'Small Size Mixed Breed',
            'mixedBreedSizeNames.dog.medium.friendly': 'Medium Mixed Breed',
            'mixedBreedSizeNames.dog.large.friendly': 'Large Size Mixed Breed',
            'mixedBreedSizeNames.dog.noSize.friendly': 'Mixed Breed',
          }
        : {
            ...baseValues,
            'mixedBreedSizeNames.cat.noSize.friendly': 'blandras',
            'mixedBreedSizeNames.dog.small.friendly': 'liten blandras', // small mixed breed
            'mixedBreedSizeNames.dog.medium.friendly': 'medelstor blandras', // medium mixed breed
            'mixedBreedSizeNames.dog.large.friendly': 'stor blandras', // larged mixed breed
            'mixedBreedSizeNames.dog.noSize.friendly': 'blandras', // Mongrel
          }
    },

    pedigreeTypeOptions() {
      if (this.showCrossBreedOption) {
        return [
          { value: 'PEDIGREE', label: 'Pedigree' },
          { value: 'CROSS_BREED', label: 'Cross Breed' },
          { value: 'MIXED_BREED', label: 'Mixed Breed' },
        ]
      } else {
        return [
          { value: 'PEDIGREE', label: 'Pedigree' },
          { value: 'MIXED_BREED', label: 'Mixed Breed' },
        ]
      }
    },

    breedSizeOptions() {
      return [
        { label: 'Small:(Up to 10KG)', value: 'small' },
        { label: 'Medium:(10KG - 20KG)', value: 'medium' },
        { label: 'Large:(More than 20KG)', value: 'large' },
      ]
    },

    isUk() {
      return this.field.parent.getFieldByPath('breed_friendly')
    },

    hasNoBreedName() {
      if (this.breedFriendlyField) {
        return !this.breedNameField?.value
      } else {
        return !this.breedField?.value.name
      }
    },
  },

  watch: {
    ['speciesField.value']() {
      this.clearBreedFieldValue()
      this.pedigreeType = null
      this.pedigreeField.value = null
      this.setFormState()
    },
  },

  created() {
    // Get the petIndex number from the field.id
    // Used in the id attributes to ensure they are unique
    this.petIndex = this.field.id.match(/\d+/)[0]

    if (
      Object.prototype.hasOwnProperty.call(
        this.field.options,
        'showCrossBreedOption'
      )
    ) {
      this.showCrossBreedOption = this.field.options.showCrossBreedOption
    }
  },

  mounted() {
    // Setup a field to bind to an autocomplete for the breed lookup
    this.breedNameField = new Mutt.fields.StringField({
      id: `${this.field.id}-breedName`,
      name: `${this.field.name}-${this.petIndex}-breedName`,
      options: this.field.options,
      parent: this.field,
    })

    if (!this.isReadOnly) {
      this.$nextTick().then(() => {
        // Store the breed autocomplete input once it has rendered so we can use it later
        this.breedAutocompleteInput =
          this.$refs.breedAutocomplete.$el.querySelector(
            '.mutt-input-wrapper-algolia > input'
          )

        if (this.breedAutocompleteInput) {
          // Becuase of how Mutt applies field options for ArrayFields, we need to dynamically switch the algolia options
          this.breedAutocompleteInput.addEventListener('focus', () => {
            this.setBreedAutocompleteFilter()
          })

          this.breedAutocompleteInput.addEventListener('change', () => {
            this.clearBreedFieldValue()
          })
        }
      })
    }

    this.setInitialPedigreeType()
    this.setInitialBreedSize()
    this.setInitialBreedName()

    // set the widget values to the form values
    this.setFormState()

    if (this.breedAutocompleteInput) {
      this.setBreedAutocompleteFilter()
    }
  },

  methods: {
    setInitialPedigreeType() {
      // Set initial state from field values
      if (this.pedigreeTypeField) {
        if (
          this.pedigreeTypeField.value === 'PEDIGREE' ||
          this.pedigreeTypeField.value === 'CROSS_BREED'
        ) {
          this.pedigreeType = this.pedigreeTypeField.value
        } else if (this.pedigreeTypeField.value) {
          this.pedigreeType = 'MIXED_BREED'
        }
      } else {
        this.pedigreeType = this.pedigreeField.value
      }
    },

    setInitialBreedSize() {
      // Set the breed size correctly for mixed breed
      if (this.pedigreeType === 'MIXED_BREED') {
        if (this.pedigreeTypeField) {
          this.breedSize =
            this.mixedBreedSizeMappings[this.speciesField.value.toLowerCase()][
              this.breedField.value
            ]
        } else {
          this.breedSize =
            this.mixedBreedSizeMappings[this.speciesField.value.toLowerCase()][
              this.breedField.value.objectID
            ]
        }
      }
    },

    setInitialBreedName() {
      if (this.breedFriendlyField) {
        this.breedNameField.value = this.breedFriendlyField.value
      } else {
        this.breedNameField.value = this.breedField.value
      }
    },

    /**
     * Set the widget form state based on the field values
     */
    async setFormState() {
      switch (this.pedigreeType) {
        case 'PEDIGREE':
        case 'CROSS_BREED':
          this.showSizeSelector = false

          // Mixed breed cat is stored as CROSS_BREED MOGGY in UK so we must treat this differently
          if (this.breedField.value !== 'MOGGY') {
            this.showBreedSelector = true

            await this.$nextTick()

            if (!this.isReadOnly && this.breedAutocompleteInput) {
              if (this.breedFriendlyField) {
                if (this.breedFriendlyField.value) {
                  this.breedAutocompleteInput.value =
                    this.breedFriendlyField.value
                } else {
                  this.breedAutocompleteInput.focus()
                }
              } else {
                if (this.breedField.value?.name) {
                  this.breedAutocompleteInput.value = this.breedField.value.name
                } else {
                  this.breedAutocompleteInput.focus()
                }
              }
            }
          }
          return

        case 'MIXED_BREED':
          this.showBreedSelector = false

          if (
            this.speciesField.value === 'CAT' ||
            this.field.options.hideDogSizeSelector === true
          ) {
            this.showSizeSelector = false

            // Set the mixed breed value to the correct preset value
            this.setMixedBreed({
              target: {
                value: 'noSize',
              },
            })
          } else {
            this.showSizeSelector = true
          }
          return

        default:
          // Pedigree Type has no value so we reset the widget
          this.showSizeSelector = false
          this.showBreedSelector = false

          if (!this.isReadOnly && this.breedAutocompleteInput) {
            await this.$nextTick()

            this.breedAutocompleteInput.value = ''
          }
          return
      }
    },

    /**
     * Set the autocomplete filter for species
     */
    setBreedAutocompleteFilter() {
      if (this.speciesField.value) {
        // Set the correct species value for the Algolia index from the locale
        let species = this.speciesField.value.toLowerCase()

        if (!this.isUk && species === 'cat') {
          species = 'katt'
        }

        if (!this.isUk && species === 'dog') {
          species = 'hund'
        }

        this.field.options.algolia.filters = [`species:${species}`]
      }
    },

    /**
     * @returns {string} the field's wrapper class
     */
    getFieldWrapperClass() {
      return 'mutt-field-wrapper mutt-field-wrapper-breed'
    },

    /**
     * @returns {string} the field class
     */
    getFieldClass() {
      return 'mutt-field mutt-field-breed-input'
    },

    /**
     * Clear all breed fields when species is selected
     */
    selectSpecies() {
      this.clearBreedFieldValue()
      this.clearPedigreeFieldValue()

      this.setFormState()
    },

    /**
     * Clears the pedigree fields
     */
    clearPedigreeFieldValue() {
      this.pedigreeType = null
      this.pedigreeField.value = null

      if (this.pedigreeTypeField) {
        this.pedigreeTypeField.value = null
      }
    },

    /**
     * Sets the pedigree fields based on selcted pedigree type
     *
     * @param {object} $event the event captured
     */
    selectPedigreeType($event) {
      // Clear the breed value as the predigree type has changed
      this.clearBreedFieldValue()

      // If there is a pedigree_type field, we need to set it to the correct value
      if (this.pedigreeTypeField) {
        switch ($event.target.value) {
          case 'PEDIGREE':
            this.pedigreeTypeField.value = 'PEDIGREE'
            this.pedigreeField.value = true
            break
          case 'CROSS_BREED':
            this.pedigreeTypeField.value = 'CROSS_BREED'
            this.pedigreeField.value = false
            break
          case 'MIXED_BREED':
            // setMixedBteed() will set the correct pedigree type value
            this.pedigreeField.value = false
            break
          default:
            break
        }
      } else {
        this.pedigreeField.value = $event.target.value
      }

      this.setFormState()
    },

    /**
     * Sets the breed from the autocomplete
     *
     * @param {object} event The event emitted from the autocomplete widget.
     * @param {object} event.value The full algolia result object that was selected.
     */
    setBreed({ value }) {
      const { iba_value: breed, name, objectID } = value
      this.breedNameField.value = name

      if (this.breedFriendlyField) {
        this.field.value = breed
        this.breedFriendlyField.value = name
      } else {
        this.field.value = {
          objectID,
          name,
        }
      }
    },

    /**
     * Set the field's value when a mixed breed is selected
     *
     * @param {object} $event Form event
     */
    setMixedBreed($event) {
      const key = $event.target.value
      const species = this.speciesField.value?.toLowerCase()

      if (!key || !species) {
        return
      }

      const objectID =
        this.backendValues[`mixedBreedSizeNames.${species}.${key}.system`]
      const name =
        this.backendValues[`mixedBreedSizeNames.${species}.${key}.friendly`]
      const type =
        this.backendValues[`mixedBreedSizeNames.${species}.${key}.type`]

      if (this.breedFriendlyField) {
        this.field.value = objectID
        this.breedFriendlyField.value = name
        this.pedigreeTypeField.value = type

        this.value = objectID

        if (this.isUk && this.speciesField.value === 'CAT') {
          this.value = 'MOGGY'
        }

        if (!this.isUk && this.speciesField.value === 'CAT') {
          this.value = {
            name: 'blandras',
            objectID: 'DOMESTIC_SHORTHAIR',
          }
        }
      } else {
        this.field.value = {
          objectID,
          name,
        }

        // Make sure the widget's value is set correctly if we are presetting values
        this.value = {
          objectID,
          name,
        }
      }
    },

    /**
     * generateElementId - Return an id adding the petIndex value to stem.
     *
     * Add the petIndex number to the stem string.
     *
     * @param {string} stem The stem of the id value that needs to be made.
     *
     * @returns {string} The generated element id
     */
    generateElementId(stem) {
      return `${stem}-pet${this.petIndex}`
    },

    /**
     * Clear the field's value
     */
    clearBreedFieldValue() {
      if (this.breedAutocompleteInput) {
        this.breedAutocompleteInput.value = ''
      }

      if (this.breedFriendlyField) {
        // We have separate fields for the breed name and breed friendly name
        this.breedField.value = ''
        this.breedFriendlyField.value = null
      } else {
        this.breedField.value = {
          name: '',
          objectID: '',
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.breed-select {
  position: relative;
}

.breed-selector :deep(.mutt-field-wrapper-autocomplete) {
  margin-bottom: 0;
}

.breed-type-heading {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 12px;
}
</style>
