import { startCase } from 'lodash-es'

import type { Coverage, CoverageOption } from '@/@types/Coverage'

/**
 * Formats a coverage options object so it's easier to use within the app.
 * The same key is used for each object, we add a coverage value (even for options
 * that don't have one by default) and make the price values a top level field.
 *
 * @param {object} originalCoverageOptions The coverage options object to format.
 * @returns {object} The formatted coverage options object.
 */
export function formatCoverageOptions(
  originalCoverageOptions: Record<string, Coverage>
): Record<string, CoverageOption> {
  const options: Record<string, CoverageOption> = {}

  if (originalCoverageOptions) {
    const coverageOptions = Object.entries(originalCoverageOptions)
    coverageOptions.forEach(([name, coverageOption]) => {
      const { price, coverage, coverage_option, ...details } = coverageOption
      const key = coverage_option || name

      // Set limit
      if (details.limits?.annual?.limit) {
        details.limit = details.limits.annual.limit as number
      } else if (details.limits?.['per-incident']?.limit) {
        details.limit = details.limits['per-incident'].limit as number
      }
      delete details.limits

      options[key] = {
        name: key,
        coverage: coverage || name,
        monthlyCost: price?.month?.amount ?? 0,
        annualCost: price?.annual?.amount ?? 0,
        firstMonthCost: price?.month1?.amount ?? 0,

        details,
      }
    })
  }

  return options
}

export const getCoverageDisplayName = (coverage: Coverage): string =>
  startCase(coverage.coverage || coverage.key)
