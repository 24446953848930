import { ApiListResponse } from '@/@types/ApiResponse'
import { Subscription } from '@/domain/Subscription'

import PaginatedParser, { PaginatedResponse } from './PaginatedParser'

export type SubscriptionJson = Subscription

export default class SubscriptionsParser {
  public static parsePaginatedResponse(
    data: ApiListResponse<SubscriptionJson>
  ): PaginatedResponse<Subscription> {
    return PaginatedParser.parsePaginatedResponse(
      data,
      SubscriptionsParser.parseMany
    )
  }

  public static parseMany(data: SubscriptionJson[]): Subscription[] {
    return data.map((quoteJson) => SubscriptionsParser.parse(quoteJson))
  }

  public static parse(data: SubscriptionJson): Subscription {
    return data
  }
}
