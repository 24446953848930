import platformClient, {
  LoginImplicitGrantOptions,
} from 'purecloud-platform-client-v2'

export default class GenesysService {
  static async genesysSubscribe(handleNotification: Function): Promise<void> {
    const usersApi = new platformClient.UsersApi()
    const notificationsApi = new platformClient.NotificationsApi()
    const users = await usersApi.getUsersMe()
    const channel = await notificationsApi.postNotificationsChannels()
    if (!channel.connectUri) {
      throw new Error('No connectUri in channel')
    }

    const webSocket = new WebSocket(channel.connectUri)
    webSocket.onmessage = (message: any): any => handleNotification(message)

    const conversationsTopic = 'v2.users.' + users.id + '.conversations'
    const body = [{ id: conversationsTopic }]

    if (!channel.id) {
      throw new Error('No channel id')
    }

    notificationsApi.putNotificationsChannelSubscriptions(channel.id, body)
  }

  static async mute(
    callId: string,
    cseParticipantId: string,
    muteState: boolean
  ): Promise<void> {
    const body = {
      muted: muteState,
    }

    const conversationsApi = new platformClient.ConversationsApi()
    conversationsApi.patchConversationsCallParticipant(
      callId,
      cseParticipantId,
      body
    )
  }

  static async endCall(callId: string, cseParticipantId: string): Promise<any> {
    const body = {
      state: 'disconnected',
    }

    const conversationsApi = new platformClient.ConversationsApi()
    conversationsApi.patchConversationsCallParticipant(
      callId,
      cseParticipantId,
      body
    )
  }

  static async answerCall(
    callId: string,
    cseParticipantId: string
  ): Promise<void> {
    const body = {
      state: 'connected',
    }

    const conversationsApi = new platformClient.ConversationsApi()
    conversationsApi.patchConversationsCallParticipant(
      callId,
      cseParticipantId,
      body
    )
  }

  static async login(options: LoginImplicitGrantOptions = {}): Promise<string> {
    const client = platformClient.ApiClient.instance
    client.setPersistSettings(true, 'genesys')
    client.setEnvironment('https://api.mypurecloud.ie')

    const grant = await client.loginImplicitGrant(
      import.meta.env.VITE_GENESYS_CLIENT_ID,
      import.meta.env.VITE_GENESYS_CALLBACK_URL,
      options
    )

    client.setAccessToken(grant.accessToken)

    return grant.accessToken
  }
}
