import * as LDClient from 'launchdarkly-js-client-sdk'
import { get } from 'lodash-es'

import type { AgencyName, InstanceName, ProductLineName } from '@/@types/Config'
import {
  loadProductLineConfig,
  loadAgencyConfig,
  loadInstanceConfig,
  loadGlobalConfig,
} from '@/config'

let ldClient: LDClient.LDClient
let providers: Provider[] = []

export interface AppConfigParams {
  email?: string
  agency?: AgencyName
  instance?: InstanceName
  providers?: Provider[]
}

export type ConfigTypeValue = Nullable<string | object | boolean | number>

export type Provider = {
  key: string
  operation: ProviderFunction
}

type ProviderContext = Record<string, any>

export type ProviderFunction = (
  val: string,
  context?: ProviderContext
) => ConfigTypeValue

const productLineConfigProviderFactory = (
  instance: InstanceName,
  agency: AgencyName
): ProviderFunction => {
  const productLineConfigs = loadProductLineConfig(instance, agency)

  return (value: string, context?: ProviderContext): ConfigTypeValue => {
    if (context?.productLine) {
      const productLineConfig =
        productLineConfigs[context.productLine as ProductLineName]

      if (!productLineConfig) {
        console.error(
          `Unknown instance, agency and product line combination (${instance}/${agency}/${context.productLine})`
        )
      }

      return get(productLineConfig, value) ?? null
    }

    return null
  }
}

const globalConfigProviderFactory = (): ProviderFunction => {
  const globalConfig = loadGlobalConfig()
  return (value: string): ConfigTypeValue => get(globalConfig, value) ?? null
}

const instanceConfigProviderFactory = (
  instance: InstanceName
): ProviderFunction => {
  const instanceConfig = loadInstanceConfig(instance)
  return (value: string): ConfigTypeValue => get(instanceConfig, value) ?? null
}

const agencyConfigProviderFactory = (
  instance: InstanceName,
  agency: AgencyName
): ProviderFunction => {
  const agencyConfig = loadAgencyConfig(instance, agency)
  return (value: string): ConfigTypeValue => get(agencyConfig, value) ?? null
}

const sessionStorageConfigProviderFactory = (): ProviderFunction => {
  let sessionStorageConfig = {}

  try {
    sessionStorageConfig = JSON.parse(
      window?.sessionStorage?.getItem?.('pio-config') || '{}'
    )
  } catch {
    console.error('Cannot parse sesssionStorage config')
  }

  return (value: string): ConfigTypeValue =>
    get(sessionStorageConfig, value) ?? null
}

const urlConfigProvider = (value: string): ConfigTypeValue => {
  if (window?.location?.search) {
    const urlParams = new URLSearchParams(window.location.search)
    const urlValue = urlParams.get(`f_${value}`)

    if (urlValue === 'true') {
      return true
    } else if (urlValue === 'false') {
      return false
    }

    return urlValue
  }

  return null
}

export const resetAppConfig = (): void => {
  providers = []
}
export const initialiseAppConfig = (params: AppConfigParams): void => {
  providers = params.providers ?? []

  providers.push({ key: 'URL', operation: urlConfigProvider })
  providers.push({
    key: 'SESSION_STORAGE',
    operation: sessionStorageConfigProviderFactory(),
  })

  if (params.instance && params.agency) {
    providers.push({
      key: 'PRODUCT_LINE',
      operation: productLineConfigProviderFactory(
        params.instance,
        params.agency
      ),
    })

    providers.push({
      key: 'AGENCY',
      operation: agencyConfigProviderFactory(params.instance, params.agency),
    })

    providers.push({
      key: 'INSTANCE',
      operation: instanceConfigProviderFactory(params.instance),
    })

    providers.push({
      key: 'GLOBAL',
      operation: globalConfigProviderFactory(),
    })
  }
}

// Create a promise and expose its "resolve" to be used outside of its scope
let resolveLaunchDarklyAndConfigInitialisedPromise: (val?: any) => void
const launchDarklyAndConfigInitialisedPromise = new Promise((resolve) => {
  resolveLaunchDarklyAndConfigInitialisedPromise = resolve
})

// Export the promise to be awaited whenever we need the LaunchDarkly Config to
// be already initialised
export const waitUntilLaunchDarklyAndConfigInitialized =
  async (): Promise<void> => {
    await launchDarklyAndConfigInitialisedPromise
  }

// Load Launch Darkly, initialise the config and resolve the promise
export const loadLaunchDarklyAndInitializeConfig = async (
  email: string,
  params: AppConfigParams
): Promise<void> => {
  const userContext: Prettify<LDClient.LDSingleKindContext> = {
    kind: 'user',
    key: email,
    email,
  }

  const organisationContext: Prettify<LDClient.LDSingleKindContext> = {
    kind: 'organisation',
    agency: params.agency,
    instance: params.instance,
    key: `organisation:${email}`,
  }

  const multiContext: Prettify<LDClient.LDMultiKindContext> = {
    kind: 'multi',
    user: userContext,
    organisation: organisationContext,
  }

  ldClient = LDClient.initialize(import.meta.env.VITE_LD_KEY!, multiContext)

  await ldClient.waitUntilReady()
  const ldProvider: Provider = {
    key: 'USER',
    operation: (value) => ldClient.variation(value) ?? null,
  }

  const providers = [...(params.providers ?? []), ldProvider]

  initialiseAppConfig({
    ...params,
    providers,
  })

  // Resolve the promise
  resolveLaunchDarklyAndConfigInitialisedPromise()
}

const callProvider = (providerKey: string, value: string): ConfigTypeValue => {
  const provider = providers.find(({ key }) => key === providerKey)

  if (provider) {
    return provider.operation(value)
  }

  return null
}

export const getLdConfig = (): any => {
  return ldClient?.allFlags() ?? {}
}

//we will use this everywhere in order to check for feature
//we might move it to a util ts file which has access
export const getConfigForFeature = (
  val: string,
  context?: ProviderContext
): ConfigTypeValue => {
  // If there is url value, return it right away, even if it's boolean.
  const urlValue = callProvider('URL', val)

  if (urlValue !== null) {
    return urlValue
  }

  // If there is session storage value, return it right away, even if it's a boolean.
  const sessionStorageValue = callProvider('SESSION_STORAGE', val)

  if (sessionStorageValue !== null) {
    return sessionStorageValue
  }

  const results: boolean[] = []

  for (const provider of providers) {
    const result = provider.operation(val, context)

    if (result !== null) {
      if (typeof result !== 'boolean') {
        return result
      }

      results.push(result)
    }
  }

  // If we got here all results are boolean, and we want to only return `true`
  // if all providers agree.
  // We check length because calling `[].every()` will always return true.
  if (results.length) {
    return results.every(Boolean)
  }

  return null
}
