const isReadPermission = (permission: string): boolean =>
  permission.split(':')[1] === 'read'
const getPermissionName = (permission: string): string =>
  permission.split(':')[0]

/**
 * Check if the user has rights for a given permission.
 *
 * The list of permissions can contain abstract permissions (like superuser,
 * etc.) so this function does more than just `[].includes()`.
 *
 * @param {string} [permission] The permission to check.
 * @param {string[]} permissions The list of permissions.
 *
 * @returns {boolean} Whether the permission is granted based on the list of permissions.
 */
export function hasPermission(
  permission: string,
  permissions: string[]
): boolean {
  // If no permission is needed then there is nothing to check so bail early.
  if (!permission) {
    return true
  }

  // all:write implies that the user has both read and write access to everything,
  // so this means any permission returns true.
  if (permissions.includes('superuser') || permissions.includes('all:write')) {
    return true
  }

  if (isReadPermission(permission) && permissions.includes('all:read')) {
    return true
  }

  // Allow if user has write access for this area and this is a read permission, as
  // write implies they should also be able to read even if they don't explicitly
  // have that permission.
  const name = getPermissionName(permission)
  if (isReadPermission(permission) && permissions.includes(`${name}:write`)) {
    return true
  }

  return permissions.includes(permission)
}

/**
 * Check if the user has at least one of the required permissions.
 *
 * @param {string[]|string|undefined} required The permissions we are checking against.
 * @param {string[]} current The users permissions.
 *
 * @returns {boolean} Whether the user has at least one of the permissions required.
 */
export function hasAnyPermission(
  required: string | string[],
  current: string[]
): boolean {
  if (!Array.isArray(required)) {
    return hasPermission(required, current)
  }

  return required.some((permission) => hasPermission(permission, current))
}

/**
 * Check if the user has all of the required permissions.
 *
 * @param {string[]|string|undefined} required The permissions we are checking against.
 * @param {string[]} current The users permissions.
 *
 * @returns {boolean} Whether the user has all of the permissions required.
 */
export function hasAllPermissions(
  required: string | string[],
  current: string[]
): boolean {
  if (!Array.isArray(required)) {
    return hasPermission(required, current)
  }

  return required.every((permission) => hasPermission(permission, current))
}
