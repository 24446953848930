import BaseError from './BaseError'
import { ErrorContext } from './ErrorContext'
import { Tag } from './Tag'

// To be used when error is from our code (NPE etc)
export default class AppError extends BaseError {
  public title: string = 'App Error'

  constructor(params: {
    title: string
    tags?: Tag[]
    context?: ErrorContext
    originalError?: unknown
    disableAutoLog?: boolean
  }) {
    if (!params.disableAutoLog) {
      params.disableAutoLog = false
    }

    super(params.title, params.originalError)
    Object.setPrototypeOf(this, AppError.prototype)

    this.tags = ['App Error', ...(params.tags ?? [])]

    this.buildContext(params.context ?? {})

    if (!params.disableAutoLog) {
      this.logError()
    } else {
      console.info(
        'AppError was not logged because disableAutoLog was true, make sure you call logError() manually'
      )
    }
  }

  private buildContext(context: ErrorContext): void {
    this.context = {
      ...this.buildInitialContext(),
      ...context,
    }
  }
}
