import type { ApiListParams } from '@/@types/ApiResponse'
import type { DeepPartial } from '@/@types/general'
import ErrorResponseFactory from '@/domain/Error/ErrorResponseFactory'
import type {
  Quote,
  CreateQuoteData,
  QuoteMtaPreviewItem,
} from '@/domain/Quote/Quote'
import type { QuoteData } from '@/domain/Quote/QuoteData'
import { CLIENT_IDENTIFIER } from '@/lib/api/ApiClient'

import ApiService from './ApiService'
import { PaginatedResponse } from './parsers/PaginatedParser'
import QuoteParser from './parsers/QuoteParser'

const QUOTES_PATH = '/quotes'

export default class QuotesService {
  public static async get(quoteId: string): Promise<Quote> {
    return ApiService.get({
      path: `${QUOTES_PATH}/${quoteId}`,
      parse: QuoteParser.parse,
    })
  }

  public static async getQuotes(
    params: {
      owner?: string
    } & ApiListParams
  ): Promise<PaginatedResponse<Quote>> {
    try {
      return await ApiService.get({
        path: QUOTES_PATH,
        params: {
          searchParams: {
            ordering: '-created_at',
            page_number: '1',
            per_page: '10',
            ...params,
          },
        },
        parse: QuoteParser.parsePaginatedResponse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error getting quotes',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }

  public static async createQuote(params: {
    account_ref: string
    data: Partial<CreateQuoteData>
    owner: string
    productline_key: string
    schema: string
    type: string
  }): Promise<Quote> {
    const { account_ref, data, owner, productline_key, schema, type } = params

    try {
      return await ApiService.post({
        path: QUOTES_PATH,
        params: {
          json: {
            account_ref: account_ref,
            data: data,
            owner: owner,
            productline_key: productline_key,
            schema: schema,
            source: CLIENT_IDENTIFIER,
            type: type,
          },
        },
        parse: QuoteParser.parse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error creating quote',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }

  public static async syncQuote(
    quoteId: string,
    params: {
      data: any
      rate?: boolean
    }
  ): Promise<Quote> {
    const { data, rate } = params

    try {
      return await ApiService.post({
        path: `${QUOTES_PATH}/${quoteId}/sync`,
        params: {
          searchParams: { rate: Boolean(rate) },
          json: data,
        },
        parse: QuoteParser.parse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error syncing quote',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }

  public static async setQuoteOwner(
    quoteId: string,
    params: {
      ownerId: string
    }
  ): Promise<void> {
    const { ownerId } = params

    try {
      await await ApiService.post({
        path: `${QUOTES_PATH}/${quoteId}/sync`,
        params: {
          json: {
            metadata: {},
            owner: ownerId,
          },
        },
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error setting quote owner',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }

  public static async rateQuote(quoteId: string): Promise<Quote> {
    try {
      return await ApiService.get({
        path: `${QUOTES_PATH}/${quoteId}/rate`,

        parse: QuoteParser.parse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error rating quote',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }

  public static async acceptQuote(quoteId: string): Promise<Quote> {
    try {
      return await ApiService.post({
        path: `${QUOTES_PATH}/${quoteId}/accept`,
        parse: QuoteParser.parse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error accepting quote',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }

  public static async reinstateQuote(quoteId: string): Promise<void> {
    try {
      await await ApiService.post({
        path: `${QUOTES_PATH}/${quoteId}/reinstate`,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error reinstating quote',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }

  public static async rejectQuote(quoteId: string): Promise<Quote> {
    try {
      return await ApiService.post({
        path: `${QUOTES_PATH}/${quoteId}/reject`,
        parse: QuoteParser.parse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error rejecting quote',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }

  public static async emailQuote(quoteId: string): Promise<void> {
    try {
      const response = await ApiService.post({
        path: `${QUOTES_PATH}/${quoteId}/email-quote`,
      })

      if (response.status !== 204) {
        const errorClass = await ErrorResponseFactory.instantiateErrorClass({
          title: 'Something bad happened while trying to send the email',
          tags: ['s-policy'],
        })

        throw errorClass
      }
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error emailing quote',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }

  public static async quoteExplain(quoteId: string): Promise<unknown> {
    try {
      return await ApiService.get({
        path: `${QUOTES_PATH}/${quoteId}/explain`,
        parse: (data: unknown) => data,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error explaining quote',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }

  public static async createQuickQuote(params: {
    agency: string
    data: DeepPartial<QuoteData>
    productLineKey: string
    owner?: string
  }): Promise<Quote> {
    const { agency, data, productLineKey, owner } = params

    try {
      return await ApiService.post({
        path: '/quick-quote',
        params: {
          searchParams: {
            token_type: 'basket',
          },
          json: {
            agency,
            data,
            owner,
            product_line_key: productLineKey,
            source: CLIENT_IDENTIFIER,
          },
        },
        parse: QuoteParser.parseQuickQuote,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error creating quick quote',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }

  public static async searchQuotes(
    params: {
      q?: string
    } & ApiListParams
  ): Promise<PaginatedResponse<Quote>> {
    try {
      return await ApiService.get({
        path: `${QUOTES_PATH}/action/search`,
        params: {
          searchParams: {
            q: '',
            ...params,
          },
        },
        parse: QuoteParser.parsePaginatedResponse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error searching quotes',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }

  public static async getMtaAdjustmentAmount(
    quoteId: string
  ): Promise<Record<string, QuoteMtaPreviewItem>> {
    try {
      return await ApiService.get({
        path: `${QUOTES_PATH}/${quoteId}/preview-mta`,
        parse: (data: unknown) => data as any, // TODO: use a proper parser
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error getting MTA adjustment amount',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }
}
