import { ElNotification } from 'element-plus'

const showNotification = (params: {
  title?: string
  message: string
  showClose?: boolean
  type: 'success' | 'warning' | 'error' | 'info'
  duration?: number
}): void => {
  const {
    title,
    type = 'success',
    message,
    showClose = true,
    duration,
  } = params

  switch (type) {
    case 'success':
      ElNotification.success({ title, message, showClose, duration })
      break
    case 'warning':
      ElNotification.warning({ title, message, showClose, duration })
      break
    case 'error':
      ElNotification.error({ title, message, showClose, duration })
      break
    case 'info':
      ElNotification.info({ title, message, showClose, duration })
      break
    default:
      ElNotification.info({ title, message, showClose, duration })
      break
  }
}

export default showNotification
