import MuttJsonPatch from '@mutt/extension-json-patch'
import Mutt from '@mutt/forms'
import MuttAddressLookup from '@mutt/widget-addresslookup'
import MuttDatetime from '@mutt/widget-datetime'
import MuttPersonnummer from '@mutt/widget-personnummer'
import { MuttTokenizer, MuttDatetimeinput, MuttPca } from '@mutt/widgets-bbm'
import MuttVue from '@mutt/widgets-vue'

import {
  CustomSerializer,
  DateTimeSerializer,
  DateSerializer,
} from '@/legacy/lib/Serializers'
import MuttAge from '@/widgets/mutt/MuttAge.vue'
import MuttAlgolia from '@/widgets/mutt/MuttAlgolia.vue'
import MuttBreed from '@/widgets/mutt/MuttBreedWidget.vue'
import MuttCancellationDate from '@/widgets/mutt/MuttCancellationDate.vue'
import MuttCurrency from '@/widgets/mutt/MuttCurrency.vue'
import MuttDateinput from '@/widgets/mutt/MuttDateinput.vue'
import MuttInception from '@/widgets/mutt/MuttInceptionWidget.vue'
import MuttPetAge from '@/widgets/mutt/MuttPetAge.vue'
import MuttReadonlyDate from '@/widgets/mutt/MuttReadonlyDate.vue'

// This function encapsulates usage of plugins without type definitions
// to keep main.ts free of use of <any> casts
// TODO: ensure anything used below has a .d.ts describing it for typescript
const MuttPlugin = {
  install(app) {
    Mutt.use(MuttJsonPatch)
    Mutt.use({
      install: () => {
        return {
          serializers: {
            custom: CustomSerializer,
            date: DateSerializer,
            datetime: DateTimeSerializer,
          },
        }
      },
    })

    app.use(MuttVue, {
      plugins: {
        MuttTokenizer,
        MuttPca,
        MuttCancellationDate,
        MuttDateinput,
        MuttDatetimeinput,
        MuttPersonnummer,
        MuttAddressLookup,
        MuttReadonlyDate,
        MuttBreed,
        MuttInception,
        MuttAutocomplete: MuttAlgolia,
        MuttCurrency,
        MuttDatetime,
        MuttAge,
        MuttPetAge,
      },
    })
  },
}

export default MuttPlugin
