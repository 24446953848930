import stringFormat from 'string-format'

export const hints = {
  CHHT001:
    'If {customer_first_name} has made a claim in a waiting period, or if the claim is for a date before their policy started, please check to see if they have upgraded or downgraded their policy.\n\nIf you cannot find proof that {customer_first_name} had a different policy with us, and that they have upgraded or downgraded, you will need to ask whether they have proof of continuous cover from another insurer.\n\nWe cannot cover conditions that first started before the customer took out an insurance policy with us.',
  CHHT002: 'Choose the appropriate pet',
  CHHT003:
    'Check to see if {customer_first_name} has claimed before and whether the condition they are claiming for is related to something they have claimed for in the past.',
  CHHT004:
    "The date the pet first received treatment, medication or advice for the customer's claim.",
  CHHT005:
    "Unless {pet_name} is under 2, we require 24 months of medical history and an itemised treatment invoice.\n\nIf {pet_name} has visited an out of hours vet or had a referral, there may be more than one itemised invoice needed to handle their claim.\n\nIf {customer_first_name} rescued or rehomed {pet_name}, they may not have access to more than 24 months of the animal's history.",
  CHHT006:
    'Depending on what {customer_first_name} is claiming for, you may need different types of evidence.\n\nIf {customer_first_name} has not claimed for {pet_name} before, we require 24 of medical history (unless the animal is under 2).\n\nIf the claim is for Vet Fees of a Specialist, you will need to ask for the treatment invoice from the business that gave the treatment.\n\nIf {pet_name} has passed away, and their value is more than £4,000, we will require proof of purchase.\n\nIf the claim is for euthanasia, cremation or burial, we will need to see the receipt for the service.',
  CHHT007:
    "'Case' is the word we use to describe a customer's claim, when a customer claims they may need to claim against multiple benefits on their policy.\n\nFor example, a case could have several losses where we would subtract an amount from their vet fee, farewell and passing away cover.",
  CHHT008:
    'If {pet_name} passed away, check to see if {customer_first_name} has farewell or passing away cover on their policy.\n\nIf {customer_first_name} has not claimed for their farewell or death benefit, get in touch with them and let them know what else they can claim for.',
  CHHT009:
    'If a payment needs to be made to more than one vet, you will need to create a new loss for each payee.\n\nFor example, if the claim for {pet_name} is for an out of hours consultation followed up by daytime treatment at a different practice, you will need to create one loss for the out of hours consultation and one loss for the daytime consultation.',
}

/**
 * Returns the formated hint text by hint key
 *
 * @param {string} hintKey The hint key. Example 'CHHT002'
 * @param {object} data Object containing the "variables" to be replaced in the hint text. Example { customer_first_name: 'John' }
 * @returns {string} Thint text with replaced string "variables"
 */
export function getHintText(
  hintKey: keyof typeof hints,
  data: Array<{ [k: string]: any } | string>
): string {
  return stringFormat(hints[hintKey], data)
}
