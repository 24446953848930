<template>
  <div
    v-if="isCallBarVisible"
    class="call-component"
    :class="containerClasses"
    data-qa-locator="call-component"
  >
    <div class="call-component__info">
      <div v-if="!isDisconnected" class="call-component__icon" />

      <SimpleText class="call-component__status" size="nano" type="primary">
        {{ phoneState }}
      </SimpleText>
    </div>

    <div class="call-component__content">
      <div v-if="!isDisconnected" class="call-component__customer">
        <div>
          <ElSkeleton :rows="2" animated :loading="!customerPhoneNumber">
            <template #template>
              <ElSkeletonItem variant="text" class="customer-name-skeleton" />
              <ElSkeletonItem variant="text" class="customer-number-skeleton" />
            </template>

            <template #default>
              <div class="call-component__customer__info">
                <SimpleText
                  v-if="customerName && !isMultipleCustomers"
                  size="nano"
                  type="primary"
                  bold
                >
                  {{ customerName }}
                </SimpleText>

                <div v-if="customerName && !isMultipleCustomers">|</div>

                <SimpleText size="nano" type="primary" bold>
                  {{ customerPhoneNumber }}
                </SimpleText>
              </div>
            </template>
          </ElSkeleton>
        </div>
      </div>
    </div>

    <div v-if="!isDisconnected" class="call-component__right-content">
      <SimpleText
        v-if="numberOfMatchesLabel"
        size="nano"
        type="primary"
        class="call-component__right-content__label"
      >
        {{ numberOfMatchesLabel }}
      </SimpleText>

      <ElButton
        v-if="searchLabelAndLink"
        role="link"
        tag="router-link"
        size="small"
        :to="searchLabelAndLink.routeParams"
        @click="trackClick"
      >
        {{ searchLabelAndLink.label }}
      </ElButton>

      <div
        v-if="isPhoneIntegrationCallControlsVisible"
        class="call-component__actions"
        data-qa-locator="call-component-actions"
      >
        <ElButton
          v-if="isActive && !isRinging"
          round
          small
          class="call-controll-button"
          :aria-label="isMuted ? 'Unmute call' : 'Mute call'"
          @click="toggleMute"
        >
          <ElIcon :color="muteColor" size="12px">
            <Microphone v-if="!isMuted" />
            <Mute v-if="isMuted" />
          </ElIcon>
        </ElButton>
        <ElButton
          v-if="isRinging && isPhoneIntegrationCallControlsVisible"
          type="success"
          round
          small
          class="call-controll-button"
          aria-label="Answer call"
          @click="phoneStore.answerCall"
        >
          <ElIcon size="12px">
            <PhoneFilled />
          </ElIcon>
        </ElButton>
        <ElButton
          v-if="
            (isActive || isRinging) && isPhoneIntegrationCallControlsVisible
          "
          round
          small
          aria-label="End call"
          class="call-controll-button end-call-button"
          type="danger"
          @click="phoneStore.endCall"
        >
          <ElIcon size="12px">
            <PhoneFilled />
          </ElIcon>
        </ElButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Microphone, Mute, PhoneFilled } from '@element-plus/icons-vue'
import { storeToRefs } from 'pinia'
import { computed, ref, watchEffect } from 'vue'

import { getConfigForFeature } from '@/lib/appConfig'
import Logger from '@/lib/logger'
import { usePhoneIntegrationStore } from '@/lib/store/phone/usePhoneIntegration'
import SimpleText from '@/patterns/SimpleText'

const phoneStore = usePhoneIntegrationStore()
const isMuted = ref(false)

const {
  isActive,
  isDisconnected,
  isRinging,
  customerPhoneNumber,
  customerName,
  customersIds,
  isMultipleCustomers,
} = storeToRefs(phoneStore)

const phoneState = computed(() => {
  if (isRinging.value) {
    return 'Incoming call'
  }

  if (isMuted.value) {
    return 'On hold'
  }

  if (isActive.value) {
    return 'Call in progress'
  }

  return undefined
})

const isPhoneIntegrationEnabled = Boolean(
  getConfigForFeature('phoneIntegrationEnabled')
)

const isPhoneIntegrationCallControlsVisible = Boolean(
  getConfigForFeature('phoneIntegrationShowActions')
)

const muteColor = computed(() => (isMuted.value ? 'var(--mp-error)' : 'black'))

const searchLabelAndLink = computed(() => {
  if (!customerPhoneNumber.value || !customersIds.value.length) {
    return null
  }

  if (isMultipleCustomers.value) {
    return {
      routeParams: {
        name: 'customer-list',
        query: {
          q: customerPhoneNumber.value,
        },
      },
      label: 'Search customers',
    }
  }

  return {
    routeParams: {
      name: 'customer-view',
      params: {
        uuid: customersIds.value[0],
        section: 'pets',
      },
    },
    label: 'View customer',
  }
})

const numberOfMatchesLabel = computed(() => {
  if (customersIds.value.length === 0) {
    return 'No matches.'
  }

  if (isMultipleCustomers.value) {
    return 'Multiple matches found.'
  }

  return '1 match'
})

const isCallBarVisible = computed(
  () => (isActive.value || isRinging.value) && isPhoneIntegrationEnabled
)

watchEffect(() => {
  if (isCallBarVisible.value) {
    document.body.classList.add('call-bar-visible')
  } else {
    document.body.classList.remove('call-bar-visible')
  }
})

const trackClick = () => {
  Logger.info('Genesys: Clicked on search customers or view customer button')
}

const toggleMute = () => {
  isMuted.value = !isMuted.value
  phoneStore.muteCall(isMuted.value)
}

const containerClasses = computed(() => ({
  'call-component__ended': isDisconnected.value && !isRinging.value,
  'call-component__ringing': isRinging.value,
}))
</script>

<style lang="scss" scoped>
.call-component {
  background-color: var(--el-menu-bg-color);
  border-top: solid 4px var(--mp-success);
  position: sticky;
  bottom: 0;
  left: 0;
  transition: all ease-in-out 0.2s;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  height: 28px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  padding: 0 20px 0 80px;
  z-index: 2;

  .call-controll-button {
    box-sizing: border-box;
    padding: var(--app-spacing-1);
    height: auto;
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__content {
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__right-content {
    display: flex;
    gap: 10px;
    align-items: baseline;
    justify-content: flex-end;

    &__label {
      color: var(--mp-body-copy);
    }
  }

  &__status {
    color: var(--mp-body-copy);
  }

  &__ended {
    grid-template-columns: 1fr;
    gap: 0;
    border-top: solid 4px var(--mp-error);
    text-align: center;
  }

  &__ringing {
    border-top: solid 4px var(--mp-warning);
  }

  &__icon {
    width: 20px;

    &::before {
      display: flex;
      border: 0;
      width: 6px;
      height: 6px;
      align-items: center;
      justify-content: center;
      content: '';
      font-size: 1em;
      border-radius: 50%;
      background: url('@/assets/icons/phone-outgoing.svg') center center
        no-repeat;
      background-color: var(--mp-success);
      background-size: 1.5em;
      padding: 0.5em;
    }
  }

  &__customer {
    display: flex;
    gap: 3em;
    align-items: center;

    &__info {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  &__ringing &__icon::before {
    background-color: var(--mp-warning);
    background-image: url('@/assets/icons/phone-incoming.svg');
  }

  .end-call-button i {
    rotate: 135deg;
  }
}
</style>
