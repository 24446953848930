<template>
  <div v-if="field" ref="dropdownMenu" :class="getFieldWrapperClass()">
    <label-widget :field="field" :field-id="getFieldId()" />

    <div>
      <span ref="algoliaWrapper" class="mutt-input-wrapper-algolia">
        <readonly-widget
          v-if="isReadOnly"
          :field="field"
          :copyable="isCopyable"
          :value="stringFormat(field.options.algolia.format, field.value)"
        />

        <input
          v-if="!isReadOnly"
          :id="getFieldId()"
          ref="inputField"
          autocomplete="off"
          autocorrect="off"
          type="text"
          class="mutt-field mutt-field-algolia mutt-field-text mutt-field-text--large"
          name="searchTerm"
          :placeholder="placeholderStr"
          :maxlength="maxSearchLength"
          @blur="checkCallback"
          @keydown.down="next"
          @keydown.up="prev"
          @input="throttledSearch"
          @keydown.enter.prevent="select"
          @focus="hideZeroResultsError = !hideZeroResultsError"
        />
      </span>
      <p v-if="displayInitialValue && !isReadOnly">
        {{ displayValue }}
      </p>
    </div>
    <div v-if="loading" class="loading" :style="algoliaInputWidth">
      <p v-if="loadingMessage">{{ loadingMessage }}</p>
      <p v-else>{{ $t('Loading...') }}</p>
    </div>
    <div
      v-if="!lock && results && results.hits.length > 0"
      class="mutt-dropdown-autocomplete"
      :style="algoliaInputWidth"
    >
      <ul ref="list" class="mutt-dropdown-autocomplete__list">
        <template v-for="(result, index) in results.hits">
          <li
            v-if="field.options.algolia.listItemTemplate"
            ref="items"
            class="mutt-dropdown-autocomplete__listitem"
            @click="select(index)"
            v-html="
              stringFormat(field.options.algolia.listItemTemplate, result)
            "
          />
          <li
            v-else
            ref="items"
            class="mutt-dropdown-autocomplete__listitem"
            @click="select(index)"
          >
            {{ stringFormat(field.options.algolia.format, result) }}
          </li>
        </template>
      </ul>
    </div>
    <div
      v-else-if="
        !loading && hasPlaceholder && results && results.hits.length === 0
      "
      class="mutt-dropdown-autocomplete--error"
      :style="algoliaInputWidth"
    >
      <ul ref="list" class="mutt-dropdown-autocomplete__list--error">
        <li
          v-show="
            !hideErrorOnBlur || (hideErrorOnBlur && !hideZeroResultsError)
          "
          class="bold mutt-dropdown-autocomplete__listitem--error"
        >
          {{ $t(`We can't find`) }} '{{ displayValue }}'.<br />
          <span v-if="zeroResultsMessage">{{ zeroResultsMessage }}</span>
          <span v-else>
            {{ $t('Sorry to ask, but have you checked the spelling?') }}
          </span>
        </li>
      </ul>
    </div>

    <help-widget :field="field" />

    <error-widget
      v-for="objField in field.object"
      v-if="!isReadOnly"
      :key="`autocomplete-error-${objField.id}`"
      :field="objField"
      :errors="objField.errors"
      :error-class="getErrorClass()"
    />
  </div>
</template>

<script>
/*
  Mutt auto-complete
  Widget with autocomplete lookup to algolia.

  Usage - Set widget name to "autocomplete"
  Requires - "algolia" config object in field options, must include
            a API id, API key, Index.

TODO: Errors are not great currently... (linked ot mutt and object validation)

*/
import MuttVue from '@mutt/widgets-vue'
import algoliasearch from 'algoliasearch'
import { throttle } from 'lodash-es'
import stringFormat from 'string-format'

export default {
  name: 'MuttAutocomplete',
  for: 'autocomplete',
  mixins: [MuttVue.mixin],
  props: {
    loadingMessage: {
      type: String,
      required: false,
      default: null,
    },
    zeroResultsMessage: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Number of milliseconds the request will be throttled by
     * The request will not be made any more frequent than throttleWaitTime ms
     */
    throttleWaitTime: {
      type: Number,
      default: 750,
    },
    /**
     * Minimum number of characters that need to be typed before search is triggered
     */
    minSearchableLength: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      value: null,
      errors: null,
      loading: false,
      results: null,
      index: -1,
      selected: false,
      lock: false,
      clearOnSelect: null,
      displayValue: null,
      displayInitialValue: false,
      listScrollTop: 0, // To store scroll position in the results list
      hideErrorOnBlur: false,
      hideZeroResultsError: false,
      throttleTime: 0,
      throttledSearch: null,
      searchLength: 0,
      maxSearchLength: 511,
    }
  },
  computed: {
    placeholderStr() {
      if (this.field.options.algolia.hasOwnProperty('placeholder')) {
        return this.$t(this.field.options.algolia.placeholder)
      }
    },

    hasPlaceholder() {
      if (!this.displayValue) {
        return false
      }

      if (!this.field.options.algolia.hasOwnProperty('placeholder')) {
        return false
      }

      if (this.results !== null) {
        if (this.results.hits.length === 0) {
          return true
        }
      }

      return false
    },

    algoliaInputWidth() {
      const inputWrapperWidth = this.$refs.algoliaWrapper.getBoundingClientRect()
        .width
      return `width: ${inputWrapperWidth}px`
    },
  },

  watch: {
    throttleTime: {
      handler(newValue) {
        this.throttledSearch = throttle(this.search, newValue)
      },
      immediate: true,
    },
  },
  mounted() {
    if (!this.field.options.algolia) {
      throw new Error(
        `Field "${this.field.name}" does not specify an Algolia config!`
      )
    }

    const algoliaConfig = this.field.options.algolia

    if (!algoliaConfig.id || !algoliaConfig.key) {
      throw new Error(
        `Field "${this.field.name}" does not specify an Algolia id/key!`
      )
    }

    this.algoliaClient = algoliasearch(algoliaConfig.id, algoliaConfig.key)

    if (!algoliaConfig.index) {
      throw new Error(
        `Field "${this.field.name}" does not specify an Algolia index!`
      )
    }

    this.algoliaIndexClient = this.algoliaClient.initIndex(algoliaConfig.index)

    this.clearOnSelect = this.field.options.clearOnSelect

    if (this.field.options.hasOwnProperty('displayInitialValue')) {
      this.displayInitialValue = this.field.options.displayInitialValue
    }

    if (this.field.options.algolia.hasOwnProperty('hideErrorOnBlur')) {
      this.hideErrorOnBlur = this.field.options.algolia.hideErrorOnBlur
    }

    this.throttleTime = this.field.options.throttleWaitTime
      ? this.field.options.throttleWaitTime
      : this.throttleWaitTime
    this.searchLength = this.field.options.minSearchableLength
      ? this.field.options.minSearchableLength
      : this.minSearchableLength

    // can't allow anything over 512 as it kills algolia
    if (
      this.field.options.maxSearchableLength &&
      this.field.options.maxSearchableLength <= this.maxSearchLength
    ) {
      this.maxSearchLength = this.field.options.maxSearchableLength
    }

    this.$nextTick().then(() => {
      if (this.field.value) {
        // NOTE: This isn't quite right but there is no
        // real way to know if an object is 'empty' other
        // that seeing if there is anything in the child values
        const hasSomeValue = Object.values(this.field.value).find((value) => {
          return value !== null
        })

        if (!hasSomeValue) {
          return
        }

        this.displayValue = this.formatValue(this.field.value)

        if (!this.isReadOnly) {
          this.$refs.inputField.value = this.displayValue
        }
      }
    })
  },

  created() {
    document.addEventListener('click', this.documentClick)
    // Initialise i18n integration. No-op if not present
    if (!this.$t) {
      this.$t = (str) => str
    }
  },

  destroyed() {
    document.removeEventListener('click', this.documentClick)
  },

  methods: {
    getFieldWrapperClass() {
      return 'mutt-field-wrapper mutt-field-wrapper-autocomplete'
    },

    documentClick(e) {
      const el = this.$refs.dropdownMenu
      const target = e.target
      if (el !== target && !el.contains(target)) {
        this.lock = true
      }
    },

    async search(event) {
      this.$emit('search')

      this.hideZeroResultsError = false
      this.displayValue = this.$refs.inputField.value
      this.lock = false

      if (!this.displayValue || this.displayValue.length < this.searchLength) {
        // Don't search empty strings (it causes algolia to return
        // the whole show)
        // search only if minimum characters required are typed
        this.results = null
        return
      }

      // algolia doesn't like strings over 512 bytes, enforce a max length
      if (this.displayValue.length > this.maxSearchLength) {
        console.warn(
          `Search value over ${this.maxSearchLength} bytes, truncating input`
        )
        this.displayValue = this.displayValue.slice(0, this.maxSearchLength)
      }

      const algoliaConfig = this.field.options.algolia

      let attributes = ['*']
      if (algoliaConfig.attributes) {
        attributes = algoliaConfig.attributes
      }

      let filters = []
      if (algoliaConfig.filters) {
        filters = algoliaConfig.filters
      }

      this.loading = true
      this.lock = true

      try {
        const results = await this.algoliaIndexClient.search(
          this.displayValue,
          {
            attributesToRetrieve: attributes,
            facetFilters: filters,
          }
        )

        this.results = results
        this.index = -1
        this.loading = false
        this.lock = false
      } catch (err) {
        console.debug('Algolia Payload', {
          displayValue: this.displayValue,
          attributesToRetrieve: attributes,
          facetFilters: filters,
        })
        throw new Error(`Algolia Plugin Error -> ${err}`)
      }
    },

    select(index) {
      // set index from click event but not if keyboard event
      if (typeof index !== 'object') {
        this.index = index
      }

      if (this.index > -1 && this.results.hits.length > 0) {
        const result = this.results.hits[this.index]

        this.displayValue = this.formatValue(result)
        this.$refs.inputField.value = this.formatValue(result)
        this.value = result
        this.field.value = result
        this.lock = true
        this.displayInitialValue = false

        this.$emit('select', this.value)
        this.submitCallback()

        if (this.clearOnSelect) {
          this.displayValue = null
          this.$refs.inputField.value = null
        }
      }
    },

    next() {
      if (this.$refs['items']) {
        const count = this.$refs['items'].length
        this.goto(
          this.index < count - 1 ? this.index + 1 : count ? 0 : -1,
          'down'
        )
      }
    },

    prev() {
      if (this.$refs['items']) {
        const count = this.$refs['items'].length
        const pos = this.index - 1

        this.goto(this.selected && pos !== -1 ? pos : count - 1, 'up')
      }
    },

    /**
     * Check if a list item is within the list's visible area
     *
     * @param {number} listItemIndex The list item index
     * @param {number} listItemHeight The CSS height of the list item
     *
     * @returns {boolean}
     */
    isListItemVisible(listItemIndex, listItemHeight) {
      const listHeight = this.$refs['list'].clientHeight
      const listItemStart = listItemIndex * listItemHeight
      const listItemEnd = listItemStart + listItemHeight

      if (
        listItemStart >= this.listScrollTop &&
        listItemEnd <= this.listScrollTop + listHeight
      ) {
        return true
      }
    },

    /**
     * Highlight the active list item and make sure it's visible by
     * scrolling the list up and down when needed
     *
     * @param {number} i The list item index
     * @param {string} direction The direction being moved within the list
     */
    goto(i, direction) {
      if (this.selected && this.$refs['items'][this.index]) {
        this.$refs['items'][this.index].classList.remove('active-item')
      }

      this.index = i

      if (i > -1 && this.$refs['items'].length > 0) {
        const list = this.$refs['list']
        const listItem = this.$refs['items'][i]

        listItem.classList.add('active-item')

        // TODO: Do I need to show the selected text in my input?
        this.selected = true

        // Scroll the list when necessary in order to keep the active
        // list item comfortably in view

        if (i === 0) {
          this.listScrollTop = list.scrollTop = 0
          return
        }

        const listHeight = list.clientHeight
        const listItemHeight = listItem.clientHeight
        const listItemOffsetTop = listItem.offsetTop
        // The count of fully visible list items will vary according
        // to the CSS height of the list
        const listItemsVisible = Math.floor(listHeight / listItemHeight)
        // Set the visible offset to be applied to the list scroll
        // position - this is relative to how many list items are
        // visible
        const visibleOffset = listItemsVisible - 2

        if (direction === 'down') {
          // Check the visibility of the list item 2 below the
          // active item. If it's not fully visible then scroll the
          // list down
          if (!this.isListItemVisible(i + 2, listItemHeight)) {
            // Scroll the list down
            this.listScrollTop = list.scrollTop =
              listItemOffsetTop - listItemHeight * visibleOffset
          }
        } else {
          // Check the visibility of the list item 2 above the
          // active item. If it's not fully visible then scroll the
          // list up
          if (!this.isListItemVisible(i - 2, listItemHeight)) {
            // Scroll the list up
            this.listScrollTop = list.scrollTop =
              listItemOffsetTop - listItemHeight
          }
        }
      }
    },

    setValue(value) {
      this.value = value

      if (this.value) {
        this.displayValue = this.stringFormat(
          this.field.options.algolia.format,
          this.value
        )
      }
    },

    formatValue(value) {
      const { algolia } = this.field.options

      // Skip formatting if there is no format defintion or the value to be
      // formatted is a string. The value passed into the funciton may be a
      // object that needs to be formatted into a string or an already formatted
      // version that can just be returned as is.
      const requiresFormatting =
        typeof value !== 'string' && algolia && algolia.format

      return requiresFormatting
        ? this.stringFormat(algolia.format, value)
        : value
    },

    checkCallback() {
      this.hideZeroResultsError = true

      if (
        this.field.options.hasOwnProperty('allowFreetype') &&
        this.field.options.allowFreetype
      ) {
        this.submitCallback()
      }
    },

    submitCallback() {
      let hits = null

      if (this.results && this.results.hits) {
        hits = this.results.hits
      }

      if (this.field.validate()) {
        this.$emit('callback', {
          key: this.field.name,
          value: this.field.value,
          displayValue: this.displayValue,
          hits: hits,
          action: 'submit',
          validated: true,
        })
      } else {
        this.$emit('callback', {
          key: this.field.name,
          value: this.field.value,
          displayValue: this.displayValue,
          hits: hits,
          action: 'submit',
          validated: false,
        })
      }
    },

    focus() {
      this.$nextTick().then(() => {
        this.$refs.inputField.focus()
      })
    },

    stringFormat,
  },
}
</script>
