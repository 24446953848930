import { cloneDeep } from 'lodash-es'

import { BasketPetChange } from '@/@types/Basket'
import { AccountSummaryPetHydrated } from '@/domain/Account/AccountSummary'
import { petAgeFromDateOfBirth } from '@/lib/utils/petAge'

export default class BasketDataAdapter {
  public static augmentAccountSummaryPetFromBasketChange(params: {
    change: Maybe<BasketPetChange>
    originalPet: AccountSummaryPetHydrated
  }): AccountSummaryPetHydrated {
    const { change, originalPet } = params

    if (!change) {
      return originalPet
    }

    const changedHydratedPet = cloneDeep(originalPet)

    if (change.data['breed/value']) {
      changedHydratedPet.breed = change.data['breed/value']
    }

    if (change.data['breed/label']) {
      changedHydratedPet.breedLabel = change.data['breed/label']
    }

    if (change.data['breed/pedigree_type']) {
      if (change.data['breed/pedigree_type'] === 'PEDIGREE') {
        changedHydratedPet.pedigreeType = 'pedigree'
      } else if (change.data['breed/pedigree_type'] === 'MIXED_BREED') {
        changedHydratedPet.pedigreeType = 'mixed'
      } else if (change.data['breed/pedigree_type'] === 'CROSS_BREED') {
        changedHydratedPet.pedigreeType = 'cross'
      }
    }

    if (change.data['date_of_birth']) {
      changedHydratedPet.dateOfBirth = change.data['date_of_birth']
      changedHydratedPet.age = petAgeFromDateOfBirth(
        change.data['date_of_birth']
      )
    }

    if (change.data.name) {
      changedHydratedPet.name = change.data.name
    }

    if (change.data.species) {
      if (change.data.species === 'DOG') {
        changedHydratedPet.species = 'dog'
      } else if (change.data.species === 'CAT') {
        changedHydratedPet.species = 'cat'
      } else {
        throw new Error(
          'Invalid species value in basket change: ' + change.data.species
        )
      }
    }

    if (change.data.gender) {
      if (change.data.gender === 'MALE') {
        changedHydratedPet.gender = 'male'
      } else if (change.data.gender === 'FEMALE') {
        changedHydratedPet.gender = 'female'
      } else {
        throw new Error(
          'Invalid gender value in basket change: ' + change.data.gender
        )
      }
    }

    if (change.data.postcode) {
      changedHydratedPet.postcode = change.data.postcode
    }

    if (change.data.spayed_neutered || change.data.spayed_neutered === false) {
      changedHydratedPet.spayedNeutered = change.data.spayed_neutered
    }

    if (change.data.state) {
      changedHydratedPet.state = change.data.state
    }

    if (change.data.value || change.data.value === 0) {
      changedHydratedPet.value = change.data.value
    }

    if (change.data.registration_number) {
      changedHydratedPet.registrationNumber = change.data.registration_number
    }

    return changedHydratedPet
  }
}
