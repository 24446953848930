/* eslint-disable @typescript-eslint/explicit-function-return-type */

import type { RouteLocationNormalized } from 'vue-router'

export default [
  {
    name: 'basket-view',
    path: '/baskets/:basket',
    component: () => import('@/views/baskets/OrderView.vue'),
    props: (to: RouteLocationNormalized) => ({
      basketId: to.params.basket,
    }),
    meta: {
      title: 'Checkout Summary',
      legacy: true,
    },
  },
  {
    name: 'basket-purchase',
    path: '/baskets/:basket/purchase',
    component: () =>
      import('@/views/baskets/SubscriptionChangePreviewLegacy.vue'),
    props: (to: RouteLocationNormalized) => ({
      basketId: to.params.basket,
    }),
    meta: {
      title: 'Subscription Preview',
      legacy: true,
    },
  },
]
