import { xor } from 'lodash-es'

export const isNotNullOrUndefined = <T extends Object>(
  input: Maybe<Nullable<T>>
): input is T => {
  return input != null
}

export const areArraysEqual = (...arrays: Array<any[]>): boolean =>
  !xor(...arrays).length
