<template>
  <div
    v-if="field"
    ref="dropdownMenu"
    :class="getFieldWrapperClass()"
    :data-qa-locator="qaLocator"
  >
    <LabelWidget
      :field="field"
      :field-id="getFieldId()"
      :data-qa-locator="qaLocator ? `${qaLocator}-label` : null"
    />

    <div>
      <span ref="algoliaWrapper" class="mutt-input-wrapper-algolia">
        <ReadonlyWidget
          v-if="isReadOnly"
          :value="formatValue(field.value)"
          :data-qa-locator="qaLocator ? `${qaLocator}-readonly` : null"
        />

        <!--
          The input below does actually have a label (see `label-widget` above),
          but the eslint a11y rule doesn't see it as it's not very explicit, so
          we can disable this rule.
        -->
        <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
        <input
          v-if="!isReadOnly"
          :id="getFieldId()"
          ref="inputField"
          autocomplete="off"
          autocorrect="off"
          type="text"
          class="mutt-field mutt-field-algolia mutt-field-text mutt-field-text--large"
          name="searchTerm"
          :placeholder="placeholderStr"
          :data-qa-locator="qaLocator ? `${qaLocator}-input` : null"
          @blur="checkCallback"
          @keydown.down="next"
          @keydown.up="prev"
          @input="throttledSearch"
          @keydown.enter.prevent="select"
          @focus="hideZeroResultsError = !hideZeroResultsError"
        />
      </span>
      <p v-if="displayInitialValue && !isReadOnly">
        {{ displayValue }}
      </p>
    </div>
    <div
      v-if="loading"
      class="loading"
      :style="algoliaInputWidth"
      :data-qa-locator="qaLocator ? `${qaLocator}-loading` : null"
    >
      <p v-if="loadingMessage">{{ loadingMessage }}</p>
      <p v-else>{{ $t('Loading...') }}</p>
    </div>
    <div
      v-if="!lock && results && (results.hits.length > 0 || showLastItem)"
      class="mutt-dropdown-autocomplete"
      :style="algoliaInputWidth"
      :data-qa-locator="qaLocator ? `${qaLocator}-results` : null"
    >
      <ul ref="list" class="mutt-dropdown-autocomplete__list">
        <template v-for="(result, idx) in results.hits">
          <li
            v-if="field.options.algolia.listItemTemplate"
            ref="items"
            :key="`${result.objectID}-item-template`"
            :data-qa-locator="qaLocator ? `${qaLocator}-result-${idx}` : null"
            class="mutt-dropdown-autocomplete__listitem"
            @click="select(idx)"
            v-html="
              stringFormat(field.options.algolia.listItemTemplate, result)
            "
          />
          <li
            v-else
            ref="items"
            :key="`${result.objectID}-item`"
            :data-qa-locator="qaLocator ? `${qaLocator}-result-${idx}` : null"
            class="mutt-dropdown-autocomplete__listitem"
            @click="select(idx)"
          >
            {{ stringFormat(field.options.algolia.format, result) }}
          </li>
        </template>
        <li
          v-if="showLastItem"
          key="last-item"
          class="mutt-dropdown-autocomplete__listitem mutt-dropdown-autocomplete__listitem--last"
          @click="field.options.lastItem.callback"
        >
          {{ field.options.lastItem.title }}
        </li>
      </ul>
    </div>
    <div
      v-else-if="
        !loading &&
        hasPlaceholder &&
        results &&
        results.hits.length === 0 &&
        !showLastItem
      "
      class="mutt-dropdown-autocomplete--error"
      :style="algoliaInputWidth"
    >
      <ul ref="list" class="mutt-dropdown-autocomplete__list--error">
        <li
          v-show="
            !hideErrorOnBlur || (hideErrorOnBlur && !hideZeroResultsError)
          "
          class="bold mutt-dropdown-autocomplete__listitem--error"
        >
          {{ $t(`We can't find`) }} '{{ displayValue }}'.<br />
          <span v-if="zeroResultsMessage">{{ zeroResultsMessage }}</span>
          <span v-else>
            {{ $t('Sorry to ask, but have you checked the spelling?') }}
          </span>
        </li>
      </ul>
    </div>
    <div
      v-if="dangerousError"
      class="mutt-dropdown-autocomplete--error"
      :style="algoliaInputWidth"
    >
      <ul ref="list" class="mutt-dropdown-autocomplete__list--error">
        <li class="bold mutt-dropdown-autocomplete__listitem--error">
          <span>{{ dangerousError }}</span>
        </li>
      </ul>
    </div>

    <HelpWidget
      :field="field"
      :data-qa-locator="qaLocator ? `${qaLocator}-help` : null"
    />

    <template v-if="!isReadOnly">
      <ErrorWidget
        v-for="objField in field.object"
        :key="`autocomplete-error-${objField.id}`"
        :field="objField"
        :errors="objField.errors"
        :error-class="getErrorClass()"
        :data-qa-locator="qaLocator ? `${qaLocator}-error` : null"
      />
    </template>
  </div>
</template>

<script>
/*
  Mutt auto-complete
  Widget with autocomplete lookup to algolia.
  Usage - Set widget name to "autocomplete"
  Requires - "algolia" config object in field options, must include
            a API id, API key, Index.
TODO: Errors are not great currently... (linked ot mutt and object validation)
*/
import MuttVue from '@mutt/widgets-vue'
import Algoliasearch from 'algoliasearch'
import { throttle } from 'lodash-es'
import stringFormat from 'string-format'
export default {
  name: 'MuttAutocomplete',
  for: 'autocomplete',
  mixins: [MuttVue.mixin],
  props: {
    loadingMessage: {
      type: String,
      required: false,
      default: null,
    },
    zeroResultsMessage: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Number of milliseconds the request will be throttled by
     * The request will not be made any more frequent than throttleWaitTime ms
     */
    throttleWaitTime: {
      type: Number,
      default: 750,
    },
    /**
     * Minimum number of characters that need to be typed before search is triggered
     */
    minSearchableLength: {
      type: Number,
      default: 3,
    },
  },
  emits: ['callback', 'search', 'select'],
  data() {
    return {
      value: null,
      errors: null,
      loading: false,
      results: null,
      index: -1,
      selected: false,
      lock: false,
      clearOnSelect: null,
      displayValue: null,
      displayInitialValue: false,
      listScrollTop: 0, // To store scroll position in the results list
      hideErrorOnBlur: false,
      hideZeroResultsError: false,
      throttleTime: 0,
      throttledSearch: null,
      searchLength: 0,
      dangerousError: null,
    }
  },
  computed: {
    placeholderStr() {
      if (this.field.options.algolia.placeholder) {
        return this.$t(this.field.options.algolia.placeholder)
      }
      return ''
    },
    hasPlaceholder() {
      if (!this.displayValue) {
        return false
      }
      if (!this.field.options.algolia.placeholder) {
        return false
      }
      if (this.results !== null) {
        if (this.results.hits.length === 0) {
          return true
        }
      }
      return false
    },
    algoliaInputWidth() {
      const inputWrapperWidth =
        this.$refs.algoliaWrapper.getBoundingClientRect().width
      return `width: ${inputWrapperWidth}px`
    },
    showLastItem() {
      return (
        this?.field?.options?.lastItem?.title &&
        this?.field?.options?.lastItem?.callback
      )
    },
  },
  watch: {
    throttleTime: {
      handler(newValue) {
        this.throttledSearch = throttle(this.search, newValue)
      },
      immediate: true,
    },
  },
  async mounted() {
    if (!this.field.options.algolia) {
      throw new Error(
        `Field "${this.field.name}" does not specify an Algolia config!`
      )
    }
    const algoliaConfig = this.field.options.algolia
    if (!algoliaConfig.id || !algoliaConfig.key) {
      throw new Error(
        `Field "${this.field.name}" does not specify an Algolia id/key!`
      )
    }
    this.algoliaClient = Algoliasearch(algoliaConfig.id, algoliaConfig.key)
    if (!algoliaConfig.index) {
      throw new Error(
        `Field "${this.field.name}" does not specify an Algolia index!`
      )
    }
    this.algoliaIndexClient = this.algoliaClient.initIndex(algoliaConfig.index)

    this.clearOnSelect = this.field.options.clearOnSelect
    if (this.field.options.displayInitialValue) {
      this.displayInitialValue = this.field.options.displayInitialValue
    }
    if (this.field.options.algolia.hideErrorOnBlur) {
      this.hideErrorOnBlur = this.field.options.algolia.hideErrorOnBlur
    }
    this.throttleTime = this.field.options.throttleWaitTime
      ? this.field.options.throttleWaitTime
      : this.throttleWaitTime
    this.searchLength = this.field.options.minSearchableLength
      ? this.field.options.minSearchableLength
      : this.minSearchableLength

    await this.$nextTick()

    if (this.field.value) {
      // NOTE: This isn't quite right but there is no
      // real way to know if an object is 'empty' other
      // that seeing if there is anything in the child values
      const hasSomeValue = Object.values(this.field.value).find((value) => {
        return value !== null
      })
      if (!hasSomeValue) {
        return
      }
      this.displayValue = this.formatValue(this.field.value)
      if (!this.isReadOnly && this.$refs.inputField) {
        this.$refs.inputField.value = this.displayValue
      }
    }
  },
  created() {
    document.addEventListener('click', this.documentClick)
    // Initialise i18n integration. No-op if not present
    if (!this.$t) {
      this.$t = (str) => str
    }
  },
  beforeUnmount() {
    document.removeEventListener('click', this.documentClick)
  },
  methods: {
    getFieldWrapperClass() {
      return 'mutt-field-wrapper mutt-field-wrapper-autocomplete'
    },
    documentClick(e) {
      const el = this.$refs.dropdownMenu
      const target = e.target
      if (el !== target && !el.contains(target)) {
        this.lock = true
      }
    },
    async search() {
      this.dangerousError = null
      this.$emit('search')
      this.hideZeroResultsError = false
      this.displayValue = this.$refs.inputField?.value
      this.lock = false
      if (!this.displayValue || this.displayValue.length < this.searchLength) {
        // Don't search empty strings (it causes algolia to return
        // the whole show)
        // search only if minimum characters required are typed
        this.results = null
        return
      }
      const algoliaConfig = this.field.options.algolia
      let attributes = ['*']
      if (algoliaConfig.attributes) {
        attributes = algoliaConfig.attributes
      }
      let filters = []
      if (algoliaConfig.filters) {
        filters = algoliaConfig.filters
      }
      this.loading = true
      this.lock = true
      const results = await this.algoliaIndexClient.search(this.displayValue, {
        attributesToRetrieve: attributes,
        facetFilters: filters,
        attributesToHighlight: [],
      })
      this.results = results
      this.index = -1
      this.loading = false
      this.lock = false
    },
    select(index) {
      // set index from click event but not if keyboard event
      if (typeof index !== 'object') {
        this.index = index
      }
      if (this.index > -1 && this.results.hits.length > 0) {
        const result = this.results.hits[this.index]
        this.displayValue = this.formatValue(result)
        if (this.$refs.inputField) {
          this.$refs.inputField.value = this.formatValue(result)
        }
        this.value = result
        this.field.value = result
        this.lock = true
        this.displayInitialValue = false
        if (result.dangerous) {
          this.dangerousError = `We don't cover the breed ${this.displayValue} or any dog that is a mix or cross of this breed`
          return
        }
        this.$emit('select', {
          key: this.field.name,
          value: this.value,
        })
        this.submitCallback()
        if (this.clearOnSelect) {
          this.displayValue = null
        }
      }
    },
    next() {
      if (this.$refs['items']) {
        const count = this.$refs['items'].length
        this.goto(
          this.index < count - 1 ? this.index + 1 : count ? 0 : -1,
          'down'
        )
      }
    },
    prev() {
      if (this.$refs['items']) {
        const count = this.$refs['items'].length
        const pos = this.index - 1
        this.goto(this.selected && pos !== -1 ? pos : count - 1, 'up')
      }
    },
    /**
     * Check if a list item is within the list's visible area
     *
     * @param {number} listItemIndex The list item index
     * @param {number} listItemHeight The CSS height of the list item
     *
     * @returns {boolean} Is list item visible
     */
    isListItemVisible(listItemIndex, listItemHeight) {
      const listHeight = this.$refs['list'].clientHeight
      const listItemStart = listItemIndex * listItemHeight
      const listItemEnd = listItemStart + listItemHeight
      if (
        listItemStart >= this.listScrollTop &&
        listItemEnd <= this.listScrollTop + listHeight
      ) {
        return true
      }

      return false
    },
    /**
     * Highlight the active list item and make sure it's visible by
     * scrolling the list up and down when needed
     *
     * @param {number} i The list item index
     * @param {string} direction The direction being moved within the list
     */
    goto(i, direction) {
      if (this.selected && this.$refs['items'][this.index]) {
        this.$refs['items'][this.index].classList.remove('active-item')
      }
      this.index = i
      if (i > -1 && this.$refs['items'].length > 0) {
        const list = this.$refs['list']
        const listItem = this.$refs['items'][i]
        listItem.classList.add('active-item')
        // TODO: Do I need to show the selected text in my input?
        this.selected = true
        // Scroll the list when necessary in order to keep the active
        // list item comfortably in view
        if (i === 0) {
          this.listScrollTop = list.scrollTop = 0
          return
        }
        const listHeight = list.clientHeight
        const listItemHeight = listItem.clientHeight
        const listItemOffsetTop = listItem.offsetTop
        // The count of fully visible list items will vary according
        // to the CSS height of the list
        const listItemsVisible = Math.floor(listHeight / listItemHeight)
        // Set the visible offset to be applied to the list scroll
        // position - this is relative to how many list items are
        // visible
        const visibleOffset = listItemsVisible - 2
        if (direction === 'down') {
          // Check the visibility of the list item 2 below the
          // active item. If it's not fully visible then scroll the
          // list down
          if (!this.isListItemVisible(i + 2, listItemHeight)) {
            // Scroll the list down
            this.listScrollTop = list.scrollTop =
              listItemOffsetTop - listItemHeight * visibleOffset
          }
        } else {
          // Check the visibility of the list item 2 above the
          // active item. If it's not fully visible then scroll the
          // list up
          if (!this.isListItemVisible(i - 2, listItemHeight)) {
            // Scroll the list up
            this.listScrollTop = list.scrollTop =
              listItemOffsetTop - listItemHeight
          }
        }
      }
    },
    setValue(value) {
      this.value = value
      if (this.value) {
        this.displayValue = this.stringFormat(
          this.field.options.algolia.format,
          this.value
        )
      }
    },
    formatValue(value) {
      const { algolia } = this.field.options

      // Skip formatting if there is no format definition or the value to be
      // formatted is a string. The value passed into the function may be a
      // object that needs to be formatted into a string or an already formatted
      // version that can just be returned as is.
      const requiresFormatting =
        typeof value !== 'string' && algolia && algolia.format

      return requiresFormatting
        ? this.stringFormat(algolia.format, value)
        : value
    },
    checkCallback() {
      this.hideZeroResultsError = true
      if (
        this.field.options.allowFreetype &&
        this.field.options.allowFreetype
      ) {
        this.submitCallback()
      }
    },
    submitCallback() {
      let hits = null
      if (this.results?.hits) {
        hits = this.results.hits
      }
      if (this.field.validate()) {
        this.$emit('callback', {
          key: this.field.name,
          value: this.field.value,
          displayValue: this.displayValue,
          hits: hits,
          action: 'submit',
          validated: true,
        })
      } else {
        this.$emit('callback', {
          key: this.field.name,
          value: this.field.value,
          displayValue: this.displayValue,
          hits: hits,
          action: 'submit',
          validated: false,
        })
      }
    },
    async focus() {
      await this.$nextTick()
      this.$refs.inputField?.focus()
    },
    stringFormat,
  },
}
</script>
