import { usePermissions } from '@/composables/usePermissions'
import { getLdConfig } from '@/lib/appConfig'
import { getBuildTime, getBuildVersion } from '@/lib/build'
import dayjs from '@/lib/dayjs'
import Logger from '@/lib/logger'
import { instance, agency } from '@/plrn'

import { ErrorContext } from './ErrorContext'
import { Tag } from './Tag'

export default abstract class BaseError extends Error {
  public context: ErrorContext = {}
  public originalError: unknown | null = null
  public title: string = 'Base Error'

  public tags: Tag[] = []

  constructor(message: string, originalError?: unknown) {
    super(message)

    if (originalError) {
      this.originalError = originalError
    }

    this.context.location = window?.location?.href

    // make sure 'instance of' works on older versions
    Object.setPrototypeOf(this, BaseError.prototype)
  }

  public addTags(tags: Tag[]): void {
    this.tags = [...this.tags, ...tags]
  }

  public logError(): void {
    Logger.error(this)
  }

  getErrorMessage(): string {
    return this.message
  }

  getFullErrorDetails(): any {
    return [
      `Error: ${this.title}`,
      `Message: ${this.message}`,
      `Tags: ${this.tags}`,
      `Context: \n ${JSON.stringify(this.context, null, 2)}`,
    ].join('\n')
  }

  public buildInitialContext(): ErrorContext {
    this.injectLDConfigToContext()
    this.injectPermissionsToContext()
    this.injectAgencyToContext()
    this.injectInstanceToContext()
    this.injectVersionToContext()
    this.injectTimeOfError()

    return this.context
  }

  public injectTimeOfError(): void {
    const dateFormat = 'D MMM YYYY, HH:mm:ss [UTC]'
    const timeOfErrorTimeFormatted = dayjs.utc().format(dateFormat)
    this.context.timeOfError = timeOfErrorTimeFormatted
  }

  public injectAgencyToContext(): void {
    this.context.agency = agency
  }

  public injectInstanceToContext(): void {
    this.context.instance = instance
  }

  public injectVersionToContext(): void {
    const dateFormat = 'D MMM YYYY, HH:mm:ss [UTC]'
    const buildTime = getBuildTime()
    const buildVersion = getBuildVersion()
    const buildTimeFormatted = dayjs.utc(buildTime).format(dateFormat)

    const version = `${buildVersion} (built on ${buildTimeFormatted})`

    this.context.version = version
  }

  public injectLDConfigToContext(): void {
    this.context.ldConfig = getLdConfig()
  }

  public injectPermissionsToContext(): void {
    const { permissions } = usePermissions()
    this.context.permissions = permissions?.value
  }
}
