import {
  CatalogFeature,
  CatalogOption,
  CatalogProduct,
  ProductCatalog,
} from '@/domain/Shop/ProductCatalog'

import { ItemTypeParser } from './ItemTypeParser'

export type ItemTypeJson = 'insurance' | 'wellbeing'
export interface CatalogProductJson {
  type: ItemTypeJson
  tags: string[]
  display_name: string
  features: string[]

  excess?: number
  copay?: number
  co_payment?: number
  limit?: number
}

export interface CatalogOptionJson {
  type: 'option'
  display_name: string
  option_type: string

  excess?: number
  copay?: number
  co_payment?: number
  limit?: number
  features: string[]
}

export interface CatalogFeatureJson {
  display_name: string
  feature_type: string

  excess?: number
  copay?: number
  co_payment?: number
  limit?: number
}

export interface ProductCatalogJson {
  skus: Record<string, CatalogProductJson | CatalogOptionJson>
  product_features: Record<string, CatalogFeatureJson>
  surcharges: Record<string, string>
}

export default class ProductCatalogParser {
  public static parse(data: ProductCatalogJson): ProductCatalog {
    const result: ProductCatalog = new Map()

    for (const skuKey in data.skus) {
      const sku = data.skus[skuKey]

      if (sku.type === 'option') {
        const catalogOption = ProductCatalogParser.parseOption(
          data.product_features,
          sku
        )
        result.set(skuKey, catalogOption)
      } else {
        const catalogProduct = ProductCatalogParser.parseProduct(
          data.product_features,
          sku
        )
        result.set(skuKey, catalogProduct)
      }
    }

    result.set('surcharges', data.surcharges)

    return result
  }

  public static parseProduct(
    productFeatures: Record<string, CatalogFeatureJson>,
    product: CatalogProductJson
  ): CatalogProduct {
    const featuresArray = product.features.map(
      (featureId) => productFeatures[featureId]
    )

    return {
      type: ItemTypeParser.parse(product.type),
      tags: product.tags,
      displayName: product.display_name,
      features: featuresArray.map(ProductCatalogParser.parseFeature),

      excess: product.excess,
      copay: product.copay ?? product.co_payment,
      limit: product.limit,
    }
  }

  public static parseOption(
    productFeatures: Record<string, CatalogFeatureJson>,
    option: CatalogOptionJson
  ): CatalogOption {
    const featuresArray = option.features.map(
      (featureId) => productFeatures[featureId]
    )

    return {
      type: option.type,
      displayName: option.display_name,
      optionType: option.option_type,
      excess: option.excess,
      copay: option.copay ?? option.co_payment,
      limit: option.limit,
      features: featuresArray.map(ProductCatalogParser.parseFeature),
    }
  }

  public static parseFeature(feature: CatalogFeatureJson): CatalogFeature {
    return {
      displayName: feature.display_name,
      featureType: feature.feature_type,
      excess: feature.excess,
      copay: feature.copay ?? feature.co_payment,
      limit: feature.limit,
    }
  }
}
