/**
 * @see {@link https://en.wikipedia.org/wiki/List_of_ZIP_Code_prefixes|Wikipedia}
 *
 * Transcribed on 2020-08-27
 */

/* eslint-disable prettier/prettier -- I like the explicit "stringification" of the numeric keys. */
export const map: Record<string, Nullable<string>> = {
  // Prefix: 2-letter state code
  '000': null, // Placeholders
  '001': null, // "Destinations outside US"
  '002': null, // Not in use
  '003': null, // Not in use
  '004': null, // Not in use

  '005': 'NY', // IRS address

  '006': 'PR', // Puerto Rico
  '007': 'PR', // Puerto Rico
  '008': 'VI', // US Virgin islands
  '009': 'PR', // Puerto Rico

  '010': 'MA',
  '011': 'MA',
  '012': 'MA',
  '013': 'MA',
  '014': 'MA',
  '015': 'MA',
  '016': 'MA',
  '017': 'MA',
  '018': 'MA',
  '019': 'MA',
  '020': 'MA',
  '021': 'MA',
  '022': 'MA',
  '023': 'MA',
  '024': 'MA',
  '025': 'MA',
  '026': 'MA',
  '027': 'MA',

  '028': 'RI',
  '029': 'RI',

  '030': 'NH',
  '031': 'NH',
  '032': 'NH',
  '033': 'NH',
  '034': 'NH',
  '035': 'NH',
  '036': 'NH',
  '037': 'NH',
  '038': 'NH',

  '039': 'ME',
  '040': 'ME',
  '041': 'ME',
  '042': 'ME',
  '043': 'ME',
  '044': 'ME',
  '045': 'ME',
  '046': 'ME',
  '047': 'ME',
  '048': 'ME',
  '049': 'ME',

  '050': 'VT',
  '051': 'VT',
  '052': 'VT',
  '053': 'VT',
  '054': 'VT',

  '055': 'MA', // IRS address

  '056': 'VT',
  '057': 'VT',
  '058': 'VT',
  '059': 'VT',

  '060': 'CT',
  '061': 'CT',
  '062': 'CT',
  '063': 'CT',
  '064': 'CT',
  '065': 'CT',
  '066': 'CT',
  '067': 'CT',
  '068': 'CT',
  '069': 'CT',

  '070': 'NJ',
  '071': 'NJ',
  '072': 'NJ',
  '073': 'NJ',
  '074': 'NJ',
  '075': 'NJ',
  '076': 'NJ',
  '077': 'NJ',
  '078': 'NJ',
  '079': 'NJ',

  '080': 'NJ',
  '081': 'NJ',
  '082': 'NJ',
  '083': 'NJ',
  '084': 'NJ',
  '085': 'NJ',
  '086': 'NJ',
  '087': 'NJ',
  '088': 'NJ',
  '089': 'NJ',

  '090': 'AE', // Military
  '091': 'AE', // Military
  '092': 'AE', // Military
  '093': 'AE', // Military
  '094': 'AE', // Military
  '095': 'AE', // Military
  '096': 'AE', // Military
  '097': 'AE', // Military
  '098': 'AE', // Military
  '099': 'AE', // Military

  '100': 'NY',
  '101': 'NY',
  '102': 'NY',
  '103': 'NY',
  '104': 'NY',
  '105': 'NY',
  '106': 'NY',
  '107': 'NY',
  '108': 'NY',
  '109': 'NY',
  '110': 'NY',
  '111': 'NY',
  '112': 'NY',
  '113': 'NY',
  '114': 'NY',
  '115': 'NY',
  '116': 'NY',
  '117': 'NY',
  '118': 'NY',
  '119': 'NY',
  '120': 'NY',
  '121': 'NY',
  '122': 'NY',
  '123': 'NY',
  '124': 'NY',
  '125': 'NY',
  '126': 'NY',
  '127': 'NY',
  '128': 'NY',
  '129': 'NY',
  '130': 'NY',
  '131': 'NY',
  '132': 'NY',
  '133': 'NY',
  '134': 'NY',
  '135': 'NY',
  '136': 'NY',
  '137': 'NY',
  '138': 'NY',
  '139': 'NY',
  '140': 'NY',
  '141': 'NY',
  '142': 'NY',
  '143': 'NY',
  '144': 'NY',
  '145': 'NY',
  '146': 'NY',
  '147': 'NY',
  '148': 'NY',
  '149': 'NY',

  '150': 'PA',
  '151': 'PA',
  '152': 'PA',
  '153': 'PA',
  '154': 'PA',
  '155': 'PA',
  '156': 'PA',
  '157': 'PA',
  '158': 'PA',
  '159': 'PA',
  '160': 'PA',
  '161': 'PA',
  '162': 'PA',
  '163': 'PA',
  '164': 'PA',
  '165': 'PA',
  '166': 'PA',
  '167': 'PA',
  '168': 'PA',
  '169': 'PA',
  '170': 'PA',
  '171': 'PA',
  '172': 'PA',
  '173': 'PA',
  '174': 'PA',
  '175': 'PA',
  '176': 'PA',
  '177': 'PA',
  '178': 'PA',
  '179': 'PA',
  '180': 'PA',
  '181': 'PA',
  '182': 'PA',
  '183': 'PA',
  '184': 'PA',
  '185': 'PA',
  '186': 'PA',
  '187': 'PA',
  '188': 'PA',
  '189': 'PA',
  '190': 'PA',
  '191': 'PA',
  '192': 'PA', // IRS address
  '193': 'PA',
  '194': 'PA',
  '195': 'PA',
  '196': 'PA',

  '197': 'DE',
  '198': 'DE',
  '199': 'DE',

  '200': 'DC', // Government address

  '201': 'VA',

  '202': 'DC', // Government address
  '203': 'DC', // Government address
  '204': 'DC', // Government address
  '205': 'DC', // Government address

  '206': 'MD',
  '207': 'MD',
  '208': 'MD',
  '209': 'MD',
  '210': 'MD',
  '211': 'MD',
  '212': 'MD',

  '213': null, // Not in use

  '214': 'MD',
  '215': 'MD',
  '216': 'MD',
  '217': 'MD',
  '218': 'MD',
  '219': 'MD',

  '220': 'VA',
  '221': 'VA',
  '222': 'VA',
  '223': 'VA',
  '224': 'VA',
  '225': 'VA',
  '226': 'VA',
  '227': 'VA',
  '228': 'VA',
  '229': 'VA',
  '230': 'VA',
  '231': 'VA',
  '232': 'VA',
  '233': 'VA',
  '234': 'VA',
  '235': 'VA',
  '236': 'VA',
  '237': 'VA',
  '238': 'VA',
  '239': 'VA',
  '240': 'VA',
  '241': 'VA',
  '242': 'VA',
  '243': 'VA',
  '244': 'VA',
  '245': 'VA',
  '246': 'VA',

  '247': 'WV',
  '248': 'WV',
  '249': 'WV',
  '250': 'WV',
  '251': 'WV',
  '252': 'WV',
  '253': 'WV',
  '254': 'WV',
  '255': 'WV',
  '256': 'WV',
  '257': 'WV',
  '258': 'WV',
  '259': 'WV',
  '260': 'WV',
  '261': 'WV',
  '262': 'WV',
  '263': 'WV',
  '264': 'WV',
  '265': 'WV',
  '266': 'WV',
  '267': 'WV',
  '268': 'WV',

  '269': null, // Not in use

  '270': 'NC',
  '271': 'NC',
  '272': 'NC',
  '273': 'NC',
  '274': 'NC',
  '275': 'NC',
  '276': 'NC',
  '277': 'NC',
  '278': 'NC',
  '279': 'NC',
  '280': 'NC',
  '281': 'NC',
  '282': 'NC',
  '283': 'NC',
  '284': 'NC',
  '285': 'NC',
  '286': 'NC',
  '287': 'NC',
  '288': 'NC',
  '289': 'NC',

  '290': 'SC',
  '291': 'SC',
  '292': 'SC',
  '293': 'SC',
  '294': 'SC',
  '295': 'SC',
  '296': 'SC',
  '297': 'SC',
  '298': 'SC',
  '299': 'SC',

  '300': 'GA',
  '301': 'GA',
  '302': 'GA',
  '303': 'GA',
  '304': 'GA',
  '305': 'GA',
  '306': 'GA',
  '307': 'GA',
  '308': 'GA',
  '309': 'GA',
  '310': 'GA',
  '311': 'GA',
  '312': 'GA',
  '313': 'GA',
  '314': 'GA',
  '315': 'GA',
  '316': 'GA',
  '317': 'GA',
  '318': 'GA',
  '319': 'GA',

  '320': 'FL',
  '321': 'FL',
  '322': 'FL',
  '323': 'FL',
  '324': 'FL',
  '325': 'FL',
  '326': 'FL',
  '327': 'FL',
  '328': 'FL',
  '329': 'FL',
  '330': 'FL',
  '331': 'FL',
  '332': 'FL',
  '333': 'FL',
  '334': 'FL',
  '335': 'FL',
  '336': 'FL',
  '337': 'FL',
  '338': 'FL',
  '339': 'FL',

  '340': 'AA', // Military

  '341': 'FL',
  '342': 'FL',

  '343': null, // Not in use

  '344': 'FL',

  '345': null, // Not in use

  '346': 'FL',
  '347': 'FL',

  '348': null, // Not in use

  '349': 'FL',

  '350': 'AL',
  '351': 'AL',
  '352': 'AL',
  '353': 'AL',
  '354': 'AL',
  '355': 'AL',
  '356': 'AL',
  '357': 'AL',
  '358': 'AL',
  '359': 'AL',
  '360': 'AL',
  '361': 'AL',
  '362': 'AL',
  '363': 'AL',
  '364': 'AL',
  '365': 'AL',
  '366': 'AL',
  '367': 'AL',
  '368': 'AL',
  '369': 'AL',

  '370': 'TN',
  '371': 'TN',
  '372': 'TN',
  '373': 'TN',
  '374': 'TN',
  '375': 'TN', // IRS address
  '376': 'TN',
  '377': 'TN',
  '378': 'TN',
  '379': 'TN',
  '380': 'TN',
  '381': 'TN',
  '382': 'TN',
  '383': 'TN',
  '384': 'TN',
  '385': 'TN',

  '386': 'MS',
  '387': 'MS',
  '388': 'MS',
  '389': 'MS',
  '390': 'MS',
  '391': 'MS',
  '392': 'MS',
  '393': 'MS',
  '394': 'MS',
  '395': 'MS',
  '396': 'MS',
  '397': 'MS',

  '398': 'GA',
  '399': 'GA', // IRS address

  '400': 'KY',
  '401': 'KY',
  '402': 'KY',
  '403': 'KY',
  '404': 'KY',
  '405': 'KY',
  '406': 'KY',
  '407': 'KY',
  '408': 'KY',
  '409': 'KY',
  '410': 'KY',
  '411': 'KY',
  '412': 'KY',
  '413': 'KY',
  '414': 'KY',
  '415': 'KY',
  '416': 'KY',
  '417': 'KY',
  '418': 'KY',

  '419': null, // Not in use

  '420': 'KY',
  '421': 'KY',
  '422': 'KY',
  '423': 'KY',
  '424': 'KY',
  '425': 'KY',
  '426': 'KY',
  '427': 'KY',

  '428': null, // Not in use
  '429': null, // Not in use

  '430': 'OH',
  '431': 'OH',
  '432': 'OH',
  '433': 'OH',
  '434': 'OH',
  '435': 'OH',
  '436': 'OH',
  '437': 'OH',
  '438': 'OH',
  '439': 'OH',
  '440': 'OH',
  '441': 'OH',
  '442': 'OH',
  '443': 'OH',
  '444': 'OH',
  '445': 'OH',
  '446': 'OH',
  '447': 'OH',
  '448': 'OH',
  '449': 'OH',
  '450': 'OH',
  '451': 'OH',
  '452': 'OH',
  '453': 'OH',
  '454': 'OH',
  '455': 'OH',
  '456': 'OH',
  '457': 'OH',
  '458': 'OH',
  '459': 'OH', // IRS address

  '460': 'IN',
  '461': 'IN',
  '462': 'IN',
  '463': 'IN',
  '464': 'IN',
  '465': 'IN',
  '466': 'IN',
  '467': 'IN',
  '468': 'IN',
  '469': 'IN',
  '470': 'IN',
  '471': 'IN',
  '472': 'IN',
  '473': 'IN',
  '474': 'IN',
  '475': 'IN',
  '476': 'IN',
  '477': 'IN',
  '478': 'IN',
  '479': 'IN',

  '480': 'MI',
  '481': 'MI',
  '482': 'MI',
  '483': 'MI',
  '484': 'MI',
  '485': 'MI',
  '486': 'MI',
  '487': 'MI',
  '488': 'MI',
  '489': 'MI',
  '490': 'MI',
  '491': 'MI',
  '492': 'MI',
  '493': 'MI',
  '494': 'MI',
  '495': 'MI',
  '496': 'MI',
  '497': 'MI',
  '498': 'MI',
  '499': 'MI',

  '500': 'IA',
  '501': 'IA',
  '502': 'IA',
  '503': 'IA',
  '504': 'IA',
  '505': 'IA',
  '506': 'IA',
  '507': 'IA',
  '508': 'IA',
  '509': 'IA',
  '510': 'IA',
  '511': 'IA',
  '512': 'IA',
  '513': 'IA',
  '514': 'IA',
  '515': 'IA',
  '516': 'IA',

  '517': null, // Not in use
  '518': null, // Not in use
  '519': null, // Not in use

  '520': 'IA',
  '521': 'IA',
  '522': 'IA',
  '523': 'IA',
  '524': 'IA',
  '525': 'IA',
  '526': 'IA',
  '527': 'IA',
  '528': 'IA',

  '529': null, // Not in use

  '530': 'WI',
  '531': 'WI',
  '532': 'WI',

  '533': null, // Not in use

  '534': 'WI',
  '535': 'WI',

  '536': null, // Not in use

  '537': 'WI',
  '538': 'WI',
  '539': 'WI',
  '540': 'WI',
  '541': 'WI',
  '542': 'WI',
  '543': 'WI',
  '544': 'WI',
  '545': 'WI',
  '546': 'WI',
  '547': 'WI',
  '548': 'WI',
  '549': 'WI',

  '550': 'MN',
  '551': 'MN',

  '552': null, // Not in use

  '553': 'MN',
  '554': 'MN',
  '555': 'MN',
  '556': 'MN',
  '557': 'MN',
  '558': 'MN',
  '559': 'MN',

  '560': 'MN',
  '561': 'MN',
  '562': 'MN',
  '563': 'MN',
  '564': 'MN',
  '565': 'MN',
  '566': 'MN',
  '567': 'MN',

  '568': null, // Not in use

  '569': 'DC', // "Parcel return"

  '570': 'SD',
  '571': 'SD',
  '572': 'SD',
  '573': 'SD',
  '574': 'SD',
  '575': 'SD',
  '576': 'SD',
  '577': 'SD',

  '578': null, // Not in use
  '579': null, // Not in use

  '580': 'ND',
  '581': 'ND',
  '582': 'ND',
  '583': 'ND',
  '584': 'ND',
  '585': 'ND',
  '586': 'ND',
  '587': 'ND',
  '588': 'ND',

  '589': null, // Not in use

  '590': 'MT',
  '591': 'MT',
  '592': 'MT',
  '593': 'MT',
  '594': 'MT',
  '595': 'MT',
  '596': 'MT',
  '597': 'MT',
  '598': 'MT',
  '599': 'MT',

  '600': 'IL',
  '601': 'IL',
  '602': 'IL',
  '603': 'IL',
  '604': 'IL',
  '605': 'IL',
  '606': 'IL',
  '607': 'IL',
  '608': 'IL',
  '609': 'IL',
  '610': 'IL',
  '611': 'IL',
  '612': 'IL',
  '613': 'IL',
  '614': 'IL',
  '615': 'IL',
  '616': 'IL',
  '617': 'IL',
  '618': 'IL',
  '619': 'IL',
  '620': 'IL',

  '621': null, // Not in use

  '622': 'IL',
  '623': 'IL',
  '624': 'IL',
  '625': 'IL',
  '626': 'IL',
  '627': 'IL',
  '628': 'IL',
  '629': 'IL',

  '630': 'MO',
  '631': 'MO',

  '632': null, // Not in use

  '633': 'MO',
  '634': 'MO',
  '635': 'MO',
  '636': 'MO',
  '637': 'MO',
  '638': 'MO',
  '639': 'MO',
  '640': 'MO',
  '641': 'MO',

  '642': null, // Not in use
  '643': null, // Not in use

  '644': 'MO',
  '645': 'MO',
  '646': 'MO',
  '647': 'MO',
  '648': 'MO',
  '649': 'MO', // IRS address
  '650': 'MO',
  '651': 'MO',
  '652': 'MO',
  '653': 'MO',
  '654': 'MO',
  '655': 'MO',
  '656': 'MO',
  '657': 'MO',
  '658': 'MO',

  '659': null, // Not in use

  '660': 'KS',
  '661': 'KS',
  '662': 'KS',

  '663': null, // Not in use

  '664': 'KS',
  '665': 'KS',
  '666': 'KS',
  '667': 'KS',
  '668': 'KS',
  '669': 'KS',
  '670': 'KS',
  '671': 'KS',
  '672': 'KS',
  '673': 'KS',
  '674': 'KS',
  '675': 'KS',
  '676': 'KS',
  '677': 'KS',
  '678': 'KS',
  '679': 'KS',

  '680': 'NE',
  '681': 'NE',

  '682': null, // Not in use

  '683': 'NE',
  '684': 'NE',
  '685': 'NE',
  '686': 'NE',
  '687': 'NE',
  '688': 'NE',
  '689': 'NE',
  '690': 'NE',
  '691': 'NE',
  '692': 'NE',
  '693': 'NE',

  '694': null, // Not in use
  '695': null, // Not in use
  '696': null, // Not in use
  '697': null, // Not in use
  '698': null, // Not in use
  '699': null, // Not in use

  '700': 'LA',
  '701': 'LA',

  '702': null, // Not in use

  '703': 'LA',
  '704': 'LA',
  '705': 'LA',
  '706': 'LA',
  '707': 'LA',
  '708': 'LA',

  '709': null, // Not in use

  '710': 'LA',
  '711': 'LA',
  '712': 'LA',
  '713': 'LA',
  '714': 'LA',

  '715': null, // Not in use

  '716': 'AR',
  '717': 'AR',
  '718': 'AR',
  '719': 'AR',
  '720': 'AR',
  '721': 'AR',
  '722': 'AR',
  '723': 'AR',
  '724': 'AR',
  '725': 'AR',
  '726': 'AR',
  '727': 'AR',
  '728': 'AR',
  '729': 'AR',

  '730': 'OK',
  '731': 'OK',

  '732': null, // Not in use

  '733': 'TX', // IRS address

  '734': 'OK',
  '735': 'OK',
  '736': 'OK',
  '737': 'OK',
  '738': 'OK',
  '739': 'OK',
  '740': 'OK',
  '741': 'OK',

  '742': null, // Not in use

  '743': 'OK',
  '744': 'OK',
  '745': 'OK',
  '746': 'OK',
  '747': 'OK',
  '748': 'OK',
  '749': 'OK',

  '750': 'TX',
  '751': 'TX',
  '752': 'TX',
  '753': 'TX',
  '754': 'TX',
  '755': 'TX',
  '756': 'TX',
  '757': 'TX',
  '758': 'TX',
  '759': 'TX',
  '760': 'TX',
  '761': 'TX',
  '762': 'TX',
  '763': 'TX',
  '764': 'TX',
  '765': 'TX',
  '766': 'TX',
  '767': 'TX',
  '768': 'TX',
  '769': 'TX',
  '770': 'TX',
  '771': 'TX',
  '772': 'TX',
  '773': 'TX',
  '774': 'TX',
  '775': 'TX',
  '776': 'TX',
  '777': 'TX',
  '778': 'TX',
  '779': 'TX',
  '780': 'TX',
  '781': 'TX',
  '782': 'TX',
  '783': 'TX',
  '784': 'TX',
  '785': 'TX',
  '786': 'TX',
  '787': 'TX',
  '788': 'TX',
  '789': 'TX',
  '790': 'TX',
  '791': 'TX',
  '792': 'TX',
  '793': 'TX',
  '794': 'TX',
  '795': 'TX',
  '796': 'TX',
  '797': 'TX',
  '798': 'TX',
  '799': 'TX',

  '800': 'CO',
  '801': 'CO',
  '802': 'CO',
  '803': 'CO',
  '804': 'CO',
  '805': 'CO',
  '806': 'CO',
  '807': 'CO',
  '808': 'CO',
  '809': 'CO',
  '810': 'CO',
  '811': 'CO',
  '812': 'CO',
  '813': 'CO',
  '814': 'CO',
  '815': 'CO',
  '816': 'CO',

  '817': null, // Not in use
  '818': null, // Not in use
  '819': null, // Not in use

  '820': 'WY',
  '821': 'WY',
  '822': 'WY',
  '823': 'WY',
  '824': 'WY',
  '825': 'WY',
  '826': 'WY',
  '827': 'WY',
  '828': 'WY',
  '829': 'WY',
  '830': 'WY',
  '831': 'WY',

  '832': 'ID',
  '833': 'ID',
  '834': 'ID',
  '835': 'ID',
  '836': 'ID',
  '837': 'ID',
  '838': 'ID',

  '839': null, // Not in use

  '840': 'UT',
  '841': 'UT',
  '842': 'UT', // IRS address
  '843': 'UT',
  '844': 'UT',
  '845': 'UT',
  '846': 'UT',
  '847': 'UT',

  '848': null, // Not in use
  '849': null, // Not in use

  '850': 'AZ',
  '851': 'AZ',
  '852': 'AZ',
  '853': 'AZ',

  '854': null, // Not in use

  '855': 'AZ',
  '856': 'AZ',
  '857': 'AZ',

  '858': null, // Not in use

  '859': 'AZ',
  '860': 'AZ',

  '861': null, // Not in use
  '862': null, // Not in use

  '863': 'AZ',
  '864': 'AZ',
  '865': 'AZ',

  '866': null, // Not in use
  '867': null, // Not in use
  '868': null, // Not in use
  '869': null, // Not in use

  '870': 'NM',
  '871': 'NM',

  '872': null, // Not in use

  '873': 'NM',
  '874': 'NM',
  '875': 'NM',

  '876': null, // Not in use

  '877': 'NM',
  '878': 'NM',
  '879': 'NM',
  '880': 'NM',
  '881': 'NM',
  '882': 'NM',
  '883': 'NM',
  '884': 'NM',

  '885': 'TX',

  '886': null, // Not in use
  '887': null, // Not in use
  '888': null, // Not in use

  '889': 'NV',
  '890': 'NV',
  '891': 'NV',

  '892': null, // Not in use

  '893': 'NV',
  '894': 'NV',
  '895': 'NV',

  '896': null, // Not in use

  '897': 'NV',
  '898': 'NV',

  '899': null, // Not in use

  '900': 'CA',
  '901': 'CA',
  '902': 'CA',
  '903': 'CA',
  '904': 'CA',
  '905': 'CA',
  '906': 'CA',
  '907': 'CA',
  '908': 'CA',

  '909': null, // Not in use

  '910': 'CA',
  '911': 'CA',
  '912': 'CA',
  '913': 'CA',
  '914': 'CA',
  '915': 'CA',
  '916': 'CA',
  '917': 'CA',
  '918': 'CA',
  '919': 'CA',
  '920': 'CA',
  '921': 'CA',
  '922': 'CA',
  '923': 'CA',
  '924': 'CA',
  '925': 'CA',
  '926': 'CA',
  '927': 'CA',
  '928': 'CA',

  '929': null, // Not in use

  '930': 'CA',
  '931': 'CA',
  '932': 'CA',
  '933': 'CA',
  '934': 'CA',
  '935': 'CA',
  '936': 'CA',
  '937': 'CA',
  '938': 'CA', // IRS address
  '939': 'CA',
  '940': 'CA',
  '941': 'CA',
  '942': 'CA',
  '943': 'CA',
  '944': 'CA',
  '945': 'CA',
  '946': 'CA',
  '947': 'CA',
  '948': 'CA',
  '949': 'CA',
  '950': 'CA',
  '951': 'CA',
  '952': 'CA',
  '953': 'CA',
  '954': 'CA',
  '955': 'CA',
  '956': 'CA',
  '957': 'CA',
  '958': 'CA',
  '959': 'CA',
  '960': 'CA',
  '961': 'CA',

  '962': 'AP', // Military
  '963': 'AP', // Military
  '964': 'AP', // Military
  '965': 'AP', // Military
  '966': 'AP', // Military

  '967': 'HI',
  '968': 'HI',

  '969': 'GU', // Guam

  '970': 'OR',
  '971': 'OR',
  '972': 'OR',
  '973': 'OR',
  '974': 'OR',
  '975': 'OR',
  '976': 'OR',
  '977': 'OR',
  '978': 'OR',
  '979': 'OR',

  '980': 'WA',
  '981': 'WA',
  '982': 'WA',
  '983': 'WA',
  '984': 'WA',
  '985': 'WA',
  '986': 'WA',

  '987': null, // Not in use

  '988': 'WA',
  '989': 'WA',
  '990': 'WA',
  '991': 'WA',
  '992': 'WA',
  '993': 'WA',
  '994': 'WA',

  '995': 'AK',
  '996': 'AK',
  '997': 'AK',
  '998': 'AK',
  '999': 'AK',
}
/* eslint-enable prettier/prettier */

/**
 * Attempt to map a partial zip code to its 2-letter state code.
 *
 * @param {string} zip The zip code to map.
 * @returns {string|null} The 2-letter state code or null if the zip has no state.
 */
export function mapPartialZip(zip: string): Nullable<string> {
  return map[String(zip).slice(0, 3)] || null
}

/**
 * Checks if a 2-letter state code is actually a valida state
 *
 * @param {string} stateCode The state code to check
 * @returns {boolean} Whether this is a valid state
 */
export function isValidStateCode(stateCode?: string): boolean {
  return Boolean(stateCode && Object.values(map).includes(stateCode))
}
