<template>
  <div
    v-if="field"
    :class="getFieldWrapperClass()"
    :data-qa-locator="qaLocator"
  >
    <label-widget
      :field="field"
      :field-id="getFieldId()"
      :data-qa-locator="qaLocator ? `${qaLocator}-label` : null"
    />
    <readonly-widget
      v-if="isReadOnly"
      :field="field"
      :copyable="isCopyable"
      :value="field.value"
      :data-qa-locator="qaLocator ? `${qaLocator}-readonly` : null"
    />
    <input
      v-if="!isReadOnly"
      :id="getFieldId()"
      ref="input"
      :minlength="10"
      pattern="^([0-9\(\)\/\+ \-]*)$"
      type="tel"
      :class="getFieldClass()"
      :placeholder="getPlaceholder"
      :autocomplete="field.options.autocomplete"
      :name="field.name"
      :aria-invalid="hasErrors ? 'true' : null"
      :aria-describedby="
        field.options.hasOwnProperty('help') ? `${getFieldId()}-help` : null
      "
      :data-qa-locator="qaLocator ? `${qaLocator}-input` : null"
      :value="getFieldValue"
      @keypress.enter.prevent="submitCallback"
      @input="value = $event.target.value"
    />
    <help-widget
      :field="field"
      :data-qa-locator="qaLocator ? `${qaLocator}-help` : null"
    />
    <error-widget
      v-if="!isReadOnly"
      :field="field"
      :errors="errors"
      :error-class="getErrorClass()"
      :data-qa-locator="qaLocator ? `${qaLocator}-error` : null"
    />
  </div>
</template>

<script>
import WidgetMixin from '../mixins/WidgetMixin'

export default {
  name: 'MuttTel',
  for: 'tel',
  mixins: [WidgetMixin],
  data() {
    return {
      value: '',
    }
  },
  methods: {
    getFieldClass() {
      let className = 'mutt-field mutt-field-tel input'

      if (this.field.attribs && this.field.attribs.hasOwnProperty('class')) {
        className = `${className} ${this.field.attribs.class}`
      }

      return className
    },
    focus() {
      this.$nextTick().then(() => {
        this.$refs.tel.focus()
      })
    },
  },
}
</script>
