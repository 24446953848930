import { Title } from '@/domain/Account/AccountSummary'

export class CustomerParser {
  public static parseTitle(title: string): Title {
    switch (title) {
      case 'Mr':
      case 'Mrs':
      case 'Miss':
      case 'Ms':
      case 'Mx':
      case 'Prof':
      case 'Dr':
        return title
      default:
        throw new Error(`Unknown title: ${title}.`)
    }
  }
}
