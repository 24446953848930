import { HTTPError } from 'ky'

import APIError, { statusCodeToUserMessage } from './APIError'
import APIErrorUnprocessableEntity from './APIErrorUnprocessableEntity'
import BaseError from './BaseError'
import { ErrorContext } from './ErrorContext'
import { Tag } from './Tag'

export default class ErrorResponseFactory {
  public static async instantiateErrorClass(params: {
    title: string
    tags?: Tag[]
    context?: ErrorContext
    originalError?: unknown
    disableAutoLog?: boolean
  }): Promise<BaseError> {
    const {
      title,
      tags = [],
      context = {},
      originalError,
      disableAutoLog,
    } = params

    if (originalError instanceof HTTPError) {
      const jsonResponse = await originalError.response.json()
      const status =
        originalError.response.status.toString() as keyof typeof statusCodeToUserMessage

      const details = jsonResponse.detail
      const method = originalError.request.method
      const url = originalError.request.url || 'Unknown url'

      const genericParams = {
        title,
        tags,
        context: {
          ...context,
          details,
          method,
          status,
          url,
        },
        originalError,
        disableAutoLog,
      }

      if (status === '422') {
        return new APIErrorUnprocessableEntity({
          ...genericParams,
          canRetry: jsonResponse.can_retry ?? false,
          invalidFields: jsonResponse.invalid_fields ?? [],
        })
      }

      return new APIError(genericParams)
    }

    // because failed to fetch is not an instance of HTTPError we can't throw an APPError here
    return new APIError(params)
  }
}
