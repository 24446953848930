/* eslint-disable @typescript-eslint/explicit-function-return-type */

import type { RouteLocationNormalized } from 'vue-router'

export default [
  {
    name: 'policy-list',
    path: '/policies',
    component: () => import('@/views/policies/PolicyList.vue'),
    props: (to: RouteLocationNormalized) => ({
      page: to.query.page,
      search: to.query.search,
      productline: to.query.productline,
      objectType: 'policies',
    }),
    meta: {
      title: 'Policies',
      legacy: false,
    },
  },
  {
    name: 'policy-payments',
    path: '/policies/:uuid/payments',
    component: () => import('@/views/policies/PolicyPayments.vue'),
    props: (to: RouteLocationNormalized) => ({
      policyId: to.params.uuid,
    }),
    meta: {
      title: 'Policy Payments',
      legacy: true,
    },
  },
  {
    name: 'policy-view',
    path: '/policies/view/:uuid/:section?',
    component: () => import('@/views/policies/PolicyView.vue'),
    props: (to: RouteLocationNormalized) => ({
      policyUuid: to.params.uuid,
      section: to.params.section || 'overview',
    }),
    meta: {
      title: 'View Policy',
      legacy: true,
    },
  },
  {
    name: 'policy-cancel',
    path: '/policies/cancel/:uuid',
    component: () => import('@/views/policies/PolicyCancel.vue'),
    props: (to: RouteLocationNormalized) => ({
      policyUuid: to.params.uuid,
    }),
    meta: {
      title: 'Cancel Policy',
      legacy: true,
    },
  },
]
