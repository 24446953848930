import { defineStore } from 'pinia'
import platformClient, {
  LoginImplicitGrantOptions,
} from 'purecloud-platform-client-v2'
import { Ref, computed, ref, watch } from 'vue'

import { PhoneState } from '@/domain/Genesys/Genesys'
import CustomersService from '@/services/CustomersService'
import GenesysService from '@/services/GenesysService'
import GenesysParser from '@/services/parsers/GenesysParser'

type ConversationEventBody = any // todo type this once we see what we get
type Participant = any // todo type this once we see what we get
type NotificationMessage = any // todo type this once we see what we get

export const usePhoneIntegrationStore = defineStore(
  'phoneIntegration',
  (): UsePhoneIntegrationStoreResult => {
    const accessToken = ref<Maybe<string>>(undefined)
    const isLoggedIn = ref<boolean>(false)
    const loading = ref<boolean>(false)
    const activeCallId = ref<Maybe<string>>(undefined)
    const activePhoneHandlerParticipantId = ref<Maybe<string>>(undefined)
    const customerPhoneNumber = ref<Maybe<string>>(undefined)
    const customerName = ref<Maybe<string>>(undefined)
    const customersIds = ref<string[]>([])
    const phoneState = ref<PhoneState>('inactive')
    const activeCallNumber = ref<Maybe<string>>()
    const activeCallState = ref(phoneState.value)

    const isMultipleCustomers = computed(() => customersIds.value.length > 1)
    const isDisconnected = computed(() => phoneState.value === 'inactive')
    const isActive = computed(() => phoneState.value === 'active')

    const isIncoming = computed(() => phoneState.value === 'inactive')
    const isRinging = computed(
      () => phoneState.value === 'incoming' && !isHangingUp.value
    )
    const isHangingUp = ref(false)

    const genesysClient = platformClient.ApiClient.instance
    genesysClient.setEnvironment('https://api.mypurecloud.ie')
    genesysClient.setPersistSettings(true, 'genesys')

    watch(accessToken, (newAccessToken: Maybe<string>) => {
      if (newAccessToken) {
        genesysClient.setAccessToken(newAccessToken)
        subscribeToGenesys()
          .then(() => {
            isLoggedIn.value = true
            loading.value = false
          })
          .catch((error) => {
            console.error(error)
            loading.value = false
          })
      }
    })

    watch(phoneState, (newPhoneState: PhoneState) => {
      activeCallState.value = newPhoneState
      if (newPhoneState === 'inactive') {
        resetCustomer()
      }
    })

    watch(isActive, (newIsActive: boolean, oldIsActive: boolean) => {
      if (oldIsActive && !newIsActive) {
        isHangingUp.value = true
        setTimeout(() => {
          isHangingUp.value = false
        }, 3000)
      }
    })

    watch(
      customerPhoneNumber,
      async (newCustomerPhoneNumber: Maybe<string>) => {
        if (newCustomerPhoneNumber) {
          // This is not ideal because Genesys might send us phone numbers with country code formatted
          // and the backend won't return the customer if the phone number stored in s-users is without country code
          // or vice-versa
          const customers = await CustomersService.search({
            q: newCustomerPhoneNumber,
            page_number: 1,
            per_page: 10,
          })

          updateCustomerNameAndIds({
            name: customers?.items?.[0]?.full_name ?? undefined,
            ids: customers?.items?.map((customer) => customer.uuid) ?? [],
          })
        }
      }
    )

    function handleConversationEvent(event: ConversationEventBody): void {
      const customer = parseCustomer(event.participants)
      const phoneHandler = parsePhoneHandler(event.participants)

      if (!customer || !phoneHandler.calls?.[0]) {
        return
      }

      activeCallId.value = event.id
      activePhoneHandlerParticipantId.value = phoneHandler.id
      updatePhoneState(phoneHandler.calls[0].state)
      if (phoneState.value !== 'inactive') {
        updateCustomerPhoneNumber(customer.calls[0].self.addressNormalized)
      } else {
        resetCustomer()
      }
    }

    function parseCustomer(participants: Participant[]): Maybe<Participant> {
      return participants.find((participant) =>
        ['external', 'customer'].includes(participant.purpose)
      )
    }

    function parsePhoneHandler(
      participants: Participant[]
    ): Maybe<Participant> {
      return participants.find((participant) =>
        ['user', 'agent'].includes(participant.purpose)
      )
    }

    function updatePhoneState(state: string): void {
      try {
        phoneState.value = GenesysParser.parsePhoneState(state)
      } catch (error) {
        console.error(error) // log error
        phoneState.value = 'inactive'
      }
    }

    function updateCustomerPhoneNumber(phoneNumber: string): void {
      const cleanedNumber = String(phoneNumber)
        .replace('tel:', '')
        .replace(/\D/g, '')
        .trim()
      const lastTenDigits = cleanedNumber.slice(-10)

      // Update the customer's phone number with the last 10 digits
      customerPhoneNumber.value = lastTenDigits
    }

    function updateCustomerNameAndIds(customer: Participant): void {
      customerName.value = customer.name // Assuming customer object has name field
      customersIds.value = customer.ids // Assuming customer object has ids field
    }

    function handleNotification(message: NotificationMessage): void {
      const data = JSON.parse(message.data)
      const rawType: string = data.topicName
      if (rawType.match(/^v2\.users\..*\.conversations$/)) {
        handleConversationEvent(data.eventBody)
      }
    }

    async function subscribeToGenesys(): Promise<void> {
      await GenesysService.genesysSubscribe(handleNotification)
    }

    function resetCustomer(): void {
      customerName.value = undefined
      customerPhoneNumber.value = undefined
      customersIds.value = []
    }

    async function autoLogin(): Promise<void> {
      const genesysToken = localStorage.getItem('genesys_auth_data')

      if (!genesysToken) {
        return
      }

      if (Object.keys(JSON.parse(genesysToken || '{}')).length) {
        await login()
      }
    }

    async function login(): Promise<void> {
      loading.value = true
      const options: LoginImplicitGrantOptions = {}
      const url = window.location.href
      const match = url.match(/deploy-preview-([0-9]{1,})/)

      if (match) {
        options.state = `${window.location.host}/${window.location.hash.replace(
          /state=.*/,
          ''
        )}`
      }

      const accessToken = await GenesysService.login(options)
      updateAccessToken(accessToken)
      loading.value = false
    }

    const callOperations = {
      muteCall: async (muteState: boolean): Promise<void> => {
        if (!activeCallId.value || !activePhoneHandlerParticipantId.value) {
          return
        }

        GenesysService.mute(
          activeCallId.value,
          activePhoneHandlerParticipantId.value,
          muteState
        )
      },
      endCall: async (): Promise<void> => {
        if (!activeCallId.value || !activePhoneHandlerParticipantId.value) {
          return
        }

        GenesysService.endCall(
          activeCallId.value,
          activePhoneHandlerParticipantId.value
        )
      },
      answerCall: async (): Promise<void> => {
        if (!activeCallId.value || !activePhoneHandlerParticipantId.value) {
          return
        }

        GenesysService.answerCall(
          activeCallId.value,
          activePhoneHandlerParticipantId.value
        )
      },
    }

    function updateAccessToken(newToken: string): void {
      if (newToken) {
        accessToken.value = newToken
      }
    }

    return {
      isLoggedIn,
      loading,
      ...callOperations,
      updateAccessToken,
      updatePhoneState,

      activeCallNumber,
      activeCallState,

      handleConversationEvent,
      login,
      autoLogin,
      isDisconnected,
      isActive,
      isRinging,
      isIncoming,
      customerName,
      customerPhoneNumber,
      customersIds,
      isMultipleCustomers,
    }
  }
)

interface UsePhoneIntegrationStoreResult {
  isLoggedIn: Ref<boolean>
  loading: Ref<boolean>
  activeCallNumber: Ref<Maybe<string>>
  activeCallState: Ref<PhoneState>
  isDisconnected: Ref<boolean>
  isActive: Ref<boolean>
  isRinging: Ref<boolean>
  isIncoming: Ref<boolean>
  customerName: Ref<Maybe<string>>
  customerPhoneNumber: Ref<Maybe<string>>
  customersIds: Ref<string[]>
  isMultipleCustomers: Ref<boolean>

  login: () => void
  autoLogin: () => Promise<void>
  muteCall: (muteState: boolean) => Promise<void>
  endCall: () => Promise<void>
  answerCall: () => Promise<void>
  updateAccessToken: (newToken: string) => void
  updatePhoneState: (newState: string) => void
  handleConversationEvent: (event: ConversationEventBody) => void
}
