<template>
  <Component
    :is="tag"
    class="text"
    :class="{
      'text--bold': bold,
      'text--semibold': semibold,
      'text--uppercase': uppercase,
      'text--underline': underline,
      [`text--line-height-${lineHeight}`]: lineHeight,
      [`text--${size}`]: size,
      [`text--${type}`]: type,
    }"
  >
    <slot />
  </Component>
</template>

<script>
export const lineHeights = ['none', 'compact', 'regular', 'loose']

export const sizes = [
  'hero',
  'large',
  'medium',
  'lead',
  'normal',
  'small',
  'micro',
  'nano',
]

export const types = ['primary', 'secondary', 'regular', 'success']

export default {
  name: 'SimpleText',
  props: {
    bold: {
      type: Boolean,
      default: false,
    },
    semibold: {
      type: Boolean,
      default: false,
    },
    lineHeight: {
      type: String,
      validator: (value) => lineHeights.includes(value),
      default: 'compact',
    },
    size: {
      type: String,
      validator: (value) => sizes.includes(value),
      default: 'normal',
    },
    tag: {
      type: String,
      default: 'span',
    },
    type: {
      type: String,
      validator: (value) => types.includes(value),
      default: 'primary',
    },
    underline: {
      type: Boolean,
      default: false,
    },
    uppercase: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.text {
  font-weight: 400;

  &--primary {
    color: var(--el-text-color-primary);
  }

  &--secondary {
    color: var(--el-text-color-secondary);
  }

  &--success {
    color: var(--mp-success-dark);
  }

  &--bold {
    font-weight: 700;
  }

  &--semibold {
    font-weight: 500;
  }

  &--hero {
    font-size: 46px;
  }

  &--large {
    font-size: 36px;
  }

  &--medium {
    font-size: 28px;
  }

  &--lead {
    font-size: 22px;
  }

  &--normal {
    font-size: 18px;
  }

  &--small {
    font-size: 16px;
  }

  &--micro {
    font-size: 14px;
  }

  &--nano {
    font-size: 12px;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--underline {
    text-decoration: underline;
  }

  &--line-height-none {
    line-height: 100%;
  }

  &--line-height-compact {
    line-height: 130%;
  }

  &--line-height-regular {
    line-height: 150%;
  }

  &--line-height-loose {
    line-height: 170%;
  }
}
</style>
