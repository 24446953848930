<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    :fill="color"
    v-bind="$attrs"
  >
    <title v-if="title">{{ title }}</title>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.5502 3.71672C6.14154 3.15937 6.98777 2.84375 8.04819 2.84375C9.01721 2.84375 9.85236 3.13223 10.4502 3.64428C11.0511 4.15892 11.3962 4.88678 11.3962 5.72247C11.3962 6.91279 10.7814 7.75203 9.80215 8.33812L9.80039 8.33917C9.36372 8.59643 9.12206 8.79334 8.98442 8.98522C8.85634 9.16378 8.80122 9.36239 8.80122 9.67019V10.3294H6.78186L6.77071 9.56703C6.75232 9.02418 6.85849 8.56015 7.11443 8.15105C7.36763 7.74631 7.75209 7.42007 8.24691 7.12384C8.65373 6.87837 8.89482 6.67929 9.03767 6.48054C9.17231 6.29322 9.23451 6.08517 9.23451 5.78459C9.23451 5.4906 9.11795 5.24573 8.91309 5.07014C8.70511 4.89188 8.38815 4.77081 7.97227 4.77081C7.55452 4.77081 7.23798 4.8926 7.02211 5.08522C6.80742 5.27679 6.66669 5.56107 6.64029 5.93659L6.62084 6.21324H4.60547L4.61757 5.90408C4.65115 5.04581 4.95912 4.27383 5.5502 3.71672ZM7.91848 10.7944C7.26627 10.7944 6.73755 11.3231 6.73755 11.9753C6.73755 12.6275 7.26627 13.1562 7.91848 13.1562C8.57069 13.1562 9.09941 12.6275 9.09941 11.9753C9.09941 11.3231 8.57069 10.7944 7.91848 10.7944Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'QuestionIcon',
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
    size: {
      type: [Number, String],
      default: 16,
    },
    title: {
      type: String,
      default: '',
    },
  },
}
</script>
