<template>
  <div
    class="ssn-widget"
    v-bind:class="getFieldWrapperClass()">

    <label-widget
      v-bind:field="field"
      v-bind:fieldId="getFieldId()"></label-widget>

    <readonly-widget
      v-if="isReadOnly"
      :field="field"
      :copyable="isCopyable"
      v-bind:value="field.value"
    />

    <div
      v-if="!isReadOnly"
      class="ssn-widget__input">
      <input
        v-if="!(detail && confirmed)"
        ref="ssn"
        name="ssn"
        autocomplete="off"
        type="text"
        v-model="ssn"
        v-on:focus="errors = null"
        v-bind:placeholder="$t('YYYYMMDD-XXXX')"
        class="mutt-field mutt-field-text input"
        data-qa-locator="ssn-input"
        v-on:keypress.enter.prevent="lookup()">

      <button
        v-if="!detail"
        class="btn btn--search"
        data-qa-locator="ssn-search-button"
        v-on:click.prevent="lookup()">
        <span>{{ $t('Search') }}</span>
      </button>
    </div>

    <div
      v-if="!isReadOnly && !confirmed && (detail || processing)"
      class="ssn-widget__results">
      <span
        v-if="processing"
        class="processing">
        <span>{{ $t('Searching') }}</span>
      </span>
      <div
        v-if="detail && !processing"
        class="ssn-widget__result">
        <span>{{ $t('Are these details correct?') }}</span><br>
        <span>{{ displayResult }}</span>
      </div>
    </div>

    <error-widget
      v-if="!isReadOnly && hasErrors && !processing"
      v-bind:field="field"
      v-bind:errors="errors"
      v-bind:errorClass="getErrorClass()" />

    <help-widget v-bind:field="field" />

    <div v-if="detail && !isReadOnly" class="ssn-widget__details">
      <template v-if="confirmed">
        <span>
          {{ ssn }} - {{ displayResult }}
        </span><br>
        <button
          ref="clearButton"
          class="btn btn--secondary btn--secondary--darkbg"
          data-qa-locator="ssn-re-enter-button"
          v-on:click.prevent="clear()">
          <span>{{ $t('Re-enter SSN') }}</span>
        </button>
      </template>

      <template v-if="!confirmed">
        <ul class="button-list">
          <li>
            <button
              class="btn btn--secondary btn--secondary--darkbg"
              data-qa-locator="ssn-try-again-button"
              v-on:click.prevent="clear()"><span>{{ $t('No, try again') }}</span></button>
          </li>
          <li>
            <button
              class="btn"
              data-qa-locator="ssn-continue-button"
              ref="continueButton"
              v-on:click.prevent="select()"><span>{{ $t('Yes, continue') }}</span></button>
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script>
import MuttVue from '@mutt/widgets-vue'
import { client } from '@/lib/api/ApiClient'
import format from 'string-format'
import { getConfigForFeature } from '@/lib/appConfig'

export default {
  name: 'mutt-personnummer',
  mixins: [
    MuttVue.mixin,
  ],
  data() {
    return {
      ssn: '',
      selectedSsn: '',
      detail: null,
      errors: null,
      processing: false,
      config: {},
      displayTemplate: '{givenName} {lastName}',
      // Note: Default is true to show populated values
      confirmed: true,
    }
  },
  methods: {
    init() {
      if (!this.field.options.hasOwnProperty('ssn')) {
        throw new Error(
          this.$t('[SSN] Invalid config for widget - missing ssn config!')
        )
      }
      const requiredConfig = [
        this.field.options.ssn.hasOwnProperty('host'),
        this.field.options.ssn.hasOwnProperty('username'),
        this.field.options.ssn.hasOwnProperty('password'),
      ]
      if (!requiredConfig.every((e) => e === true)) {
        throw new Error(
          this.$t('[SSN] Invalid config - missing paramater!')
        )
      }
      if (this.field.options.ssn.hasOwnProperty('template')) {
        this.displayTemplate = this.field.options.ssn.template
      }
      // Initialise i18n integration. No-op if not present
      if (!this.$t) {
        this.$t = (str) => str
      }
      // Guard against field value being undefined
      if (!this.field.value) {
        this.field.value = null
      }
      this.ssn = this.field.value
      this.selectedSsn = this.field.value
    },
    validate() {
      // Strip out '-' characters before validating
      if (this.ssn) {
        this.ssn = this.normaliseYear(this.ssn.replace(/-/g,''))
      }
      if (!this.ssn || this.ssn === '') {
        this.errors = [
          this.$t('Please enter a Personnummer.'),
        ]
        console.debug('[SSN] Invalid', this.ssn)
        return false
      }
      return true
    },
    normaliseYear(ssn) {
      // Append centuries on 10 digit long numbers
      if (ssn.length === 10) {
        const year = parseInt(ssn.substring(0, 2))
        const twoDigitsCurrentYear = parseInt(new Date().getFullYear().toString().substring(2, 4));
        // Append 19, if someone's date of birth would result in them being born in the future
        const century = (twoDigitsCurrentYear - year) <= 0 ? '19' : '20'
        return `${century}${ssn}`
      }
      return ssn
    },
    async lookup() {
      if (this.processing === true) {
        return
      }
      if (!this.validate()) {
        return
      }
      this.processing = true
      // Set confirmed to false so that searching indicator shows
      this.confirmed = false
      // Reset errors
      this.errors = null
      // Lookup the SSN number
      let response
      try {
        const url = getConfigForFeature('ssnLookupUrl')
        response = await client(url, {
          searchParams: {
            SSN: this.ssn,
          },
        }).json()
      } catch (error) {
        console.error('[SSN] Lookup Error', error)
        return
      }
      console.debug('[SSN] Lookup Response', response)
      try {
        if (response.responseCode === 'NotFound') {
          throw 'Sorry, we were unable to find that Personnummer.'
        }
        if (response.responseCode !== 'Ok') {
          throw 'Sorry, something has gone wrong. Please try again later.'
        }
        if (typeof response.basic === 'undefined') {
          throw 'Sorry, we were unable to find that Personnummer.'
        }
        if (
          this.field.options.ssn.hasOwnProperty('requiredFieldPaths') &&
          !this.dataContainsRequiredFields(this.field.options.ssn.requiredFieldPaths, response)
        ) {
          throw 'Sorry, that Personnummer has missing information which is required.'
        }
        // If a minumum age option has been set for this field and if the
        // user's age is below the minimum age, display an error
        if (this.field.options.ssn.hasOwnProperty('minimumAge')) {
          const minimumAge = this.field.options.ssn.minimumAge
          const userAge = response.extended.age
          if (minimumAge && typeof minimumAge === 'number' && userAge && userAge < minimumAge) {
            throw `Sorry, to proceed the minimum age is ${minimumAge} years old.`
          }
        }
        this.detail = response
        this.$nextTick(() => {
          this.$refs.continueButton.focus()
        })
      } catch (error) {
        this.errors = [
          this.$t(error),
        ]
      } finally {
        this.processing = false
      }
    },
    /**
     * Checks the returned data for required fields
     *
     * @param {string[]} requiredFieldPaths the field paths to check
     * @param {Object} personData the data to check
     * @emits dataContainsRequiredFields#ssnRequiredFieldsMissing
     * @returns {boolean} whether the identity is protected or not
     */
    dataContainsRequiredFields(requiredFieldPaths, personData) {
      // Make a copy of the data so we don't mutate it
      const personDataCopy = Object.assign({}, personData)
      // check field exists on object (including nested)
      let missingFields = []
      for (const fieldPath of requiredFieldPaths) {
        // See if the field path exists and there is data there
        const fieldValue = fieldPath
          .split('.')
          .reduce((obj, key) => {
            if (obj && obj.hasOwnProperty(key)) {
              return obj[key]
            } else {
              return null
            }
          }, personDataCopy)
        if (!fieldValue) {
          missingFields.push(fieldPath)
        }
      }
      if(missingFields.length > 0) {
        this.$emit('callback', {
          action: 'ssnRequiredFieldsMissing',
          bubble: true,
          result: personData,
          missingFields
        })
        console.debug(
          '[SSN] Data missing required fields',
          missingFields,
          personData
        )
        return false
      }
      return true
    },
    select() {
      // Commit the value
      this.field.value = this.ssn
      if (!this.field.validate()) {
        return
      }
      this.confirmed = true
      this.errors = null
      const eventPayload = {
        action: 'ssnSelect',
        bubble: true,
        result: this.detail,
      }
      this.$emit('callback', eventPayload)
      this.field?.callback?.(eventPayload)
      this.$emit('callback', {
        action: 'toggleOverlay',
        validated: true,
      })
    },
    clear() {
      // Clear the ssn which will trigger the watcher
      this.ssn = ''
      this.focus()
    },
    getValue() {
      return this.selectedSsn
    },
    setValue(value) {
      this.ssn = value
      this.selectedSsn = value
    },
    focus() {
      this.$nextTick(() => {
        if (this.$refs.ssn) {
          this.$refs.ssn.focus()
        }
      })
    },
  },
  computed: {
    displayResult() {
      if (this.detail === null) {
        return null
      }
      const detail = Object.assign({
        ssn: this.ssn,
      }, this.detail.basic)
      return format(this.displayTemplate, detail)
    },
  },
  watch: {
    ssn() {
      this.errors = null
      // If there is SSN detail and the value is
      // changing then we shoudl clear it
      if (this.detail) {
        const eventPayload = {
          action: 'ssnClear',
          bubble: true,
        }
        this.$emit('callback', eventPayload)
        this.field?.callback?.(eventPayload)
        this.selectedSsn = ''
        this.detail = null
        this.field.value = ''
      }
    },
  },
}
</script>
