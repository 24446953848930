import { getConfigForFeature } from '../appConfig'
/**
 * Get the date format ordering for a given locale (or the default locale if not
 * passed in).
 *
 * This function returns the format ordering as an array of strings.
 *
 * @param {string} [locale] The locale string used to determine the date order (defaults to browser setting).
 * @returns {string[]} The date order as dictated by the locale (e.g. ['day', 'month', 'year']).
 */
export function dateOrderForLocale(locale: string): string[] {
  const format = new Intl.DateTimeFormat(locale).formatToParts()
  return format
    .filter((part) => part.type !== 'literal')
    .map((part) => part.type)
}

export interface FormatDateOptions extends Intl.DateTimeFormatOptions {
  locale?: string
}

/**
 * Format a date to a locale-aware string given some options.
 *
 * @param {object|string} date A moment/Day.js/Date object or ISO string.
 * @param {object} [options] The options param.
 * @param {string} [options.locale] The locale which determines the date format.
 * @param {string} [options.day='numeric'] The format of the day.
 * @param {string} [options.month='short'] The format of the month.
 * @param {string} [options.year='numeric'] The format of the year.
 * @param {string} [options.timeZone='UTC'] The timezone
 * @returns {string} The formatted date
 */
export function formatDate(
  date: string,
  options: FormatDateOptions = {}
): string {
  const parsed = Date.parse(date)

  if (isNaN(parsed)) {
    return 'Invalid date'
  }

  const configLocale = (getConfigForFeature('locale') ??
    undefined) as Maybe<string>

  const {
    locale = configLocale,
    day = 'numeric',
    month = 'short',
    year = 'numeric',
    hour,
    minute,
    second,
    timeZone = 'UTC',
  } = options

  return new Intl.DateTimeFormat(locale, {
    day,
    month,
    year,
    hour,
    minute,
    second,
    timeZone,
  }).format(parsed)
}

export function dateFormatForLocale(): string {
  return (getConfigForFeature('dateFormatForLocale') as string) ?? 'D MMM YYYY'
}
