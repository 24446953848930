<template>
  <button
    class="chip"
    :class="selected && 'chip--selected'"
    type="button"
    @click="handleClick"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'Chip',

  props: {
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['click'],
  methods: {
    handleClick(e) {
      this.$emit('click', e.target.textContent)
    },
  },
}
</script>

<style lang="scss" scoped>
.chip {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--el-color-primary);
  background: var(--el-color-primary-light-9);
  padding: 0 12px;
  border-radius: 15px;
  font-size: 12px;
  box-sizing: border-box;
  height: 20px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 2px;
  cursor: pointer;

  &--selected {
    color: var(--el-color-primary-light-9);
    background: var(--el-color-primary);
  }
}

.size-l {
  height: 32px;
  padding: 0 24px;
  font-size: 14px;
}
</style>
