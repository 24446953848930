import { useWebSocket } from '@vueuse/core'
import { ElNotification } from 'element-plus'
import { debounce } from 'lodash-es'
import { h, watch, onBeforeUnmount } from 'vue'
import { useRoute } from 'vue-router'

import FormattedPageViewer from '@/components/FormattedPageViewer.vue'
import UserAvatar from '@/components/UserAvatar.vue'
import { getConfigForFeature } from '@/lib/appConfig'
import { useAuth0 } from '@/lib/auth0/auth0-vue-proxy'

const ENABLED_ROUTES = ['vet-practice-view']

interface PageViewer {
  email: string
  name: string
  nickname: string
  picture: string
  sub: string
  started_viewing_timestamp: number
}

interface OnListUsersEvent {
  event: 'onListUsers'
  data: {
    page: string
    viewers: PageViewer[]
  }
}

interface OnViewerJoinedEvent {
  event: 'onViewerJoined'
  data: {
    page: string
    viewer: PageViewer
  }
}

interface OnViewerLeftEvent {
  event: 'onViewerLeft'
  data: {
    page: string
    viewer: PageViewer
  }
}

type Event = OnViewerLeftEvent | OnViewerJoinedEvent | OnListUsersEvent

const usePageViewers = (): { start: () => Promise<void> } => {
  let notifications: { [sub: string]: ReturnType<typeof ElNotification> } = {}
  let currentUrl = window.location.href
  let displayNotifications = false
  let sendCurrentPage: () => void = () => null

  const auth = useAuth0()
  const route = useRoute()

  const closeNotification = (viewerSub: string): void => {
    const { [viewerSub]: notification, ...otherNotifications } = notifications
    if (notification) {
      notification.close()
    }

    notifications = otherNotifications
  }

  const closeAllNotifications = (): void => {
    Object.keys(notifications).forEach(closeNotification)
  }

  const addNotification = (viewer: PageViewer): void => {
    if (!notifications[viewer.sub]) {
      notifications[viewer.sub] = ElNotification({
        message: h(FormattedPageViewer, { viewer }),
        icon: h(UserAvatar, { user: viewer }),
        duration: 0,
        position: 'bottom-left',
      })
    }
  }

  watch(
    () => route.name,
    (newName): void => {
      if (newName && ENABLED_ROUTES.includes(newName.toString())) {
        displayNotifications = true
      } else {
        displayNotifications = false
      }
      closeAllNotifications()
    }
  )

  watch(
    () => route.path,
    (newPath): void => {
      closeAllNotifications()
      currentUrl = `${window.location.origin}${newPath}`
      sendCurrentPage()
    }
  )

  onBeforeUnmount(() => {
    closeAllNotifications()
  })

  if (import.meta.hot) {
    import.meta.hot.accept(() => {
      closeAllNotifications()
      sendCurrentPage()
    })
  }

  return {
    start: async (): Promise<void> => {
      const accessToken = await auth.getAccessTokenSilently()
      const websocket = useWebSocket(
        getConfigForFeature('websocketApiUrl') as string,
        {
          autoReconnect: {
            retries: 3,
            delay: 30000,
            onFailed() {
              console.error('Could not reconnect websocket')
            },
          },
          heartbeat: {
            message: JSON.stringify({ action: 'ping' }),
            interval: 30000,
            pongTimeout: 30000,
          },
          protocols: ['authorization', accessToken],
        }
      )

      watch(websocket.status, (status, oldStatus) => {
        if (status === 'OPEN' && oldStatus !== 'OPEN') {
          sendCurrentPage()
        }

        if (status === 'CLOSED' && oldStatus !== 'CLOSED') {
          closeAllNotifications()
        }
      })

      watch(websocket.data, async (eventPayload: string | null) => {
        if (displayNotifications && eventPayload && eventPayload !== 'pong') {
          try {
            const { event, data }: Event = JSON.parse(eventPayload)

            if (event === 'onListUsers') {
              for (const viewer of data.viewers) {
                addNotification(viewer)
                await new Promise((resolve) => setTimeout(resolve, 100))
              }
            }

            if (event === 'onViewerJoined') {
              addNotification(data.viewer)
            }

            if (event === 'onViewerLeft') {
              closeNotification(data.viewer.sub)
            }
          } catch (err) {
            console.error(err)
          }
        }
      })

      sendCurrentPage = debounce(() => {
        if (websocket && websocket.status.value === 'OPEN') {
          websocket.send(
            JSON.stringify({ action: 'view', data: { page: currentUrl } })
          )
        }
      })
    },
  }
}

export default usePageViewers
