import {
  getOptionDetails,
  getProductDetails,
} from '@/application/getProductDetails'
import { Order, OrderItem, OrderItemOption, OrderProduct } from '@/domain/Order'
import { Quote } from '@/domain/Quote/Quote'
import { Subscription } from '@/domain/Subscription'
import { Dayjs } from '@/lib/dayjs'
import { isNotNullOrUndefined } from '@/lib/utils/arrays'
import { getNextSubscriptionBillingDate } from '@/lib/utils/subscriptions'

export default class OrderAdapter {
  public static calculateNextSubscriptionDate(params: {
    quotes: Quote[]
    subscription: Nullable<Subscription>
  }): Nullable<Dayjs> {
    const { quotes, subscription } = params

    const inceptionDates = quotes
      .map((quote: Quote) => quote.data?.policy.inception_date)
      .filter(isNotNullOrUndefined)

    return getNextSubscriptionBillingDate({
      subscription,
      inceptionDates,
    })
  }

  public static async addSubscriptionToOrder(params: {
    order: Order
    quotes: Quote[]
    subscription: Nullable<Subscription>
  }): Promise<Order> {
    const { order, quotes, subscription } = params

    // currently for non-sgp Shop api does not return order.subscription
    // so we calculate it in the frontend
    // this is a temporary solution until US is fully migrated to SGP
    const totalCurrentSubscriptions = (subscription?.items ?? [])
      .filter((item) => item.status === 'ACTIVE')
      .reduce((total, item) => {
        return total + item.monthly_cost
      }, 0)

    const regularCost =
      totalCurrentSubscriptions + order.price.monthlyDelta.total

    const nextCost =
      totalCurrentSubscriptions +
      order.price.termDelta.total -
      order.price.monthlyDelta.total * 11

    const date = this.calculateNextSubscriptionDate({ quotes, subscription })

    if (!date) {
      return order
    }

    const day = date.date()
    order.subscription = {
      paymentDay: day,
      schedule: [],
      price: {
        monthlyTotal: {
          discount: 0,
          discountPercentage: 0,
          discountType: undefined,
          tax: 0,
          subtotal: regularCost,
          total: regularCost,
        },
        termTotal: {
          discount: 0,
          discountPercentage: 0,
          discountType: undefined,
          subtotal: order.price.termDelta.total,
          tax: 0,
          total: order.price.termDelta.total,
        },
        remaining: {
          discount: 0,
          discountPercentage: 0,
          discountType: undefined,
          subtotal: 0,
          tax: 0,
          total: 0,
        },
        surcharges: [],
      },
      nextPayment: {
        date: date.format(),
        total: nextCost,
      },
    }

    return order
  }

  public static async addCatalogDetailsToOrder(params: {
    order: Order
  }): Promise<Order> {
    const { order } = params

    const petsWithDetails = await Promise.all(
      order.pets.map(async (pet) => {
        const itemsWithDetails = await Promise.all(
          pet.items.map(async (item): Promise<OrderItem> => {
            const product = await OrderAdapter.addCatalogDetailsToProduct({
              product: item.product,
            })
            const options = item.options
              ? await OrderAdapter.addCatalogDetailsToOptions({
                  options: item.options,
                })
              : undefined

            return {
              ...item,
              product,
              options,
            }
          })
        )

        return {
          ...pet,
          items: itemsWithDetails,
        }
      })
    )

    return {
      ...order,
      pets: petsWithDetails,
    }
  }

  private static async addCatalogDetailsToProduct(params: {
    product: OrderProduct
  }): Promise<OrderProduct> {
    const { product } = params

    const details = await getProductDetails(product.sku)

    return {
      ...product,
      details,
    }
  }

  private static async addCatalogDetailsToOptions(params: {
    options: OrderItemOption[]
  }): Promise<OrderItemOption[]> {
    const { options } = params

    const detailsPromises = options.map((option) =>
      getOptionDetails(option.sku)
    )

    const detailsResult = await Promise.all(detailsPromises)

    const optionsHydrated = options.map((option, index) => {
      const details = detailsResult[index]

      return {
        ...option,
        details,
      }
    })

    return optionsHydrated
  }
}
