<template>
  <div class="page-404-container">
    <h1>Error 404</h1>
    <span>
      It seems like we were unable to find the page you were looking for.
    </span>
    <button class="btn btn-1" type="button" @click="goBack">Go back</button>
    <img src="@/assets/icons/404-searching.gif" alt="404 page search" />
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',

  methods: {
    goBack() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss" scoped>
.page-404-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 216px;
  color: #2c3e48;
  box-sizing: border-box;

  h1 {
    font-size: 36px;
    line-height: 0.8;
    margin-bottom: 24px;
  }

  span {
    font-size: 18px;
    margin-bottom: 40px;
  }

  img {
    width: 170px;
  }

  button {
    line-height: 1.72;
    font-size: 18px;
    margin-bottom: 130px;
  }
}
</style>
