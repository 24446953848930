export const readactEmailIfExists = (value: string): string => {
  const emailPattern =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+(@|%40)[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/gi

  if (value.match(emailPattern)) {
    return value.replaceAll(emailPattern, '[redacted-email]')
  }

  return value
}
