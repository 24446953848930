<template>
  <div v-if="field" :class="getFieldWrapperClass()">
    <label-widget :field="field" :field-id="getFieldId()"></label-widget>
    <readonly-widget
      v-if="isReadOnly"
      :field="field"
      :copyable="isCopyable"
      :value="formattedDate"
    />
    <span v-if="!isReadOnly" class="mutt-field-wrapper-dateofbirth">
      <template
        v-for="(inputField, inputFieldIndex) in inputFields"
        :key="inputField.model"
      >
        <input
          :ref="inputField.model"
          v-model="$data[inputField.model]"
          :class="
            `mutt-field mutt-dateinput mutt-dateinput--${inputField.model}`
          "
          :required="required"
          v-bind="inputField.attrs"
          @keyup="jumper($event, inputField.model)"
          @keypress.enter.prevent="submitCallback"
          @click="checkJump(inputField.model, inputFieldIndex)"
          @focus="checkJump(inputField.model, inputFieldIndex)"
        />
        <span
          v-if="shouldShowSeparator(inputFieldIndex)"
          :key="`separator-${inputField.model}`"
          class="mutt-dateinput-separator"
          v-html="dateFieldSeparator"
        />
      </template>
    </span>
    <help-widget :field="field"></help-widget>
    <error-widget
      v-if="!isReadOnly"
      :field="field"
      :errors="errors"
      :error-class="getErrorClass()"
    ></error-widget>
  </div>
</template>

<script>
/* eslint-disable no-useless-escape */
import MuttVue from '@mutt/widgets-vue'
import { MomentDateValidator } from './validators/Validators.js'
import moment from 'moment'
export default {
  name: 'mutt-datetime',
  mixins: [MuttVue.mixin],
  emits: ['datetimeValidated'],
  data() {
    return {
      updateOnFirstSet: true,
      errors: null,
      value: null,
      initialised: false,
      date: null,
      day: null,
      month: null,
      year: null,
      hour: '00',
      minute: '00',
      second: '00',
      hideDay: false,
      min: null,
      max: null,
      required: null,
      dateFormat: 'day-month-year',
      isTimeField: false,
      activeFieldIndex: 0,
      jump: true,
      dateFieldSeparator: null,
    }
  },
  computed: {
    formattedDate() {
      let format = this.isTimeField ? 'h:mm:ss, MMMM Do YYYY' : 'MMMM Do YYYY'
      return moment.utc(this.field.value).format(format)
    },
    inputFields() {
      // Maps input types to array in order of dateFormat to be looped through as inputs
      let inputFields = this.dateFormat
        .split('-')
        .filter(date => !(this.hideDay && date === 'day'))
        .map(date => this.inputTypes[date])
      if (this.isTimeField) {
        let timeFields = ['hour', 'minute', 'second'].map(
          date => this.inputTypes[date]
        )
        inputFields = [...inputFields, ...timeFields]
      }
      return inputFields
    },
    inputFieldOrder() {
      return this.inputFields.map(fieldObject => fieldObject.model)
    },
    inputTypes() {
      return {
        day: {
          model: 'day',
          attrs: {
            type: 'text',
            inputmode: 'numeric',
            pattern: '^(0?[1-9]|[12]\\d|3[01])$', //double escape to preserve \d in regex
            placeholder: this.$t('DD'),
          },
        },
        month: {
          model: 'month',
          attrs: {
            type: 'text',
            inputmode: 'numeric',
            pattern: '^(0?[1-9]|1[012])$',
            placeholder: this.$t('MM'),
          },
        },
        year: {
          model: 'year',
          attrs: {
            type: 'text',
            inputmode: 'numeric',
            min: this.getMinYears,
            max: this.getMaxYears,
            placeholder: this.$t('YYYY'),
          },
        },
        hour: {
          model: 'hour',
          attrs: {
            type: 'text',
            inputmode: 'numeric',
            pattern: '^[01]?\\d|2[0-3]$', //double escape to preserve \d in regex
            placeholder: this.$t('hh'),
          },
        },
        minute: {
          model: 'minute',
          attrs: {
            type: 'text',
            inputmode: 'numeric',
            pattern: '^[0-5]?\\d$', //double escape to preserve \d in regex
            placeholder: this.$t('mm'),
          },
        },
        second: {
          model: 'second',
          attrs: {
            type: 'text',
            inputmode: 'numeric',
            pattern: '^[0-5]?\\d$', //double escape to preserve \d in regex
            placeholder: this.$t('ss'),
          },
        },
      }
    },
    getMinYears() {
      if (this.min) {
        return this.min.year()
      }
      return moment
        .utc()
        .add(moment.duration('P-120Y'))
        .year()
    },
    getMaxYears() {
      if (this.max) {
        return this.max.year()
      }
      return moment
        .utc()
        .add(moment.duration('P120Y'))
        .year()
    },
  },
  watch: {
    day() {
      this.preBuildValue('day')
    },
    month() {
      this.preBuildValue('month')
    },
    year() {
      this.preBuildValue('year')
    },
    hour() {
      this.preBuildValue('hour')
    },
    minute() {
      this.preBuildValue('minute')
    },
    second() {
      this.preBuildValue('second')
    },
    activeFieldIndex(newValue) {
      // If active field changes and exists, focus on it
      if (newValue > this.inputFields.length - 1 || newValue < 0) {
        this.activeFieldIndex = this.inputFields.length - 1
      } else {
        const activeField = this.inputFields[newValue]
        this.$refs[activeField.model][0].focus()
      }
    },
    'field.value': function(newValue) {
      // if the field.value gets set for the first time, run the following
      // this resolves an issue where the component can mount but not receive field.value until later
      if (this.updateOnFirstSet == true) {
        this.updateOnFirstSet = false
        this.setInitialValue(newValue, null)
      }
    },
  },
  mounted() {
    // build min date value from options string
    // ISO-8601 e.g 1970-01-01T00:00:00Z
    if (this.field.options.min) {
      // Check for ISO-8601 duration string - i.e P1Y
      if (this.field.options.min.startsWith('P')) {
        this.min = moment.utc().add(moment.duration(this.field.options.min))
      } else {
        this.min = moment.utc(this.field.options.min)
      }
    }
    if (this.field.options.showTime) {
      this.isTimeField = true
    }
    if (this.field.options.max) {
      // Check for ISO-8601 duration string - i.e P1Y
      if (this.field.options.max.startsWith('P')) {
        this.max = moment.utc().add(moment.duration(this.field.options.max))
      } else {
        this.max = moment.utc(this.field.options.max)
      }
    }
    // Set date format config
    if (this.field.options.dateFormat) {
      this.dateFormat = this.field.options.dateFormat
    }
    if (this.field.options.dateFieldSeparator) {
      this.dateFieldSeparator = this.field.options.dateFieldSeparator
    }
    let messages = this.field.options.messages || null
    // check if the day field is set to be hidden for this field
    if (this.field.options.hideDay) this.hideDay = true
    this.field.validators.push(
      new MomentDateValidator({
        min: this.min,
        max: this.max,
        messages,
        required: this.required,
      })
    )
    this.setInitialValue(this.field.value, null)
    this.initialised = true
    if (this.field.value && this.field.validate()) {
      // Emit that the field has been populated
      this.$emit('callback', {
        key: this.field.name,
        value: this.field.value,
        action: 'populated',
        validated: true,
        bubble: true,
      })
    }
  },
  created() {
    // Initialise i18n integration. No-op if not present
    if (!this.$t) {
      this.$t = str => str
    }
    if (this.field.options.required === false) {
      this.required = false
    } else {
      // The field is required if the options value is missing, undefined, null, or true.
      // This maintains the legacy behaviour of this field, which defaulted to being required.
      this.required = true
    }
    if (this.$i18n && this.$i18n.locale) {
      // set the moment locale
      moment.locale(this.$i18n.locale)
    }
  },
  methods: {
    zeroPad(value) {
      if (value && value.toString().length === 1) {
        value = `0${value}`
      }
      return value
    },
    getFieldClass() {
      return 'mutt-field mutt-field-choice mutt-field-dateinput'
    },
    getFieldWrapperClass() {
      if (this.hasErrors) {
        return `mutt-field-wrapper ${this.getErrorWrapperClass()}`
      }
      return 'mutt-field-wrapper'
    },
    preBuildValue() {
      // Called when any of the fields changes
      if (this.initialised) {
        this.buildValue()
      }
    },
    buildValue() {
       // If the day field is hidden set the day value to '01'
        // so that the date will validate successfully
        if (this.hideDay) {
          this.day = '01'
        }
        // YY can be a valid but ambiguious date
        // Check we have the right length
        let yearValidLength = false
        if (this.year) {
          if (this.year.toString().length > 3) {
            yearValidLength = true
          }
        }
        const reset = () => {
          this.field.refreshValidationState(false)
          this.field.value = null
        }
        if (
          yearValidLength &&
          this.day &&
          this.month &&
          (this.hour || this.hour === 0) && // hour can be 0 which is falsy
          (this.minute || this.minute === 0) && // minute can be 0 which is falsy
          (this.second || this.second === 0) // second can be 0 which is falsy
        ) {
          const date = `${this.year}-${this.zeroPad(this.month)}-${this.zeroPad(
            this.day
          )}`
          const time = `${this.zeroPad(this.hour)}:${this.zeroPad(
            this.minute
          )}:${this.zeroPad(this.second)}`
          let value
          if (this.isTimeField) {
            value = moment.utc(`${date}T${time}Z`)
          } else {
            value = moment.utc(`${date}`)
          }
          if (value.isValid()) {
            this.field.value = value
            const validated = this.field.validate()

            this.$emit('datetimeValidated', {
              key: this.field.name,
              value: this.field.value,
              action: 'populated',
              validated,
              bubble: true,
            })
          } else {
            reset()
          }
        } else {
          reset()
        }
    },
    restrictDates(dateElement, dateVal) {
      switch (dateElement) {
        case 'second':
        case 'minute':
          if (dateVal > 59) {
            this[dateElement] = '59'
          }
          break
        case 'hour':
          if (dateVal > 23) {
            this.hour = '23'
          }
          break
        case 'day':
          if (dateVal > 31) {
            this.day = '31'
          } else if (dateVal === '00') {
            this.day = '01'
          }
          break
        case 'month':
          if (dateVal > 12) {
            this.month = '12'
          } else if (dateVal === '00') {
            this.month = '01'
          }
          break
        default:
          break
      }
    },
    jumper() {
      // Get currently active field and jump cursor if date validates
      const activeField = this.inputFields[this.activeFieldIndex]
      switch (activeField.model) {
        case 'day': {
          this.updateActiveField('day', 2)
          break
        }
        case 'month': {
          this.updateActiveField('month', 2)
          break
        }
        case 'year': {
          this.updateActiveField('year', 4)
          break
        }
        case 'hour': {
          this.updateActiveField('hour', 2)
          break
        }
        case 'minute': {
          this.updateActiveField('minute', 2)
          break
        }
        case 'second': {
          this.updateActiveField('second', 2)
          break
        }
        default:
          break
      }
    },
    checkJump(type, index) {
      // update activeFieldIndex position, if user manually selects field
      this.activeFieldIndex = index
      // We need to disable the jump if people edit or use a non-standard
      // order....
      if (this[type]) {
        this.jump = false
      }
    },
    updateActiveField(field, minLength) {
      if (this[field]) {
        // Update the day input field to within the
        // allowed range if necessary
        this.restrictDates(field, this[field])
        if (this[field].length > minLength - 1 && this.jump) {
          this.activeFieldIndex += 1
        }
      }
    },
    focus() {
      // Focus on first field for the first time
      this.$nextTick(() => {
        this.$refs[this.inputFields[this.activeFieldIndex].model][0].focus()
      })
    },
    resetInputs() {
      this.year = null
      this.month = null
      this.day = null
      this.jump = true
      this.hour = '00'
      this.minute = '00'
      this.second = '00'
    },
    isNotZero(value) {
      return value !== '0'
    },
    refresh() {
      this.initialised = false
      const value = moment.isMoment(this.field.value)
        ? this.field.value
        : moment.utc(this.field.value)
      if (value.isValid()) {
        this.second = this.zeroPad(value.second())
        this.minute = this.zeroPad(value.minute())
        this.hour = this.zeroPad(value.hour())
        this.day = this.zeroPad(value.date())
        this.month = this.zeroPad(value.month() + 1)
        this.year = value.year()
      } else {
        this.resetInputs()
      }
      this.initialised = true
    },
    setInitialValue(newValue, oldValue) {
      let initialValue = oldValue
      if (newValue) {
        initialValue = moment.utc(newValue)
      } else if (this.field.options.default) {
        initialValue = moment.utc(this.field.options.default)
      }
      if (initialValue) {
        this.day = this.zeroPad(initialValue.date())
        this.month = this.zeroPad(initialValue.month() + 1)
        this.year = initialValue.year()
      }
    },
    shouldShowSeparator(fieldIndex) {
      // The separator should only be called between date fields:
      // if the current index is a date field (day/month/year)
      // and the next field is also a date field, show the separator
      if (
        this.dateFieldSeparator &&
        this.inputFields[fieldIndex] &&
        this.inputFields[fieldIndex + 1]
      ) {
        const thisField = this.inputFields[fieldIndex]
        const nextField = this.inputFields[fieldIndex + 1]
        if (
          ['day', 'month', 'year'].indexOf(thisField.model) > -1 &&
          ['day', 'month', 'year'].indexOf(nextField.model) > -1
        ) {
          return true
        }
      }
      return false
    },
  },
}
</script>
