<template>
  <div
    v-if="field"
    class="mutt-field-wrapper mutt-field-wrapper-payee-vet_id"
    :class="getFieldWrapperClass()"
  >
    <ReadonlyWidget
      :value="formattedValue"
      :data-qa-locator="qaLocator ? `${qaLocator}-readonly` : null"
    />
  </div>
</template>

<script>
import MuttVue from '@mutt/widgets-vue'

import dayjs from '@/lib/dayjs'

export default {
  name: 'MuttReadonlyDate',
  for: 'readonly-date',
  mixins: [MuttVue.mixin],
  computed: {
    formattedValue() {
      return dayjs(this.field.value).format('MMMM Do YYYY')
    },
  },
}
</script>
