export const knowAggregatorSources = [
  { key: 's-aggregator-ctm', value: 'CTM', name: 'Compare The Market' },
  { key: 's-aggregator-msm', value: 'MSM', name: 'Money Supermarket' },
  { key: 's-aggregator-goco', value: 'GOCO', name: 'Go Compare' },
  { key: 's-aggregator-confused', value: 'CONFUSED', name: 'Confused.com' },
  { key: 's-aggregator-quidco', value: 'QUIDCO', name: 'Quidco' },
  { key: 's-aggregator-forbes', value: 'FORBES', name: 'Forbes' },
  { key: 's-aggregator-es', value: 'ES', name: 'Evening Standard' },
] as const

export const knownQuoteSources = [
  { key: 'app-quotebuy/se', value: 'Q&B' },
  { key: 'app-se-quotebuy-katt-hund', value: 'Q&B' },
  { key: '@manypets/se-quotebuy-katt-hund', value: 'Q&B' },
  { key: 'se-quotebuy-katt-hund', value: 'Q&B' },
  { key: 'app-bbm-quotebuy-cat-dog', value: 'Q&B' },
  { key: 'app-quotebuy/uk-pio', value: 'Q&B' },
  { key: 'app-quotebuy/uk', value: 'Q&B' },
  { key: 'app-quotebuy/us', value: 'Q&B' },
  { key: 'uk-quotebuy-cat-dog', value: 'Q&B' },
  {
    key: 'app-bbm-quotebuy-medical-travel',
    value: 'Q&B',
  },
  {
    key: 'app-aggs-quotebuy-cat-dog-pio',
    value: 'Q&B (Aggs)',
  },
  { key: '@boughtbymany/app-account/uk-account', value: 'My Account' },
  { key: 'uk-account', value: 'My Account' },
  { key: 'distribution', value: 'API (Distribution)' },
  { key: 'uk', value: 'UK' },
  { key: 'app-io-pas', value: 'PIO' },
  { key: 'legacy-cat-dog', value: 'Legacy (cat-dog)' },

  { key: 'cerberus-api', value: 'System Test' },
  { key: 'postman-system-tests', value: 'System Test' },
  { key: 'shop-system-test', value: 'System Test' },
  { key: 's-claim-tests-api', value: 'System Test' },
  ...knowAggregatorSources,
] as const
