import type { Lifecycle } from '@/@types/Lifecycle'
import { apiClient } from '@/lib/api/ApiClient'

/**
 * Fetches hydrated lifecycles by user.
 *
 * @param {object} params The params to send with the request.
 * @param {string} params.customerId The customer ID
 * @returns {Array} The customer hydrated lifecycles
 */
export async function fetchHydratedLifecycles({
  customerId,
}: {
  customerId: string
}): Promise<Lifecycle[]> {
  return await apiClient
    .get(`/v2/hydrated-lifecycles`, {
      searchParams: {
        customer_uuid: customerId,
      },
    })
    .json()
}
