// FIXME: since we switched from Jest to Vitest we should revisit this file and
// the comments below

// We should use this module whenever we need to resolve urls(usually dynamic)
// that rely on import.meta.url which currently causes ESM problems for Jest.
// Having all "import.meta.url"s in one file is easy to mock when testing.

// There is "__mocks__/assetsUrl.ts" and inside tests, we can simply mock the file
// with "jest.mock('@/lib/assetsUrls')" and Jest won't try to import and break
// because of this file.

// Vite uses import.meta.url to analize urls(static strings or dynamic template
// literals) when building/bundling.
// ref: https://vitejs.dev/guide/assets.html#new-url-url-import-meta-url

export type ImageSize = 'large' | 'medium' | 'small'
export type AppTheme = 'dark' | 'light'

/**
 * Returns the url of the agency logo
 *
 * @param {object} options Options
 * @param {string|null|undefined} options.agency The agency name
 * @param {string} options.size The size (small/medium)
 * @param {string} options.variant The variant (light/dark)
 * @returns {string} Url to the agency logo
 */
export function getAgencyLogoUrl({
  agency = '',
  size = 'small',
  variant = 'light',
}: {
  agency?: Nullable<string>
  size?: ImageSize
  variant?: AppTheme
}): string {
  if (agency) {
    return new URL(
      `/src/assets/icons/agencies/${agency.toLowerCase()}-${size}-${variant}.svg`,
      import.meta.url
    ).href
  } else {
    return getSiteLogoUrl(variant)
  }
}

/**
 * Returns the url of the PIO logo
 *
 * @param {string} variant (light/dark)
 * @returns {string} Url to the PIO logo
 */
export function getSiteLogoUrl(variant: AppTheme = 'dark'): string {
  return new URL(`/src/assets/icons/logo-${variant}.svg`, import.meta.url).href
}

export function getServiceBadgeUrl({
  type = 'on',
  variant = 'light',
}: {
  variant: AppTheme
  type: 'off' | 'on'
}): string {
  return new URL(
    `/src/assets/icons/service_badge-${type}-${variant}.svg`,
    import.meta.url
  ).href
}
