import type { Auth0VueClientOptions } from '@auth0/auth0-vue'

import { createAuth0 } from '@/lib/auth0/auth0-vue-proxy'
import { plrn, instance } from '@/plrn'

const authorizationParams: Auth0VueClientOptions['authorizationParams'] = {
  redirect_uri: `${window.location.origin}/login`,
}

if (plrn && instance) {
  // Set audience and scope as explained in this GitHub issue:
  // https://github.com/auth0/auth0-spa-js/issues/895
  authorizationParams.audience = instance
  authorizationParams.scope = `profile email ${plrn}`
}

const auth0 = createAuth0({
  domain: import.meta.env.VITE_AUTH0_DOMAIN as string,
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID as string,
  authorizationParams,
})

export default auth0
