import type { ApiListParams, ApiListSearchParams } from '@/@types/ApiResponse'
import type { PatchOperation } from '@/@types/JSONPatch'
import type { PolicyRenewalTimelineEvent } from '@/@types/TimelineEvent'
import ErrorResponseFactory from '@/domain/Error/ErrorResponseFactory'
import type { Policy, PolicyCoolingOffStatus } from '@/domain/Policy'
import { CLIENT_IDENTIFIER } from '@/lib/api/ApiClient'
import { fetchHydratedLifecycles } from '@/lib/api/global/Lifecycles'
import dayjs from '@/lib/dayjs'
import {
  getPolicyTimelineEventsFromLifecycle,
  getRenewalFromTimeline,
} from '@/lib/utils/lifecycles'

import ApiService from './ApiService'
import type { PaginatedResponse } from './parsers/PaginatedParser'
import PoliciesParser from './parsers/PoliciesParser'

const POLICIES_PATH = '/policies'

export interface PolicyListParams extends ApiListParams {
  cessation_date?: string
  policy_holder_email?: string
  policy_holder_swedish_identity_number?: string
  inception_date?: string
  owner?: string
  paid_until_date?: string
  status?: string
  productline?: string
  productline_key?: string
  quote?: string
  ref?: string
  schema?: string
  subscription?: string
}

export interface V2PolicyListParams extends PolicyListParams {
  include_legacy: boolean
}

export default class PoliciesService {
  public static async get(policyId: string): Promise<Policy> {
    try {
      return await ApiService.get({
        path: `${POLICIES_PATH}/${policyId}`,
        parse: PoliciesParser.parse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error fetching policy',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }

  public static async cancelV1(
    policyId: string,
    params: {
      effective_date: string
      reason: string
    }
  ): Promise<string> {
    try {
      return await ApiService.post({
        path: `${POLICIES_PATH}/${policyId}/cancel`,
        params: {
          json: {
            ...params,
            source: CLIENT_IDENTIFIER,
          },
        },
        parse: (data: { quote_uuid: string }) => data.quote_uuid,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error cancelling v1 policy',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }

  public static async undoCancellation(policyId: string): Promise<void> {
    try {
      await ApiService.post({
        path: `/v2${POLICIES_PATH}/${policyId}/undo_cancellation`,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error undoing cancellation',
        originalError: error,
        tags: ['s-policy'],
        disableAutoLog: true,
      })

      throw errorClass
    }
  }

  public static async mta(
    policyId: string,
    params: {
      effective_date: string
      steps: Array<{
        mta_type: string
        patches: PatchOperation[]
      }>
    }
  ): Promise<string> {
    const { effective_date, steps } = params

    try {
      return await ApiService.post({
        path: `${POLICIES_PATH}/${policyId}/mta`,
        params: {
          json: {
            effective_date,
            steps,
            source: CLIENT_IDENTIFIER,
          },
        },
        parse: (data: { quote_uuid: string }) => data.quote_uuid,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error making mid-term adjustment',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }

  public static async getCancellationRefundAmount(
    policyId: string,
    params: {
      effectiveDate: string
      cancellationQuoteId: string
    }
  ): Promise<number> {
    const { effectiveDate, cancellationQuoteId } = params

    try {
      return await ApiService.post({
        path: `${POLICIES_PATH}/${policyId}/preview-cancel`,
        params: {
          searchParams: {
            cancellation_quote_uuid: cancellationQuoteId,
          },
          json: {
            effective_date: effectiveDate,
          },
        },
        parse: (data: { refund_amount: number }) => data.refund_amount,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error fetching cancellation refund amount',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }

  public static async getRenewal(
    policyId: string,
    params: {
      owner: string
    }
  ): Promise<Maybe<PolicyRenewalTimelineEvent>> {
    const { owner } = params

    try {
      const lifecycles = await fetchHydratedLifecycles({
        customerId: owner,
      })

      const lifecycleForPolicy = lifecycles.find(
        (lifecycle) => lifecycle.policy_uuid === policyId
      )

      /**
       * Returns a single renewal item from the timeline if one exists, otherwise this will return undefined.
       */
      if (lifecycleForPolicy) {
        return getRenewalFromTimeline(
          getPolicyTimelineEventsFromLifecycle(lifecycleForPolicy)
        )
      }
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error getting renewal',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }

  public static async getInvoices(policyId: string): Promise<unknown[]> {
    try {
      return await ApiService.get({
        path: `${POLICIES_PATH}/${policyId}/invoices`,
        parse: (data: unknown[]) => data,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error fetching invoices',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }

  public static async getStripeCustomer(policyId: string): Promise<unknown> {
    try {
      return await ApiService.get({
        path: `${POLICIES_PATH}/${policyId}/stripe_customer`,
        parse: (data: unknown) => data,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error fetching stripe customer',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }

  public static async getPolicies(
    params: PolicyListParams
  ): Promise<PaginatedResponse<Policy>> {
    try {
      return await ApiService.get({
        path: POLICIES_PATH,
        params: {
          searchParams: { ...params },
        },
        parse: PoliciesParser.parsePaginatedResponse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error fetching policies',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }

  public static async getAllPoliciesForCustomer(params: {
    customerId: string
  }): Promise<Maybe<Policy[]>> {
    const { customerId } = params

    const result = []
    let hasMore = true
    let pageNumber = 1

    do {
      try {
        const response = await PoliciesService.getPolicies({
          owner: customerId,
          page_number: pageNumber,
          per_page: 20,
        })

        result.push(...response.items)
        hasMore = response.total > result.length
        pageNumber++
      } catch (error) {
        if (result.length === 0) {
          const errorClass = await ErrorResponseFactory.instantiateErrorClass({
            title: `Error fetching policies page ${pageNumber}`,
            originalError: error,
            tags: ['s-policy'],
          })

          throw errorClass
        }

        console.error(`Error fetching policies page ${pageNumber}`)
        hasMore = false
      }
    } while (hasMore)

    if (result) {
      return result
    }
  }

  public static async search(
    params: ApiListSearchParams
  ): Promise<PaginatedResponse<Policy>> {
    try {
      return await ApiService.get({
        path: `${POLICIES_PATH}/action/search`,
        params: {
          searchParams: { ...params },
        },
        parse: PoliciesParser.parsePaginatedResponse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error searching policies',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }

  public static async getDocumentUrl(
    policyId: string,
    params: { document: string }
  ): Promise<string> {
    const { document } = params

    try {
      return await ApiService.get({
        path: `${POLICIES_PATH}/${policyId}/documents/${document}`,
        parse: (data: string) => data,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error fetching document url',
        originalError: error,
        tags: ['s-policy'],
        disableAutoLog: true,
      })

      throw errorClass
    }
  }

  public static async getCoolingOffStatus(
    policyId: string
  ): Promise<PolicyCoolingOffStatus> {
    try {
      return await ApiService.get({
        path: `${POLICIES_PATH}/${policyId}/action/get-cooling-off-status`,
        params: {
          searchParams: {
            date: dayjs.utc().format('YYYY-MM-DD'),
          },
        },
        parse: PoliciesParser.parseCoolingOffStatus,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error fetching cooling off status',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }

  public static async emailDocuments(policyId: string): Promise<Response> {
    try {
      return await ApiService.post({
        path: `${POLICIES_PATH}/${policyId}/email-documents`,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error emailing documents',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }

  public static async suspend(policyId: string): Promise<Response> {
    try {
      return await ApiService.post({
        path: `${POLICIES_PATH}/${policyId}/suspend`,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error suspending policy',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }

  public static async unsuspend(policyId: string): Promise<Response> {
    try {
      return await ApiService.post({
        path: `${POLICIES_PATH}/${policyId}/unsuspend`,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error unsuspending policy',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }
}
