<template>
  <div v-if="field" class="mutt-tokenizer" :class="getFieldWrapperClass()">
    <component
      :is="autocompleteWidget"
      v-if="!isReadOnly && autoField"
      :field="autoField"
      :clear-on-select="true"
      @select="addToken"
    />
    <ul v-if="!isReadOnly">
      <li v-for="(selection, index) in tokens" :data-item="index">
        {{ formatValue(selection) }}
        <span class="delete-item" @click="removeToken(index)"> X</span>
      </li>
    </ul>
    <label-widget v-if="isReadOnly" :field="field" :field-id="getFieldId()" />
    <readonly-widget
      v-if="isReadOnly"
      :field="field"
      :copyable="isCopyable"
      :value="readOnlyValues()"
    />
    <error-widget
      v-if="!isReadOnly"
      :field="field"
      :errors="errors"
      :error-class="getErrorClass()"
    />
  </div>
</template>

<script>
/*
  Mutt tokenizer
  Widget with autocomplete lookup to algolia.
  Allows selection of multiple items
  Adds selected entities underneath
  Usage - Set widget name to "tokenizer"
  Requires - "alogia" config object in field options, must include
            a API id, API key, Index.

*/
import Mutt from '@mutt/forms'
import MuttVue from '@mutt/widgets-vue'
import stringFormat from 'string-format'

import MuttAutoComplete from './MuttAutoComplete.vue'

export default {
  name: 'MuttTokenizer',
  for: 'tokenizer',
  mixins: [MuttVue.mixin],
  data() {
    return {
      errors: null,
      value: null,
      tokens: [],
      autoField: null,
      autocompleteWidget: MuttAutoComplete,
    }
  },
  watch: {
    tokens(tokens) {
      this.value = tokens
      this.field.value = this.value
    },
  },
  mounted() {
    this.autoField = new Mutt.fields.StringField({
      id: this.field.id,
      name: this.field.name,
      options: this.field.options,
      parent: this.field,
    })

    // Careful now....
    this.$nextTick().then(() => {
      if (this.field.value) {
        const newTokens = this.field.value.slice()
        this.tokens = newTokens
      }
    })
  },
  methods: {
    addToken(value) {
      this.tokens.push(value)
    },
    removeToken(index) {
      this.tokens.splice(index, 1)
    },
    formatValue(selectedObject) {
      if (!selectedObject) {
        return ''
      }

      if (this.field.options.hasOwnProperty('algolia')) {
        return stringFormat(this.field.options.algolia.format, selectedObject)
      } else if (this.field.options.hasOwnProperty('formatter')) {
        return this.field.options.formatter(selectedObject)
      }

      return ''
    },
    readOnlyValues() {
      return this.value
        .map((val) => {
          return this.formatValue(val)
        })
        .join(', ')
    },
  },
}
</script>
