export const inputAutoFocus = {
  mounted: (el: HTMLElement): void => {
    // Find the input element inside the wrapper
    const inputElement = el.querySelector('input')
    if (inputElement) {
      setTimeout(() => {
        inputElement?.focus()
      }, 100)
    }
  },
}
