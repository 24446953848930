import { ApiListResponse } from '@/@types/ApiResponse'
import type { Quote } from '@/domain/Quote/Quote'
import { knownQuoteSources } from '@/lib/quoteSources'

import PaginatedParser from './PaginatedParser'
import type { PaginatedResponse } from './PaginatedParser'

type QuoteSources = (typeof knownQuoteSources)[number]['key']

export type QuoteJson = Quote

export interface QuickQuoteJson {
  quote: QuoteJson
  token: string
}

export default class QuoteParser {
  public static parsePaginatedResponse(
    data: ApiListResponse<QuoteJson>
  ): PaginatedResponse<Quote> {
    return PaginatedParser.parsePaginatedResponse(data, QuoteParser.parseMany)
  }

  public static parseMany(data: QuoteJson[]): Quote[] {
    return data.map((quoteJson) => QuoteParser.parse(quoteJson))
  }

  public static parse(data: QuoteJson): Quote {
    return {
      ...data,
      source: QuoteParser.parseSource(data.source),
    }
  }

  public static parseQuickQuote(data: QuickQuoteJson): Quote {
    return data.quote
  }

  public static parseSource(source: QuoteSources | string): string {
    return (
      knownQuoteSources.filter(
        (knownQuoteSource) => knownQuoteSource.key === source
      )[0]?.value ?? source
    )
  }
}
