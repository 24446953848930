<template>
  <QuestionIcon
    class="pio-hint"
    title="Hint"
    :color="iconColor"
    v-bind="$attrs"
    @click="showHint"
  />
</template>

<script>
import QuestionIcon from '@/ui/icons/QuestionIcon.vue'

export default {
  name: 'PioHint',
  components: { QuestionIcon },
  props: {
    header: {
      type: String,
      required: false,
      default: undefined,
    },
    body: {
      type: String,
      required: false,
      default: undefined,
    },
    iconColor: {
      type: String,
      default: undefined,
    },
  },
  methods: {
    showHint() {
      this.$alert({
        title: this.header,
        message: this.body,
        customClass: 'hint-dialog',
        closeOnClickModal: true,
        closeOnPressEscape: true,
      })
    },
  },
}
</script>

<style lang="scss">
.pio-hint {
  cursor: pointer;
}

.el-message-box.hint-dialog {
  width: 500px;

  p {
    white-space: pre-line;
  }
}
</style>
