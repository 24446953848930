import type { ApiListResponse } from '@/@types/ApiResponse'
import type { PolicyCoolingOffStatus } from '@/domain/Policy'
import type { PolicyV2 } from '@/domain/PolicyV2'

import PaginatedParser, { PaginatedResponse } from './PaginatedParser'

export type PolicyJson = PolicyV2
export type PolicyCoolingOffStatusJson = PolicyCoolingOffStatus

export default class PoliciesParserV2 {
  public static parsePaginatedResponse(
    data: ApiListResponse<PolicyJson>
  ): PaginatedResponse<PolicyV2> {
    return PaginatedParser.parsePaginatedResponse(
      data,
      PoliciesParserV2.parseMany
    )
  }

  public static parseMany(data: PolicyJson[]): PolicyV2[] {
    return data.map((quoteJson) => PoliciesParserV2.parse(quoteJson))
  }

  public static parse(data: PolicyJson): PolicyV2 {
    return data
  }

  public static parseCoolingOffStatus(
    data: PolicyCoolingOffStatusJson
  ): PolicyCoolingOffStatus {
    return data
  }
}
