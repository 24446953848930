import moment from 'moment'
import Mutt from '@mutt/forms'

/**
 * MomentDateTimeValidator - Validate the moment date object is between
 * certain values
 *
 * @class
 */
export class MomentDateTimeValidator extends Mutt.validators.Validator {
  constructor({
    min = null,
    max = null,
    messages = null,
    required = true,
  } = {}) {
    super(messages)

    if (min !== null) {
      this.min = min
    }

    if (max !== null) {
      this.max = max
    }

    this.messages = messages
    this.required = required
  }

  validate(value) {
    const regex = /\$\[([^\]]+)\]/g
    if (!value) {
      if (!this.required) {
        return true
      }

      this.error = 'This field is required.'
      return false
    }

    if (typeof value === 'string') {
      value = moment.utc(value)
    }

    if (!moment.isMoment(value)) {
      return false
    }

    if (
      Object.prototype.hasOwnProperty.call(value, 'isValid') &&
      !value.isValid()
    ) {
      this.error = 'Please enter a valid date.'
      return false
    }

    if (this.min && value < this.min) {
      if (this.messages && this.messages.min) {
        if (regex.exec(this.messages.min) !== null) {
          this.error = this.messages.min.replace(
            regex,
            this.min.format(this.messages.format || 'DD/MM/YYYY')
          )
        } else {
          this.error = this.messages.min
        }
      } else {
        this.error = 'Date is before earliest allowed.'
      }
      return false
    }

    if (this.max && value > this.max) {
      if (this.messages && this.messages.max) {
        if (regex.exec(this.messages.max) !== null) {
          this.error = this.messages.max.replace(
            regex,
            this.max.format(this.messages.format || 'DD/MM/YYYY')
          )
        } else {
          this.error = this.messages.max
        }
      } else {
        this.error = 'Date is after maximum allowed.'
      }
      return false
    }

    return true
  }
}

/**
 * MomentDateValidator - Validate the moment date object is between
 * certain values
 *
 * @class
 */
export class MomentDateValidator extends MomentDateTimeValidator {
  constructor({
    min = null,
    max = null,
    messages = null,
    required = null,
  } = {}) {
    super({ min, max, messages, required })

    if (min !== null) {
      this.min = this.min.startOf('day')
    }

    if (max !== null) {
      this.max = this.max.endOf('day')
    }

    this.messages = messages
  }
}
