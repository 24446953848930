import { ProductLineName } from '@/@types/Config'
import { getConfigForFeature } from '@/lib/appConfig'

export function getSgpProductLines(): ProductLineName[] {
  return (getConfigForFeature('sgpProductLines') ?? []) as ProductLineName[]
}

export function disableOldFlow(): boolean {
  return Boolean(getConfigForFeature('flags.disableOldQuotingFlow'))
}

export function newFlowEnabled(): boolean {
  const forceDisableNewFlow = Boolean(
    getConfigForFeature('flags.forceDisableNewFlow')
  )

  if (forceDisableNewFlow) {
    return false
  }

  const enableNewFlow = Boolean(getConfigForFeature('flags.enableNewFlow'))
  const isNewFlowEnabledForUser = Boolean(
    getConfigForFeature('sgp.force-enabled')
  )

  return enableNewFlow || isNewFlowEnabledForUser
}
