import { ApiListResponse } from '@/@types/ApiResponse'
import {
  Customer,
  CustomerAddress,
  CustomerNote,
  CustomerWithAddress,
} from '@/domain/Customer'

import PaginatedParser, { PaginatedResponse } from './PaginatedParser'

export type CustomerJson = Customer
export type CustomerAddressJson = CustomerAddress
export type CustomerWithAddressJson = CustomerWithAddress
export type CustomerNoteJson = CustomerNote

export default class CustomersParser {
  public static parsePaginatedResponse(
    data: ApiListResponse<CustomerJson>
  ): PaginatedResponse<Customer> {
    return PaginatedParser.parsePaginatedResponse(
      data,
      CustomersParser.parseMany
    )
  }

  public static parsePaginatedAddressResponse(
    data: ApiListResponse<CustomerAddressJson>
  ): PaginatedResponse<CustomerAddress> {
    return PaginatedParser.parsePaginatedResponse(
      data,
      CustomersParser.parseAddressMany
    )
  }

  public static parsePaginatedNoteResponse(
    data: ApiListResponse<CustomerNoteJson>
  ): PaginatedResponse<CustomerNote> {
    return PaginatedParser.parsePaginatedResponse(
      data,
      CustomersParser.parseNoteMany
    )
  }

  public static parseMany(data: CustomerJson[]): Customer[] {
    return data.map(CustomersParser.parse)
  }

  public static parseAddressMany(
    data: CustomerAddressJson[]
  ): CustomerAddress[] {
    return data.map((address) => CustomersParser.parseAddress(address))
  }

  public static parseNoteMany(data: CustomerNoteJson[]): CustomerNote[] {
    return data.map(CustomersParser.parseNote)
  }

  public static parse(data: CustomerJson): Customer {
    return data
  }

  public static parseAddress(data: CustomerAddressJson): CustomerAddress {
    return data
  }

  public static parseNote(data: CustomerNoteJson): CustomerNote {
    return data
  }
}
