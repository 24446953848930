<template>
  <div
    v-if="field"
    class="mutt-field-wrapper--hidden"
    :class="getFieldWrapperClass()"
    :data-qa-locator="qaLocator"
  >
    <input
      v-model="value"
      type="hidden"
      class="mutt-field-hidden"
      :class="getFieldClass()"
      :name="field.name"
      :data-qa-locator="qaLocator ? `${qaLocator}-input` : null"
    />
  </div>
</template>

<script>
import WidgetMixin from '../mixins/WidgetMixin'

export default {
  name: 'MuttHidden',
  for: 'hidden',
  mixins: [WidgetMixin],
}
</script>
