import ElementPlus from 'element-plus'
import '@/theme.scss'

const ElementPlugin = {
  install(app) {
    app.use(ElementPlus)

    // Returns `"confirm"` when confirmed and `"close"/"cancel"` when closed otherwise
    const originalAlert = app.config.globalProperties.$alert
    app.config.globalProperties.$alert = async ({
      title,
      message,
      ...options
    }) => {
      try {
        return await originalAlert(message, title, options)
      } catch (actionOrError) {
        if (actionOrError instanceof Error) {
          throw actionOrError
        }

        return actionOrError
      }
    }

    // Returns `true` when confirmed and `false` when closed otherwise
    const originalConfirm = app.config.globalProperties.$confirm
    app.config.globalProperties.$confirm = async ({
      title,
      message,
      ...options
    }) => {
      try {
        await originalConfirm(message, title, options)
        return true
      } catch (actionOrError) {
        if (actionOrError instanceof Error) {
          throw actionOrError
        }

        return false
      }
    }

    // Returns object `{ value: 'input value', action: 'confirm' }` when confirmed
    // and `{ action: 'close/cancel' }` when closed otherwise
    const originalPrompt = app.config.globalProperties.$prompt
    app.config.globalProperties.$prompt = async ({
      title,
      message,
      ...options
    }) => {
      try {
        return await originalPrompt(message, title, options)
      } catch (actionOrError) {
        if (actionOrError instanceof Error) {
          throw actionOrError
        }

        return { action: actionOrError }
      }
    }
  },
}

export default ElementPlugin
