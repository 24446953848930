/**
 * @file Text Field
 */

'use strict'

import Mutt from '../index'

import { Field } from './core'

/**
 * String Field, used to input text values
 *
 * @class
 */
export class StringField extends Field {
  get type() {
    return 'string'
  }

  getWidget() {
    return Mutt.config.getWidget('text')
  }

  /**
   * Initial value for a field of this type
   */
  initialValue() {
    return ''
  }
}
