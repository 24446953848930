<template>
  <div v-if="field" class="mutt-field-wrapper">
    <label-widget :field="field" :field-id="getFieldId()" />
    <readonly-widget
      v-if="isReadOnly"
      :field="field"
      :copyable="isCopyable"
      :value="field.value"
    />
    <div class="pca-search">
      <input
        v-show="!isReadOnly && !manual"
        ref="pcaSearchValue"
        v-model="pcaSearchValue"
        class="address_postcode mutt-field"
        type="text"
        name="pcaSearchValue"
        :placeholder="field.options.placeholder"
        @keydown.enter="checkSubmit"
      />
    </div>
    <div v-if="manual" class="manual">
      <mutt-widget
        v-for="objectField of sortedPcaElements"
        :key="objectField.id"
        :field="objectField"
      />
    </div>
    <div v-if="pcaControl && !isReadOnly" class="show-manual">
      <a
        v-if="manual"
        class="button"
        data-qa-locator="postcode-lookup-manual-toggle"
        @click.prevent="toggleManual(false)"
      >
        <span class="green-icon"> &lt;</span> {{ searchToggleText }}
      </a>
      <a
        v-else
        class="button show-manual__link"
        data-qa-locator="postcode-lookup-manual-toggle"
        @click.prevent="toggleManual(true)"
      >
        {{ manualToggleText }}
      </a>
    </div>
    <help-widget :field="field" />
    <error-widget
      v-if="!isReadOnly"
      :field="field"
      :errors="errors"
      :error-class="getErrorClass()"
    />
    <button
      v-if="showDoneButton"
      class="btn"
      type="submit"
      :disabled="disabled"
      @click.prevent="callback"
    >
      Done
    </button>
  </div>
</template>

<script>
import MuttVue from '@mutt/widgets-vue'

import { PostCodeValidator } from './validators/Validators.js'
const CSS_URL = `https://services.postcodeanywhere.co.uk/css/captureplus-2.30.min.css`
const CDN_URL = `https://services.postcodeanywhere.co.uk/js/captureplus-2.30.min.js`
export default {
  name: 'MuttPca',
  for: 'pca',
  mixins: [MuttVue.mixin],
  data() {
    return {
      errors: null,
      value: null,
      pcaSearchValue: '',
      manual: false,
      pcaControl: null,
      pcaFailed: null,
      pcaOptions: {},
      pcaFields: [],
      canSubmitOnEnter: false,
      manualToggleText: "Can't find your address?",
      searchToggleText: 'Prefer to search for your address?',
      showDoneButton: true,
      switchWhenFinished: false, // switch to manual mode when address selected
    }
  },
  computed: {
    disabled() {
      const line1Value = this.field.getFieldByPath('line1').value
      const line2Value = this.field.getFieldByPath('line2').value
      const cityValue = this.field.getFieldByPath('city').value
      const postcodeValue = this.field.getFieldByPath('postcode').value
      if (line1Value || line2Value) {
        if (cityValue && postcodeValue) {
          return false
        }
        return true
      } else {
        return true
      }
    },
    sortedPcaElements() {
      /*
       * Mutt displays the fields in the order they appear
       * in the schema. However, we rather show them in the
       * order they have in pca.elements
       */
      const acc = []
      for (const element of this.field.options.pca.elements) {
        if (this.field.object[element.element]) {
          acc.push(this.field.object[element.element])
        }
      }
      return acc
    },
  },
  mounted() {
    if (!Object.prototype.hasOwnProperty.call(this.field.options, 'pca')) {
      throw new Error(`Field missing pca options!`)
    }
    if (!this.field.options.pca.key) {
      throw new Error(`PCA_KEY not present!`)
    }
    if (
      Object.prototype.hasOwnProperty.call(
        this.field.options.pca,
        'defaultManual'
      )
    ) {
      this.manual = this.field.options.pca.defaultManual
    }
    if (this.field.options.pca.manualToggleText) {
      this.manualToggleText = this.field.options.pca.manualToggleText
    }
    if (this.field.options.pca.searchToggleText) {
      this.searchToggleText = this.field.options.pca.searchToggleText
    }
    if (
      Object.prototype.hasOwnProperty.call(
        this.field.options.pca,
        'showDoneButton'
      )
    ) {
      this.showDoneButton = this.field.options.pca.showDoneButton
    }
    if (
      Object.prototype.hasOwnProperty.call(
        this.field.options.pca,
        'switchWhenFinished'
      )
    ) {
      this.switchWhenFinished = this.field.options.pca.switchWhenFinished
    }
    this.pcaOptions = {
      key: this.field.options.pca.key,
      bar: {
        visible: false,
        showCountry: false,
        showLogo: false,
      },
    }

    if (this.field.getFieldByPath('postcode').value) {
      this.toggleManual(true)
    }
    this.configureValidators()
    this.$nextTick().then(() => {
      this.injectCDN()
    })
  },
  methods: {
    initialisePCA() {
      if (this.pcaFailed) {
        return
      }
      window.pca.ready(() => {
        this.pcaFields.push({
          element: 'pcaSearchValue',
          field: 'PostalCode',
          mode: window.pca.fieldMode['SEARCH'],
        })
        this.field.options.pca.elements.forEach((el) => {
          el.mode = window.pca.fieldMode[el.mode]
          this.pcaFields.push(el)
        })
        this.pcaControl = new window.pca.Address(
          this.pcaFields,
          this.pcaOptions
        )
        this.pcaControl.listen('load', () => {
          this.pcaControl.setCountry('UK')
        })
        this.pcaControl.listen('search', () => {
          // do not allow enter key to submit form until address has been chosen
          this.canSubmitOnEnter = false
        })
        // on return from PCA request we populate all our hidden fields
        this.pcaControl.listen('populate', (response) => {
          this.pcaSearchValue = response.PostalCode
          const result = {}
          for (const element of this.field.options.pca.elements) {
            result[element.element] = response[element.field] || ''
          }
          this.value = result
          this.field.value = this.value
          // allow enter key to submit form now that address has been chosen
          this.canSubmitOnEnter = true
          if (this.switchWhenFinished) {
            this.manual = true
          }
          this.$emit('callback', {
            action: 'callback', // Double callback
            type: 'pcaPopulate',
            pca: this.pcaSearchValue,
            value: this.value,
          })
        })
        this.pcaControl.load()
      })
    },
    checkSubmit() {
      if (this.field.value && this.canSubmitOnEnter) {
        this.callback()
      }
    },
    configureValidators() {
      this.field.getFieldByPath('postcode').validators = [
        new PostCodeValidator(),
      ]
    },
    toggleManual(state) {
      this.manual = state
      this.focus()
    },
    focus() {
      this.$nextTick().then(() => {
        const fieldToFocus = this.$el.querySelector('input[type=text]')
        if (fieldToFocus) {
          fieldToFocus.focus()
        }
      })
    },
    onPcaError() {
      this.pcaFailed = true
      this.manual = true
    },
    injectCDN() {
      const l = document.createElement('link')
      l.rel = 'stylesheet'
      l.href = CSS_URL
      document.head.appendChild(l)
      const s = document.createElement('script')
      s.src = CDN_URL
      s.onerror = this.onPcaError.bind(this)
      s.onload = this.initialisePCA.bind(this)
      document.head.appendChild(s)
    },
    callback() {
      let line1Validate = this.field.getFieldByPath('line1').validate()
      const line2Validate = this.field.getFieldByPath('line2').validate()
      const cityValidate = this.field.getFieldByPath('city').validate()
      const postcodeValidate = this.field.getFieldByPath('postcode').validate()
      if (!line1Validate && line2Validate) {
        this.field.getFieldByPath('line1').value = this.field.getFieldByPath(
          'line2'
        ).value
        this.field.getFieldByPath('line2').value = ''
        line1Validate = this.field.getFieldByPath('line1').validate()
      }
      if (line1Validate && line2Validate && cityValidate && postcodeValidate) {
        this.$emit('callback', {
          validated: true,
          action: 'toggleOverlay',
        })
      }
      // Fields can have an optional callback, this happens
      // everytime, regardless of validation preferences
      if (this.field.options.callback) {
        this.field.options.callback(this.field)
      }
    },
  },
}
</script>
