import { ItemTypeJson } from './ProductCatalogParser'

export type ItemType = 'insurance' | 'wellness'

export class ItemTypeParser {
  public static parse(type: string): ItemType {
    switch (type) {
      case 'insurance':
        return 'insurance'
      case 'wellbeing':
        return 'wellness'

      default:
        throw new Error(`Invalid item type: ${type}`)
    }
  }

  public static serialise(type: string): ItemTypeJson {
    switch (type) {
      case 'insurance':
        return 'insurance'
      case 'wellness':
        return 'wellbeing'

      default:
        throw new Error(`Invalid item type: ${type}`)
    }
  }
}
