import ErrorResponseFactory from '@/domain/Error/ErrorResponseFactory'
import type { PolicyV2 } from '@/domain/PolicyV2'

import ApiService from './ApiService'
import type { PaginatedResponse } from './parsers/PaginatedParser'
import PoliciesParserV2 from './parsers/PoliciesParserV2'
import type { PolicyListParams } from './PoliciesService'

const POLICIES_PATH = '/v2/policies'

export interface V2PolicyListParams extends PolicyListParams {
  include_legacy: boolean
}

export default class PoliciesV2Service {
  public static async get(policyId: string): Promise<PolicyV2> {
    return ApiService.get({
      path: `${POLICIES_PATH}/${policyId}`,
      parse: (data: PolicyV2) => data,
    })
  }

  public static async getPolicyAtDate(
    policyId: string,
    params: { date: string }
  ): Promise<PolicyV2> {
    const { date } = params
    return ApiService.get({
      path: `${POLICIES_PATH}/${policyId}`,
      params: {
        searchParams: {
          policy_at_date: date,
        },
      },
      parse: (data: PolicyV2) => data,
    })
  }

  public static async getPolicies(
    params: V2PolicyListParams
  ): Promise<PaginatedResponse<PolicyV2>> {
    try {
      return await ApiService.get({
        path: POLICIES_PATH,
        params: {
          searchParams: { ...params },
        },
        parse: PoliciesParserV2.parsePaginatedResponse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error fetching policies',
        originalError: error,
        tags: ['s-policy'],
      })

      throw errorClass
    }
  }

  public static async getAllPoliciesForCustomer(params: {
    customerId: string
  }): Promise<Maybe<PolicyV2[]>> {
    const { customerId } = params

    const result = []
    let hasMore = true
    let pageNumber = 1

    do {
      try {
        const response = await PoliciesV2Service.getPolicies({
          owner: customerId,
          page_number: pageNumber,
          per_page: 20,
          include_legacy: true,
        })

        result.push(...response.items)
        hasMore = response.total > result.length
        pageNumber++
      } catch (error) {
        if (result.length === 0) {
          const errorClass = await ErrorResponseFactory.instantiateErrorClass({
            title: `Error fetching policies page ${pageNumber}`,
            originalError: error,
            tags: ['s-policy'],
          })

          throw errorClass
        }

        console.error(`Error fetching policies page ${pageNumber}`)
        hasMore = false
      }
    } while (hasMore)

    if (result) {
      return result
    }
  }
}
