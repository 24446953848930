<template>
  <label v-if="showLabel" :for="fieldId" class="mutt-label">
    {{ getLabel }}
    <slot />
  </label>
</template>

<script>
import Formatters from '../../lib/formaters'
import { i18n as _ } from '../../lib/i18n'
import ValueSubstituteMixin from '../../mixins/ValueSubstitute'

export default {
  name: 'LabelWidget',
  mixins: [ValueSubstituteMixin],
  props: ['field', 'fieldId'],
  computed: {
    showLabel() {
      if (this.field.options.hasOwnProperty('showLabel')) {
        return this.field.options.showLabel
      }

      if (this.field.label) {
        return true
      }

      return false
    },
    getLabel() {
      const regex = /\$\[([^\]]+)\]/g
      let label = this.field.label

      // Run translation first to get translation value
      label = _(this, label)

      if (regex.exec(label) !== null) {
        /*
         * Substitute any strings with $[fieldName] with
         * the respective fieldname value
         */
        label = this.substituteValues(label)
      }

      if (label.indexOf('_') > -1) {
        return Formatters.capitalize(this.field.label.replace('_', ' '))
      }

      return label
    },
  },
}
</script>
