import { PhoneState } from '@/domain/Genesys/Genesys'

export default class GenesysParser {
  public static parsePhoneState(state: string): PhoneState {
    switch (state) {
      case 'connected':
        return 'active'
      case 'disconnected':
        return 'inactive'
      case 'dialing':
        return 'dialing'
      case 'terminated':
        return 'inactive'
      case 'alerting':
        return 'incoming'
      default:
        throw new Error(`Unknown phone state: ${state}`)
    }
  }
}
