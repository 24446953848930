import { FlagOptions } from '@/domain/Flag'
import type { Loss, LossFormData } from '@/novelClaims/domain/Loss'

export interface Claim {
  agency: string
  continuation_id?: string
  created_at: string
  evidence?: ClaimEvidence
  flags: ClaimFlag[]
  id: string
  insured_entity_deceased?: boolean
  insured_entity_id: string
  locked_due_to?: 'IN_CIPIT_PIPELINE' | 'SENT_TO_COVEA'
  losses: Loss[]
  owner: string
  policy_sid: string
  ref: string
  status: ClaimStatus
  status_reason?: ClaimStatusReason
  title?: string
  updated_at: string
}

export type ClaimFlag =
  | 'COMPLAINT'
  | 'DECEASED'
  | 'HIGH_VALUE'
  | 'INVESTIGATION_NEEDED'
  | 'REOPENED'
  | 'VULNERABLE_CUSTOMER'
  | 'PRE_EXISTING'
  | 'POTENTIAL_RECOVERY'
  | 'FRAUD_THIRD_PARTY_NON_CUSTOMER'
  | 'APPROVED_UNDER_EXCESS'

export type ClaimStatus =
  | 'APPROVED'
  | 'ASSESSMENT'
  | 'CLOSED'
  | 'DECLINED'
  | 'FINAL_REVIEW'
  | 'INFO_REQUIRED'
  | 'OPEN'
  | 'PAID'
  | 'PAYMENT_SENT'

export type ClaimStatusReason =
  | 'APPROVED'
  | 'COVER_PERIOD'
  | 'COVERAGE'
  | 'COVERAGE_REVIEW'
  | 'DUPLICATE'
  | 'FINAL_REVIEW'
  | 'IN_ARREARS'
  | 'INFO_RECEIVED'
  | 'INFO_REQUIRED'
  | 'INVESTIGATION'
  | 'INVESTIGATION_COMPLETE'
  | 'NEW'
  | 'NO_RESPONSE'
  | 'PAID'
  | 'POLICY_CHECK'
  | 'POLICY_ISSUE'
  | 'POLICY_LIMIT'
  | 'PREEXISTING'
  | 'PROCESSING'
  | 'READY_FOR_CALCULATION'
  | 'UNDER_EXCESS'
  | 'WITHDRAWN'

export interface ClaimEvidenceItem {
  description?: string
  provided_by: 'CUSTOMER' | 'THIRD_PARTY' | 'VET'
  status?: 'APPROVED' | 'NO_LONGER_REQUIRED' | 'PENDING' | 'REJECTED'
  type: 'INVOICE' | 'MEDICAL_HISTORY' | 'OTHER' | 'PROOF_OF_DEATH'
  id: string
}

export type ClaimEvidence = ClaimEvidenceItem[]

// OPTIONS
export interface ClaimStatusOption {
  value: ClaimStatus
  label: string
  reasons: ClaimStatusReason[]
  allowedChange: ClaimStatus[]
}

export const claimStatusOptions: ClaimStatusOption[] = [
  {
    value: 'OPEN',
    label: 'Open',
    reasons: ['NEW'],
    allowedChange: [
      'INFO_REQUIRED',
      'ASSESSMENT',
      'DECLINED',
      'CLOSED',
      'PAID',
    ],
  },
  {
    value: 'INFO_REQUIRED',
    label: 'Info required',
    reasons: ['INFO_REQUIRED', 'INFO_RECEIVED'],
    allowedChange: [
      'INFO_REQUIRED',
      'ASSESSMENT',
      'DECLINED',
      'CLOSED',
      'PAID',
    ],
  },
  {
    value: 'ASSESSMENT',
    label: 'Assessment',
    reasons: [
      'READY_FOR_CALCULATION',
      'INVESTIGATION',
      'COVERAGE_REVIEW',
      'POLICY_ISSUE',
    ],
    allowedChange: [
      'ASSESSMENT',
      'INFO_REQUIRED',
      'DECLINED',
      'CLOSED',
      'PAID',
    ],
  },
  {
    value: 'FINAL_REVIEW',
    label: 'Final review',
    reasons: ['FINAL_REVIEW'],
    allowedChange: [
      'INFO_REQUIRED',
      'ASSESSMENT',
      'DECLINED',
      'CLOSED',
      'PAID',
    ],
  },
  {
    value: 'APPROVED',
    label: 'Approved',
    reasons: ['APPROVED'],
    allowedChange: ['DECLINED', 'CLOSED', 'PAID'],
  },
  {
    value: 'DECLINED',
    label: 'Declined',
    reasons: [
      'COVERAGE',
      'POLICY_LIMIT',
      'INVESTIGATION_COMPLETE',
      'COVER_PERIOD',
      'UNDER_EXCESS',
      'PREEXISTING',
    ],
    allowedChange: ['CLOSED', 'PAID'],
  },
  {
    value: 'PAYMENT_SENT',
    label: 'Payment sent',
    reasons: ['PROCESSING'],
    allowedChange: ['PAID'],
  },
  {
    value: 'PAID',
    label: 'Paid',
    reasons: ['PAID'],
    allowedChange: [],
  },
  {
    value: 'CLOSED',
    label: 'Closed',
    reasons: [
      'DUPLICATE',
      'IN_ARREARS',
      'NO_RESPONSE',
      'POLICY_CHECK',
      'WITHDRAWN',
    ],
    allowedChange: [
      'INFO_REQUIRED',
      'ASSESSMENT',
      'FINAL_REVIEW',
      'DECLINED',
      'PAID',
    ],
  },
]

export interface ClaimFlagOption extends FlagOptions {
  value: ClaimFlag
  label: string
  color: string
}

// We list all the available claim flags here to be used in instanceConfig.
// These should not be imported directly but retrieved from the config instead.
export const AVAILABLE_CLAIM_FLAG_OPTIONS: Record<ClaimFlag, ClaimFlagOption> =
  {
    DECEASED: {
      value: 'DECEASED',
      label: 'Deceased',
      color: '#2C3E48',
    },
    COMPLAINT: {
      value: 'COMPLAINT',
      label: 'Complaint',
      color: '#F76D4D',
    },
    VULNERABLE_CUSTOMER: {
      value: 'VULNERABLE_CUSTOMER',
      label: 'Vulnerable customer',
      color: '#4E8AF1',
    },
    REOPENED: {
      value: 'REOPENED',
      label: 'Reopened',
      color: '#20CB81',
    },
    HIGH_VALUE: {
      value: 'HIGH_VALUE',
      label: 'High value',
      color: '#FCCA1B',
    },
    INVESTIGATION_NEEDED: {
      value: 'INVESTIGATION_NEEDED',
      label: 'Investigation needed',
      color: '#E3007E',
    },
    PRE_EXISTING: {
      value: 'PRE_EXISTING',
      label: 'Pre-existing (PX policy)',
      color: '#8516FF',
    },
    POTENTIAL_RECOVERY: {
      value: 'POTENTIAL_RECOVERY',
      label: 'Potential recovery',
      color: '#00eae8',
    },
    FRAUD_THIRD_PARTY_NON_CUSTOMER: {
      value: 'FRAUD_THIRD_PARTY_NON_CUSTOMER',
      label: 'Fraud - Third party/non customer',
      color: '#F32C2C',
    },
    APPROVED_UNDER_EXCESS: {
      label: 'Approved under excess',
      value: 'APPROVED_UNDER_EXCESS',
      color: '#005B3E',
    },
  }

interface ClaimEvidenceStatusOption {
  value: ClaimEvidenceItem['status']
  label: string
}
export const claimEvidenceStatusOptions: ClaimEvidenceStatusOption[] = [
  { value: 'PENDING', label: 'Pending' },
  { value: 'NO_LONGER_REQUIRED', label: 'No longer Required' },
  { value: 'APPROVED', label: 'Approved' },
  { value: 'REJECTED', label: 'Rejected' },
]

interface ClaimEvidenceTypeOption {
  value: ClaimEvidenceItem['type']
  label: string
}
export const claimEvidenceTypeOptions: ClaimEvidenceTypeOption[] = [
  { value: 'INVOICE', label: 'Invoice' },
  { value: 'MEDICAL_HISTORY', label: 'Medical history' },
  { value: 'PROOF_OF_DEATH', label: 'Proof of death' },
  { value: 'OTHER', label: 'Other' },
]

interface ClaimEvidenceProvidedByOption {
  value: ClaimEvidenceItem['provided_by']
  label: string
}
export const claimEvidenceProvidedByOptions: ClaimEvidenceProvidedByOption[] = [
  { value: 'CUSTOMER', label: 'Customer' },
  { value: 'VET', label: 'Vet' },
  { value: 'THIRD_PARTY', label: 'Third party' },
]

export interface ClaimFormData
  extends Pick<
    Claim,
    | 'title'
    | 'policy_sid'
    | 'insured_entity_id'
    | 'continuation_id'
    | 'evidence'
  > {
  losses: LossFormData[]
}

export interface SearchClaim
  extends Pick<
    Claim,
    | 'created_at'
    | 'evidence'
    | 'flags'
    | 'id'
    | 'ref'
    | 'status'
    | 'title'
    | 'insured_entity_id'
    | 'policy_sid'
    | 'owner'
  > {}

export interface ClaimDates {
  minDOL: Maybe<string>
  maxDOL: Maybe<string>
  minTED: Maybe<string>
  maxTED: Maybe<string>
  minTreatmentDate: Maybe<string>
  maxTreatmentDate: Maybe<string>
}
