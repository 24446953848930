import {
  AvailableProductItem,
  AvailableProductPolicy,
  AvailableProductsBase,
  ProductInfoBase,
} from '@/domain/Shop/AvailableProducts'

import { ItemTypeParser } from './ItemTypeParser'
import ProductPriceParser, {
  AvailableProductPriceJson,
} from './ProductPriceParser'

export interface AvailableProductItemJson {
  sku: string
  price: AvailableProductPriceJson
}

export interface AvailableProductPolicyJson {
  accident_waiting_period_days?: number
}

export interface ProductInfoJson {
  type: string
  policy?: AvailableProductPolicyJson
  product: AvailableProductItemJson
  options: AvailableProductItemJson[]
}

export interface AvailableProductsJson {
  id: string
  pet: string
  products: ProductInfoJson[]
}

export default class AvailableProductsParser {
  public static parse(data: AvailableProductsJson): AvailableProductsBase {
    return {
      orderId: data.id,
      petId: data.pet,
      products:
        data?.products?.map(AvailableProductsParser.parseProductInfo) ?? [],
    }
  }

  public static parseProductInfo(data: ProductInfoJson): ProductInfoBase {
    return {
      type: ItemTypeParser.parse(data.type),
      policy: AvailableProductsParser.parseAvailableProductPolicy(data.policy),
      product: AvailableProductsParser.parseAvailableProductItem(data.product),
      options: data.options.map(
        AvailableProductsParser.parseAvailableProductItem
      ),
    }
  }

  public static parseAvailableProductItem(
    data: AvailableProductItemJson
  ): AvailableProductItem {
    return {
      sku: data.sku,
      price: ProductPriceParser.parseAvailableProductPrice(data.price),
    }
  }

  public static parseAvailableProductPolicy(
    data: Maybe<AvailableProductPolicyJson>
  ): AvailableProductPolicy {
    return {
      accidentWaitingPeriodDays: data?.accident_waiting_period_days,
    }
  }
}
