<template>
  <div
    v-if="field"
    :class="getFieldWrapperClass()"
    :data-qa-locator="buildQaLocator()"
  >
    <LabelWidget
      :field="field"
      :field-id="getFieldId()"
      :data-qa-locator="buildQaLocator('label')"
    />

    <ReadonlyWidget
      v-if="isReadOnly"
      :value="readOnlyValue"
      :data-qa-locator="buildQaLocator('readonly')"
    />

    <template v-else>
      <template v-if="mode === 'date'">
        <div class="mutt-pet-age__inputs-wrapper">
          <label>
            <div>Month</div>
            <input
              ref="dateMonth"
              :value="dateMonth"
              class="mutt-field mutt-pet-age__input"
              type="text"
              inputmode="numeric"
              pattern="^(0?[1-9]|1[012])$"
              name="month"
              placeholder="MM"
              :data-qa-locator="buildQaLocator('mode-date-month')"
              @input="dateMonthDidChange"
              @blur="dateMonthDidBlur"
            />
          </label>

          <label>
            <div>Year</div>
            <input
              ref="dateYear"
              :value="dateYear"
              class="mutt-field mutt-pet-age__date-year mutt-pet-age__input"
              type="text"
              inputmode="numeric"
              pattern="^\d{4}$"
              name="year"
              placeholder="YYYY"
              :data-qa-locator="buildQaLocator('mode-date-year')"
              @input="dateYearDidChange"
            />
          </label>
          <span
            v-if="value"
            class="mutt-pet-age__help-text"
            :data-qa-locator="buildQaLocator('mode-date-value')"
          >
            ({{ displayAge }})
          </span>
        </div>
      </template>

      <template v-else>
        <label>
          <input
            :value="ageYears"
            type="text"
            inputmode="numeric"
            name="years"
            class="mutt-field mutt-pet-age__age-years mutt-pet-age__input"
            :data-qa-locator="buildQaLocator('mode-age-years')"
            @input="ageYearsDidChange"
          />
          years,
        </label>

        <label>
          <input
            :value="ageMonths"
            type="text"
            inputmode="numeric"
            name="months"
            class="mutt-field mutt-pet-age__input"
            :data-qa-locator="buildQaLocator('mode-age-months')"
            @input="ageMonthsDidChange"
          />
          months
        </label>
        old
      </template>

      <div class="mutt-pet-age__toggle-action">
        <TextLink type="button" @click="toggle">
          or enter as {{ mode === 'date' ? 'age' : 'date' }}
        </TextLink>
      </div>

      <ErrorWidget
        :field="field"
        :errors="errors"
        :error-class="getErrorClass()"
        :data-qa-locator="buildQaLocator('error')"
      />
    </template>
  </div>
</template>

<script>
import MuttVue from '@mutt/widgets-vue'

import { DayjsDateValidator } from '@/legacy/lib/Validators'
import dayjs from '@/lib/dayjs'
import TextLink from '@/ui/TextLink.vue'

export default {
  name: 'MuttPetAge',
  components: { TextLink },
  for: 'pet-age',
  mixins: [MuttVue.mixin],
  data() {
    return {
      mode: this.field.options.defaultMode ?? 'age',
      ageMonths: '',
      ageYears: '',
      dateMonth: '',
      dateYear: '',
      value: null,
    }
  },
  computed: {
    displayAge() {
      const yearLabel = this.ageYears === '1' ? 'year' : 'years'
      const monthLabel = this.ageMonths === '1' ? 'month' : 'months'

      return `${this.ageYears} ${yearLabel}, ${this.ageMonths} ${monthLabel} old`
    },
    displayDate() {
      return this.value.format('MMMM YYYY')
    },
    readOnlyValue() {
      return `${this.displayAge} (born ${this.displayDate})`
    },
  },
  mounted() {
    this.value = this.field.value ? dayjs(this.field.value) : null
    this.setAgeFromValue()
    this.setDateFromValue()

    this.setupValidator()
  },
  methods: {
    getFieldWrapperClass() {
      return `${this._getFieldWrapperClass()} mutt-pet-age`
    },
    buildQaLocator(suffix) {
      return this.qaLocator ? `${this.qaLocator}-${suffix}` : null
    },
    setupValidator() {
      let earliestDate = null
      let latestDate = null

      // These are in ISO-8601 duration format.
      if (this.field.options.minAge) {
        latestDate = dayjs
          .utc()
          .add(dayjs.duration(`P-${this.field.options.minAge}`))
      }
      if (this.field.options.maxAge) {
        earliestDate = dayjs
          .utc()
          .add(dayjs.duration(`P-${this.field.options.maxAge}`))
      }

      const messages = this.field.options.messages

      this.field.validators.push(
        new DayjsDateValidator({
          min: earliestDate,
          max: latestDate,
          messages: {
            max: messages?.minAge ?? 'The age entered is too young',
            min: messages?.maxAge ?? 'The age entered is too old',
          },
        })
      )
    },
    buildValueFromDate(year, month) {
      let value = null

      // Dayjs/JS uses 0-based months, so subtract 1 from human input
      const dateMonth = Number(month) - 1

      if (year?.length === 4 && dateMonth >= 0) {
        const date = dayjs().startOf('month')
        date.year(year)
        date.month(dateMonth)

        value = date
      }

      return value
    },
    buildValueFromAge(years, months) {
      let value = null

      if (years !== '' && months !== '') {
        value = dayjs()
          .startOf('month')
          .subtract(years, 'years')
          .subtract(months, 'months')
      }

      return value
    },
    setValueFromDate() {
      this.value = this.buildValueFromDate(this.dateYear, this.dateMonth)
    },
    setValueFromAge() {
      this.value = this.buildValueFromAge(this.ageYears, this.ageMonths)
    },
    setAgeFromValue() {
      this.ageYears = this.value
        ? String(dayjs().startOf('month').diff(this.value, 'years'))
        : ''
      this.ageMonths = this.value
        ? String(dayjs().startOf('month').diff(this.value, 'months') % 12)
        : ''
    },
    setDateFromValue() {
      this.dateYear = this.value ? this.value.format('YYYY') : ''
      this.dateMonth = this.value ? this.value.format('MM') : ''
    },
    dateMonthDidBlur() {
      if (this.dateMonth.length) {
        this.dateMonth = this.dateMonth.padStart(2, '0')
      }
    },
    dateMonthDidChange(event) {
      let dateMonth = event.target.value

      if (dateMonth.match(/^\d*$/)) {
        // Clamp months to 12
        if (dateMonth > 12) {
          dateMonth = '12'
        }

        this.dateMonth = dateMonth

        this.clampDateToToday()

        this.setValueFromDate()
        this.setAgeFromValue()
      }

      const shouldJump = this.dateMonth > 1 || this.dateMonth === '01'
      if (shouldJump) {
        this.$refs.dateYear.focus()
      }

      this.updateInputsFromData()
    },
    dateYearDidChange(event) {
      const dateYear = event.target.value

      if (dateYear.match(/^\d*$/)) {
        this.dateYear = dateYear

        this.clampDateToToday()

        this.setValueFromDate()
        this.setAgeFromValue()
      }

      this.updateInputsFromData()
    },
    ageYearsDidChange(event) {
      const ageYears = event.target.value

      if (ageYears.match(/^\d*$/)) {
        this.ageYears = ageYears

        if (this.ageYears !== '' && this.ageMonths === '') {
          this.ageMonths = '0'
        }

        this.setValueFromAge()
        this.setDateFromValue()
      }

      this.updateInputsFromData()
    },
    ageMonthsDidChange(event) {
      let ageMonths = event.target.value

      if (ageMonths.match(/^\d*$/)) {
        // Clamp months to 11
        if (ageMonths > 11) {
          ageMonths = '11'
        }

        this.ageMonths = ageMonths

        if (this.ageMonths !== '' && this.ageYears === '') {
          this.ageYears = '0'
        }

        this.setValueFromAge()
        this.setDateFromValue()
      }

      this.updateInputsFromData()
    },
    clampDateToToday() {
      const now = dayjs().startOf('month')
      const date = this.buildValueFromDate(this.dateYear, this.dateMonth)

      if (date?.isAfter(now)) {
        this.dateYear = now.format('YYYY')
        this.dateMonth = now.format('MM')
      }
    },
    toggle() {
      const newMode = this.mode === 'date' ? 'age' : 'date'
      this.mode = newMode
    },
    updateInputsFromData() {
      // In order to ensure that the input elements contain the values we have
      // in the component data, we need to force a re-render of the component.

      // We do this because of a limitation of Vue whereby the input event is
      // fired after the user enters text and the input element now contains
      // this text. In order to discard the user's invalid input we simply don't
      // persist the change in the component state. However, Vue doesn't know to
      // re-render the input elements as nothing has changed as far as it knows.
      // `$forceUpdate` causes a re-render, which has the effect of forcing the
      // rendered value of the input to match the component data and not what
      // the user entered.

      this.$forceUpdate()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/sass/settings/_settings';
@import '@/sass/icons/_icons';
@import '@/sass/helpers/helpers';
@import '@/sass/components/form/_form';

.mutt-pet-age {
  &__input {
    @extend .form__control--xxsmall;
  }

  &__age-years {
    max-width: 65px;
  }
  &__date-year {
    max-width: 75px;
  }

  &__toggle-action {
    margin-top: 12px;
  }

  &__inputs-wrapper {
    display: flex;
    align-items: flex-end;

    label + label {
      margin-left: 2px;
    }
  }

  &__help-text {
    display: inline-block;
    font-size: 14px;
    font-style: italic;
    margin: 4.5px 10px;
  }
}
</style>
