<template>
  <section class="layout layout--bkg-2 layout--split">
    <div class="inner inner--xxxlarge layout__inner">
      <Intro
        :photo="user.picture"
        :heading="user.name === user.email ? '' : user.name"
        :description="user.email"
      />

      <div class="layout__main">
        <div class="form formatted large-text layout__form">
          <template v-if="passwordResetRequested">
            <h2 class="heading2">Password reset email sent</h2>

            <p>
              We have sent you an email confirmation to
              <strong>{{ user.email }}</strong>
              .
            </p>

            <p>
              Didn’t receive the email? Please check your spam folder for an
              email from <strong>sysadmin@manypets.com</strong>. Occasionally,
              it may take several minutes for the email to arrive. Otherwise,
              please try again.
            </p>

            <button type="button" class="btn-2" @click="goBack">Go back</button>
          </template>
          <template v-else>
            <h2 class="heading2">Reset password</h2>

            <p>
              To reset your password, click on the button below and we will send
              instructions to your email address.
            </p>

            <button
              type="button"
              class="btn"
              :disabled="passwordResetRequested"
              @click="resetPassword"
            >
              Request a password reset
            </button>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Intro from '@/components/legacy/Intro.vue'

export default {
  name: 'UserProfile',
  components: {
    Intro,
  },
  data() {
    return {
      passwordResetRequested: false,
    }
  },
  computed: {
    user() {
      return this.$auth0?.user?.value ?? {}
    },
  },
  methods: {
    async resetPassword() {
      try {
        this.passwordResetRequested = true

        const response = await fetch(
          `https://${
            import.meta.env.VITE_AUTH0_DOMAIN
          }/dbconnections/change_password`,
          {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              client_id: import.meta.env.VITE_AUTH0_CLIENT_ID,
              connection: import.meta.env.VITE_AUTH0_CONNECTION,
              email: this.user.email,
            }),
          }
        )

        if (response.status !== 200) {
          throw new Error(response.statusText)
        }
      } catch (error) {
        this.passwordResetRequested = false

        this.$error({
          title: 'Unable to send "password reset" email.',
          error,
        })
      }
    },
    goBack() {
      window.history.back()
    },
  },
}
</script>
