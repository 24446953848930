import type { CaptureContext, SeverityLevel, ScopeContext } from '@sentry/types'
import { captureException, captureMessage } from '@sentry/vue'

import BaseError from '@/domain/Error/BaseError'
import { ErrorContext } from '@/domain/Error/ErrorContext'
import { BasicTag, Tag, RegionTag, ServiceTag } from '@/domain/Error/Tag'

const isBasicTag = (tag: Tag): tag is BasicTag =>
  ['API', 'UI', 'Third Party', 'App Error', 'Other'].includes(tag as string)

const isRegionTag = (tag: Tag): tag is RegionTag =>
  ['UK', 'US'].includes(tag as string)

const isServiceTag = (tag: Tag): tag is ServiceTag =>
  ['Shop', 'Account', 's-subscription', 's-user', 's-policy'].includes(
    tag as string
  )

export function formatTags(tags: Tag[] = []): ScopeContext['tags'] {
  const formattedTags: ScopeContext['tags'] = {}

  tags.forEach((tag) => {
    if (isBasicTag(tag)) {
      formattedTags['area'] = tag
    } else if (isRegionTag(tag)) {
      formattedTags['region'] = tag
    } else if (isServiceTag(tag)) {
      formattedTags['service'] = tag
    } else {
      formattedTags[tag] = true
    }
  })

  return formattedTags
}

export default class Logger {
  static isCustomError(error: unknown): error is BaseError {
    return error instanceof BaseError
  }

  static error(error: BaseError): void {
    let localContext: CaptureContext = {}
    const tags = error.tags
    const context = error.context
    const level: SeverityLevel = 'error'

    if (Logger.isCustomError(error)) {
      localContext = {
        extra: {
          ...context,
        } as unknown as ScopeContext['extra'],
        tags: formatTags(tags),
        level,
      }
    } else if (context) {
      localContext = {
        extra: {},
        tags: formatTags(['unhandled']),
        level,
      }
    }

    // Capture the original error's stack trace if it exists
    if (error.originalError instanceof Error) {
      error.stack = error.originalError.stack ?? error.stack
    }

    // if we have an original error, append the message to the error message
    // and update the name to the original error's name
    if (error.originalError && error.originalError instanceof Error) {
      error.name = error.originalError.name ?? error.title
      error.message = `${error.message} - ${error.originalError.message}`
    }

    captureException(error, localContext)
  }

  static info(
    message: string,
    params: ErrorContext & { tags?: Tag[] } = {},
    level: SeverityLevel = 'log'
  ): void {
    let localContext: CaptureContext = {}

    if (Object.keys(params).length) {
      const { tags, ...contextWithoutTags } = params

      localContext = {
        extra: contextWithoutTags as unknown as ScopeContext['extra'],
        tags: formatTags(tags),
        level,
      }
    }

    captureMessage(message, localContext)
  }
}
