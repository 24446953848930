import { ApiListResponse } from '@/@types/ApiResponse'

export interface PaginatedResponseMeta {
  max_results?: number
  page?: number
  total?: number
}
export interface PaginatedResponse<T> {
  total: number
  items: T[]
  _meta?: PaginatedResponseMeta
}

export default class PaginatedParser {
  public static parsePaginatedResponse<I, O>(
    data: ApiListResponse<I>,
    parseMany: (i: I[]) => O[]
  ): PaginatedResponse<O> {
    const result: PaginatedResponse<O> = {
      total: data._meta?.total ?? 0,
      items: parseMany(data._embedded.items),
    }

    //backwards compatibility for generic legacy components
    if (data._meta) {
      result._meta = { ...data._meta }
    }

    return result
  }
}
