import type {
  AccountSummaryItem,
  AccountSummaryItemProduct,
  AccountSummaryItemOption,
  AccountSummaryItemPolicy,
} from '@/domain/Account/AccountSummaryItem'

import { ItemTypeParser } from '../Shop/ItemTypeParser'
import ProductPriceParser from '../Shop/ProductPriceParser'

import type {
  ItemJson,
  OptionJson,
  PolicyJson,
  ProductJson,
} from './AccountParser'

export default class AccountSummaryItemParser {
  public static parse(data: ItemJson): AccountSummaryItem {
    return {
      id: data.id,
      ref: data.ref,
      type: ItemTypeParser.parse(data.type),
      productName: data.product_name,
      petId: data.pet,
      policy: AccountSummaryItemParser.parseItemPolicy(data.policy),
      product: AccountSummaryItemParser.parseItemProduct(data.product),
      options: data.options.map(AccountSummaryItemParser.parseItemOption),
      price: ProductPriceParser.parseTotalPrice(data.price),
      startDate: data.start_date,
      endDate: data.end_date,
      moneyback: data.moneyback
        ? { expectedAmount: data.moneyback.expected_amount }
        : undefined,
    }
  }

  public static parseItemOption(data: OptionJson): AccountSummaryItemOption {
    return {
      sku: data.sku,
      price: ProductPriceParser.parseTotalPrice(data.price),
    }
  }

  public static parseItemProduct(data: ProductJson): AccountSummaryItemProduct {
    return {
      sku: data.sku,
      price: ProductPriceParser.parseTotalPrice(data.price),
    }
  }

  public static parseItemPolicy(data: PolicyJson): AccountSummaryItemPolicy {
    return {
      status: data?.status || 'ON_RISK',
      coolingOff: data?.cooling_off ?? false,
      waitingPeriodEndDate: data?.accident_waiting_period_end_date ?? null,
      coPayment: data?.co_payment,
      excess: data?.excess,
      limit: data?.limit,
      policyUuid: data?.x_policy_uuid,
      termNumber: data?.term_number,
    }
  }
}
