import Mutt from '@mutt/forms'

import dayjs from '@/lib/dayjs'

/**
 * DateTime Serializer
 */
export class DateTimeSerializer extends Mutt.serializers.Serializer {
  serialize() {
    if (this.value && dayjs.isDayjs(this.value)) {
      return this.value.format()
    }

    return this.value
  }
}

/**
 * DateTime Serializer -
 */
export class DateSerializer extends Mutt.serializers.Serializer {
  serialize() {
    if (this.value && dayjs.isDayjs(this.value)) {
      return this.value.format('YYYY-MM-DD')
    }

    return this.value
  }
}

/**
 * Custom Serializer - use custom serialization function
 */
export class CustomSerializer extends Mutt.serializers.Serializer {
  serialize() {
    return this.options.func(this.value)
  }
}
