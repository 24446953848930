/**
 * @file JSON Patch for Mutt Forms
 * @author Nick Snell <nick@boughtbymany.com>
 * @copyright Bought By Many 2018
 */

 'use strict'

 import { createPatch, createTests } from 'rfc6902'

 export default {
   install() {
     return {
       extensions: {
         /**
          * Overide the Mutt forms base populate function to
          * store the original data - we later use this for
          * the patch comparisson.
          */
         populate(data) {
           for (let fieldset of this.fieldsets) {
             fieldset.populate(data)
           }
           // We need to fetch the data after it's set,
           // not before. This ensures we don't end up with a
           // data object that contains key/values that don't
           // exist in the form
           this._originalData = this.data()
         },

         /**
          * Return a JSON patch
          * @returns array Array of JSON Patch objects
          */
         getJSONPatch() {
           if (!this._originalData) {
             throw new Error(
               'Unable to generate a JSON Patch Diff when no data is populated!'
             )
           }

           return createPatch(this._originalData, this.data())
         },

         /**
          *
          */
         getJSONPatchTests() {
           if (!this._originalData) {
             throw new Error(
               'Unable to generate a JSON Patch Diff when no data is populated!'
             )
           }

           return createTests(this._originalData, this.getJSONPatch())
         }
       }
     }
   }
 }
