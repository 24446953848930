<template>
  <div
    v-if="field"
    class="mutt-field-wrapper"
  >
    <label-widget
      v-bind:field="field"
      v-bind:field-id="getFieldId()"
    ></label-widget>
    <readonly-widget
      v-if="isReadOnly"
      :field="field"
      :copyable="isCopyable"
      v-bind:value="field.value"
    />

    <template v-for="objectField of field.object">
      <mutt-widget
        v-if="objectField.name === 'postcode' || manual"
        v-bind:key="objectField.id"
        v-bind:ref="objectField.name"
        v-bind:field="objectField"
      >
      </mutt-widget>
    </template>

    <div
      v-if="addressLookupControl && !isReadOnly"
      class="show-manual"
    >
      <button
        v-if="!manual"
        type="button"
        class="btn btn--secondary btn--manual-toggle"
        v-on:click.prevent='toggleManual(true)'
      >
        {{ $t("Can't find an address?") }}
      </button>
      <button
        v-if="manual"
        type="button"
        class="btn btn--secondary btn--search-toggle"
        v-on:click.prevent="toggleManual(false)"
      >
        <span class=""> &lt;</span> {{ $t('Search for an address') }}
      </button>
    </div>
    <help-widget v-bind:field="field"></help-widget>
    <error-widget
      v-if="!isReadOnly"
      v-bind:field="field"
      v-bind:errors="errors"
      v-bind:error-class="getErrorClass()"
    >
    </error-widget>
  </div>
</template>

<script>
import MuttVue from "@mutt/widgets-vue"
import { PostcodeValidator } from "./lib/validator.js"
const CSS_URL = "https://services.postcodeanywhere.co.uk/css/address-3.70.css"
const CDN_URL = "https://services.postcodeanywhere.co.uk/js/address-3.70.js"
export default {
  name: "mutt-address-lookup", // eslint-disable-line vue/name-property-casing
  mixins: [MuttVue.mixin],
  data() {
    return {
      errors: null,
      value: null,
      manual: false,
      addressLookupControl: null,
      addressLookupFailed: null,
      addressLookupOptions: {},
      addressLookupFields: [],
      postcodeField: null,
    }
  },
  mounted() {
    this.injectCDN()
  },
  methods: {
    init() {
      if (!this.field.options.hasOwnProperty("addressLookup")) {
        throw new Error(`Field missing address lookup options!`)
      }
      if (!this.field.options.addressLookup.key) {
        throw new Error(`ADDRESS_LOOKUP_KEY not present!`)
      }
      this.postcodeField = this.field.getFieldByPath("postcode")
      // Store the original placeholder so we can use it later
      this.postcodePlaceholder = this.postcodeField.options.placeholder
      // Switch the postcode placeholder to use the address lookup placeholder
      this.postcodeField.options.placeholder = this.$t(
        this.field.options.addressLookup.placeholder || "Enter a postcode"
      )
      this.configureValidators({
        pattern: this.field.options.addressLookup.postcodePattern,
        requiredErrorMessage: this.field.options.addressLookup
          .requiredErrorMessage,
        invalidPatternErrorMessage: this.field.options.addressLookup
          .invalidPatternErrorMessage,
      })
      this.value = this.field.value
    },
    initialiseAddressLookup() {
      const addressLookupOptions = {
        key: this.field.options.addressLookup.key,
        bar: {
          visible: false,
          showCountry: false,
          showLogo: false,
        },
        search: {
          countries: this.field.options.addressLookup.countries || "UK",
        },
      }
      window.pca.ready(() => {
        const addressLookupFields = this.field.options.addressLookup.elements.map(
          el => {
            el.mode = window.pca.fieldMode[el.mode]
            return el
          }
        )
        this.addressLookupControl = new window.pca.Address(
          addressLookupFields,
          addressLookupOptions
        )
        // on return from address lookup request we populate all our hidden fields
        this.addressLookupControl.listen("populate", this.select)
        this.addressLookupControl.load()
      })
    },
    select(data) {
      let result = {}
      for (let element of this.field.options.addressLookup.elements) {
        result[element.element] = data[element.field] || ""
      }
      this.value = result
      this.field.value = this.value
      this.postcodeField.validate()
      this.$emit("callback", {
        action: "callback", // Double callback
        type: "addressLookupPopulate",
        addressLookup: data.PostalCode,
        value: this.value,
      })
    },
    configureValidators(options) {
      this.field.getFieldByPath("postcode").validators = [
        new PostcodeValidator(options),
      ]
    },
    toggleManual(state) {
      this.manual = state
      this.focus()
    },
    focus() {
      this.$nextTick(() => {
        const fieldToFocus = this.$el.querySelector("input[type=text]")
        if (fieldToFocus) {
          fieldToFocus.focus()
        }
      })
    },
    onAddressLookupError() {
      this.addressLookupFailed = true
      this.manual = true
    },
    injectCDN() {
      let l = document.createElement("link")
      l.rel = "stylesheet"
      l.href = CSS_URL
      document.head.appendChild(l)
      let s = document.createElement("script")
      s.src = CDN_URL
      s.onerror = this.onAddressLookupError.bind(this)
      s.onload = this.initialiseAddressLookup.bind(this)
      document.head.appendChild(s)
    },
  },
}
</script>
