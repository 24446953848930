<template>
  <SimpleText class="title" tag="div" size="small" bold> Tags </SimpleText>

  <ul class="tags-wrapper">
    <li v-for="tagData in tagsData" :key="tagData.tag" class="tags-list">
      <ElTag class="error-tag" :type="tagData.type">
        {{ tagData.tag }}
      </ElTag>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { Tag } from '@/domain/Error/Tag'
import SimpleText from '@/patterns/SimpleText'

interface Props {
  tags: Tag[]
}

const props = defineProps<Props>()

const colors = computed(() => ['success', 'warning', 'danger', 'info'] as const)
const tagsData = computed(() => {
  return props.tags.map((tag, index) => {
    return {
      tag,
      type: colors.value[index % colors.value.length],
    }
  })
})
</script>

<style lang="scss" scoped>
.title {
  margin-top: var(--app-spacing-5);
  margin-bottom: var(--app-spacing-1);
}
.tags-wrapper {
  display: flex;
  gap: var(--app-spacing-1);
  list-style: none;
  margin: 0;
  padding-left: var(--app-spacing-2);
}

.tags-list {
  display: flex;
  gap: var(--app-spacing-1);
  align-items: center;
}
</style>
