<template>
  <ElDialog
    :model-value="visible"
    top="1vh"
    width="700px"
    align-center
    :before-close="cancel"
  >
    <template #header="{ titleId }">
      <SimpleText :id="titleId" tag="div" size="lead" bold>
        {{ title }}
      </SimpleText>
    </template>

    <SimpleText
      v-if="message"
      class="title"
      type="secondary"
      tag="div"
      size="small"
      bold
    >
      {{ message }}
    </SimpleText>

    <SimpleText
      v-if="formattedMessage"
      class="title"
      type="secondary"
      tag="div"
      size="micro"
      bold
    >
      {{ formattedMessage }}
    </SimpleText>

    <div class="error-details-wrapper">
      <ErrorDialogContext v-if="context" :context="context" />
      <ErrorDialogTags :tags="tags" />

      <div class="copy-button-wrapper">
        <ElButton @click="copyErrorDetails">
          Copy details for reporting
        </ElButton>
      </div>
    </div>

    <template #footer>
      <ElButton type="primary" @click="cancel">Close</ElButton>
    </template>
  </ElDialog>
</template>

<script lang="ts" setup>
import { useClipboard } from '@vueuse/core'
import { computed } from 'vue'

import useErrorModal from '@/composables/useErrorModal'
import { ErrorContext } from '@/domain/Error/ErrorContext'
import { Tag } from '@/domain/Error/Tag'
import showNotification from '@/lib/showNotification'
import SimpleText from '@/patterns/SimpleText'

import ErrorDialogContext from './ErrorDialogContext.vue'
import ErrorDialogTags from './ErrorDialogTags.vue'
const { copy } = useClipboard()

const { error, hideError } = useErrorModal()

const title = computed(() => {
  if (error.value) {
    return error.value.title
  }

  return 'Error'
})

const tags = computed((): Tag[] => {
  if (error.value) {
    return error.value.tags
  }

  return ['unhandled']
})

const context = computed((): Nullable<ErrorContext> => {
  if (error.value) {
    return error.value.context
  }

  return null
})

// APIError class adds a formmated message to the error object
const formattedMessage = computed(() => {
  return error.value?.getErrorMessage()
})

// Message title that was set by error class
const message = computed(() => {
  return error.value?.message !== formattedMessage.value
    ? error.value?.message
    : null
})

const visible = computed(() => {
  return error.value !== null
})

const cancel = () => {
  hideError()
}

const copyErrorDetails = () => {
  if (error.value) {
    const data = error.value.getFullErrorDetails()
    copy(data)
    showNotification({
      title: 'Copied to clipboard',
      type: 'success',
      message: 'The error details have been copied to your clipboard.',
      duration: 2000,
    })
  }
}
</script>

<style lang="scss" scoped>
.page-info {
  margin-bottom: 0;
}

.title {
  margin-bottom: var(--app-spacing-1);
}

.message {
  padding-left: var(--app-spacing-2);
}

.message-wrapper {
  padding: var(--app-spacing-2) 0;
}

:deep(.el-dialog__body) {
  padding-top: 0 !important;
}

.error-details-wrapper {
  margin-top: var(--app-spacing-2);
  background: #f7f7f7;
  padding: var(--app-spacing-5);
}

.copy-button-wrapper {
  display: flex;
  flex-direction: row-reverse;
}
</style>
