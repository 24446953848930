import type { BasketV1Item } from '@/domain/BasketV1'
import type { Policy } from '@/domain/Policy'
import type { Quote } from '@/domain/Quote/Quote'
import type { SubscriptionItem } from '@/domain/Subscription'
import PoliciesService from '@/services/PoliciesService'
import QuotesService from '@/services/QuotesService'

export const curry =
  (func: Function, ...curriedArgs: any[]) =>
  (...args: any[]): any =>
    func(...curriedArgs, ...args)

/**
 * Fetch an external item for a subscription/basket item.
 *
 * If the item type is not recognised/supported this function will return `null`.
 *
 * @param {object} params The parameters for this function.
 * @param {string} params.id The external ID of the external item.
 * @param {string} params.type The type of the external item.
 * @returns {object|null} The external item, if the type was recognised.
 */
export async function getExternalItem({
  id,
  type,
}: {
  id: string
  type: 'POLICY' | 'QUOTE'
}): Promise<Nullable<Policy | Quote>> {
  switch (type) {
    case 'POLICY':
      return PoliciesService.get(id)
    case 'QUOTE':
      return QuotesService.get(id)
  }
}

/**
 * @param {object} params The parameters for this function.
 * @param {object[]} params.items The subscription or basket items to fetch external items for.
 * @param {Function} params.mutation A mutation function to call when each is fetched.
 * @returns {void}
 */
export async function loadExternalItems({
  items,
  mutation,
}: {
  items: Array<(SubscriptionItem | BasketV1Item) & { type: 'QUOTE' | 'POLICY' }>
  mutation: any
}): Promise<any> {
  const requests = items.map(async (item) => {
    const externalItem = await getExternalItem({
      id: item.external_id ?? '',
      type: item.type,
    })

    mutation({
      item: externalItem,
      id: item.external_id,
    })
  })

  await Promise.all(requests)
}
