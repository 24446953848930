<template>
  <Component :is="tag" class="text-link" v-bind="$attrs">
    <slot />
  </Component>
</template>

<script>
export default {
  name: 'TextLink',
  props: {
    tag: {
      type: String,
      default: 'button',
      validator: function (value) {
        return ['a', 'button', 'router-link'].includes(value)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/sass/settings/_settings.scss';

// Need to use specific selectors here so the targetting is specific enough to
// override the defaults that come from the global styles.

.formatted .text-link,
.table-list .text-link,
.table-list a:not([class*='btn']):not(.pio-button).text-link,
.text-link {
  display: inline-flex;
  align-items: center;
  color: $color-3;
  font-size: 15px;
  font-weight: 500;
  // Needs !important to override "button" styling from somewhere else adding
  // text-decoration: none !important.
  text-decoration: underline !important;

  &:hover {
    color: $color-2;
  }

  /* Undo button styles */
  appearance: none;
  background-color: transparent;
  border-radius: 0;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: none;
}
</style>
