import { AccountSummary } from '@/domain/Account/AccountSummary'
import { AccountSummaryItem } from '@/domain/Account/AccountSummaryItem'
import { ProductPayments, ProductTotalPrice } from '@/domain/Shop/ProductPrice'

export default class AccountSummaryPaymentsAdapter {
  public static adaptPayments(
    originalAccountSummary: AccountSummary
  ): AccountSummary {
    const accountSummaryWithCorrectItemPrices = {
      ...originalAccountSummary,
      pets: originalAccountSummary.pets.map((pet) => {
        return {
          ...pet,
          items: pet.items.map((item) => {
            return AccountSummaryPaymentsAdapter.fixAccountSummaryItemPrice({
              ...item,
              options:
                item.options?.map((option) => {
                  return {
                    ...option,
                  }
                }) ?? [],
            })
          }),
        }
      }),
    }

    const accountSummaryWithCorrectTotalPrices =
      AccountSummaryPaymentsAdapter.fixTotalAccountSummaryPriceFromItems(
        accountSummaryWithCorrectItemPrices
      )

    const accountSummaryWithPayments =
      AccountSummaryPaymentsAdapter.fixPaymentsOnAccountSummary(
        accountSummaryWithCorrectTotalPrices
      )

    return accountSummaryWithPayments
  }

  public static fixPriceFromPayments(
    monthsDiff: number,
    deltaPrice: ProductTotalPrice,
    payments: Maybe<ProductPayments>
  ): ProductTotalPrice {
    if (payments && payments.cause) {
      const retained = payments.firstMonth - payments.remainingMonths
      const retainedMonthly = Math.ceil(retained / monthsDiff)

      return {
        ...deltaPrice,
        monthlyTotal: {
          ...deltaPrice.monthlyTotal,
          total: deltaPrice.monthlyTotal.total - retainedMonthly,
          subtotal: deltaPrice.monthlyTotal.subtotal - retainedMonthly,
        },
      }
    }

    return deltaPrice
  }

  public static fixAccountSummaryItemPrice(
    accountSummaryitem: AccountSummaryItem
  ): AccountSummaryItem {
    if (accountSummaryitem.price.payments) {
      const monthsDiff = 12

      const clonedAccountSummaryItem = structuredClone(accountSummaryitem)

      clonedAccountSummaryItem.price =
        AccountSummaryPaymentsAdapter.fixPriceFromPayments(
          monthsDiff,
          accountSummaryitem.price,
          accountSummaryitem.price.payments
        )
      clonedAccountSummaryItem.product.price =
        AccountSummaryPaymentsAdapter.fixPriceFromPayments(
          monthsDiff,
          accountSummaryitem.product.price,
          accountSummaryitem.price.payments
        )

      return clonedAccountSummaryItem
    }

    return accountSummaryitem
  }

  public static fixTotalAccountSummaryPriceFromItems(
    accountSummaryWithCorrectPrices: AccountSummary
  ): AccountSummary {
    const accountSummaryHasPayments = accountSummaryWithCorrectPrices.pets
      .flatMap((pet) => pet.items)
      .some((item) => item.price.payments)

    if (!accountSummaryHasPayments) {
      return accountSummaryWithCorrectPrices
    }

    const sumPricesOfItems = accountSummaryWithCorrectPrices.pets
      .flatMap((pet) => pet.items)
      .filter((item) => item.policy?.status !== 'CANCELLED')
      .reduce(
        (acc, item) => {
          return {
            total: acc.total + item.price.monthlyTotal.total,
            subTotal: acc.subTotal + item.price.monthlyTotal.subtotal,
          }
        },
        {
          total: 0,
          subTotal: 0,
        }
      )

    const totalPrices = {
      ...accountSummaryWithCorrectPrices.price,
      monthlyTotal: {
        ...accountSummaryWithCorrectPrices.price.monthlyTotal,
        total: sumPricesOfItems.total,
        subtotal: sumPricesOfItems.subTotal,
      },
      termDelta: accountSummaryWithCorrectPrices.price.termTotal,
    }

    return {
      ...accountSummaryWithCorrectPrices,
      price: totalPrices,
    }
  }

  public static fixPaymentsOnAccountSummary(
    accountSummary: AccountSummary
  ): AccountSummary {
    let value: Maybe<ProductPayments> = undefined

    accountSummary.pets
      .flatMap((pet) => pet.items)
      .forEach((item) => {
        if (item.price.payments && item.price.payments.cause) {
          const prevValue = value

          if (!prevValue) {
            value = {
              ...item.price.payments,
              firstMonth: item.price.payments.firstMonth,
              remainingMonths: item.price.payments.remainingMonths,
              upfrontPremium: item.price.payments.upfrontPremium,
            }
          } else {
            value = {
              ...prevValue,
              firstMonth: prevValue.firstMonth + item.price.payments.firstMonth,
              remainingMonths:
                prevValue.remainingMonths + item.price.payments.remainingMonths,
              upfrontPremium:
                prevValue.upfrontPremium + item.price.payments.upfrontPremium,
            }
          }
        }
      })

    if (value !== undefined) {
      accountSummary.price.payments = value
    }

    return accountSummary
  }
}
