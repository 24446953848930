import Mutt from '@mutt/forms'
import moment from 'moment'

/**
 * DateValidator - Validate the date between certain values
 *
 * @class
 */
export class DateValidator extends Mutt.validators.Validator {
  constructor({ min = null, max = null, messages = null } = {}) {
    super(messages)

    if (min !== null) {
      this.min = new Date(min.getTime())
      this.min.setHours(0, 0, 0, 0)
    }

    if (max !== null) {
      this.max = new Date(max.getTime())
      this.max.setHours(23, 59, 59, 999)
    }

    this.messages = messages
  }

  validate(value) {
    if (!value) {
      this.error = 'This field is required.'
      return false
    } else {
      // Check that value string (in format 'YYYY-MM-DD') contains only
      // numbers and dashes and is 10 characters long
      if (typeof value === 'string') {
        const dateStrPattern = /^[0-9\-]+$/
        const regexTest = dateStrPattern.test(value)
        if (!regexTest && value.length === 10) {
          this.error = 'Not a valid date.'
          return false
        }
      }

      const date = new Date(value)
      if (date instanceof Date && !isNaN(date.valueOf())) {
        if (this.min && date < this.min) {
          if (this.messages && this.messages.min) {
            this.error = this.messages.min
          } else {
            this.error = 'Date is before earliest allowed.'
          }
          return false
        }
        if (this.max && date > this.max) {
          if (this.messages && this.messages.max) {
            this.error = this.messages.max
          } else {
            this.error = 'Date is after maximum allowed.'
          }
          return false
        }
        return true
      } else {
        this.error = 'Not a valid date.'
        return false
      }
    }
  }
}

/**
 * MomentDateTimeValidator - Validate the moment date object is between
 * certain values
 *
 * @class
 */
export class MomentDateTimeValidator extends Mutt.validators.Validator {
  constructor({
    min = null,
    max = null,
    messages = null,
    required = true,
  } = {}) {
    super(messages)

    if (min !== null) {
      this.min = min
    }

    if (max !== null) {
      this.max = max
    }

    this.messages = messages
    this.required = required
  }

  validate(value) {
    if (!value) {
      if (!this.required) {
        return true
      }

      this.error = 'This field is required.'
      return false
    }

    if (typeof value === 'string') {
      value = moment.utc(value)
    }

    if (!value instanceof moment) {
      return false
    }

    if (value.hasOwnProperty('isValid') && !value.isValid()) {
      this.error = 'Please enter a valid date.'
      return false
    }

    if (this.min && value < this.min) {
      if (this.messages && this.messages.min) {
        this.error = this.messages.min
      } else {
        this.error = 'Date is before earliest allowed.'
      }
      return false
    }

    if (this.max && value > this.max) {
      if (this.messages && this.messages.max) {
        this.error = this.messages.max
      } else {
        this.error = 'Date is after maximum allowed.'
      }
      return false
    }

    return true
  }
}

/**
 * MomentDateValidator - Validate the moment date object is between
 * certain values
 *
 * @class
 */
export class MomentDateValidator extends MomentDateTimeValidator {
  constructor({
    min = null,
    max = null,
    messages = null,
    required = null,
  } = {}) {
    super({ min, max, messages, required })

    if (min !== null) {
      this.min = this.min.startOf('day')
    }

    if (max !== null) {
      this.max = this.max.endOf('day')
    }

    this.messages = messages
  }
}

/**
 * TelephoneValidator - Simple check that telpehone number strings contain
 * at least min number of characters and consist only of numbers and spaces
 *
 * @class
 */
export class TelephoneValidator extends Mutt.validators.Validator {
  validate(value) {
    if (!value) {
      this.error = this.messages.required
      return false
    }

    // Regex test for numbers and ' '
    const telephoneStrPattern = /^[0-9\ ]+$/
    const regexTest = telephoneStrPattern.test(value)

    // Strip spaces before checking length
    value = value.replace(/\s/g, '')

    if (!regexTest || value.length < 10) {
      this.error = 'Not a valid telephone number.'
      return false
    }

    return true
  }
}

export class PostCodeValidator extends Mutt.validators.RegexValidator {
  constructor() {
    const msg = 'Please enter a valid UK postcode'
    super(PostCodeValidator.getPostCodePattern(), {
      required: msg,
      invalidPattern: msg,
    })
  }

  static getPostCodePattern() {
    return /[A-Z]{1,2}[A-Z0-9]{1,2} ?[0-9][A-Z]{2}/i
  }
}
