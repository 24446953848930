import { PetWithProducts } from '@/@types/Pet'
import { apiClient } from '@/lib/api/ApiClient'

/**
 * Get customer pets products
 *
 * @param {string} id Customer id
 * @returns {object} Customer products
 */
export async function getCustomerProducts(
  id: string
): Promise<PetWithProducts[]> {
  return await apiClient.get(`/v2/customers/${id}/products`).json()
}
