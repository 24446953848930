import { ApiListResponse } from '@/@types/ApiResponse'
import { Policy, PolicyCoolingOffStatus } from '@/domain/Policy'

import PaginatedParser, { PaginatedResponse } from './PaginatedParser'

export type PolicyJson = Policy
export type PolicyCoolingOffStatusJson = PolicyCoolingOffStatus

export default class PoliciesParser {
  public static parsePaginatedResponse(
    data: ApiListResponse<PolicyJson>
  ): PaginatedResponse<Policy> {
    return PaginatedParser.parsePaginatedResponse(
      data,
      PoliciesParser.parseMany
    )
  }

  public static parseMany(data: PolicyJson[]): Policy[] {
    return data.map((quoteJson) => PoliciesParser.parse(quoteJson))
  }

  public static parse(data: PolicyJson): Policy {
    return data
  }

  public static parseCoolingOffStatus(
    data: PolicyCoolingOffStatusJson
  ): PolicyCoolingOffStatus {
    return data
  }
}
