import { ref } from 'vue'

import { getSurchargesDetails } from '@/application/getProductDetails'
import { CatalogSurcharges } from '@/domain/Shop/ProductCatalog'

import useErrorModal from './useErrorModal'

const surcharges = ref<Nullable<CatalogSurcharges>>(null)

interface UseSrchargesResult {
  loadSurcharges: () => Promise<void>
  getSurcharges: () => Nullable<CatalogSurcharges>
  getSurchargeByKey: (key: string) => string
}

export default function useSurcharges(): UseSrchargesResult {
  async function loadSurcharges(): Promise<void> {
    try {
      const response = await getSurchargesDetails()
      surcharges.value = response
    } catch (error) {
      const { showError } = useErrorModal()
      showError(error)
    }
  }

  function getSurcharges(): Nullable<CatalogSurcharges> {
    return surcharges.value
  }

  function getSurchargeByKey(key: string): string {
    return surcharges.value?.[key] ?? key
  }

  return {
    loadSurcharges,
    getSurcharges,
    getSurchargeByKey,
  }
}
