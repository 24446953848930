import { ProductLineConfig } from '@/@types/Config'
import deepFreeze from '@/lib/utils/deepFreeze'

const productLineConfig: ProductLineConfig = {
  claims: {
    minWarningTimespan: {
      duration: 'P14D',
      format: 'days',
    },
    approveLimitPermissionPrefix: 'claims:insurance_gbp:',
    algoliaVetsConfig: {
      api_key: '8404765b9ecda907f74175f126d105f2',
      app_id: 'ORKMXLHYI4',
      index: 'prod_bbm_vets',
    },
  },
  rules: {
    paymentMethods: {
      monthly: ['STRIPE_BILLING'],
      yearly: ['STRIPE_BILLING'],
    },
  },
}

export default deepFreeze(productLineConfig)
