import APIError from './APIError'
import { ErrorContext } from './ErrorContext'
import { Tag } from './Tag'

export default class APIErrorUnprocessableEntity extends APIError {
  public canRetry: boolean
  public invalidFields: string[]

  constructor(params: {
    title: string
    tags?: Tag[]
    context?: ErrorContext
    originalError?: unknown
    disableAutoLog?: boolean
    canRetry: boolean
    invalidFields: string[]
  }) {
    super(params)
    this.canRetry = params.canRetry
    this.invalidFields = params.invalidFields

    // DO NOT REMOVE THE FOLLOWING LINE or instance of will be FALSE
    Object.setPrototypeOf(this, APIErrorUnprocessableEntity.prototype)
  }
}
