import type { Subscription, SubscriptionItem } from '@/domain/Subscription'
import { isSubscriptionProductLine } from '@/legacy/lib/utils'
import dayjs, { Dayjs } from '@/lib/dayjs'
import { PolicySnapshot } from '@/lib/utils/policySnapshot'

import { sum } from '.'

/**
 * Try to transpose a date to a new day of month, being careful not to "overflow",
 * instead clamping to the last day in the month.
 *
 * @param {dayjs} date The date to set the day of month of.
 * @param {number} day The day of month  to set on that date.
 * @returns {dayjs} The original date, transposed to the day passed, but clamped
 *          to the end of the month.
 */
export function setDateAndClampToMonth(date: Dayjs, day: number): Dayjs {
  const clampedDate = Math.min(day, date.daysInMonth())
  return date.set('date', clampedDate)
}

type PreviousAndNextPaymentDates = {
  nextPaymentDate: Dayjs
  previousPaymentDate: Dayjs
}

/**
 * Calculate the previous and next billing dates given a billing day and a reference date.
 *
 * @param {number} billingDay The usual billing day fr the subscription.
 * @param {dayjs} date The date for reference.
 * @returns {{previousPaymentDate: dayjs, nextPaymentDate: dayjs}} An object
 *          containing the previous and next payment dates from the passed
 *          reference date.
 */
export function previousAndNextPaymentDates(
  billingDay: number,
  date: Dayjs
): PreviousAndNextPaymentDates {
  // Set the billing date of the month
  const paymentDateOnMonth = setDateAndClampToMonth(date, billingDay)

  if (paymentDateOnMonth.isAfter(date)) {
    return {
      previousPaymentDate: setDateAndClampToMonth(
        paymentDateOnMonth.subtract(1, 'month'),
        billingDay
      ),
      nextPaymentDate: paymentDateOnMonth,
    }
  } else {
    return {
      previousPaymentDate: paymentDateOnMonth,
      nextPaymentDate: setDateAndClampToMonth(
        paymentDateOnMonth.add(1, 'month'),
        billingDay
      ),
    }
  }
}

type BillingPeriod = {
  billingPeriod: number
  remainingPeriod: number
}

/**
 * Calculate billing period data given a billing day and a reference date.
 *
 * @param {number} billingDay The usual billing day fr the subscription.
 * @param {dayjs} date The date for reference.
 * @returns {{billingPeriod: number, remainingPeriod: number}} An object
 *          containing the total and remaining billing period in days from the
 *          passed reference date.
 */
export function getBillingPeriod(
  billingDay: number,
  date: Dayjs
): BillingPeriod {
  const { previousPaymentDate, nextPaymentDate } = previousAndNextPaymentDates(
    billingDay,
    date
  )

  return {
    billingPeriod: nextPaymentDate.diff(previousPaymentDate, 'days'),
    remainingPeriod: nextPaymentDate.diff(date, 'days'),
  }
}

type EstimateProratedNextPaymentAmountParams = {
  billingDay: number
  effectiveDate: Dayjs
  monthlyAfter: number
  monthlyBefore: number
}

/**
 * Guess the approximate _next_ payment amount based on a change in monthly
 * payment amounts, and the fact that the date that the amount changes may be
 * inside of a billing period.
 *
 * @param {object} params The parameters as described below.
 * @param {number} params.monthlyBefore The previous nominal monthly cost.
 * @param {number} params.monthlyAfter The new nominal monthly cost.
 * @param {number} params.billingDay The standard billing day.
 * @param {dayjs} params.effectiveDate The date the new monthly payments become effective.
 * @returns {number} The approximate next payment amount based on passed parameters.
 */
export function estimateProratedNextPaymentAmount({
  monthlyBefore,
  monthlyAfter,
  billingDay,
  effectiveDate,
}: EstimateProratedNextPaymentAmountParams): number {
  const { remainingPeriod, billingPeriod } = getBillingPeriod(
    billingDay,
    effectiveDate
  )

  const priceDifference = monthlyAfter - monthlyBefore
  const remainingPeriodFraction = remainingPeriod / billingPeriod

  const proratedDifference = priceDifference * remainingPeriodFraction

  const nextPaymentAmount = monthlyAfter + proratedDifference

  return nextPaymentAmount
}

export const getNextSubscriptionBillingDateForPolicy = ({
  subscription,
  items = [],
}: {
  subscription: Nullable<Subscription>
  items: SubscriptionItem[]
}): Nullable<Dayjs> => {
  return getNextSubscriptionBillingDate({
    subscription,
    inceptionDates: items.map((item) => item.data.policy.inception_date),
  })
}

export const getNextSubscriptionBillingDateForPolicySnapshot = ({
  subscription,
  policiesSnapshots = [],
}: {
  subscription: Nullable<Subscription>
  policiesSnapshots: PolicySnapshot[]
}): Nullable<Dayjs> => {
  return getNextSubscriptionBillingDate({
    subscription,
    inceptionDates: policiesSnapshots.map((snapshot) => snapshot.inceptionDate),
  })
}

/**
 * Try to determine the billing date for a subscription based on the subscription's own billing DOM
 * using the subscription items external objects' inception dates as a fallback.
 *
 * @param params The function params
 * @param params.subscription The Subscription object
 * @param params.inceptionDates The array of inception dates.
 * @returns {dayjs|null} The calculated next billing date for the subscription.
 */
export function getNextSubscriptionBillingDate({
  subscription,
  inceptionDates,
}: {
  subscription: Nullable<Subscription>
  inceptionDates: string[]
}): Nullable<Dayjs> {
  let subscriptionBillingDate = subscription?.stripe_billing_day
    ? getNextSubscriptionBillingDateNaive(subscription.stripe_billing_day)
    : null

  if (!subscriptionBillingDate && inceptionDates.length) {
    subscriptionBillingDate = inceptionDates.reduce(
      (minDate, item) => dayjs.min(dayjs.utc(minDate), dayjs.utc(item)),
      dayjs(inceptionDates[0])
    )
  }

  return subscriptionBillingDate
}

/**
 * Get the next billing date for a subscription based on its billing DOM.
 *
 * @param {string} [originalBillingDay] The day of the month that the subscription is billed on.
 * @returns {dayjs|null} The calculated next billing date for the subscription.
 */
export function getNextSubscriptionBillingDateNaive(
  originalBillingDay: string
): Nullable<Dayjs> {
  if (!originalBillingDay) {
    return null
  }

  const dayOfMonth = parseInt(originalBillingDay)

  // Set to the first day of this month or next month so that we can safely set
  // the date later. If we didn't do this there is an edge case of adding two months.
  const nextBillingMonth =
    dayOfMonth > dayjs.utc().date()
      ? dayjs.utc().startOf('month')
      : dayjs.utc().startOf('month').add(1, 'month')

  // If the billing day is after the last day in this month, use the last day
  // of the month.
  const date = Math.min(dayOfMonth, nextBillingMonth.daysInMonth())
  const nextBillingDate = nextBillingMonth.date(date)

  return nextBillingDate
}

/**
 * Determine whether a subscription item is active or future dated.
 *
 * This is useful for returning the subscription items that we would think of as
 * being on the subscription.
 *
 * @param {object} item Subscription item to check.
 * @returns {boolean} Whether the given subscription item is active or future dated.
 */
export function subscriptionItemIsActiveOrFutureDated(
  item: SubscriptionItem
): boolean {
  return item.status === 'ACTIVE' || subscriptionItemIsFutureDated(item)
}

/**
 * Determine whether a subscription item is future dated.
 *
 * @param {object} item Subscription item to check.
 * @returns {boolean} Whether the given subscription item is future dated.
 */
export function subscriptionItemIsFutureDated(item: SubscriptionItem): boolean {
  return (
    item.status === 'INACTIVE' && item.metadata?.future_dated_inception === true
  )
}

/**
 * Get the total monthly cost of a subscription based on its items array.
 *
 * @param {object[]} items The array of subscription items.
 * @returns {number} The total monthly cost of all valid subscription items.
 */
export function subscriptionMonthlyCost(items: SubscriptionItem[]): number {
  return sum(items, 'monthly_cost', subscriptionItemIsActiveOrFutureDated)
}

export interface Quote {
  account_ref: string
  productline_key: string
  basket: Object
}

/**
 * Return whether the quote is a "subscription quote" or not.
 *
 * @param {object} quote The quote to check.
 * @returns {boolean} Whether the quote should use the subscription UI journey.
 */
export function isSubscriptionQuote(quote: Quote): boolean {
  const { account_ref: agency, productline_key: productLine, basket } = quote
  return Boolean(basket && isSubscriptionProductLine(agency, productLine))
}

/**
 * Return the Stripe subscription url. Depending on the subscription id, it
 * could point to Stripe subscroption view or Stripe customer view.
 *
 * @param {string} subscriptionId Stripe subscription id or Stripe customer id
 * @param {boolean} isProduction Should it point to production environemnt
 * @returns {string|null} Stripe url
 */
export function getStripeSubscriptionUrl(
  subscriptionId: Maybe<string>,
  isProduction: boolean = false
): Nullable<string> {
  if (!subscriptionId) {
    return null
  }

  const baseStripeUrl = isProduction
    ? 'https://dashboard.stripe.com'
    : 'https://dashboard.stripe.com/test'

  return subscriptionId.startsWith('cus_')
    ? `${baseStripeUrl}/customers/${subscriptionId}`
    : `${baseStripeUrl}/subscriptions/${subscriptionId}`
}

/**
 * Returns whether the subscription is "dormant" or not
 *
 * @param {Subscription} subscription The subscription object
 *
 * @returns boolean
 */
export function isDormantSubscription(subscription: Subscription): boolean {
  // A dormant subscription is defined as one that has at least some items,
  // all of which are inactive but none of which are future dated inception.
  // i.e. a subscription that only has cancelled items, and at least one.
  return Boolean(
    subscription.items?.length &&
      subscription.items?.every(
        (item) =>
          item.status === 'INACTIVE' &&
          item.metadata?.future_dated_inception !== true
      )
  )
}
