import { CustomerFlag } from '@/domain/CustomerFlag'

interface CustomerFlagJson {
  flags: {
    vulnerable?: boolean
  }
}

export default class CustomerFlagParser {
  public static parse(data: CustomerFlagJson): CustomerFlag[] {
    const result: CustomerFlag[] = []

    if (data.flags.vulnerable) {
      result.push('VULNERABLE')
    }

    return result
  }
}
