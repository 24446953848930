import type { Duration, DurationUnitType } from 'dayjs/plugin/duration'

export const formatDuration = (
  duration: Duration,
  format: DurationUnitType
): string => {
  const value = duration.as(format)

  if (Number.isNaN(value)) {
    console.error(
      `Unsupported format ${format} when formatting ${duration.toISOString()}`
    )
    return 'unknown'
  }

  // this is safe currently as all the English language singular
  // versions of the units we care about are just the plural without an 's'
  // May need looking at again once we introduce translations
  const unit = value === 1 ? format.substring(0, format.length - 1) : format
  return `${value} ${unit}`
}
