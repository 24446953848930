/* eslint-disable jsdoc/require-returns, jsdoc/require-param-description */
import { useMemoize } from '@vueuse/core'
import algoliasearch from 'algoliasearch'
import type { SearchIndex } from 'algoliasearch'

import type { ApiListResponse, ApiListParams } from '@/@types/ApiResponse'
import type { AlgoliaVetsConfig } from '@/@types/Config'
import type { PatchOperation } from '@/@types/JSONPatch'
import { apiClient } from '@/lib/api/ApiClient'
import { getConfigForFeature } from '@/lib/appConfig'
import {
  Calculation,
  CalculationAssessmentResult,
} from '@/novelClaims/domain/Calculation'
import { Claim, ClaimFormData, SearchClaim } from '@/novelClaims/domain/Claim'
import type { ClaimNote } from '@/novelClaims/domain/ClaimNote'
import { CustomerWithAddress } from '@/novelClaims/domain/Customer'
import type { Deductions } from '@/novelClaims/domain/Deductions'
import type { Doc } from '@/novelClaims/domain/Doc'
import type {
  DocRequest,
  DocRequestFormData,
} from '@/novelClaims/domain/DocRequest'
import { DuplicateLoss } from '@/novelClaims/domain/DuplicateLoss'
import type {
  Loss,
  LossPayee,
  LossVet,
  LossFormData,
} from '@/novelClaims/domain/Loss'
import type {
  Payee,
  PayeePayload,
  PayeeFormData,
  Relationship,
} from '@/novelClaims/domain/Payee'
import type { PolicyV2 } from '@/novelClaims/domain/PolicyV2'
import type {
  VetPractice,
  SearchVetPractice,
  VetPracticeFormData,
} from '@/novelClaims/domain/VetPractice'
import { sortClaimLosses } from '@/novelClaims/utils/claimUtils'

// TODO: move these into separate services or backport to the existing services
// (maybe do this after we remove the legacy claims)

// CUSTOMERS
////////////////////////////////////////////////////////////////////////////////
const CUSTOMERS_PATH = '/users'

export async function getCustomer(id: string): Promise<CustomerWithAddress> {
  return await apiClient
    .get(`${CUSTOMERS_PATH}/${id}`, {
      searchParams: {
        include_address: true,
      },
    })
    .json<CustomerWithAddress>()
}

// CLAIMS
////////////////////////////////////////////////////////////////////////////////
const CLAIMS_PATH = '/v3/claims'

export async function getClaim(id: string): Promise<Claim> {
  const claim = await apiClient.get(`${CLAIMS_PATH}/${id}`).json<Claim>()
  sortClaimLosses(claim)
  return claim
}

export async function getClaims(
  params?: {
    insured_entity_id?: string
    owner?: string
    policy_sid?: string
    ref?: string
    status?: string
    title?: string
    continuation_id?: string
    vet_practice_id?: string
  } & ApiListParams
): Promise<ApiListResponse<Claim>> {
  const response = await apiClient
    .get(CLAIMS_PATH, {
      searchParams: {
        ...params,
      },
    })
    .json<ApiListResponse<Claim>>()

  response._embedded.items.forEach(sortClaimLosses)
  return response
}

export async function createClaim(data: ClaimFormData): Promise<Claim> {
  const claim = await apiClient
    .post(CLAIMS_PATH, {
      searchParams: {
        complete_claim: true,
      },
      json: data,
    })
    .json<Claim>()

  sortClaimLosses(claim)
  return claim
}

export async function patchClaim(id: string, data: any): Promise<Claim> {
  const claim = await apiClient
    .patch(`${CLAIMS_PATH}/${id}`, { json: data })
    .json<Claim>()

  sortClaimLosses(claim)
  return claim
}

export async function searchClaims(
  params?: {
    q?: string
  } & ApiListParams
): Promise<ApiListResponse<SearchClaim>> {
  return await apiClient
    .get(`${CLAIMS_PATH}/search`, { searchParams: { ...params } })
    .json()
}

export async function createClaimLoss(
  claimId: string,
  data: LossFormData
): Promise<Loss> {
  return await apiClient
    .post(`${CLAIMS_PATH}/${claimId}/losses`, {
      json: data,
      searchParams: {
        complete_loss: true,
      },
    })
    .json()
}

export async function patchClaimLoss(
  claimId: string,
  lossId: string,
  data: any
): Promise<Loss> {
  return await apiClient
    .patch(`${CLAIMS_PATH}/${claimId}/losses/${lossId}`, { json: data })
    .json()
}

export async function updateClaimLossPayee(
  claimId: string,
  lossId: string,
  params: { payee: LossPayee }
): Promise<LossPayee> {
  const { payee } = params
  return await apiClient
    .put(`${CLAIMS_PATH}/${claimId}/losses/${lossId}/payee`, {
      json: payee,
    })
    .json<LossPayee>()
}

export async function updateClaimLossVets(
  claimId: string,
  lossId: string,
  params: { vets: LossVet[] }
): Promise<Loss> {
  const { vets } = params
  return await apiClient
    .put(`${CLAIMS_PATH}/${claimId}/losses/${lossId}/vets`, {
      json: vets,
    })
    .json<Loss>()
}

export async function approveClaimLoss(
  claimId: string,
  lossId: string
): Promise<Loss> {
  return await apiClient
    .post(`${CLAIMS_PATH}/${claimId}/losses/${lossId}/approve`)
    .json<Loss>()
}

export async function closeClaimLoss(
  claimId: string,
  lossId: string,
  params: { reason: string }
): Promise<Loss> {
  return await apiClient
    .post(`${CLAIMS_PATH}/${claimId}/losses/${lossId}/close`, {
      json: {
        reason: params.reason,
      },
    })
    .json<Loss>()
}

export async function declineClaimLoss(
  claimId: string,
  lossId: string,
  params: { reason: string }
): Promise<Loss> {
  return await apiClient
    .post(`${CLAIMS_PATH}/${claimId}/losses/${lossId}/decline`, {
      json: {
        reason: params.reason,
      },
    })
    .json<Loss>()
}

export async function reopenClaimLoss(
  claimId: string,
  lossId: string,
  params: { reason: string }
): Promise<Loss> {
  return await apiClient
    .post(`${CLAIMS_PATH}/${claimId}/losses/${lossId}/reopen`, {
      json: {
        reason: params.reason,
      },
    })
    .json<Loss>()
}

export async function deleteClaimLoss(
  claimId: string,
  lossId: string
): Promise<void> {
  return await apiClient
    .delete(`${CLAIMS_PATH}/${claimId}/losses/${lossId}`)
    .json()
}

export async function connectClaimContinuation(
  claimId: string,
  continuationId: string
): Promise<void> {
  return await apiClient
    .put(`${CLAIMS_PATH}/${claimId}/continuation`, {
      json: {
        claim_id: continuationId,
      },
    })
    .json()
}

export async function disconnectClaimContinuation(
  claimId: string
): Promise<void> {
  return await apiClient.delete(`${CLAIMS_PATH}/${claimId}/continuation`).json()
}

export async function deleteClaim(claimId: string): Promise<void> {
  return await apiClient.delete(`${CLAIMS_PATH}/${claimId}`).json()
}

// CALCULATIONS
///////////////////////////////////////////////////////////////////////////////
const CALCULATIONS_PATH = '/v2/calculations'

export async function createCalculation(
  data: Partial<Calculation>
): Promise<Calculation> {
  return await apiClient.post(CALCULATIONS_PATH, { json: data }).json()
}

export async function getCalculations(
  searchParams: any
): Promise<ApiListResponse<Calculation>> {
  return await apiClient.get(CALCULATIONS_PATH, { searchParams }).json()
}

export async function assessCalculation(
  id: string,
  data: { assessment_result: CalculationAssessmentResult },
  params?: {
    force_over_limit?: boolean
  }
): Promise<Calculation> {
  return await apiClient
    .post(`${CALCULATIONS_PATH}/${id}/assess`, {
      searchParams: { ...params },
      json: data,
    })
    .json()
}

export async function withdrawCalculation(id: string): Promise<Calculation> {
  return await apiClient.post(`${CALCULATIONS_PATH}/${id}/withdraw`).json()
}

// CLAIM NOTES
///////////////////////////////////////////////////////////////////////////////
const CLAIM_NOTES_PATH = '/v2/notes'

export async function getClaimNotes(
  params?: {
    author?: string
    case_id?: string
    continuation_id?: string
    category?: string
  } & ApiListParams
): Promise<ApiListResponse<ClaimNote>> {
  return apiClient
    .get(CLAIM_NOTES_PATH, {
      searchParams: {
        ordering: '-created_at',
        page_number: 1,
        per_page: 200,
        ...params,
      },
    })
    .json<ApiListResponse<ClaimNote>>()
}

export async function createClaimNote(
  claimNote: Partial<ClaimNote>
): Promise<ClaimNote> {
  return apiClient
    .post(CLAIM_NOTES_PATH, {
      json: claimNote,
    })
    .json<ClaimNote>()
}

// POLICIES
///////////////////////////////////////////////////////////////////////////////
const POLICES_V2_PATH = '/v2/policies'

export async function getPoliciesV2(
  searchParams?: {
    owner?: string
    include_legacy?: boolean
  } & ApiListParams
): Promise<ApiListResponse<PolicyV2>> {
  return await apiClient
    .get(`${POLICES_V2_PATH}`, {
      searchParams: {
        include_legacy: true, // Include the legacy cat-dog policies by default
        ...searchParams,
      },
    })
    .json()
}

export async function getPolicyV2(
  policyId: string,
  searchParams?: { policy_at_date: string }
): Promise<PolicyV2> {
  const policy = await apiClient
    .get(`${POLICES_V2_PATH}/${policyId}`, {
      searchParams,
    })
    .json<PolicyV2>()

  // Fetching the PolicyV2 by id does not always return the id in the response (cat-dog policies)
  // Makes things easier to work with downstream if we always have the id when we fetch by id
  if (!policy.id && policyId.startsWith('pol_')) {
    policy.id = policyId
  }

  return policy
}

// ALGOLIA INDEXES
///////////////////////////////////////////////////////////////////////////////
// Global algolia vets indexes cache (based on product line key)
const algoliaVetsIndexesMap: Record<string, SearchIndex> = {}

export function getAlgoliaVetsIndex(productLineKey: string): SearchIndex {
  if (!algoliaVetsIndexesMap[productLineKey]) {
    const algoliaConfig = getConfigForFeature('claims.algoliaVetsConfig', {
      productLine: productLineKey,
    }) as AlgoliaVetsConfig

    const algoliaVetsClient = algoliasearch(
      algoliaConfig.app_id,
      algoliaConfig.api_key
    )

    algoliaVetsIndexesMap[productLineKey] = algoliaVetsClient.initIndex(
      algoliaConfig.index
    )
  }

  return algoliaVetsIndexesMap[productLineKey]
}

// Global algolia conditions index cache
// TODO: we use only 1 conditions index for all envs and all product line.
// We should create indexes per env per PL.
let algoliaConditionsIndex: Maybe<SearchIndex>

export function getAlgoliaConditionsIndex(): SearchIndex {
  if (!algoliaConditionsIndex) {
    const algoliaConditionsClient = algoliasearch(
      import.meta.env.VITE_ALGOLIA_CONDITIONS_ID!,
      import.meta.env.VITE_ALGOLIA_CONDITIONS_KEY!
    )
    algoliaConditionsIndex = algoliaConditionsClient.initIndex(
      import.meta.env.VITE_ALGOLIA_CONDITIONS_INDEX!
    )
  }

  return algoliaConditionsIndex
}

// PAYMENTS (LOSS PAYEE)
///////////////////////////////////////////////////////////////////////////////
// TODO: this does not belong in API
export function createPayeePayloadFromData(data: PayeeFormData): PayeePayload {
  const payload = {
    account_holder_name: data.account_holder_name,
    relationship: data.relationship,
  }

  if (data.payment_type === 'UKAccount') {
    return {
      payment_type: 'UKAccount',
      account_type: data.account_type,
      account_details: {
        account_number: data.account_number,
        sort_code: data.sort_code,
      },
      ...payload,
    }
  }

  if (data.payment_type === 'USAAccount') {
    return {
      payment_type: 'USAAccount',
      account_type: data.account_type,
      account_details: {
        account_name: data.account_name,
        account_number: data.account_number,
        routing_number: data.routing_number,
        account_purpose: data.account_purpose,
      },
      ...payload,
    }
  }

  if (data.payment_type === 'USAAddress') {
    return {
      payment_type: 'USAAddress',
      account_details: {
        address_line_1: data.address.line1,
        address_line_2: data.address.line2,
        city: data.address.city,
        us_state: data.address.county,
        zip_code: data.address.postcode,
      },
      ...payload,
    }
  }

  throw new Error(`Could not generate payee payload`)
}

// TODO: this does not belong in API
export function createFormDataFromPayee(payee: Payee): Partial<PayeeFormData> {
  const data = {
    account_holder_name: payee.account_holder_name,
    payment_type: payee.payment_type,
    relationship: payee.relationship,
  }

  if (payee.payment_type === 'UKAccount') {
    return {
      account_type: payee.account_type,
      // account_number and sort_code are masked
      ...data,
    }
  }

  if (payee.payment_type === 'USAAccount') {
    return {
      account_type: payee.account_type,
      account_name: payee.account_details.account_name,
      account_purpose: payee.account_details.account_purpose,
      // account_number and routing_number are masked
      ...data,
    }
  }

  if (payee.payment_type === 'USAAddress') {
    return {
      address: {
        line1: payee.account_details.address_line_1,
        line2: payee.account_details.address_line_2,
        city: payee.account_details.city,
        county: payee.account_details.us_state,
        postcode: payee.account_details.zip_code,
      },
      ...data,
    }
  }

  throw new Error(
    // @ts-expect-error we should not reach this code
    `Could not generate form data for payment_type "${payee.payment_type}"`
  )
}

export async function createPayee({
  customerId,
  paymentPayload,
}: {
  customerId: string
  paymentPayload: PayeePayload
}): Promise<Payee> {
  return await apiClient
    .post(`/payouts/default-payment-method/${customerId}`, {
      json: paymentPayload,
    })
    .json()
}

// https://github.com/boughtbymany/service-payments/blob/master/docs/payouts/public_views.md
export async function getPayee({
  payeeId,
  relationship,
}: {
  payeeId: string
  relationship: Relationship
}): Promise<Payee> {
  return await apiClient
    .get('/payouts/staff-facing-payment-method', {
      searchParams: {
        payee: payeeId,
        relationship,
      },
    })
    .json()
}

// https://github.com/boughtbymany/service-payments/blob/master/docs/payouts/public_views.md
export async function getDefaultCustomerPayee({
  customerId,
}: {
  customerId: string
}): Promise<Nullable<Payee>> {
  try {
    return await apiClient
      .get('/payouts/staff-facing-payment-method', {
        searchParams: {
          user_uuid: customerId,
          relationship: 'POLICYHOLDER',
        },
      })
      .json()
  } catch (error: any) {
    if (error.response?.status === 404) {
      return null
    }

    throw error
  }
}

export async function validatePayeeAccount(details: {
  account_name: string
  account_number: string
  sort_code: string
}): Promise<{
  valid: boolean
  message?: string
}> {
  try {
    return await apiClient
      .post('/payments/validate', {
        json: {
          details,
          method: 'DIRECT_DEBIT',
        },
      })
      .json()
  } catch (error: any) {
    if (error.response?.status === 422) {
      const json = await error.response.clone().json()
      return {
        valid: false,
        message: json.detail || 'Invalid account details',
      }
    }

    throw error
  }
}

// VET PRACTICES
///////////////////////////////////////////////////////////////////////////////

const VET_PRACTICES_PATH = '/vet-practices'

export async function getVetPractice(id: string): Promise<VetPractice> {
  return await apiClient.get(`${VET_PRACTICES_PATH}/${id}`).json()
}

export async function getVetPractices(
  params?: ApiListParams
): Promise<ApiListResponse<VetPractice>> {
  return await apiClient
    .get(VET_PRACTICES_PATH, {
      searchParams: {
        ...params,
      },
    })
    .json()
}

export async function createVetPractice(
  data: VetPracticeFormData
): Promise<VetPractice> {
  return await apiClient
    .post(VET_PRACTICES_PATH, { json: data })
    .json<VetPractice>()
}

export async function patchVetPractice(
  id: string,
  patches: PatchOperation[]
): Promise<VetPractice> {
  return await apiClient
    .patch(`${VET_PRACTICES_PATH}/${id}`, { json: patches })
    .json<VetPractice>()
}

export async function deleteVetPractice(id: string): Promise<void> {
  return await apiClient.delete(`${VET_PRACTICES_PATH}/${id}`).json()
}

export async function searchVetPractices(
  params?: {
    q?: string
    exclude_corporate_offices?: boolean
  } & ApiListParams
): Promise<ApiListResponse<SearchVetPractice>> {
  return await apiClient
    .get(`${VET_PRACTICES_PATH}/search`, { searchParams: { ...params } })
    .json()
}

export async function migrateVetPracticeClaims(
  id: string,
  data: { new_vet_practice_id: string; reason?: string }
): Promise<void> {
  return await apiClient
    .post(`${VET_PRACTICES_PATH}/${id}/migrate-claims`, { json: data })
    .json()
}

// DOCUMENTS
///////////////////////////////////////////////////////////////////////////////

const DOCUMENTS_PATH = '/documents'

export async function getDocuments(
  params: {
    tags?: string
    owner?: string
  } & ApiListParams
): Promise<ApiListResponse<Doc>> {
  return await apiClient
    .get(DOCUMENTS_PATH, {
      searchParams: {
        ordering: '-created_at',
        page_number: 1,
        per_page: 10,
        ...params,
      },
    })
    .json()
}

export async function getDocument(id: string): Promise<Doc> {
  return await apiClient.get(`${DOCUMENTS_PATH}/${id}`).json()
}

export async function makeUploadUrl(payload: { mime_type: string }): Promise<{
  fetch_url: string
  fields: Record<string, string>
  url: string
  s3_uri: string
}> {
  return await apiClient
    .post(`${DOCUMENTS_PATH}/make-upload-url`, {
      json: payload,
    })
    .json()
}

export async function createDocument(data: Partial<Doc>): Promise<Doc> {
  return await apiClient
    .post(DOCUMENTS_PATH, {
      json: data,
    })
    .json<Doc>()
}

export async function patchDocument(
  id: string,
  patches: PatchOperation[]
): Promise<Doc> {
  return await apiClient
    .patch(`${DOCUMENTS_PATH}/${id}`, { json: patches })
    .json()
}

export async function deleteDocument(id: string): Promise<void> {
  return await apiClient.delete(`${DOCUMENTS_PATH}/${id}`).json()
}

export async function linkEntity(payload: {
  entity_type: 'loss'
  entity_id: string
  link: string[]
  unlink: string[]
}): Promise<Doc[]> {
  return await apiClient
    .post(`${DOCUMENTS_PATH}/link_entity`, { json: payload })
    .json<Doc[]>()
}

// DOCUMENT REQUESTS
///////////////////////////////////////////////////////////////////////////////
const DOCUMENT_REQUESTS_PATH = '/document-requests'

export async function createDocumentRequest(
  data: DocRequestFormData
): Promise<DocRequest> {
  return await apiClient
    .post(DOCUMENT_REQUESTS_PATH, {
      json: data,
    })
    .json<DocRequest>()
}

// POLICY IN ARREARS (SUBSCRIPTION/S-LEDGER)
///////////////////////////////////////////////////////////////////////////////

export async function checkPolicyInArrears(policyId: string): Promise<boolean> {
  const response = await apiClient
    .get(`/check_in_arrears/${policyId}`)
    .json<{ is_in_arrears: boolean }>()

  return response.is_in_arrears
}

// DEDUCTIONS
///////////////////////////////////////////////////////////////////////////////

export async function _getDeductions(): Promise<Deductions> {
  return await apiClient.get(`/deductions`).json<Deductions>()
}

export const getDeductions = useMemoize(_getDeductions)

// POLICY LIMITS
///////////////////////////////////////////////////////////////////////////////
const POLICY_LIMITS_PATH = '/v2/limits'

// The endpoint that fetches the policy balances is in PolicyLimitsService.
// It might be good to move it here for consistency in the future.

export async function adjustExcessBalance(data: {
  amount: number
  description: string
  loss_id: string
}): Promise<void> {
  await apiClient
    .post(`${POLICY_LIMITS_PATH}/adjust-excess-balance`, {
      json: data,
    })
    .json()
}

// LOSS DUPLICATES
///////////////////////////////////////////////////////////////////////////////
export async function checkDuplicateLosses(params: {
  pet_uuid: string
  policy_sid: string
  treatment_start_date: string
  treatment_end_date: string
}): Promise<DuplicateLoss[]> {
  const response = await apiClient
    .post('/check-duplicates', {
      json: params,
    })
    .json<ApiListResponse<DuplicateLoss>>()

  return response._embedded.items
}

export async function getDuplicateLosses(
  petUuid: string
): Promise<DuplicateLoss[]> {
  const response = await apiClient
    .get('/duplicates', {
      searchParams: {
        pet_uuid: petUuid,
      },
    })
    .json<ApiListResponse<DuplicateLoss>>()

  return response._embedded.items
}
