<template>
  <button class="agency-card" type="button" @click="$emit('click')">
    <div class="agency-card__icon" :class="`${agencyRef}-large-light`" />
    <h5 class="agency-card__heading">
      {{ title }}
    </h5>
  </button>
</template>

<script>
export default {
  name: 'AgencyCard',

  props: {
    agencyRef: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  emits: ['click'],
}
</script>

<style lang="scss" scoped>
.agency-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 228px;
  height: 287px;
  border-radius: 8px;
  color: var(--el-color-primary);
  background: var(--el-color-primary-light-9);
  text-align: center;
  font-size: 18px;
  box-shadow: 0 1px 1px rgba(44, 62, 72, 0.3);
  cursor: pointer;
  padding: 30px;
  margin: 8px;

  &__heading {
    display: flex;
    flex-shrink: 0;
    flex: 1;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 156px;
    height: 156px;
    margin-bottom: 15px;
    flex: 3;
    flex-shrink: 0;
  }

  &:hover {
    color: var(--el-color-primary-light-9);
    background: var(--el-color-primary);
    box-shadow: 0 4px 13px 0 rgba(44, 62, 72, 0.3);
  }
}
</style>
