// This proxy is used to conditionally switch between the real and the mocked version
// of the "@auth0/auth0-vue" module at runtime, so that we can bypass and stub
// the authentication when running component tests.
import {
  createAuth0 as createAuth0Original,
  useAuth0 as useAuth0Original,
  authGuard as authGuardOriginal,
} from '@auth0/auth0-vue'

import {
  createAuth0 as createAuth0Mock,
  useAuth0 as useAuth0Mock,
  authGuard as authGuardMock,
} from '@/lib/auth0/auth0-vue-mock'

let createAuth0: typeof createAuth0Original
let useAuth0: typeof useAuth0Original
let authGuard: typeof authGuardOriginal

// We set 'mock-id-token' and 'mock-access-token' in session storage when Playwright's page initialises
if (window.sessionStorage.getItem('mock-id-token')) {
  // We are casting the mocks so that we have proper TS types throughout the code
  createAuth0 = createAuth0Mock as unknown as typeof createAuth0Original
  useAuth0 = useAuth0Mock as unknown as typeof useAuth0Original
  authGuard = authGuardMock as unknown as typeof authGuardOriginal
} else {
  createAuth0 = createAuth0Original
  useAuth0 = useAuth0Original
  authGuard = authGuardOriginal
}

export { createAuth0, useAuth0, authGuard }
