import {
  CatalogOption,
  CatalogProduct,
  CatalogSurcharges,
  isCatalogOption,
  isCatalogProduct,
  isCatalogSurcharge,
  ProductCatalog,
  ProductCatalogElement,
} from '@/domain/Shop/ProductCatalog'
import ProductCatalogService from '@/services/Shop/ProductCatalogService'

let hydratePromise: Promise<void> | undefined
let catalog: ProductCatalog = new Map()

export async function fetchAndStoreCatalog(): Promise<void> {
  const catalogDetails = await ProductCatalogService.getCatalog()
  catalog = catalogDetails
}

export function resetCatalog(): void {
  catalog = new Map()
  hydratePromise = undefined
}

export async function loadCatalogData(): Promise<void> {
  if (!hydratePromise) {
    hydratePromise = fetchAndStoreCatalog()
  }

  await hydratePromise
  hydratePromise = undefined
}

export async function getCatalogItem(
  key: string
): Promise<ProductCatalogElement> {
  let productDetails = catalog.get(key)

  if (!productDetails) {
    await loadCatalogData()
    productDetails = catalog.get(key)
  }

  if (!productDetails) {
    throw new Error(`Product details for SKU ${key} not found`)
  }

  return productDetails
}

export async function getOptionDetails(sku: string): Promise<CatalogOption> {
  const details = await getCatalogItem(sku)

  if (!isCatalogOption(details)) {
    throw new Error(`Product details for SKU ${sku} is not a catalog option`)
  }

  return details
}

export async function getProductDetails(sku: string): Promise<CatalogProduct> {
  const details = await getCatalogItem(sku)

  if (!isCatalogProduct(details)) {
    throw new Error(`Product details for SKU ${sku} is not a catalog product`)
  }

  return details
}

export async function getSurchargesDetails(): Promise<CatalogSurcharges> {
  let surchargeDetails = catalog.get('surcharges')

  if (!surchargeDetails) {
    await loadCatalogData()
    surchargeDetails = catalog.get('surcharges')
  }

  if (!surchargeDetails) {
    return {} as Record<string, string>
  }

  if (!isCatalogSurcharge(surchargeDetails)) {
    throw new Error('Surcharge details not found')
  }

  return surchargeDetails
}
