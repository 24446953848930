import { AgencyConfig } from '@/@types/Config'
import deepFreeze from '@/lib/utils/deepFreeze'

const agencyConfig: AgencyConfig = {
  flags: {
    allowCreatingNewClaim: true,
    allowEditProductAtRenewal: true,
    ibsuiteSync: true,
    usePca: true,
    showCalculationsList: true,
    customerNotesEnabled: true,
    allowEditClaimTitle: true,
    allowAddLossRecoveredAmount: true,
    allowDocumentRequestFromVet: true,
    allowDifferentPostcodes: false,
    dobAsRatingFactor: true,
    blockQuoting: false,
    enableNewFlow: true, // will be used to force SGP enable for staging
    forceDisableNewFlow: false,
    enableEmailOrder: false,
    nudgeForNewFlow: false,
    disableOldQuotingFlow: false,
    displayCancellationWarningText: true,
    displayCustomerFlagsDropdown: true,
    displayFullAddressOnNewBusiness: true,
    allowEditLegacyCustomer: false,
    disableEditEffectiveDate: false,
    disableEditInceptionDate: false,
    enableNewBusinessNextDayInceptionDate: false,
    enableEndOfBillingPlusOneCancellation: false,
    enableDirectDebit: true,
    enableNewDocumentsTab: true,
    removeSubscriptionsTab: true,
    displayWaitingPeriod: false,
    enableCommunication: true,
    enableUserHistory: false,
    enableRetainedPremiumsCalculations: false,
    enableReinstatement: true,
    enableSendingAffiliateCode: true,
    enableResetSmsMfa: true,
    enableNewRenewalUi: true,
    newFinancialTabIsEnabled: true,
    enableCerfScore: true,
    ibaPetTimelineEnabled: true,
    enableEditOfPaymentDate: true,
  },
  currency: {
    code: 'GBP',
    symbol: '£',
    symbolPosition: 'prepend',
  },
  copaymentType: 'co-payment',
  excessType: 'excess',
  locale: 'en-GB',
  dateFormatForLocale: 'D MMM YYYY',
  algolia: {
    breedLookup: {
      id: 'ORKMXLHYI4',
      index: 'dev_cat_dog_pio_pets',
      key: '41a5bf6bdd81ce963852ccd5a7529353',
    },
  },
  payment: {
    mode: 'stripe',
    speik: {
      allowDevMode: true,
      iframeSrc:
        'https://uk-preprod.aeriandi.com/Player/app/v1/78480/4432469/AgentPayFramework/',
      paymentAccount: 'UkCatDogPioStaging',
    },
    stripe: {
      publishable_key: 'pk_test_8V022ZFb1roxiosPUVrNu0LU',
    },
  },
  globalProductLines: ['cat-dog-pio', 'pet-insurance-uk'],
  claims: {
    docTypes: [
      'MEDICAL_HISTORY',
      'INVOICE',
      'REFERRAL_REPORT',
      'TREATMENT_NOTE',
      'PRESCRIPTION',
      // 'ESTIMATE',
      // 'RECEIPT',
      'OTHER',
    ],
  },
  vetPractices: {
    country: 'UK',
    corporateNetworks: [
      'CVS',
      'Goddards',
      'IVC',
      'Linneaus',
      'Medivet',
      'Pets at Home',
      'VetsNow',
    ],
  },
  paymentTypes: ['UKAccount'],
  customerCountryIsRequired: true,
  customerStateIsRequired: true,
  updatePetStateOnCustomerEdit: false,
  legacyProductLines: ['cat-dog'],
  quotableProductLines: ['cat-dog', 'cat-dog-pio'],
  sgpProductLines: ['pet-insurance-uk'],
  newFlowEnabledProductLines: ['pet-insurance-uk', 'cat-dog-pio'],
  usePcaStateCode: false,
  maxPetAge: 21,
}

export default deepFreeze(agencyConfig)
