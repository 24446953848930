import type { ApiListParams } from '@/@types/ApiResponse'
import type { Subscription } from '@/domain/Subscription'

import ApiService from './ApiService'
import { PaginatedResponse } from './parsers/PaginatedParser'
import SubscriptionsParser from './parsers/SubscriptionsParser'

const SUBSCRIPTIONS_PATH = '/subscriptions'

export default class SubscriptionsService {
  public static async get(subscriptionId: string): Promise<Subscription> {
    return await ApiService.get({
      path: `${SUBSCRIPTIONS_PATH}/${subscriptionId}`,
      parse: SubscriptionsParser.parse,
    })
  }

  public static async getSubscriptions(
    params?: {
      owner?: string
      status?: string
    } & ApiListParams
  ): Promise<Subscription[]> {
    const response = await ApiService.get({
      path: SUBSCRIPTIONS_PATH,
      params: {
        searchParams: {
          page_number: 1,
          per_page: 10,
          ...params,
        },
      },
      parse: SubscriptionsParser.parsePaginatedResponse,
    })

    return response.items
  }

  public static async searchSubscriptions(
    params?: {
      owner: string
    } & ApiListParams
  ): Promise<PaginatedResponse<Subscription>> {
    return await ApiService.get({
      path: SUBSCRIPTIONS_PATH,
      params: {
        searchParams: {
          q: '',
          ...params,
        },
      },
      parse: SubscriptionsParser.parsePaginatedResponse,
    })
  }

  public static async create(params: {
    customerId: string
  }): Promise<Subscription> {
    const { customerId } = params

    return await ApiService.post({
      path: SUBSCRIPTIONS_PATH,
      params: {
        json: {
          owner: customerId,
        },
      },
      parse: SubscriptionsParser.parse,
    })
  }

  public static async setStripePaymentMethodId(params: {
    subscriptionId: string
    paymentMethodId: string
  }): Promise<Subscription> {
    const { subscriptionId, paymentMethodId } = params

    return await ApiService.post({
      path: `${SUBSCRIPTIONS_PATH}/${subscriptionId}/set-stripe-payment-method-id`,
      params: {
        json: {
          payment_method_id: paymentMethodId,
        },
      },
      parse: SubscriptionsParser.parse,
    })
  }

  public static async previewSubscriptionChange(params: {
    subscriptionId: string
    data: {
      subscriptionItem: string
      effectiveDate: string
      price: number
      interval: string
      prorate: boolean
    }
  }): Promise<unknown> {
    const { subscriptionId, data } = params

    return await ApiService.post({
      path: `${SUBSCRIPTIONS_PATH}/${subscriptionId}/preview`,
      params: {
        json: {
          subscription_item: data.subscriptionItem,
          effective_date: data.effectiveDate,
          price: data.price,
          interval: data.interval,
          prorate: data.prorate,
        },
      },
      parse: (data: unknown) => data,
    })
  }
}
