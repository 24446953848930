import dayjs from '@/lib/dayjs'

/**
 * Get the build context
 *
 * @returns {string} the build context
 */
export function getBuildContext(): string {
  return window.BUILD_CONTEXT
}

/**
 * Get the build commit ref
 *
 * @returns {string} the build commit ref
 */
export function getBuildCommitRef(): string {
  return window.BUILD_COMMIT_REF
}

/**
 * Get the build version
 *
 * @returns {string} the build version
 */
export function getBuildVersion(): string {
  return window.BUILD_COMMIT_REF?.substring(0, 6) || 'local'
}

/**
 * Get the build time
 *
 * @returns {string} the build times
 */
export function getBuildTime(): Date {
  return dayjs.utc(window.BUILD_TIMESTAMP).toDate()
}
