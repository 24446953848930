import {
  PedigreeType,
  PetGender,
  Species,
} from '@/domain/Account/AccountSummary'

export default class PetParser {
  public static parseSpecies(data: string): Species {
    switch (data) {
      case 'dog':
      case 'DOG':
        return 'dog'
      case 'cat':
      case 'CAT':
        return 'cat'
      default:
        throw new Error(`Unknown species: ${data}`)
    }
  }

  public static parsePetGender(data: string): PetGender {
    switch (data) {
      case 'male':
      case 'MALE':
        return 'male'
      case 'female':
      case 'FEMALE':
        return 'female'
      default:
        throw new Error(`Unknown gender: ${data}`)
    }
  }

  public static parsePedigreeType(data: string): PedigreeType {
    switch (data) {
      case 'pedigree':
      case 'PEDIGREE':
        return 'pedigree'
      case 'mixed':
      case 'MIXED':
      case 'MIXED_BREED':
        return 'mixed'
      case 'cross':
      case 'CROSS':
      case 'CROSS_BREED':
        return 'cross'
      default:
        throw new Error(`Unknown pedigree type: ${data}`)
    }
  }
}
