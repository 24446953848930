import BaseError from './BaseError'
import { ErrorContext } from './ErrorContext'
import { Tag } from './Tag'

export const statusCodeToUserMessage = {
  '400': 'Bad request. Please check your input and try again.',
  '401': 'Unauthorized. Please log in again.',
  '403': 'You don’t have permission to access this resource.',
  '404': 'Resource has not been found.',
  '405': 'Method not allowed.',
  '408': 'Request timeout. Please try again.',
  '409': 'Conflict! The request could not be completed due to a conflict.',
  '410': 'This resource is no longer available.',
  '413': 'The request size is too large.',
  '414': 'The request URL is too long.',
  '415': 'Unsupported media type. The server will not accept the request.',
  '422': 'Unprocessable entity',
  '429': 'Too many requests. Please slow down and try again.',
  '500': 'Internal server error. We’re working on fixing this.',
  '501': 'This feature is not implemented yet.',
  '502': 'Bad gateway. The server received an invalid response.',
  '503':
    'Service unavailable. We’re doing some maintenance. Please try again later.',
  '504': 'Gateway timeout. The server didn’t respond in time.',
} as const

export default class APIError extends BaseError {
  public title: string = 'Api Error'

  constructor(params: {
    title: string
    tags?: Tag[]
    context?: ErrorContext
    originalError?: unknown
    disableAutoLog?: boolean
  }) {
    if (!params.disableAutoLog) {
      params.disableAutoLog = false
    }

    super(params.title, params.originalError)
    Object.setPrototypeOf(this, APIError.prototype)

    this.tags = ['API', ...(params.tags ?? [])]

    // no longer needed it we extract this in a util handleApiError fn
    this.buildContext(params.context ?? {})

    if (!params.disableAutoLog) {
      this.logError()
    } else {
      console.info(
        'APIError was not logged because disableAutoLog was true, make sure you call logError() manually'
      )
    }
  }

  private buildContext(context: ErrorContext): void {
    this.context = {
      ...this.buildInitialContext(),
      ...context,
    }
    const message = this.getErrorMessage()

    if (message) {
      this.context.message = message
    }
  }

  public getStatusCodeErrorMessage(): Nullable<string> {
    const status = this.getErrorStatus()

    if (status) {
      const statusCodeMessage = statusCodeToUserMessage[status]

      return statusCodeMessage ? `Error ${status}: ${statusCodeMessage}` : null
    }
    return null
  }

  public getErrorMessage(): string {
    const statusCodeMessage = this.getStatusCodeErrorMessage()

    return statusCodeMessage ? statusCodeMessage : this.message
  }

  public getErrorStatus(): keyof typeof statusCodeToUserMessage | null {
    if (this.context.status) {
      return this.context.status
    }

    return null
  }
}
