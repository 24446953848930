import { ProductLineName } from '@/@types/Config'
import { getConfigForFeature } from '@/lib/appConfig'

interface Coverage {
  section: string
}

interface Variation {
  coverages: Coverage[]
  name: string
  optional_coverages: Coverage[]
  price: {
    annual: {
      amount: number
    }
    monthly: {
      amount: number
    }
    monthly1: {
      amount: number
    }
  }
  pricing_key: string
  variation: number
}

interface Product {
  pricing_key: string // Internal product name like CLASSIC
  variations?: Variation[]
  variation?: number
}

export interface Quote {
  data: {
    policy?: {
      addons?: string
      cessation_date?: string
      fixed_excess?: string
      inception_date?: string
      key?: string // Internal product name like CLASSIC
      option?: string // External product name like "Regular"
      variation?: number | string
      voluntary_excess?: string
    }
  }
  products?: Product[]
}

/**
 * Determine whether a quote is in a valid state.
 *
 * @param quote The quote to check for validity.
 * @returns boolean Whether the quote is in a valid state.
 */
export function quoteInValidState(quote: Quote): boolean {
  try {
    // If there's no selected product or variation, we can't do our check to see
    // if it's valid, so we have to assume that the quote is valid for now.
    if (!quote.data.policy) {
      return true
    }

    const selectedProduct = quote.data.policy.key || quote.data.policy.option
    const selectedVariation = quote.data.policy.variation

    // If there's no selected product or variation, we can't do our check to see
    // if it's valid, so we have to assume that the quote is valid for now.
    if (
      !selectedProduct ||
      selectedVariation === '' || // API can return variation as an empty string
      selectedVariation == null
    ) {
      return true
    }

    const product = quote.products?.find(
      ({ pricing_key }) => pricing_key === selectedProduct
    )

    if (product) {
      // If the product has variations, check that the selected variation is
      // available.
      if (product.variations) {
        // If we get here then we're fairly certain selectedVariation has to be a number
        return Boolean(product.variations[<number>selectedVariation])
      }

      // If the product does not have variations, check that its variation
      // property matches the selected variation.
      return product.variation === selectedVariation
    }

    // If we got here, there's a selected product and variation, but we couldn't
    // find the product -> invalid
    return false
  } catch {
    // If at any point this code errors, we don't want to break anything or say
    // the code is invalid (it may just be in a format we're not expecting) so
    // return true.
    return true
  }
}

/**
 * Determines whether there is a product line enabled for the new flow.
 *
 * @returns boolean
 */
export function hasNewFlowEnabledProductLines(): boolean {
  const productLines = (getConfigForFeature('newFlowEnabledProductLines') ??
    []) as ProductLineName[]

  return productLines.length > 0
}

/**
 * Returns product lines that are enabled for the new flow.
 *
 * @returns ProductLineName[]
 */
export function newFlowEnabledProductLines(): ProductLineName[] {
  return (getConfigForFeature('newFlowEnabledProductLines') ??
    []) as ProductLineName[]
}
