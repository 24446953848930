import dayjs from 'dayjs'
import 'dayjs/locale/en-gb'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import calendar from 'dayjs/plugin/calendar'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import isMoment from 'dayjs/plugin/isMoment'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import localeData from 'dayjs/plugin/localeData'
import minMax from 'dayjs/plugin/minMax'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.locale('en-gb')
dayjs.extend(utc)
dayjs.extend(calendar)
dayjs.extend(duration)
dayjs.extend(localeData)
dayjs.extend(advancedFormat)
dayjs.extend(relativeTime)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(isBetween)
dayjs.extend(minMax)
dayjs.extend(isMoment) // used by some Mutt widget dependencies
dayjs.extend(timezone)

export default dayjs

export type { ConfigType as DayJsParsable } from 'dayjs'

export { Dayjs } from 'dayjs'
