import Dot from 'dot-object'
import { cloneDeep, isEqual } from 'lodash-es'

const dot = new Dot('/')
dot.keepArray = true

/**
 * ApplyChanges
 *
 * @param {object} src Src object
 * @param {object} changes Changes object
 * @returns {object} Object with the changes applied
 */
export function applyChanges<T extends {}>(src: T, changes: Partial<T>): T {
  const newObject = cloneDeep(src)
  for (const [key, value] of Object.entries(changes)) {
    dot.str(key, value, newObject)
  }

  return newObject
}

/**
 * Determine the changes between two objects.
 *
 * @param {object} src The source object.
 * @param {object} dst The final object with changes.
 * @returns {object} The changes as a flattened structure where nested keys are slash-separated.
 */
export function calculateObjectChanges<T>(src: T, dst: T): {} {
  const flatSrc = dot.dot(src)
  const flatDst = dot.dot(dst)

  return Object.fromEntries(
    Object.entries(flatDst).filter(
      ([key, value]) => !isEqual(value, flatSrc[key])
    )
  )
}
