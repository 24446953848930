<template>
  <div
    v-if="field"
    class="mutt-field-wrapper--checkbox mutt-field-wrapper--radio"
    :class="getFieldWrapperClass()"
    :data-qa-locator="qaLocator"
  >
    <label-widget
      :field="field"
      :field-id="getFieldId()"
      :data-qa-locator="qaLocator ? `${qaLocator}-label` : null"
    />
    <template v-if="!isReadOnly">
      <div
        v-for="(choice, index) of field.choices"
        :key="`radio-${choice[0]}`"
        class="mutt-field-radio-item"
      >
        <input
          :id="`${field.id}-${choice[0]}`"
          v-model="value"
          type="radio"
          :name="field.id"
          :class="getFieldClass()"
          :aria-invalid="hasErrors ? 'true' : null"
          :aria-describedby="
            field.options.hasOwnProperty('help') ? `${getFieldId()}-help` : null
          "
          :data-qa-locator="qaLocator ? `${qaLocator}-${index}-input` : null"
          :value="choice[0]"
          @click="value === choice[0] && select(choice[0], choice[1])"
          @change="select(choice[0], choice[1])"
        />
        <label
          :for="`${field.id}-${choice[0]}`"
          class="mutt-label"
          :data-qa-locator="qaLocator ? `${qaLocator}-${index}-label` : null"
          >{{ getChoice(choice[1]) }}</label
        >
      </div>
    </template>
    <readonly-widget
      v-if="isReadOnly"
      :field="field"
      :copyable="isCopyable"
      :value="displayValue"
      :data-qa-locator="qaLocator ? `${qaLocator}-readonly` : null"
    />
    <help-widget
      :field="field"
      :data-qa-locator="qaLocator ? `${qaLocator}-help` : null"
    />
    <error-widget
      v-if="!isReadOnly"
      :field="field"
      :errors="errors"
      :error-class="getErrorClass()"
      :data-qa-locator="qaLocator ? `${qaLocator}-error` : null"
    />
  </div>
</template>

<script>
import { i18n as _ } from '../lib/i18n'
import WidgetMixin from '../mixins/WidgetMixin'

export default {
  name: 'MuttRadio',
  for: 'radio',
  mixins: [WidgetMixin],
  computed: {
    displayValue() {
      const choice = this.field.choices.find(([value]) => value === this.value)

      if (choice) {
        return this.getChoice(choice[1])
      }

      return this.value
    },
  },
  created() {
    // Booleans do not have choices, so we must contrive
    // them if they aren't already set
    if (this.field.type === 'boolean') {
      if (this.field.options.hasOwnProperty('choices')) {
        this.field.choices = this.field.options.choices
      } else {
        this.field.choices = [
          [true, _(this, 'Yes')],
          [false, _(this, 'No')],
        ]
      }
    }

    // Copy this prop as we may need to alter/overide it
    this.displayReadonly = this.readonly

    if (this.field.options.hasOwnProperty('readonly')) {
      this.displayReadonly = this.field.options.readonly
    }
  },
  methods: {
    getChoice(choice) {
      return _(this, choice)
    },

    getFieldClass() {
      let className = 'mutt-field mutt-field-radio'

      if (this.field.attribs && this.field.attribs.hasOwnProperty('class')) {
        className = `${className} ${this.field.attribs.class}`
      }

      return className
    },

    select(choice, label) {
      this.value = choice

      // By default label property is updated once a selection is made,
      // but sometimes it might not be a desired outcome.
      // Updating label disables formatters functionality,
      // so updateLabel=false brings this widget in line with others.
      const updateLabel = this.field.options.hasOwnProperty('updateLabel')
        ? this.field.options.updateLabel
        : true

      if (this.field.validate()) {
        this.$emit('callback', {
          key: this.field.name,
          value: this.field.value,
          action: 'radioSelect',
          label: updateLabel ? label : null,
          validated: true,
        })
      } else {
        // Here for completeness but shouldn't really occur?
        this.$emit('callback', {
          key: this.field.name,
          value: this.field.value,
          action: 'radioSelect',
          label: updateLabel ? label : null,
          validated: false,
        })
      }
    },
  },
}
</script>
