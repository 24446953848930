import { isFunction } from 'lodash-es'
import { createRouter, createWebHistory, LocationQueryValue } from 'vue-router'
import type { RouteLocationNormalized, RouteLocationRaw } from 'vue-router'

import PageNotFound from '@/components/legacy/PageNotFound.vue'
import { authGuard } from '@/lib/auth0/auth0-vue-proxy'
import authRoutes from '@/lib/routes/auth'
import basketRoutes from '@/lib/routes/baskets'
import customerRoutes from '@/lib/routes/customers'
import { quoteWithoutAnOwnerButWithExistingCustomerEmailGuard } from '@/lib/routes/guards'
import policyRoutes from '@/lib/routes/policies'
import quoteRoutes from '@/lib/routes/quotes'
import v2BasketsRoutes from '@/lib/routes/v2Baskets'
import { novelClaimsRoutes } from '@/novelClaims/routes'
import { plrn } from '@/plrn'
import Dashboard from '@/views/Dashboard.vue'

// When redirecting inside router guard, vue-router will throw an error.
// If we use <router-link> this doens't happen because it suspends the error itself.
// If we use .push()/.replace() however, we need to either catch the error every time "push().catch(() => ...)"
// or handle it globally like this:
// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
// const originalPush: Function = Router.prototype.push
// Router.prototype.push = function push(
//   to: RouteLocationRaw,
//   onResolve?: Function,
//   onReject?: Function
// ): Promise<NavigationFailure | void | undefined> {
//   if (onResolve || onReject) {
//     return originalPush.call(this, location, onResolve, onReject)
//   }

//   return originalPush.call(this, location).catch((err: Error) => {
//     if (Router.isNavigationFailure(err)) {
//       // resolve err
//       return err
//     }
//     // rethrow error
//     return Promise.reject(err)
//   })
// }

const routes = [
  ...customerRoutes,
  ...policyRoutes,
  ...quoteRoutes,
  ...basketRoutes,
  ...v2BasketsRoutes,
  ...authRoutes,
  ...novelClaimsRoutes,
  {
    name: 'dashboard',
    path: '/',
    component: Dashboard,
    meta: {
      title: 'Dashboard',
    },
  },
  {
    path: '/genesys-callback',
    name: 'genesys',
    component: (): Promise<any> => import('@/views/phone/PhoneIntegration.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    component: PageNotFound,
    meta: {
      agencyRequired: false,
      title: 'Page not found',
      legacy: true,
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: (to, from) => {
    if (typeof to.name === 'string' && typeof from.name === 'string') {
      if (
        to.name.startsWith('claim-overview') &&
        from.name.startsWith('claim-overview')
      ) {
        return {}
      }
    }

    // Scroll to top of page on all route changes
    return { top: 0, left: 0 }
  },
})

router.beforeEach(authGuard)
router.beforeResolve(quoteWithoutAnOwnerButWithExistingCustomerEmailGuard)
router.beforeEach((to: RouteLocationNormalized): RouteLocationRaw | boolean => {
  if (to.meta?.agencyRequired === false || plrn) {
    return true
  }

  // User needs to choose an agency
  // eslint-disable-next-line no-console
  console.log('Redirecting user to choose agency')
  return { name: 'choose-agency' }
})

export const preserveQueryParams = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
): RouteLocationRaw | boolean => {
  const fromHasAFlag = Object.keys(from.query).some((key) =>
    key.startsWith('f_')
  )

  const toHasAFlag = Object.keys(to.query).some((key) => key.startsWith('f_'))

  if (fromHasAFlag && !toHasAFlag) {
    const preservedFlags: Record<
      string,
      LocationQueryValue | LocationQueryValue[]
    > = {}
    Object.keys(from.query)
      .filter((key) => key.startsWith('f_'))
      .forEach((key) => {
        preservedFlags[key] = from.query[key]
      })

    if (from.query) {
      return { ...to, query: { ...to.query, ...preservedFlags } }
    }
  }

  return true
}

router.beforeEach(preserveQueryParams)

router.afterEach((to: RouteLocationNormalized) => {
  const titleSuffix = ' | Policies IO'

  if (to.meta?.title) {
    if (isFunction(to.meta.title)) {
      document.title = `${to.meta.title(to)}${titleSuffix}`
    } else {
      document.title = `${to.meta.title}${titleSuffix}`
    }
  } else {
    console.warn(`No title set for route:`, to)
    document.title = titleSuffix
  }
})

export default router
