import { Order, OrderItem } from '@/domain/Order'
import { ProductDeltaPrice, ProductPayments } from '@/domain/Shop/ProductPrice'
import dayjs from '@/lib/dayjs'

export default class OrderPaymentsAdapter {
  public static adaptPayments(originalOrder: Order): Order {
    const orderWithCorrectItemPrices = {
      ...originalOrder,
      pets: originalOrder.pets.map((pet) => {
        return {
          ...pet,
          items: pet.items.map((item) => {
            return OrderPaymentsAdapter.fixOrderItemPrice(originalOrder, {
              ...item,
              options:
                item.options?.map((option) => {
                  return {
                    ...option,
                  }
                }) ?? [],
            })
          }),
        }
      }),
    }

    const orderWithCorrectTotalPrices =
      OrderPaymentsAdapter.fixTotalOrderPriceFromItems(
        orderWithCorrectItemPrices
      )

    const orderWithPayments = OrderPaymentsAdapter.fixPaymentsOnOrder(
      orderWithCorrectTotalPrices
    )

    return orderWithPayments
  }

  public static fixPriceFromPayments(
    monthsDiff: number,
    deltaPrice: ProductDeltaPrice,
    payments: Maybe<ProductPayments>
  ): ProductDeltaPrice {
    if (payments && payments.cause) {
      const retained = payments.firstMonth - payments.remainingMonths
      const retainedMonthly = Math.ceil(retained / monthsDiff)

      return {
        ...deltaPrice,
        monthlyDelta: {
          ...deltaPrice.monthlyDelta,
          total: deltaPrice.monthlyDelta.total - retainedMonthly,
          subtotal: deltaPrice.monthlyDelta.subtotal - retainedMonthly,
        },
      }
    }

    return deltaPrice
  }

  public static fixOrderItemPrice(
    order: Order,
    orderItem: OrderItem
  ): OrderItem {
    if (orderItem.price.payments && orderItem.price.monthlyDelta.total > 0) {
      let monthsDiff = 12

      if (orderItem.action === 'create') {
        const endDate = orderItem.endDate
        const startDate = order.effectiveDate
        monthsDiff = dayjs(endDate).diff(dayjs(startDate), 'month') + 1
      }

      const clonedOrderItem = structuredClone(orderItem)

      clonedOrderItem.price = OrderPaymentsAdapter.fixPriceFromPayments(
        monthsDiff,
        orderItem.price,
        orderItem.price.payments
      )
      clonedOrderItem.product.price = OrderPaymentsAdapter.fixPriceFromPayments(
        monthsDiff,
        orderItem.product.price,
        orderItem.price.payments
      )

      return clonedOrderItem
    }

    return orderItem
  }

  public static fixTotalOrderPriceFromItems(
    orderWithCorrectTotalPrices: Order
  ): Order {
    const orderHasPayments = orderWithCorrectTotalPrices.pets
      .flatMap((pet) => pet.items)
      .some((item) => item.price.payments)

    if (!orderHasPayments) {
      return orderWithCorrectTotalPrices
    }

    const sumPricesOfItems = orderWithCorrectTotalPrices.pets
      .flatMap((pet) => pet.items)
      .reduce(
        (acc, item) => {
          return {
            total: acc.total + item.price.monthlyDelta.total,
            subTotal: acc.subTotal + item.price.monthlyDelta.subtotal,
          }
        },
        {
          total: 0,
          subTotal: 0,
        }
      )

    const totalPrices = {
      ...orderWithCorrectTotalPrices.price,
      monthlyDelta: {
        ...orderWithCorrectTotalPrices.price.monthlyDelta,
        total: sumPricesOfItems.total,
        subtotal: sumPricesOfItems.subTotal,
      },
      termDelta: orderWithCorrectTotalPrices.price.termDelta,
    }

    return {
      ...orderWithCorrectTotalPrices,
      price: totalPrices,
    }
  }

  public static fixPaymentsOnOrder(order: Order): Order {
    let value: Maybe<ProductPayments> = undefined

    order.pets
      .flatMap((pet) => pet.items)
      .filter((item) => item.action === 'create')
      .forEach((item) => {
        if (item.price.payments && item.price.payments.cause) {
          const prevValue = value

          if (!prevValue) {
            value = {
              ...item.price.payments,
              firstMonth: item.price.payments.firstMonth,
              remainingMonths: item.price.payments.remainingMonths,
              upfrontPremium: item.price.payments.upfrontPremium,
            }
          } else {
            value = {
              ...prevValue,
              firstMonth: prevValue.firstMonth + item.price.payments.firstMonth,
              remainingMonths:
                prevValue.remainingMonths + item.price.payments.remainingMonths,
              upfrontPremium:
                prevValue.upfrontPremium + item.price.payments.upfrontPremium,
            }
          }
        }
      })

    if (value !== undefined) {
      order.price.payments = value
    }

    return order
  }
}
