<template>
  <div
    v-if="field"
    :class="getFieldWrapperClass()"
    :data-qa-locator="qaLocator"
  >
    <LabelWidget
      :field="field"
      :field-id="getFieldId()"
      :data-qa-locator="qaLocator ? `${qaLocator}-label` : null"
    />
    <ReadonlyWidget
      v-if="isReadOnly"
      :field="field"
      :copyable="isCopyable"
      :value="value"
      :data-qa-locator="qaLocator ? `${qaLocator}-readonly` : null"
    />
    <div v-if="!isReadOnly" class="mutt-currency-input-container">
      <!--
        The input below does actually have a label (see `label-widget` above),
        but the eslint a11y rule doesn't see it as it's not very explicit, so we
        can disable this rule.
      -->
      <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
      <input
        :id="getFieldId()"
        ref="currency"
        v-model="value"
        class="mutt-field mutt-field-currency"
        min="0"
        step="0.01"
        type="number"
        pattern="\d*"
        :data-qa-locator="qaLocator ? `${qaLocator}-input` : null"
        @keypress.enter.prevent="submitCallback"
      />
    </div>
    <HelpWidget
      :field="field"
      :data-qa-locator="qaLocator ? `${qaLocator}-help` : null"
    />
    <ErrorWidget
      v-if="!isReadOnly"
      :field="field"
      :errors="errors"
      :error-class="getErrorClass()"
      :data-qa-locator="qaLocator ? `${qaLocator}-error` : null"
    />
  </div>
</template>

<script>
import MuttVue from '@mutt/widgets-vue'

export default {
  name: 'MuttCurrency',
  for: 'currency',
  mixins: [MuttVue.mixin],
  data() {
    return {
      value: '',
      initialValueProcessed: false,
    }
  },
  watch: {
    value() {
      this.field.validate()
    },
  },
  mounted() {
    // If we have an initial it will be in the raw minor units but displayed in
    // major units. This forces it to be converted and displayed correctly.
    this.setValue(this.value)
  },
  methods: {
    getFieldWrapperClass() {
      return `${this._getFieldWrapperClass()} mutt-field-wrapper-currency`
    },
    getValue() {
      if (this.value || this.value === 0) {
        return Math.round(this.value * 100)
      }
    },
    setValue(value) {
      if (value || value === 0) {
        this.value = (value / 100).toFixed(2)
      }
    },
    async focus() {
      await this.$nextTick()
      this.$refs.currency.focus()
    },
  },
}
</script>
