<template>
  <div
    v-if="field"
    class="cancellation-date-selector"
    :class="getFieldWrapperClass()"
  >
    <h2 class="cancellation-date-heading">
      {{ field.label }}
    </h2>

    <ReadonlyWidget v-if="isReadOnly" :value="formattedValue" />

    <div v-if="isFixedValue">
      <ReadonlyWidget :value="formattedValue" />
    </div>
    <div
      v-else-if="!isReadOnly"
      class="mutt-field-wrapper mutt-field-wrapper--checkbox mutt-field-wrapper--radio"
    >
      <ul class="cancellation-date-list">
        <li v-if="showInception" class="mutt-field-radio-item">
          <input
            id="inception"
            ref="radioCancellation"
            :checked="isInception"
            value="inception"
            type="radio"
            name="cancellationDate"
            class="mutt-field mutt-field-radio"
            @change="setDate"
          />
          <label class="mutt-label" for="inception">Inception</label>
        </li>
        <li v-if="showCessation" class="mutt-field-radio-item">
          <input
            id="cessation"
            ref="radioCancellation"
            :checked="isCessation"
            value="cessation"
            type="radio"
            name="cancellationDate"
            class="mutt-field mutt-field-radio"
            @change="setDate"
          />
          <label class="mutt-label" for="cessation">Cessation</label>
        </li>
        <li v-if="showToday" class="mutt-field-radio-item">
          <input
            id="today"
            ref="radioCancellation"
            :checked="isToday"
            value="today"
            type="radio"
            name="cancellationDate"
            class="mutt-field mutt-field-radio"
            @change="setDate"
          />
          <label class="mutt-label" for="today">Today</label>
        </li>
        <li v-if="showTomorrow" class="mutt-field-radio-item">
          <input
            id="tomorrow"
            ref="radioCancellation"
            :checked="isTomorrow"
            value="tomorrow"
            type="radio"
            name="cancellationDate"
            class="mutt-field mutt-field-radio"
            @change="setDate"
          />
          <label class="mutt-label" for="tomorrow">Tomorrow</label>
        </li>
        <li class="mutt-field-radio-item">
          <input
            id="custom"
            ref="radioCancellation"
            :checked="isCustomDate"
            value="custom"
            type="radio"
            name="cancellationDate"
            class="mutt-field mutt-field-radio"
            @change="setDate"
          />
          <label class="mutt-label" for="custom">Choose date</label>
        </li>
      </ul>

      <div v-if="isCustomDate" class="date-selector dateValue">
        <div class="date-options">
          <MuttDateinput
            ref="dateInput"
            :field="field"
            @callback="submitCallback"
          />
        </div>
      </div>
      <p v-else-if="field.value" class="dateValue">
        {{ formattedValue }}
      </p>

      <ErrorWidget
        v-if="!isReadOnly"
        :field="field"
        :errors="errors"
        :error-class="getErrorClass()"
      />
    </div>

    <HelpWidget :field="field" />
  </div>
</template>

<script>
import { MuttDateinput } from '@mutt/widgets-bbm'
import MuttVue from '@mutt/widgets-vue'

import { DayjsDateValidator } from '@/legacy/lib/Validators'
import dayjs from '@/lib/dayjs'

const DATE_FORMAT = 'mediumDate'

export default {
  name: 'MuttCancellationDate',
  for: 'cancellation-date',
  components: {
    MuttDateinput,
  },
  mixins: [MuttVue.mixin],
  emits: ['callback'],
  data() {
    return {
      cancellationReasonRule: null,
      errors: null,
      showDateSelector: false,
      invalidCancellationDate: null,
    }
  },

  computed: {
    effectiveDateRule() {
      return this.cancellationReasonRule?.effectiveDate
    },

    maxFutureDays() {
      return this.cancellationReasonRule?.maxFutureDays
    },

    minFutureDays() {
      return this.cancellationReasonRule?.minFutureDays
    },

    formattedValue() {
      let preamble = ''

      if (this.isFixedValue) {
        if (this.effectiveDateRule === 'immediate') {
          preamble += 'Immediately - '
        } else if (this.effectiveDateRule === 'end-of-billing') {
          preamble += 'End of billing period - '
        }
      }

      return preamble + this.$d(dayjs(this.field.value).toDate(), DATE_FORMAT)
    },

    isFixedValue() {
      return this.effectiveDateRule !== 'free'
    },

    showInception() {
      const { inceptionDate } = this.field.options
      return (
        Boolean(inceptionDate) && this.isAllowedDate(dayjs.utc(inceptionDate))
      )
    },

    showCessation() {
      const { cessationDate } = this.field.options
      return (
        Boolean(cessationDate) && this.isAllowedDate(dayjs.utc(cessationDate))
      )
    },

    showToday() {
      return this.isAllowedDate(dayjs.utc())
    },

    showTomorrow() {
      return this.isAllowedDate(dayjs.utc().add(1, 'day'))
    },

    isInception() {
      if (this.showInception && dayjs.isDayjs(this.field.value)) {
        return dayjs
          .utc(this.field.options.inceptionDate)
          .isSame(this.field.value, 'day')
      }
      return false
    },

    isCessation() {
      if (this.showCessation && dayjs.isDayjs(this.field.value)) {
        return dayjs
          .utc(this.field.options.cessationDate)
          .isSame(this.field.value, 'day')
      }
      return false
    },

    isToday() {
      if (dayjs.isDayjs(this.field.value)) {
        return dayjs.utc().isSame(this.field.value, 'day')
      }
      return false
    },

    isTomorrow() {
      if (dayjs.isDayjs(this.field.value)) {
        return dayjs.utc().add(1, 'day').isSame(this.field.value, 'day')
      }
      return false
    },

    isFixedDate() {
      return (
        this.isInception || this.isCessation || this.isToday || this.isTomorrow
      )
    },

    isCustomDate() {
      return this.showDateSelector || (this.field.value && !this.isFixedDate)
    },
  },

  methods: {
    setInvalidCancellationDate(cancellationDate) {
      this.invalidCancellationDate = cancellationDate
      this.updateDateValidator()
    },
    setCancellationReasonRule(cancellationReasonRule) {
      this.cancellationReasonRule = cancellationReasonRule
      this.updateDateValidator()
    },
    updateDateValidator() {
      let min = null
      let max = null

      if (this.maxFutureDays != null) {
        max = dayjs.utc().add(this.maxFutureDays, 'day')
      }

      if (this.minFutureDays != null) {
        min = dayjs.utc().subtract(this.minFutureDays, 'day')
      }

      const exclude = this.invalidCancellationDate
        ? [this.invalidCancellationDate]
        : null

      this.field.validators = [new DayjsDateValidator({ max, min, exclude })]
    },
    setDate($event) {
      switch ($event.target.value) {
        case 'inception':
          this.selectFixed(
            dayjs.utc(this.field.options.inceptionDate).startOf('day')
          )
          break
        case 'cessation':
          this.selectFixed(
            dayjs.utc(this.field.options.cessationDate).startOf('day')
          )
          break
        case 'today':
          this.selectFixed(dayjs.utc().startOf('day'))
          break
        case 'tomorrow':
          this.selectFixed(dayjs.utc().startOf('day').add(1, 'days'))
          break
        case 'custom':
          this.selectCustom()
          break
        default:
          break
      }
    },
    async selectFixed(date) {
      // * Note: this function/comment copied wholesale (function modified to
      // * take a passed date) from inception date widget.

      this.showDateSelector = false
      // NB Ideally we would reset the inputs here, but
      // because Vue it reactively updating 3 values, this
      // creates a problem - next tick is only after 1 value
      // updates. This ultimately creates a race condition, where
      // the value is normally accidentally cleared (inside
      // MuttDateInput).
      // For now this has the limitation that when coming back, the
      // custom date is pre-filled even if you subsequently hit
      // today/tomorrow
      // this.$refs.dateInput.resetInputs()
      await this.$nextTick()
      this.field.value = date
      this.dateSelected()
    },
    async selectCustom() {
      this.showDateSelector = true
      await this.$nextTick()
      this.$refs.dateInput.focus()
    },
    dateSelected() {
      if (!this.field.validate()) {
        return
      }

      this.errors = null

      this.$emit('callback', {
        action: 'dateSelected',
        validated: true,
      })
    },
    isAllowedDate(date) {
      if (
        this.invalidCancellationDate &&
        date.isSame(this.invalidCancellationDate, 'day')
      ) {
        return false
      }

      if (this.minFutureDays != null) {
        const minFutureDate = dayjs
          .utc()
          .add(this.minFutureDays, 'days')
          .startOf('day')

        if (date.isBefore(minFutureDate)) {
          return false
        }
      }

      if (this.maxFutureDays != null) {
        const maxFutureDate = dayjs
          .utc()
          .add(this.maxFutureDays, 'days')
          .endOf('day')

        if (date.isAfter(maxFutureDate)) {
          return false
        }
      }

      return true
    },
  },
}
</script>

<style lang="scss" scoped>
.dateValue {
  margin-top: 1em;
}
</style>
