import { AgencyName } from '@/@types/Config'
import {
  AccountSummaryCustomerHydrated,
  AccountSummaryPetHydrated,
  Title,
} from '@/domain/Account/AccountSummary'

import { OrderCustomerNewJson, OrderPetNewJson } from './OrderParser'

interface PetOrderPayload extends Omit<OrderPetNewJson, 'id' | 'display_name'> {
  name: string
}

export default class OrderDto {
  public static toCreateAddPetOrderPet(
    agencyName: AgencyName,
    pet: Omit<AccountSummaryPetHydrated, 'items' | 'id' | 'age'>
  ): PetOrderPayload {
    const result: PetOrderPayload = {
      name: pet.name,
      gender: pet.gender,
      dob: pet.dateOfBirth,
      species: pet.species,
      breed: {
        display_name: pet.breedLabel as string,
        value: pet.breed as string,
      },
      postcode: pet.postcode,
      pedigree_type: pet.pedigreeType as string,
      spayed_neutered: pet.spayedNeutered,
    }

    if (agencyName === 'BBM') {
      result.value = pet.value
    }

    if (agencyName === 'MP-US') {
      result.state = pet.state
    }

    return result
  }

  public static toCreateAddPetOrder(
    agencyName: AgencyName,
    pets: Omit<AccountSummaryPetHydrated, 'items' | 'id' | 'age'>[]
  ): PetOrderPayload[] {
    return pets.map((pet) => OrderDto.toCreateAddPetOrderPet(agencyName, pet))
  }

  public static toBeginPaymentMethodCapture(
    customer: AccountSummaryCustomerHydrated
  ): OrderCustomerNewJson {
    return {
      first_name: customer.firstName,
      last_name: customer.lastName,
      email: customer.email,
      address: {
        line1: customer.address?.line1,
        line2: customer.address?.line2,
        city: customer.address?.city,
        postcode: customer.address?.postcode,
      },
    }
  }

  public static toOrderTitle(title: Title): string {
    switch (title) {
      case 'MR':
        return 'Mr'
      case 'MRS':
        return 'Mrs'
      case 'MISS':
        return 'Miss'
      case 'MS':
        return 'Ms'
      case 'MX':
        return 'Mx'
      case 'PROF':
        return 'Prof'
      case 'DR':
        return 'Dr'

      default:
        return title
    }
  }

  public static toOrderNewBusinessCustomer(
    customer: Partial<AccountSummaryCustomerHydrated>
  ): OrderCustomerNewJson {
    return {
      title: customer.title
        ? OrderDto.toOrderTitle(customer.title as Title)
        : undefined,
      first_name: customer.firstName,
      last_name: customer.lastName,
      email: customer.email ?? '',
      dob: customer.dateOfBirth,
      phone: customer.phone,
      address: {
        line1: customer.address?.line1,
        line2: customer.address?.line2,
        city: customer.address?.city,
        postcode: customer.address?.postcode,
        state_county: customer.address?.stateOrCounty,
        country: customer.address?.country,
      },
    }
  }

  public static toOrderNewBusinessPet(
    pet: AccountSummaryPetHydrated
  ): PetOrderPayload {
    return {
      name: pet.name ?? '',
      gender: pet.gender,
      dob: pet.dateOfBirth,
      species: pet.species,
      breed: {
        display_name: pet.breedLabel ?? '',
        value: pet.breed ?? '',
      },
      pedigree_type: pet.pedigreeType as string,
      postcode: pet.postcode,
      ...(pet.state ? { state: pet.state } : {}),
      spayed_neutered: pet.spayedNeutered,
      ...(pet.value || pet.value === 0 ? { value: pet.value } : {}),
    }
  }
}
