import Dot from 'dot-object'

import type { VetFeesCoverage } from '@/@types/Coverage'
import type {
  Adjustment,
  Connection,
  Contract,
  Lifecycle,
  ContractChange,
  AdjustmentType,
  TerminatingAdjustmentType,
  TerminatingAdjustment,
} from '@/@types/Lifecycle'
import type {
  PolicyRenewalTimelineEvent,
  PolicyTimelineEvent,
  RenewalStatus,
  TimelineEvent,
} from '@/@types/TimelineEvent'
import dayjs, { DayJsParsable } from '@/lib/dayjs'
import {
  calculatePriceFromCoverages,
  isVetFeesCoverage,
} from '@/lib/utils/coverageBundles'

import { isNotNullOrUndefined } from './arrays'

import { extractCoPayment } from '.'

export const isAdjustment = (
  connection: Connection
): connection is Adjustment => connection.id.startsWith('adj_')

export const isContract = (connection: Connection): connection is Contract =>
  connection.id.startsWith('con_')

/**
 * Type guard for renewal adjustment connections
 *
 * @param adjustment A connection of type adjustment on a lifecycle
 * @returns boolean indicating if the connection is an Adjustment object
 */
const isRenewalAdjustment = (adjustment: Adjustment): boolean =>
  adjustment.type === 'RENEWAL'

/**
 * Type guard for lapse adjustment connections
 *
 * @param adjustment A connection of type adjustment on a lifecycle
 * @returns boolean indicating if the connection is an Adjustment object
 */
const isLapseAdjustment = (adjustment: Adjustment): boolean =>
  adjustment.type === 'LAPSE'

const getAdjustmentRejectionReason = (adjustment: Adjustment): Maybe<string> =>
  adjustment.actions
    ?.map((action) => action?.data?.reason)
    .filter(isNotNullOrUndefined)?.[0]

interface GetPolicyTimelineEventFromLifecycleProps {
  adjustment: Adjustment
  preAdjustmentContract?: Contract
  postAdjustmentContract?: Contract
  productLine: string
  policyId: string
}

interface ExtractChangesBetweenContractsProps {
  preAdjustmentContract: Contract
  postAdjustmentContract: Contract
  adjustment: Adjustment
}

export const extractChangesBetweenContracts = ({
  preAdjustmentContract,
  adjustment,
  postAdjustmentContract,
}: ExtractChangesBetweenContractsProps): ContractChange[] => {
  // needs to be a record since we don't want to store multiple of the same change (eg: if the user changed pet age multiple times
  // we just show the latest change)
  const cache: Record<string, ContractChange> = {}

  const dot = new Dot('/')
  dot.keepArray = true

  const ignoredActionKeys = [
    'pet/breed/value', // we only need the label which is also going to be present
    'coverages', // the old/new values are not strings so we're treating them elsewhere
  ]

  for (const action of adjustment.actions ?? []) {
    for (const [key, newValue] of Object.entries(action.data ?? {})) {
      const oldValue = dot.pick(key, preAdjustmentContract) as string

      if (!ignoredActionKeys.includes(key)) {
        cache[key] = {
          key,
          oldValue,
          newValue,
          petName: key.includes('pet')
            ? preAdjustmentContract.pet.name
            : undefined,
        }
      }
    }
  }

  const preAdjustmentCoverages = preAdjustmentContract.coverages || {}
  const postAdjustmentCoverages = postAdjustmentContract.coverages || {}
  const priceBefore = calculatePriceFromCoverages(preAdjustmentCoverages)
  const priceAfter = calculatePriceFromCoverages(postAdjustmentCoverages)

  if (priceBefore.monthly !== priceAfter.monthly) {
    cache['price/monthly'] = {
      key: 'price/monthly',
      oldValue: priceBefore.monthly,
      newValue: priceAfter.monthly,
    }
  }

  if (priceBefore.annual !== priceAfter.annual) {
    cache['price/annual'] = {
      key: 'price/annual',
      oldValue: priceBefore.annual,
      newValue: priceAfter.annual,
    }
  }

  const preAdjustmentVetFeesCoverage: Maybe<VetFeesCoverage> =
    preAdjustmentCoverages.vet_fees &&
    isVetFeesCoverage(preAdjustmentCoverages.vet_fees)
      ? preAdjustmentCoverages.vet_fees
      : undefined

  const postAdjustmentVetFeesCoverage: Maybe<VetFeesCoverage> =
    postAdjustmentCoverages.vet_fees &&
    isVetFeesCoverage(postAdjustmentCoverages.vet_fees)
      ? postAdjustmentCoverages.vet_fees
      : undefined

  const copayBefore = extractCoPayment(preAdjustmentVetFeesCoverage)
  const copayAfter = extractCoPayment(postAdjustmentVetFeesCoverage)
  const excessBefore = preAdjustmentVetFeesCoverage?.['excess']
  const excessAfter = postAdjustmentVetFeesCoverage?.['excess']

  if (copayBefore !== copayAfter) {
    cache['co-payment'] = {
      key: 'co-payment',
      oldValue: copayBefore,
      newValue: copayAfter,
    }
  }

  if (excessBefore !== excessAfter) {
    cache['excess'] = {
      key: 'excess',
      oldValue: excessBefore,
      newValue: excessAfter,
    }
  }

  if (preAdjustmentContract.coverages && postAdjustmentContract.coverages) {
    Object.keys(preAdjustmentContract.coverages).forEach((coverage) => {
      if (!postAdjustmentContract.coverages[coverage]) {
        cache[`coverages/${coverage}`] = {
          key: `coverages/${coverage}`,
          oldValue: coverage,
          newValue: undefined,
        }
      }
    })

    Object.keys(postAdjustmentContract.coverages).forEach((coverage) => {
      if (!preAdjustmentContract.coverages[coverage]) {
        cache[`coverages/${coverage}`] = {
          key: `coverages/${coverage}`,
          oldValue: undefined,
          newValue: coverage,
        }
      }
    })
  }

  return Object.values(cache)
}

const getPolicyTimelineEventFromLifecycle = ({
  adjustment,
  preAdjustmentContract,
  postAdjustmentContract,
  productLine,
  policyId,
}: GetPolicyTimelineEventFromLifecycleProps): PolicyTimelineEvent => {
  const postAdjustmentCoverages = postAdjustmentContract?.coverages || {}
  const preAdjustmentCoverages = preAdjustmentContract?.coverages || {}
  const infoContract = postAdjustmentContract || preAdjustmentContract
  const pet = postAdjustmentContract?.pet || preAdjustmentContract?.pet

  const product =
    postAdjustmentContract?.product || preAdjustmentContract?.product

  const price = calculatePriceFromCoverages(
    postAdjustmentContract ? postAdjustmentCoverages : preAdjustmentCoverages
  )
  let status: Maybe<RenewalStatus>

  let contractChanges: ContractChange[] = []
  if (preAdjustmentContract && postAdjustmentContract) {
    contractChanges = extractChangesBetweenContracts({
      preAdjustmentContract,
      adjustment,
      postAdjustmentContract,
    })
  }

  if (isRenewalAdjustment(adjustment)) {
    status = 'POLICY_DUE_TO_RENEW'
  } else if (isLapseAdjustment(adjustment)) {
    status = 'POLICY_DUE_TO_LAPSE'
  }

  const timelineEvent: PolicyTimelineEvent = {
    entity_type: 'POLICY',
    data: {
      event_type: adjustment.type,
      price,
      pet_name: pet?.name,
      policy_ref: infoContract?.ref,
      product,
      product_line: productLine,
      status,
      contractChanges,
    },
    date: adjustment.date,
    pet_id: pet?.id,
    entity_id: policyId,
  }

  const terminationInfo = getAdjustmentTerminationInfo(adjustment)

  if (terminationInfo?.reason) {
    timelineEvent.data.terminationReason = terminationInfo?.reason
  }

  return timelineEvent
}

export const getTimelineEventsFromLifecycles = (
  lifecycles: Lifecycle[]
): TimelineEvent[] => {
  return lifecycles.flatMap((lifecycle: Lifecycle) =>
    getPolicyTimelineEventsFromLifecycle(lifecycle)
  )
}

export const getPolicyTimelineEventsFromLifecycle = (
  lifecycle: Lifecycle
): TimelineEvent[] => {
  return lifecycle.connections
    .filter(isAdjustment)
    .map((adjustment: Adjustment) => {
      const index = lifecycle.connections.indexOf(adjustment)
      // The first adjustment in the lifecycle will not have a prior contract
      const preAdjustmentContract = <Maybe<Contract>>(
        lifecycle.connections[index - 1]
      )
      const postAdjustmentContract = <Maybe<Contract>>(
        lifecycle.connections[index + 1]
      )

      return getPolicyTimelineEventFromLifecycle({
        adjustment,
        preAdjustmentContract,
        postAdjustmentContract,
        productLine: lifecycle.product_line,
        policyId: lifecycle.policy,
      })
    })
}

/**
 * Checks if a timeline event is a policy renewal
 *
 * @param event {TimelineEvent}
 * @returns Boolean
 */
const isPolicyRenewalRenewTimelineEvent = (event: TimelineEvent): boolean => {
  return (
    dayjs(event.date).isAfter(dayjs()) &&
    event.entity_type === 'POLICY' &&
    event.data.event_type === 'RENEWAL'
  )
}

/**
 * Checks if a timeline event is a policy lapse
 *
 * @param event {TimelineEvent}
 * @returns Boolean
 */
const isPolicyRenewalLapseTimelineEvent = (event: TimelineEvent): boolean => {
  return (
    dayjs(event.date).isAfter(dayjs()) &&
    event.entity_type === 'POLICY' &&
    event.data.event_type === 'LAPSE'
  )
}

/**
 * Takes all lifecycles and maps them to their upcoming renewals - where present
 *
 * @param timeline Events
 * @returns All renewals
 */
export function getRenewalFromTimeline(
  timeline: TimelineEvent[]
): Maybe<PolicyRenewalTimelineEvent> {
  return timeline.find(isPolicyRenewalTimelineEvent)
}

export const isPolicyRenewalTimelineEvent = (
  timelineEvent: TimelineEvent
): timelineEvent is PolicyRenewalTimelineEvent =>
  isPolicyRenewalLapseTimelineEvent(timelineEvent) ||
  isPolicyRenewalRenewTimelineEvent(timelineEvent)

interface ExtractPriceDifferenceProps {
  priceChange: ContractChange<string | number>
  priceFormatCallback: (price: number) => string
  frequency: string
}

export const extractPriceDifference = ({
  priceChange,
  priceFormatCallback,
  frequency,
}: ExtractPriceDifferenceProps): string => {
  const { oldValue, newValue } = priceChange

  if (newValue && oldValue && newValue !== oldValue) {
    const diff = Math.abs(Number(newValue) - Number(oldValue))
    const sign = newValue > oldValue ? '+' : '-'

    return `${sign}${priceFormatCallback(diff)}/${frequency}`
  }

  return ''
}

export const getLifecycleForPolicy = (params: {
  policyId: string
  lifecycles: Lifecycle[]
}): Maybe<Lifecycle> => {
  const { policyId, lifecycles } = params

  return lifecycles.find(
    (lifecycle) =>
      lifecycle.policy_uuid === policyId || lifecycle.policy === policyId
  )
}

export const getPolicyTimezone = (
  policyId: string,
  lifecycles: Lifecycle[]
): Maybe<string> => {
  const policyLifecycle = getLifecycleForPolicy({ policyId, lifecycles })

  return (policyLifecycle?.connections?.[0] as Adjustment)?.timezone
}

export interface AdjustmentTerminationInfo {
  type: 'VOID' | 'CANCELLATION' | 'LAPSE'
  date: DayJsParsable
  reason?: string
  upcoming: boolean
}

export const getAdjustmentTerminationInfo = (
  adjustment: Adjustment
): Maybe<AdjustmentTerminationInfo> => {
  if (isTerminatingAdjustment(adjustment)) {
    return {
      type: adjustment.type,
      date: adjustment.date,
      reason:
        adjustment.cancellation_reason ?? // Used in VOID and CANCELLATION
        getAdjustmentRejectionReason(adjustment), // Used in LAPSE
      upcoming: dayjs(adjustment.date).isAfter(dayjs()),
    }
  }
}

export const getPolicyTerminationInfo = (params: {
  lifecycle: Lifecycle
}): Maybe<AdjustmentTerminationInfo> => {
  const { lifecycle } = params

  const terminatingAdjustment = lifecycle?.connections
    .filter(isAdjustment)
    .find(isTerminatingAdjustment)

  if (terminatingAdjustment) {
    return getAdjustmentTerminationInfo(terminatingAdjustment)
  }
}

export const getPolicyCancellationDate = (params: {
  lifecycle: Lifecycle
}): Maybe<DayJsParsable> => {
  const { lifecycle } = params
  const terminationInfo = getPolicyTerminationInfo({ lifecycle })

  if (terminationInfo?.type === 'CANCELLATION' && terminationInfo.upcoming) {
    return terminationInfo.date
  }
}

export const isPolicyYearStartingAdjustment = (
  adjustment: Adjustment
): boolean =>
  isStartingAdjustment(adjustment) || isContinuingAdjustment(adjustment)

export const isStartingAdjustment = (adjustment: Adjustment): boolean =>
  adjustment.type === 'SALE'

export const isTerminatingAdjustmentType = (
  adjustmentType: AdjustmentType
): adjustmentType is TerminatingAdjustmentType =>
  adjustmentType === 'VOID' ||
  adjustmentType === 'CANCELLATION' ||
  adjustmentType === 'LAPSE'

export const isTerminatingAdjustment = (
  adjustment: Adjustment
): adjustment is TerminatingAdjustment =>
  isTerminatingAdjustmentType(adjustment.type)

export const isPresumedContinuingAdjustment = (
  adjustment: Adjustment
): boolean => adjustment.type === 'PRESUMED_RENEWAL'

export const isContinuingAdjustment = (adjustment: Adjustment): boolean =>
  adjustment.type === 'RENEWAL'

export const isPeriodEndAdjustment = (adjustment: Adjustment): boolean =>
  isTerminatingAdjustment(adjustment) ||
  isPresumedContinuingAdjustment(adjustment) ||
  isContinuingAdjustment(adjustment)

export const getCustomerLastPhone = (params: {
  customerUuid: string
  lifecycles: Lifecycle[]
}): Nullable<string> => {
  const { customerUuid, lifecycles } = params

  const telephones = lifecycles
    .flatMap((lifecycle) => lifecycle.connections)
    .filter(isContract)
    .map((contract) => contract.customers[customerUuid]?.telephone)
    .filter(isNotNullOrUndefined)

  return telephones[telephones.length - 1]
}
