import {
  ProductDeltaPrice,
  ProductTotalPrice,
  ProductPriceDetails,
  SubscriptionPrice,
  RemainingTotalPrice,
  AvailableProductPrice,
  ProductPayments,
} from '@/domain/Shop/ProductPrice'

export interface ProductPriceJson {
  annual: ProductPriceDetailsJson
  monthly: ProductPriceDetailsJson
  surcharges?: string[]
}

export interface AvailableProductPriceJson {
  monthly_total: ProductPriceDetailsJson
  annual_total: ProductPriceDetailsJson
  surcharges?: string[]
}

export interface ProductPricePaymentsJson {
  cause: string
  first_month: number
  remaining_months: number
}

export interface ProductDeltaPriceJson {
  term_delta: ProductPriceDetailsJson
  monthly_delta: ProductPriceDetailsJson
  surcharges?: string[]
  payments?: ProductPricePaymentsJson
}

export interface ProductTotalPriceJson {
  term_total: ProductPriceDetailsJson
  monthly_total: ProductPriceDetailsJson
  remaining?: ProductPriceDetailsJson
  surcharges?: string[]
  payments?: ProductPricePaymentsJson
}

export interface SubscriptionPriceJson {
  monthly_total: ProductPriceDetailsJson
  term_total: ProductPriceDetailsJson
  remaining: ProductPriceDetailsJson
  surcharges?: string[]
}

export interface ProductPriceDetailsJson {
  discount: number
  subtotal: number
  tax: number
  total: number
}

export default class ProductPriceParser {
  public static parse(data: ProductPriceJson): ProductTotalPrice {
    return {
      monthlyTotal: ProductPriceParser.parseProductPriceDetails(data.monthly),
      termTotal: {
        discount: 0,
        discountType: undefined,
        subtotal: 0,
        tax: 0,
        total: 0,
        discountPercentage: 0,
      },
      surcharges: data.surcharges ?? [],
    }
  }

  public static parseAvailableProductPrice(
    data: AvailableProductPriceJson
  ): AvailableProductPrice {
    return {
      monthlyTotal: ProductPriceParser.parseProductPriceDetails(
        data.monthly_total
      ),
      annualTotal: ProductPriceParser.parseProductPriceDetails(
        data.annual_total
      ),
      surcharges: data.surcharges ?? [],
    }
  }

  public static parseBackwardsCompatible(
    data: ProductPriceJson
  ): ProductTotalPrice {
    return {
      monthlyTotal: ProductPriceParser.parseProductPriceDetails(data.monthly),
      termTotal: ProductPriceParser.parseProductPriceDetails(data.annual),
      surcharges: data.surcharges ?? [],
    }
  }

  public static parseSubscriptionPrice(
    data: SubscriptionPriceJson
  ): SubscriptionPrice {
    return {
      monthlyTotal: ProductPriceParser.parseProductPriceDetails(
        data.monthly_total
      ),
      termTotal: ProductPriceParser.parseProductPriceDetails(data.term_total),
      remaining: ProductPriceParser.parseProductPriceDetails(data.remaining),
      surcharges: data.surcharges ?? [],
    }
  }

  public static parsePayments(
    data: ProductDeltaPriceJson | ProductTotalPriceJson
  ): Maybe<ProductPayments> {
    if (data.payments) {
      return {
        cause: data.payments.cause,
        firstMonth: data.payments.first_month,
        remainingMonths: data.payments.remaining_months,
        upfrontPremium:
          data.payments.first_month - data.payments.remaining_months,
      }
    }

    return undefined
  }

  public static parseTotalPrice(
    data: ProductTotalPriceJson
  ): ProductTotalPrice {
    const result: ProductTotalPrice = {
      monthlyTotal: ProductPriceParser.parseProductPriceDetails(
        data.monthly_total
      ),
      termTotal: ProductPriceParser.parseProductPriceDetails(data.term_total),
      surcharges: data.surcharges ?? [],
    }

    if (data.payments) {
      result.payments = ProductPriceParser.parsePayments(data)
    }

    return result
  }

  public static parseRemainingTotalPrice(
    data: ProductTotalPriceJson
  ): RemainingTotalPrice {
    const result: RemainingTotalPrice = {
      monthlyTotal: ProductPriceParser.parseProductPriceDetails(
        data.monthly_total
      ),
      termTotal: ProductPriceParser.parseProductPriceDetails(data.term_total),
      surcharges: data.surcharges ?? [],
    }

    if (data.remaining) {
      result.remaining = ProductPriceParser.parseProductPriceDetails(
        data.remaining
      )
    }

    if (data.payments) {
      result.payments = ProductPriceParser.parsePayments(data)
    }

    return result
  }

  public static parseDeltaPrice(
    data: ProductDeltaPriceJson
  ): ProductDeltaPrice {
    const result: ProductDeltaPrice = {
      monthlyDelta: ProductPriceParser.parseProductPriceDetails(
        data.monthly_delta
      ),
      termDelta: ProductPriceParser.parseProductPriceDetails(data.term_delta),
      surcharges: data.surcharges ?? [],
    }

    if (data.payments) {
      result.payments = ProductPriceParser.parsePayments(data)
    }

    return result
  }

  public static parseProductPriceDetails(
    data: ProductPriceDetailsJson
  ): ProductPriceDetails {
    if (!data) {
      return {
        discount: 0,
        subtotal: 0,
        total: 0,
        tax: 0,
        discountPercentage: 0,
        discountType: undefined,
      }
    }

    const discountType =
      !!data.discount && data.discount > 0 ? 'MPD' : undefined
    const discountPercentage = !!data.discount && data.discount > 0 ? 15 : 0

    return {
      discount: data.discount,
      subtotal: data.subtotal,
      total: data.total,
      tax: data.tax,
      discountPercentage,
      discountType,
    }
  }
}
