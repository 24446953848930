<template>
  <div
    v-if="field"
    :class="getFieldWrapperClass()"
    :data-qa-locator="qaLocator"
  >
    <LabelWidget
      :field="field"
      :field-id="getFieldId()"
      :data-qa-locator="qaLocator ? `${qaLocator}-label` : null"
    />
    <ReadonlyWidget
      v-if="isReadOnly"
      :value="formatDate(field.value)"
      :data-qa-locator="qaLocator ? `${qaLocator}-readonly` : null"
    />
    <span v-if="!isReadOnly" class="mutt-field-wrapper-dateinput">
      <input
        v-for="(inputField, inputFieldIndex) in inputFields"
        :ref="(el) => setInputRef(inputField.model, el)"
        :key="inputField.model"
        v-model="$data[inputField.model]"
        onwheel="event.preventDefault()"
        :class="`mutt-field mutt-dateinput mutt-dateinput--${inputField.model}`"
        :required="required"
        :data-qa-locator="qaLocator ? `${qaLocator}-${inputField.model}` : null"
        v-bind="inputField.attrs"
        @keyup="jumper($event, inputField.model)"
        @keypress.enter.prevent="submitCallback"
        @click="checkJump(inputField.model, inputFieldIndex)"
        @focus="checkJump(inputField.model, inputFieldIndex)"
      />
    </span>
    <HelpWidget
      :field="field"
      :data-qa-locator="qaLocator ? `${qaLocator}-help` : null"
    />
    <ErrorWidget
      v-if="!isReadOnly"
      :field="field"
      :errors="errors"
      :error-class="getErrorClass()"
      :data-qa-locator="qaLocator ? `${qaLocator}-error` : null"
    />
  </div>
</template>

<script>
import MuttVue from '@mutt/widgets-vue'

import { DayjsDateValidator } from '@/legacy/lib/Validators'
import dayjs from '@/lib/dayjs'
import { formatDate } from '@/lib/utils/localisation'

export default {
  name: 'MuttDateinput',
  for: 'dateinput',
  mixins: [MuttVue.mixin],
  emits: ['callback'],
  data() {
    return {
      errors: null,
      value: null,
      initalised: false,
      date: null,
      day: null,
      month: null,
      year: null,
      hideDay: false,
      min: null,
      max: null,
      required: null,
      dateFormat: 'day-month-year',
      activeFieldIndex: 0,
      jump: true,
      inputRefs: {},
    }
  },
  computed: {
    inputFields() {
      // Maps input types to array in order of dateFormat to be looped through as inputs
      return this.dateFormat
        .split('-')
        .filter((date) => !(this.hideDay && date === 'day'))
        .map((date) => this.inputTypes[date])
    },
    inputTypes() {
      return {
        day: {
          model: 'day',
          attrs: {
            type: 'number',
            pattern: '^(0?[1-9]|[12]\\d|3[01])$',
            placeholder: this.$t('DD'),
          },
        },
        month: {
          model: 'month',
          attrs: {
            type: 'number',
            pattern: '^(0?[1-9]|1[012])$',
            placeholder: this.$t('MM'),
          },
        },
        year: {
          model: 'year',
          attrs: {
            type: 'number',
            min: this.getMinYears,
            max: this.getMaxYears,
            placeholder: this.$t('YYYY'),
          },
        },
      }
    },
    getMinYears() {
      if (this.min) {
        return this.min.year()
      }
      return dayjs.utc().add(dayjs.duration('P-120Y')).year()
    },
    getMaxYears() {
      if (this.max) {
        return this.max.year()
      }
      return dayjs.utc().add(dayjs.duration('P120Y')).year()
    },
  },
  watch: {
    day() {
      if (this.initalised) {
        setTimeout(() => {
          this.buildValue()
        }, 1000)
      }
    },
    month() {
      if (this.initalised) {
        setTimeout(() => {
          this.buildValue()
        }, 1000)
      }
    },
    year() {
      if (this.initalised) {
        this.buildValue()
      }
    },
    activeFieldIndex(newValue) {
      // If active field changes and exists, focus on it
      if (newValue > this.inputFields.length - 1 || newValue < 0) {
        this.activeFieldIndex = this.inputFields.length - 1
      } else {
        const activeField = this.inputFields[newValue]
        this.inputRefs[activeField.model].focus()
      }
    },
  },
  mounted() {
    // build min date value from options string
    // ISO8601 e.g 1970-01-01T00:00:00Z
    if (this.field.options.min) {
      // Check for ISO-8601 durantion string - i.e P1Y
      if (this.field.options.min.startsWith('P')) {
        this.min = dayjs.utc().add(dayjs.duration(this.field.options.min))
      } else {
        this.min = dayjs.utc(this.field.options.min)
      }
    }
    // edge case for 18 and above
    if (this.field.options.max === 'adultonly') {
      this.max = dayjs.utc().add(dayjs.duration('P18Y'))
    } else if (this.field.options.max) {
      // Check for ISO-8601 durantion string - i.e P1Y
      if (this.field.options.max.startsWith('P')) {
        this.max = dayjs.utc().add(dayjs.duration(this.field.options.max))
      } else {
        this.max = dayjs.utc(this.field.options.max)
      }
    }
    // Set date format config
    if (this.field.options.dateFormat) {
      this.dateFormat = this.field.options.dateFormat
    }
    const messages = this.field.options.messages || null
    // check if the day field is set to be hidden for this field
    if (this.field.options.hideDay) {
      this.hideDay = true
    }
    this.field.validators.push(
      new DayjsDateValidator({
        min: this.min,
        max: this.max,
        messages,
        required: this.required,
      })
    )
    let initialValue = null
    if (this.field.value) {
      initialValue = dayjs.utc(this.field.value)
    } else if (this.field.options.default) {
      initialValue = dayjs.utc(this.field.options.default)
    }
    if (initialValue) {
      this.day = initialValue.date()
      this.month = initialValue.month() + 1
      this.year = initialValue.year()
      this.field.value = initialValue
    }
    this.initalised = true
    if (this.field.value && this.field.validate()) {
      // Emit that the field has been populated
      this.$emit('callback', {
        key: this.field.name,
        value: this.field.value,
        action: 'populated',
        validated: true,
        bubble: true,
      })
    }
  },
  created() {
    // Initialise i18n integration. No-op if not present
    if (!this.$t) {
      this.$t = (str) => str
    }
    if (this.field.options.required === false) {
      this.required = false
    } else {
      // The field is required if the options value is missing, undefined, null, or true.
      // This maintains the legacy behaviour of this field, which defaulted to being required.
      this.required = true
    }
  },
  methods: {
    formatDate,

    setInputRef(model, el) {
      this.inputRefs[model] = el
    },

    zeroPad(value) {
      if (value?.toString().length === 1) {
        value = `0${value}`
      }
      return value
    },
    getFieldClass() {
      return 'mutt-field mutt-field-choice mutt-field-dateinput'
    },
    getFieldWrapperClass() {
      if (this.hasErrors) {
        return `mutt-field-wrapper ${this.getErrorWrapperClass()}`
      }
      return 'mutt-field-wrapper'
    },
    buildValue() {
      // If the day field is hidden set the day value to '01'
      // so that the date will validate successfully
      if (this.hideDay) {
        this.day = '01'
      }
      // YY can be a valid but ambiguious date
      // Check we have the right length
      let yearValidLength = false
      if (this.year) {
        if (this.year.toString().length > 3) {
          yearValidLength = true
        }
      }
      const reset = () => {
        this.field.refreshValidationState(false)
        this.field.value = null
      }
      if (yearValidLength && this.day && this.month) {
        const date = `${this.year}-${this.zeroPad(this.month)}-${this.zeroPad(
          this.day
        )}`
        const value = dayjs.utc(`${date}`)
        if (value.isValid()) {
          this.field.value = value
          this.field.validate()
        } else {
          reset()
        }
      } else {
        reset()
      }
    },
    restrictDates(dateElement, dateVal) {
      if (dateElement === 'day') {
        if (dateVal > 31) {
          this.day = '31'
        } else if (dateVal === '00') {
          this.day = '01'
        }
      }
      if (dateElement === 'month') {
        if (dateVal > 12) {
          this.month = '12'
        } else if (dateVal === '00') {
          this.month = '01'
        }
      }
    },
    jumper() {
      // Get currently active field and jump cursor if date validates
      const activeField = this.inputFields[this.activeFieldIndex]
      switch (activeField.model) {
        case 'day': {
          if (this.day) {
            // Update the day input field to within the
            // allowed range if necessary
            this.restrictDates('day', this.day)
            if (this.day.length > 1 && this.jump) {
              this.activeFieldIndex += 1
            }
          }
          break
        }
        case 'month': {
          if (this.month) {
            // Update the month input field to within the
            // allowed range if necessary
            this.restrictDates('month', this.month)
            if (this.month.length > 1 && this.jump) {
              this.activeFieldIndex += 1
            }
          }
          break
        }
        case 'year': {
          if (this.year) {
            // Update the year input field to within the
            // allowed range if necessary
            this.restrictDates('year', this.year)
            if (this.year.length >= 4 && this.jump) {
              this.activeFieldIndex += 1
            }
          }
          break
        }
        default:
          break
      }
    },
    checkJump(type, index) {
      // update activeFieldIndex position, if user manually selects field
      this.activeFieldIndex = index
      // We need to disable the jump if people edit or use a non-standard
      // order....
      if (this[type]) {
        this.jump = false
      }
    },
    async focus() {
      // Focus on first field for the first time
      await this.$nextTick()
      this.inputRefs[this.inputFields[this.activeFieldIndex].model].focus()
    },
    resetInputs() {
      this.year = null
      this.month = null
      this.day = null
      this.jump = true
    },
    isNotZero(value) {
      return value !== '0'
    },
    refresh() {
      this.initalised = false
      const value = dayjs.isDayjs(this.field.value)
        ? this.field.value
        : dayjs.utc(this.field.value)
      if (value.isValid()) {
        this.day = value.date()
        this.month = value.month() + 1
        this.year = value.year()
      } else {
        this.resetInputs()
      }
      this.initalised = true
    },
  },
}
</script>
