import { ProductLineConfig } from '@/@types/Config'
import deepFreeze from '@/lib/utils/deepFreeze'

const productLineConfig: ProductLineConfig = {
  flags: {
    allowTreatmentEdit: true,
    allowViewingPolicyLimits: false,
    allowAddingToExistingPet: true,
  },
  claims: {
    minWarningTimespan: {
      duration: 'PT24H',
      format: 'hours',
    },
    approveLimitPermissionPrefix: 'claims:wellness_usd:',
    defaultCondition: {
      algoliaID: '154496001',
      group: 'Preventative or elective procedures',
      id: 'con_Z8U4mSEKSKdGmLisN1TZYl',
      section: 'Medical Procedure',
      subgroup: 'Preventative or elective procedures',
    },
    algoliaVetsConfig: {
      api_key: '8dc3d952cd2b4736cebe4004a0547b6a',
      app_id: 'ORKMXLHYI4',
      index: 'prod_usa_bbm_vets',
    },
  },
  coverageTreatments: {
    dental_cleaning: [
      {
        id: 'DENTAL_CLEANING',
        label: 'Dental cleaning',
      },
    ],
    holistic_care: [
      {
        id: 'SUPPLEMENT_JOINT',
        label: 'Supplement joint',
      },
      {
        id: 'SUPPLEMENT_ALLERGIES',
        label: 'Supplement allergies',
      },
      { id: 'SUPPLEMENT_GASTROENTERIC', label: 'Supplement - gastroenteric' },
      { id: 'SUPPLEMENT_MISCELLANEOUS', label: 'Supplement - miscellaneous' },
      { id: 'MASSAGE_TREATMENT', label: 'Massage treatment' },
      { id: 'REIKI_TREATMENT', label: 'Reiki treatment' },
      { id: 'CHIROPRACTIC_TREATMENT', label: 'Chiropractic treatment' },
      { id: 'ACUPUNCTURE_TREATMENT', label: 'Acupuncture treatment' },
      {
        id: 'MISCELANEOUS_TREATMENT_OR_SERVICE',
        label: 'Miscellaneous treatment or service',
      },
      {
        id: 'THERAPEUTIC_SHAMPOO_OR_SKINCARE_TREATMENTS',
        label: 'Therapeutic shampoo or skincare treatments',
      },
      { id: 'VITAMINS', label: 'Vitamins' },
      { id: 'MISCELANEOUS_PRODUCT', label: 'Miscellaneous products' },
    ],
    parasite_prevention: [
      { id: 'FLEA_TICK_PREVENTATIVE', label: 'Flea and tick preventative' },
      { id: 'HEARTWORM_PREVENTATIVE', label: 'Heartworm preventative' },
      { id: 'HEARTWORM_TEST', label: 'Heartworm test' },
      { id: 'COMBO_TEST', label: 'Combo test' },
      { id: 'FECAL_TEST', label: 'Fecal test' },
    ],
    vaccines_and_wellness_exams: [
      { id: 'EXAM', label: 'Exam' },
      { id: 'VACCINE_DHPP_DHLPP', label: 'Vaccine - DHPP/DHLPP' },
      { id: 'VACCINE_BORDETELLA', label: 'Vaccine - Bordetella' },
      { id: 'VACCINE_RABIES', label: 'Vaccine - Rabies' },
      { id: 'VACCINE_FELV', label: 'Vaccine - Felv' },
      { id: 'VACCINE_FVRCP', label: 'Vaccine - FVRCP' },
      { id: 'VACCINE_FIP', label: 'Vaccine - FIP' },
      { id: 'VACCINE_CANINE_MISC', label: 'Vaccine - Canine misc' },
      { id: 'VACCINE_FELINE_MISC', label: 'Vaccine - Feline misc' },
    ],
  },
  rules: {
    cancellation: [
      {
        id: 'too-expensive',
        label: 'Too expensive',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          CLAIMS: ['term-end'],
          default: ['today'],
        },
      },
      {
        id: 'pet-missing',
        label: 'Pet missing',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          CLAIMS: ['today'],
          default: ['today'],
        },
      },
      {
        id: 'pet-rip',
        label: 'Pet RIP',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          CLAIMS: ['today'],
          default: ['today'],
        },
      },
      {
        id: 'pet-stolen',
        label: 'Pet stolen',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          CLAIMS: ['today'],
          default: ['today'],
        },
      },
      {
        id: 'transferred-owner',
        label: 'Transferred owner',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          CLAIMS: ['today'],
          default: ['today'],
        },
      },
      {
        id: 'service-unacceptable',
        label: 'Service unacceptable',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          CLAIMS: ['term-end'],
          default: ['today'],
        },
      },
      {
        id: 'claim-denied-waiting-period',
        label: 'Claim denied - waiting period',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          CLAIMS: ['term-end'],
          default: ['today'],
        },
      },
      {
        id: 'customer-self-funding',
        label: 'Customer self-funding',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          CLAIMS: ['term-end'],
          default: ['today'],
        },
      },
      {
        id: 'switched-to-competitor',
        label: 'Switched to competitor',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          CLAIMS: ['term-end'],
          default: ['today'],
        },
      },
      {
        id: 'unable-to-switch',
        label: 'Unable to switch',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          CLAIMS: ['term-end'],
          default: ['today'],
        },
      },
      {
        id: 'no-reason-provided',
        label: 'No reason provided',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          CLAIMS: ['term-end'],
          default: ['today'],
        },
      },
      {
        id: 'compliance-customer-breach',
        label: 'Compliance customer breach',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          CLAIMS: ['term-start'],
          default: ['term-start'],
        },
      },
      {
        id: 'violation-of-law',
        label: 'Violation of law',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          CLAIMS: ['term-end'],
          default: ['today'],
        },
      },
      {
        id: 'customer-misrepresentation',
        label: 'Customer misrepresentation',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          CLAIMS: ['term-end'],
          default: ['today'],
        },
      },
      {
        id: 'underwriting-decision',
        label: 'Underwriting decision',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          CLAIMS: ['term-end'],
          default: ['today'],
        },
      },
      {
        id: 'merged-policy',
        label: 'Merged policy',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          CLAIMS: ['term-end'],
          default: ['today'],
        },
      },
      {
        id: 'system-limitation',
        label: 'System limitation',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          CLAIMS: ['term-end'],
          default: ['today'],
        },
      },
      {
        id: 'move-to-another-product',
        label: 'Moved to another product',
        rules: {
          INSIDE_COOLING_OFF: ['term-start'],
          CLAIMS: ['term-end'],
          default: ['today'],
        },
      },
      {
        id: 'non-payment',
        label: 'Non payment',
        unselectable: true,
      },
    ],
  },
}

export default deepFreeze(productLineConfig)
