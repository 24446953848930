<template>
  <nav class="the-site-nav">
    <ElMenu
      :default-active="activeMenuIndex"
      :collapse="true"
      :background-color="colours.navigationBackgroundColor"
      :text-color="colours.navigationTextColor"
      :active-text-color="colours.navigationActiveTextColor"
    >
      <ElMenuItem class="dashboard-image-wrapper" index="/dashboard">
        <ElTooltip content="Search" placement="right">
          <RouterLink :to="{ name: 'dashboard' }">
            <img class="dashboard-image" :src="siteLogoUrl" alt="Dashboard" />
          </RouterLink>
        </ElTooltip>
      </ElMenuItem>

      <ElMenuItem v-if="canCreateNewQuote">
        <RouterLink :to="{ name: 'order-new' }">
          <ElIcon>
            <Plus aria-label="New quote" />
          </ElIcon>
        </RouterLink>
        <template #title>New quote</template>
      </ElMenuItem>

      <div v-if="canCreateNewQuote" class="divider-wrapper">
        <div class="divider" />
      </div>

      <ElMenuItem
        v-if="hasAnyPermissions(['customers:read', 'customers:write'])"
        index="/customers"
      >
        <RouterLink :to="{ name: 'customer-list' }">
          <ElIcon>
            <Avatar aria-label="Customers" />
          </ElIcon>
        </RouterLink>
        <template #title>Customers</template>
      </ElMenuItem>
      <ElMenuItem
        v-if="hasAnyPermissions(['quotes:read', 'quotes:write'])"
        index="/quotes"
      >
        <RouterLink :to="{ name: 'quote-list' }">
          <ElIcon>
            <Edit aria-label="Quotes" />
          </ElIcon>
        </RouterLink>
        <template #title>Quotes</template>
      </ElMenuItem>
      <ElMenuItem
        v-if="hasAnyPermissions(['policies:read', 'policies:write'])"
        index="/policies"
      >
        <RouterLink :to="{ name: 'policy-list' }">
          <ElIcon>
            <Tickets aria-label="Policies" />
          </ElIcon>
        </RouterLink>
        <template #title>Policies</template>
      </ElMenuItem>
      <ElMenuItem
        v-if="hasAnyPermissions(['claims:read', 'claims:write'])"
        index="/claims"
      >
        <RouterLink :to="{ name: 'claim-list' }">
          <ElIcon>
            <Checked aria-label="Claims" />
          </ElIcon>
        </RouterLink>
        <template #title>Claims</template>
      </ElMenuItem>
      <ElMenuItem
        v-if="hasAnyPermissions(['claims:read', 'claims:write'])"
        index="/vet-practices"
      >
        <RouterLink :to="{ name: 'vet-practice-list' }">
          <ElIcon>
            <OfficeBuilding aria-label="Vet practices" />
          </ElIcon>
        </RouterLink>
        <template #title>Vet Practices</template>
      </ElMenuItem>
      <div class="divider-wrapper">
        <div class="divider" />
      </div>
      <ElMenuItem index="/choose-agency">
        <RouterLink :to="{ name: 'choose-agency' }">
          <ElIcon class="image-icon" aria-label="Agency">
            <img
              width="24"
              height="24"
              :src="getAgencyLogoSrc(agency, 'small')"
              :alt="agencyLogoText"
            />
          </ElIcon>
        </RouterLink>
        <template #title>{{ agencyLogoText }}</template>
      </ElMenuItem>
      <ElMenuItem
        v-if="isPhoneIntegrationEnabled"
        v-loading.fullscreen="loading"
        index="phone"
        @click="phone"
      >
        <ElIcon id="genesys-icon">
          <img
            class="dashboard-image"
            :src="getServiceBadgeSrc(isLoggedIn ? 'on' : 'off')"
            :alt="isLoggedIn ? 'service_badge-on' : 'service_badge-off'"
          />
        </ElIcon>
        <template #title>{{
          isLoggedIn
            ? 'You are logged in to Genesys Cloud'
            : 'Log in to Genesys Cloud'
        }}</template>
      </ElMenuItem>
      <ElSubMenu index="user">
        <template #title>
          <UserAvatar
            :user="{
              nickname: userInformation.name,
              picture: userInformation.avatar,
            }"
          />
        </template>
        <ElMenuItem index="/profile">
          <RouterLink :to="{ name: 'user-profile' }">Profile</RouterLink>
        </ElMenuItem>
        <ElMenuItem index="logout" @click="logout"> Logout </ElMenuItem>
      </ElSubMenu>
    </ElMenu>
  </nav>
</template>

<script lang="ts" setup>
import {
  Avatar,
  Checked,
  Edit,
  OfficeBuilding,
  Plus,
  Tickets,
} from '@element-plus/icons-vue'
import { ElNotification } from 'element-plus'
import { storeToRefs } from 'pinia'
import { inject, watch, computed, onMounted } from 'vue'
import { RouterLink, useRoute } from 'vue-router'

import { AgencyName, InstanceName } from '@/@types/Config'
import UserAvatar from '@/components/UserAvatar.vue'
import { usePermissions } from '@/composables/usePermissions'
import { getConfigForFeature } from '@/lib/appConfig'
import {
  getAgencyLogoUrl,
  getSiteLogoUrl,
  ImageSize,
  getServiceBadgeUrl,
} from '@/lib/assetsUrls'
import { useAuth0 } from '@/lib/auth0/auth0-vue-proxy'
import { usePhoneIntegrationStore } from '@/lib/store/phone/usePhoneIntegration'
import { generateAgencyName } from '@/lib/utils'
import { hasAnyPermission } from '@/lib/utils/permissions'
import { hasNewFlowEnabledProductLines } from '@/lib/utils/quotes'
import { newFlowEnabled } from '@/lib/utils/sgp'

const phoneIntegrationStore = usePhoneIntegrationStore()

const auth0 = useAuth0()
const route = useRoute()
const agency = inject('agency') as AgencyName
const instance = inject('instance') as InstanceName
const { agencyPermissions } = usePermissions()
const { isLoggedIn, loading } = storeToRefs(phoneIntegrationStore)

const activeMenuIndex = computed(() => '/' + route.path.split('/')[1])

const appColorTheme = computed(() => {
  // Light theme only if isProductionInstance is explicitly set to false.
  // Dark theme if isProductionInstance is set to true or not set yet.
  // (no instance/agency is selected yet)
  return getConfigForFeature('isProductionInstance') === false
    ? 'light'
    : 'dark'
})

const isPhoneIntegrationEnabled =
  true || Boolean(getConfigForFeature('phoneIntegrationEnabled'))

watch(
  appColorTheme,
  () => {
    if (appColorTheme.value === 'dark') {
      document.body.classList.add('theme--dark')
      document.body.classList.remove('theme--light')
    } else {
      document.body.classList.add('theme--light')
      document.body.classList.remove('theme--dark')
    }
  },
  {
    immediate: true,
  }
)

watch(isLoggedIn, (newState: boolean) => {
  if (newState) {
    ElNotification.success({
      title: 'Logged in to Genesys Cloud',
      duration: 6000,
    })
  }
})

const siteLogoUrl = computed(() => {
  // we want the image variant to be the opposite of the theme
  const theme = appColorTheme.value === 'dark' ? 'light' : 'dark'

  return getSiteLogoUrl(theme)
})

const agencyLogoText = computed(() => {
  return agency
    ? `${generateAgencyName(agency)} - ${instance}`
    : 'Please select an agency'
})

const userInformation = computed(() => {
  return {
    name: auth0?.user?.value?.name,
    avatar: auth0?.user?.value?.picture,
  }
})

const hasAnyPermissions = (permissions: string[]) => {
  return hasAnyPermission(permissions, agencyPermissions.value)
}

const canQuoteUsingNewFlow = computed(() => {
  return (
    !getConfigForFeature('flags.blockQuoting') &&
    newFlowEnabled() &&
    hasNewFlowEnabledProductLines()
  )
})

const canCreateNewQuote = computed(() => {
  return canQuoteUsingNewFlow.value && hasAnyPermissions(['quotes:write'])
})

const colours = computed(() => {
  if (appColorTheme.value === 'light') {
    return {
      navigationBackgroundColor: '',
      navigationTextColor: '#fff',
      navigationActiveTextColor: '#fcca1b',
    }
  }

  return {
    navigationBackgroundColor: '#3e6a55',
    navigationTextColor: '',
    navigationActiveTextColor: '',
  }
})

const getAgencyLogoSrc = (agency: string, size?: ImageSize) => {
  // we want the image variant to be the opposite of the theme
  const variant = appColorTheme.value === 'dark' ? 'light' : 'dark'

  return getAgencyLogoUrl({ agency, size, variant })
}

const getServiceBadgeSrc = (type: 'off' | 'on') => {
  // we want the image variant to be the opposite of the theme
  const variant = appColorTheme.value === 'dark' ? 'light' : 'dark'

  return getServiceBadgeUrl({ type, variant })
}

const phone = () => {
  phoneIntegrationStore.login()
}

const logout = () => {
  auth0.logout({
    logoutParams: {
      returnTo: window.location.origin,
    },
  })
}

onMounted(() => {
  phoneIntegrationStore.autoLogin()
})
</script>

<style lang="scss" scoped>
.dashboard-image-wrapper {
  margin-top: var(--app-spacing-4);
  margin-bottom: var(--app-spacing-2);
}

.dashboard-image {
  vertical-align: middle;
  text-align: center;
  height: 24px;
  width: 24px;
}

.the-site-nav {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  // 81 brings it one higher that the intro.
  z-index: 81;
}

.el-menu-item a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  padding: 0 20px;
  color: inherit;
}

// Override padding in submenu which is a bug in E+
:deep(.el-sub-menu__title) {
  padding-right: var(--el-menu-base-level-padding);
}

.theme--dark {
  .el-menu-item {
    color: var(--el-color-primary-light-9);

    &:hover {
      background-color: var(--el-color-primary-light-5);
    }

    a .el-icon {
      color: var(--el-color-primary-light-9);
    }

    @supports selector(:focus-visible) {
      *:focus {
        outline: none;
      }

      *:focus-visible {
        background: var(--el-color-primary-light-5);
        border-radius: 0;
      }
    }
  }

  .el-sub-menu {
    .el-icon {
      color: var(--el-color-primary-light-9);
    }
  }
}

.theme--light {
  .el-menu-item {
    color: var(--el-color-primary);

    &:hover {
      background-color: var(--el-color-primary-light-5);
    }

    a .el-icon {
      color: var(--el-color-primary);
    }

    @supports selector(:focus-visible) {
      *:focus {
        outline: none;
      }

      *:focus-visible {
        background: var(--el-color-primary-light-5);
        border-radius: 0;
      }
    }
  }

  .el-sub-menu {
    .el-icon {
      color: var(--el-color-primary);
    }
  }
}

.image-icon {
  height: auto;
}

.avatar {
  object-fit: cover;
}

.divider-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--app-spacing-2) 0;

  .divider {
    display: block;
    width: 60%;
    height: 1px;
    border-bottom: 0.5px solid var(--el-color-primary-light-5);
  }
}
</style>
