<template>
  <div
    v-if="field"
    class="inception-date-selector"
    :class="getFieldWrapperClass()"
  >
    <label class="mutt-label" :for="field.name">
      {{ field.label }}
    </label>

    <ReadonlyWidget v-if="isReadOnly" :value="field.value" />

    <div
      v-if="!isReadOnly"
      class="mutt-field-wrapper mutt-field-wrapper--checkbox mutt-field-wrapper--radio"
    >
      <ul class="inception-date-list">
        <li v-if="canQuoteForToday" class="mutt-field-radio-item">
          <input
            id="today"
            ref="radioInception"
            :checked="isToday"
            value="today"
            type="radio"
            name="inceptionDate"
            class="mutt-field mutt-field-radio"
            @change="setDate"
          />
          <label class="mutt-label" for="today">Today</label>
        </li>
        <li v-if="canQuoteForTomorrow" class="mutt-field-radio-item">
          <input
            id="tomorrow"
            ref="radioInception"
            :checked="isTomorrow"
            value="tomorrow"
            type="radio"
            name="inceptionDate"
            class="mutt-field mutt-field-radio"
            @change="setDate"
          />
          <label class="mutt-label" for="tomorrow">Tomorrow</label>
        </li>
        <li v-if="canQuoteForCustomDate" class="mutt-field-radio-item">
          <input
            id="custom"
            ref="radioInception"
            :checked="
              showDateSelector ||
              (field.value && !isToday && !isTomorrow) ||
              null
            "
            value="custom"
            type="radio"
            name="inceptionDate"
            class="mutt-field mutt-field-radio"
            @change="setDate"
          />
          <label class="mutt-label" for="custom">Choose date</label>
        </li>
      </ul>

      <div
        v-if="showDateSelector || (field.value && !isToday && !isTomorrow)"
        class="date-selector dateValue"
      >
        <div class="date-options">
          <MuttDateinput
            ref="dateInput"
            :field="field"
            @callback="submitCallback"
          />
        </div>
      </div>
      <p v-else-if="field.value" class="dateValue">
        {{ fomattedValue }}
      </p>

      <ErrorWidget
        v-if="!isReadOnly"
        :field="field"
        :errors="errors"
        :error-class="getErrorClass()"
      />
    </div>

    <HelpWidget :field="field" />
  </div>
</template>

<script>
import { MuttDateinput } from '@mutt/widgets-bbm'
import MuttVue from '@mutt/widgets-vue'

import dayjs from '@/lib/dayjs'

const DATE_FORMAT = 'Do MMMM YYYY'

export default {
  name: 'MuttInception',
  for: 'inception',
  components: {
    MuttDateinput,
  },
  mixins: [MuttVue.mixin],
  emits: ['callback'],
  data() {
    return {
      errors: null,
      showDateSelector: false,
    }
  },

  computed: {
    fomattedValue() {
      return dayjs.utc(this.field.value).format(DATE_FORMAT)
    },
    isToday() {
      if (dayjs.isDayjs(this.field.value)) {
        return dayjs.utc().isSame(this.field.value, 'day')
      }
      return false
    },

    isTomorrow() {
      if (dayjs.isDayjs(this.field.value)) {
        return dayjs
          .utc()
          .startOf('date')
          .add(1, 'days')
          .isSame(this.field.value, 'day')
      }
      return false
    },
    canQuoteForToday() {
      return this.possibleInceptionDaysInTheFuture() > 0
    },
    canQuoteForTomorrow() {
      return this.possibleInceptionDaysInTheFuture() > 1
    },
    canQuoteForCustomDate() {
      return this.possibleInceptionDaysInTheFuture() > 2
    },
  },

  methods: {
    possibleInceptionDaysInTheFuture() {
      const daysInTheFuture = this.field.options.max.match(/P(\d+)D/)
      return daysInTheFuture ? parseInt(daysInTheFuture[1], 10) : -1
    },
    setDate($event) {
      switch ($event.target.value) {
        case 'today':
          this.selectToday()
          break
        case 'tomorrow':
          this.selectTomorrow()
          break
        case 'custom':
          this.selectCustom()
          break
        default:
          break
      }
    },
    async selectToday() {
      this.showDateSelector = false
      // NB Ideally we would reset the inputs here, but
      // because Vue it reactivly updating 3 values, this
      // creates a problem - next tick is only after 1 value
      // updates. This ultimetly creates a race condition, where
      // the value is normally accidentally cleared (inside
      // MuttDateInput).
      // For now this has the limitation that when coming back, the
      // custom date is pre-filled even if you subsequently hit
      // today/tomorrow
      // this.$refs.dateInput.resetInputs()
      await this.$nextTick()
      this.field.value = dayjs.utc().startOf('date')
      this.dateSelected()
    },

    async selectTomorrow() {
      this.showDateSelector = false
      // NB See note above in selectToday as to why this is not enabled.
      // this.$refs.dateInput.resetInputs()
      await this.$nextTick()
      this.field.value = dayjs.utc().startOf('date').add(1, 'days')
      this.dateSelected()
    },

    async selectCustom() {
      this.showDateSelector = true
      await this.$nextTick()
      this.$refs.dateInput.focus()
    },

    dateSelected() {
      if (!this.field.validate()) {
        return
      }

      this.errors = null

      this.$emit('callback', {
        action: 'dateSelected',
        validated: true,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.dateValue {
  margin-top: 1em;
}
</style>
