<template>
  <div v-if="field" :class="getFieldWrapperClass()">
    <fieldset :class="getFieldClass()">
      <legend v-if="field.label">{{ getLegend() }}</legend>
      <mutt-widget
        v-for="objectField of field.object"
        :key="objectField.id"
        :field="objectField"
        :readonly="readonly"
        :copyable="copyable"
        @callback="$emit('callback', $event)"
        @change="$emit('change', $event)"
        @select="$emit('select', $event)"
        @input="$emit('input', $event)"
      />
    </fieldset>
  </div>
</template>

<script>
import { i18n as _ } from '../lib/i18n'
import WidgetMixin from '../mixins/WidgetMixin'

export default {
  name: 'MuttObject',
  for: 'object',
  mixins: [WidgetMixin],
  emits: ['callback', 'change', 'select', 'input'],
  methods: {
    getLegend() {
      if (this.field.label) {
        return _(this, this.field.label)
      }
      return ''
    },
    getFieldClass() {
      let className = 'mutt-field mutt-field-object'

      if (this.field.attribs && this.field.attribs.hasOwnProperty('class')) {
        className = `${className} ${this.field.attribs.class}`
      }

      return className
    },
  },
}
</script>
