<template>
  <form v-if="form" role="form" @submit.prevent>
    <fieldset v-for="fieldset of form.fieldsets">
      <!-- <legend v-if="fieldset.label">{{ getFieldsetLegend(fieldset) }}</legend> -->
      <mutt-widget
        v-for="field of fieldset.fields"
        :key="field.id"
        :field="field"
        :readonly="readonly"
        v-bind="$attrs"
      />
      <button @click="submit">{{ getSubmitCta }}</button>
    </fieldset>
  </form>
</template>

<script>
import { toRaw } from 'vue'

import { i18n as _ } from './lib/i18n'

export default {
  name: 'MuttVue',
  props: {
    schema: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
    },
    data: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['init', 'submit'],
  data() {
    return {
      form: null,
    }
  },
  computed: {
    getSubmitCta() {
      return _(this, 'Submit')
    },
  },
  created() {
    this.form = new this.$mutt(toRaw(this.schema), toRaw(this.options))
    this.$emit('init', this.form)
  },
  mounted() {
    if (this.data) {
      this.form.populate(toRaw(this.data))
    }
  },
  methods: {
    getLegend(fieldset) {
      return _(this, fieldset.label)
    },
    submit() {
      this.$emit('submit', this.form)
    },
  },
}
</script>
