import Mutt from '@mutt/forms'

import dayjs from '@/lib/dayjs'
import { mapPartialZip, isValidStateCode } from '@/lib/zipMapping'

/**
 * Email validator - validates against a valid email address format
 */
export class EmailValidator extends Mutt.validators.Validator {
  constructor({ messages = null, required = true } = {}) {
    super(messages)
    this.required = required
  }

  validate(value) {
    // eslint-disable-next-line
    const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    if (!value) {
      if (!this.required) {
        return true
      }
      this.error = 'Please enter an email address'
      return false
    } else if (!regex.test(value)) {
      this.error = 'Please enter a valid email address'
      return false
    }
    return true
  }
}

/**
 * StringValidator - Validate the value length and pattern
 *   Can optionally allow unicode chars:
 *   ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿıŒœŠšŸŽž
 *
 * @class
 */
export class StringValidator extends Mutt.validators.Validator {
  constructor({
    min = 2,
    max = 25,
    messages = null,
    allowUnicode = false,
  } = {}) {
    super(messages)
    this.min = min
    this.max = max

    const defaultPattern = /^[a-zA-Z\s]+$/
    const unicodePattern = /^[a-zA-Z\u00c0-\u017e\s]+$/

    this.pattern = allowUnicode ? unicodePattern : defaultPattern
  }

  validate(value) {
    if (!value) {
      this.error = this.messages.required
      return false
    }

    value = value.trim()

    if (value.length < this.min) {
      this.error = `Must be at least ${this.min} characters.`
      return false
    }

    if (value.length > this.max) {
      this.error = `Must be at most ${this.max} characters.`
      return false
    }

    if (!value.match(this.pattern)) {
      this.error = 'Only letters and spaces can be used.'
      return false
    }

    return true
  }
}

export class ZipCodeValidator extends Mutt.validators.Validator {
  constructor({ messages = null, format = '' } = {}) {
    super(messages)
    this.format = format
  }

  validate(value) {
    if (!value) {
      return true
    }

    const shortRegex = /^\d{5}$/
    const longRegex = /^\d{5}-\d{4}$/

    switch (this.format) {
      case 'short':
        if (!shortRegex.test(value)) {
          this.error = `Please enter a valid zip code: XXXXX`
          return false
        }
        break
      case 'long':
        if (!longRegex.test(value)) {
          this.error = `Please enter a valid zip code: XXXXX-XXXX`
          return false
        }
        break
      default:
        if (!shortRegex.test(value) && !longRegex.test(value)) {
          this.error = `Please enter a valid zip code: XXXXX or XXXXX-XXXX`
          return false
        }
        break
    }

    return true
  }
}

export class ZipStateValidator extends Mutt.validators.Validator {
  constructor({ messages = null, supportedStates = [] } = {}) {
    super(messages)
    this.supportedStates = supportedStates
  }

  validate(value) {
    const state = mapPartialZip(value)
    const passes = this.supportedStates.includes(state)

    if (!passes) {
      if (state) {
        this.error = `Your state (${state}) is not yet supported.`
      } else {
        this.error = `You appear to have entered an invalid zip code.`
      }
    }

    return passes
  }
}

/**
 * StateCodeValidator - Validates a 2-letter state codes.
 * If supportedStates option is not specified or left empty array, we will
 * validate agains all states.
 */
export class StateCodeValidator extends Mutt.validators.Validator {
  constructor({ messages = null, supportedStates = [] } = {}) {
    super(messages)
    this.supportedStates = supportedStates
  }

  validate(value) {
    if (!value) {
      return true
    }

    if (!isValidStateCode(value)) {
      this.error = `Please enter a valid state: XX`
      return false
    }

    // We only check agains supported states if we set any
    if (this.supportedStates.length && !this.supportedStates.includes(value)) {
      this.error = `Your state (${value}) is not yet supported.`
      return false
    }

    return true
  }
}

/**
 * OptionalFieldsValidator - Validates that if any of the triggerFields of an
 * object is set, then the requiredFields are required. If non of the
 * triggerFields is set - do not require the requiredFields to be set.
 *
 * @class
 */
export class OptionalObjectFieldsValidator extends Mutt.validators.Validator {
  constructor({
    messages = null,
    triggerFields = [], // If empty array, all object fields are a trigger
    requiredFields = [], // If empty array, all object fields will be required
  } = {}) {
    super(messages)
    this.triggerFields = triggerFields
    this.requiredFields = requiredFields
  }

  validate(value) {
    const triggerFields = this.triggerFields.length
      ? this.triggerFields
      : Object.keys(value)

    // Check if values is empty string, null or undefined
    const isEmpty = (value) => value === '' || value == null

    const isFieldSet = triggerFields.some(
      (fieldName) => !isEmpty(value[fieldName])
    )

    if (isFieldSet) {
      // At least one trigger field is set
      // We need to make sure that all required fields are set too
      const error = {}
      let valid = true

      const requiredFields = this.requiredFields.length
        ? this.requiredFields
        : Object.keys(value)

      requiredFields.forEach((fieldName) => {
        if (isEmpty(value[fieldName])) {
          error[fieldName] = 'This field is required.'
          valid = false
        }
      })

      if (!valid) {
        this.error = error
        return false
      }
    }

    return true
  }
}

/**
 * DayjsDateTimeValidator - Validate the dayjs date object is between
 * certain values
 *
 * @class
 */
export class DayjsDateTimeValidator extends Mutt.validators.Validator {
  constructor({
    min = null,
    max = null,
    messages = null,
    required = true,
    exclude = null,
  } = {}) {
    super(messages)

    if (min !== null) {
      this.min = min
    }

    if (max !== null) {
      this.max = max
    }

    this.required = required
    this.messages = messages
    this.exclude = exclude
  }

  validate(value) {
    if (!value) {
      if (!this.required) {
        return true
      }

      this.error = 'This field is required.'
      return false
    }

    if (typeof value === 'string') {
      value = dayjs.utc(value)
    }

    if (!dayjs.isDayjs(value)) {
      return false
    }

    if (!value.isValid()) {
      this.error = 'Please enter a valid date.'
      return false
    }

    if (this.min && value < this.min) {
      if (this.messages?.min) {
        this.error = this.messages.min
      } else {
        this.error = 'Date is before earliest allowed.'
      }
      return false
    }

    if (this.max && value > this.max) {
      if (this.messages?.max) {
        this.error = this.messages.max
      } else {
        this.error = 'Date is after maximum allowed.'
      }
      return false
    }

    if (this.exclude) {
      if (this.exclude.some((exclude) => exclude.isSame(value, 'day'))) {
        this.error = this.messages?.exclude ?? 'Date is not allowed.'
        return false
      }
    }

    return true
  }
}

/**
 * DayjsDateValidator - Validate the dayjs date object is between
 * certain values
 *
 * @class
 */
export class DayjsDateValidator extends DayjsDateTimeValidator {
  constructor({
    min = null,
    max = null,
    messages = null,
    required,
    exclude,
  } = {}) {
    super({ min, max, messages, required, exclude })

    if (min !== null) {
      this.min = this.min.startOf('day')
    }

    if (max !== null) {
      this.max = this.max.endOf('day')
    }

    this.messages = messages
    this.exclude = exclude
  }
}

/**
 * AccountNameValidator
 *
 * @class
 */
export class AccountNameValidator extends Mutt.validators.Validator {
  validate(value) {
    // Pass validation if there's no value. If needed we can add RequiredValidator
    if (!value) {
      return true
    }

    if (value.trim().length < 3) {
      this.error =
        'Please enter a valid bank account name - must be more than 3 characters.'
      return false
    }

    if (value.trim().length > 34) {
      this.error =
        'Please enter a valid bank account name - must be no more than 34 characters.'
      return false
    }

    // IBA REQUIRES a space be in the name, we need to enforce
    // this here. There is no point forcing a user to do it,
    // just adding one on the end is sufficent
    if (!value.trim().includes(' ')) {
      this.error =
        'Please enter a valid bank account name - must have at least two names.'
      return false
    }

    return true
  }
}

/**
 * AccountnumberValidator - Validate as string in format 01234567890
 *
 * @class
 */
export class AccountNumberValidator extends Mutt.validators.Validator {
  validate(value) {
    if (value && !/^([0-9]{8})$/.test(value)) {
      this.error = 'Please enter a valid account number: XXXXXXXX'
      return false
    }

    return true
  }
}

/**
 * SortcodeValidator - Validate as string in format 00-00-00
 *
 * @class
 */
export class SortCodeValidator extends Mutt.validators.Validator {
  validate(value) {
    if (
      value &&
      !/^(?!(?:0{6}|00-00-00))(?:\d{6}|\d\d-\d\d-\d\d)$/.test(value)
    ) {
      this.error = 'Please enter a valid sort code: XX-XX-XX or XXXXXX'
      return false
    }

    return true
  }
}

/**
 * TelephoneValidator - Simple check that telpehone number strings contain
 * at least min number of characters and consist only of numbers and spaces
 *
 * @class
 */
export class TelephoneValidator extends Mutt.validators.Validator {
  validate(value) {
    if (!value) {
      this.error = this.messages.required
      return false
    }

    // Regex test for numbers, allowing spaces, brackets and + - symbols
    const telephoneStrPattern = /^[-+() ]*(\d[-+() ]*)+$/
    const regexTest = telephoneStrPattern.test(value)

    // Strip letters, symbols and spaces before checking length
    value = value.replace(/[^0-9]/g, '')

    if (!regexTest || value.length < 10) {
      this.error =
        'Your phone number is missing one or more digits or contains an invalid character. Only numbers and the symbols ( ) + - are allowed.'
      return false
    }

    return true
  }
}

/**
 * PostCodeValidator - Validate as UK postal code
 *
 * @class
 */
export class PostCodeValidator extends Mutt.validators.RegexValidator {
  constructor() {
    const msg = 'Please enter a valid UK postcode'
    super(PostCodeValidator.getPostCodePattern(), {
      required: msg,
      invalidPattern: msg,
    })
  }
  static getPostCodePattern() {
    return /[A-Z]{1,2}[A-Z0-9]{1,2} ?[0-9][A-Z]{2}/i
  }
}

export class NumberValidator extends Mutt.validators.Validator {
  constructor({ min = null, max = null, messages = null } = {}) {
    super({ min, max, messages })

    this.min = min
    this.max = max
    this.messages = messages
  }

  validate(value) {
    if (value == null || value === '') {
      this.error = this.messages.required
      return false
    }

    const numericValue = Number(value)

    if (this.min != null && numericValue < this.min) {
      this.error =
        this.messages?.min ??
        `The number you have entered is below the minimum allowed (${this.min})`
      return false
    }

    if (this.max != null && numericValue > this.max) {
      this.error =
        this.messages?.max ??
        `The number you have entered is above the maximum allowed (${this.max})`
      return false
    }

    return true
  }
}
