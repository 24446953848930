import Mutt from "@mutt/forms"

const UK_POSTCODE_PATTERN =
  "^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$"
const REQUIRED_ERROR_MESSAGE = "Please enter a valid UK postcode"
const INVALID_PATTERN_MESSAGE = "Please enter a valid UK postcode"

export class PostcodeValidator extends Mutt.validators.RegexValidator {
  constructor({
    pattern = UK_POSTCODE_PATTERN,
    requiredErrorMessage = REQUIRED_ERROR_MESSAGE,
    invalidPatternErrorMessage = INVALID_PATTERN_MESSAGE,
  } = {}) {
    super(new RegExp(pattern, "i"), {
      required: requiredErrorMessage,
      invalidPattern: invalidPatternErrorMessage,
    })
  }
}
