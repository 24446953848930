import { groupBy, reduce, sumBy, uniq } from 'lodash-es'

import type {
  Basket,
  BasketChange,
  BasketCustomerChange,
  BasketPetChange,
  BasketPolicyChange,
  BasketCreateChange,
  PolicyChangeData,
} from '@/@types/Basket'
import { Coverage } from '@/@types/Coverage'
import { Lifecycle } from '@/@types/Lifecycle'
import { Pet } from '@/@types/Pet'
import dayjs from '@/lib/dayjs'
import { isNotNullOrUndefined } from '@/lib/utils/arrays'
import { BundleProducts, CoverageBundle } from '@/lib/utils/coverageBundles'

export const findBasketChange = (
  basket: Nullable<Basket>,
  { entity, entity_id, correlation_id, type, data }: Partial<BasketChange>
): Maybe<BasketChange> =>
  basket?.changes?.find(
    (change) =>
      entity === change.entity &&
      entity_id === change.entity_id &&
      type === change.type &&
      (!correlation_id || correlation_id === change.correlation_id) &&
      (!(data as PolicyChangeData)?.pet ||
        (data as PolicyChangeData)?.pet?.id ===
          (change.data as PolicyChangeData)?.pet?.id)
  )

export const hasBasketChange = (
  basket: Nullable<Basket>,
  change: Partial<BasketChange>
): boolean => Boolean(findBasketChange(basket, change))

export const hasPolicyLapseChange = (
  basket: Nullable<Basket>,
  policyId: string
): boolean =>
  hasBasketChange(basket, {
    entity: 'POLICY',
    entity_id: policyId,
    type: 'LAPSE',
  })

export const hasPolicyRenewChange = (
  basket: Nullable<Basket>,
  policyId: string
): boolean =>
  hasBasketChange(basket, {
    entity: 'POLICY',
    entity_id: policyId,
    type: 'RENEW',
  })

export const isBasketCreateChange = (
  change: BasketChange
): change is BasketCreateChange => change.type === 'CREATE'

export const isBasketSetChange = (
  change: BasketChange
): change is BasketCreateChange => change.type === 'SET'

export const isBasketPolicyCreateChange = (
  change: BasketChange
): change is BasketCreateChange & BasketPolicyChange =>
  isBasketPolicyChange(change) && isBasketCreateChange(change)

export const isBasketPolicyChange = (
  change: BasketChange
): change is BasketPolicyChange => change.entity === 'POLICY'

export const isBasketPetChange = (
  change: BasketChange
): change is BasketPetChange => change.entity === 'PET'

export const isBasketCustomerChange = (
  change: BasketChange
): change is BasketCustomerChange => change.entity === 'CUSTOMER'
export interface PetBundles {
  petName?: string
  isNew: boolean
  bundles: BundleProducts
}

const extractBundlesFromPetPolicies = (
  productLines: string[],
  petPolicies: BasketPolicyChange[],
  date: string,
  lifecycles?: Lifecycle[]
): BundleProducts => {
  return reduce(
    uniq(productLines),
    (result: BundleProducts, productLine?: string): BundleProducts => {
      if (productLine) {
        const bundlesByProductLine = petPolicies
          .filter((petPolicy) =>
            policyHasProductLine(petPolicy, productLine, lifecycles)
          )
          .flatMap((petPolicy) => {
            if (!petPolicy.ratings) {
              return null
            }

            const ratings = petPolicy.ratings[date]

            return ratings.bundles.map<CoverageBundle>((bundle) => {
              const compulsory: Coverage[] = bundle.compulsory.map(
                (coverage) => ({
                  key: coverage,
                  ...ratings.coverage_options[coverage],
                })
              )

              const optional: Coverage[] = bundle.optional
                .map((coverage) => ({
                  key: coverage,
                  ...ratings.coverage_options[coverage],
                }))
                .map((optionlCoverage) => ({
                  ...optionlCoverage,
                  productPrice: {
                    monthlyTotal: {
                      discount: 0,
                      subtotal: optionlCoverage.price?.month?.amount ?? 0,
                      tax: 0,
                      total: optionlCoverage.price?.month?.amount ?? 0,
                      discountPercentage: 0,
                    },
                    annualTotal: {
                      discount: 0,
                      subtotal: optionlCoverage.price?.annual?.amount ?? 0,
                      tax: 0,
                      total: optionlCoverage.price?.annual?.amount ?? 0,
                      discountPercentage: 0,
                    },
                    surcharges: [],
                  },
                }))

              const coverageAnnualPrice: number = sumBy(
                compulsory,
                (coverage) => coverage.price?.annual?.amount ?? 0
              )

              const coverageMonthlyPrice: number = sumBy(
                compulsory,
                (coverage) => coverage.price?.month?.amount ?? 0
              )

              const surcharges = compulsory.map((coverage) => {
                const coverageSurcharges = coverage.price?.surcharge_rate ?? {}

                return Object.keys(coverageSurcharges)
              })

              const result = {
                policyChangeId: petPolicy.id,
                product: bundle.product,
                compulsory,
                optional,
                price: {
                  monthlyTotal: {
                    discount: 0,
                    subtotal: coverageMonthlyPrice,
                    tax: 0,
                    total: coverageMonthlyPrice,
                    discountPercentage: 0,
                  },
                  annualTotal: {
                    discount: 0,
                    subtotal: coverageAnnualPrice,
                    tax: 0,
                    total: coverageAnnualPrice,
                    discountPercentage: 0,
                  },
                  surcharges: surcharges.length
                    ? Array.from(new Set(...surcharges))
                    : [],
                },
              }

              return result
            })
          })
          .filter(isNotNullOrUndefined)

        const bundlesByProducts = groupBy(
          bundlesByProductLine,
          (bundle) => bundle.product
        )

        result[productLine] = bundlesByProducts
      }

      return result
    },
    {}
  )
}

export const policyHasProductLine = (
  policy: BasketPolicyChange,
  productLine: string,
  lifecycles?: Lifecycle[]
): boolean => {
  if (policy.data.product_line === productLine) {
    return true
  }

  if (lifecycles?.length) {
    const lifecycleForPolicy = lifecycles.find(
      (lifecycle) => policy.entity_id === lifecycle.policy
    )

    if (lifecycleForPolicy && lifecycleForPolicy.product_line === productLine) {
      return true
    }
  }

  return false
}

export const formatEditPetBundles = (
  basket: Basket,
  pet: Pet,
  lifecycles: Lifecycle[]
): Maybe<PetBundles> => {
  const petPolicies = basket.changes
    .filter(isBasketPolicyChange)
    .filter((policyChange) =>
      pet?.products?.some((product) => product.id === policyChange.entity_id)
    )

  const productLines = lifecycles
    .filter((lifeCycle) =>
      petPolicies.map((petPolicy) => petPolicy.entity_id === lifeCycle.policy)
    )
    .map((lifeCycle) => lifeCycle.product_line)

  const bundles = extractBundlesFromPetPolicies(
    productLines,
    petPolicies,
    basket.effective_date,
    lifecycles
  )

  productLines.forEach((productLine: string) => {
    if (
      bundles[productLine] &&
      Object.keys(bundles[productLine]).length === 0
    ) {
      delete bundles[productLine]
    }
  })

  return {
    petName: pet.name,
    isNew: false,
    bundles,
  }
}

interface PetData {
  name?: string
  id?: string
}

export const formatNewPetBundles = (
  basket: Basket,
  petData: PetData
): Maybe<PetBundles> => {
  const petPolicies = basket.changes
    .filter(isBasketPolicyChange)
    .filter((policyChange) => policyChange.data.pet?.id === petData.id)

  const productLines = petPolicies
    .map((policyChange) => policyChange.data.product_line)
    .filter(isNotNullOrUndefined)

  const bundles = extractBundlesFromPetPolicies(
    productLines,
    petPolicies,
    basket.effective_date
  )

  return {
    petName: petData.name,
    isNew: true,
    bundles,
  }
}

export const getPetBundles = (
  basket: Basket,
  pets?: Pet[],
  lifecycles?: Lifecycle[]
): PetBundles[] => {
  const basketHasNewPet = basket.changes.some(
    (change) => isBasketPetChange(change) && isBasketCreateChange(change)
  )

  if (basketHasNewPet) {
    return basket.changes
      .filter(isBasketPetChange)
      .map((petChange) =>
        formatNewPetBundles(basket, {
          id: petChange.correlation_id,
          name: petChange.data.name,
        })
      )
      .filter(isNotNullOrUndefined)
  }

  if (pets?.length && lifecycles?.length) {
    const isEdit = basket.changes.every(
      (change) => isBasketPolicyChange(change) && isBasketSetChange(change)
    )

    if (isEdit) {
      return pets
        .map((pet) => formatEditPetBundles(basket, pet, lifecycles))
        .filter(isNotNullOrUndefined)
    } else {
      return pets
        .map((pet) =>
          formatNewPetBundles(basket, {
            id: pet.id,
            name: pet.name,
          })
        )
        .filter(isNotNullOrUndefined)
    }
  }

  return []
}

export const basketHasRenewalTypeChanges = (basket: Basket): boolean => {
  if (!basket.changes?.length) {
    return false
  }

  return basket.changes.some(
    (change) => change.type === 'RENEW' || change.type === 'LAPSE'
  )
}

export interface BundleOptions {
  vetFees: Maybe<number>
  excess: Maybe<number>
  copay: Maybe<number>
}

interface GetSelectedCoveragesForPolicyChangeProps {
  basket: Basket
  policyChangeId: string
}

export const getSelectedCoveragesForPolicyChange = ({
  basket,
  policyChangeId,
}: GetSelectedCoveragesForPolicyChangeProps): string[] | null => {
  const policyChange: Maybe<BasketPolicyChange> = basket.changes
    .filter(isBasketPolicyChange)
    .find((change) => change.id === policyChangeId)

  return policyChange?.data.coverages ?? null
}

export const isBasketCheckedOut = (basket: Basket): boolean => {
  return basket.checkout !== 'NOT_TRIED'
}

export const isCancellationOnlyBasket = (basket: Basket): boolean => {
  return basket.changes.every(({ type }) => type === 'CANCEL')
}

export const isBasketInThePast = (basket: Basket): boolean => {
  return dayjs(basket.effective_date).isBefore(dayjs(), 'day')
}
