<template>
  <div>
    <TextBase size="medium" type="primary">
      {{ viewer.nickname }}
    </TextBase>
    <TextBase size="small" type="secondary">
      {{ timeAgo }}
    </TextBase>
  </div>
</template>

<script setup lang="ts">
import { useTimeAgo } from '@vueuse/core'

import { TextBase } from '@/patterns/Text'
interface Props {
  viewer: { nickname: string; started_viewing_timestamp: number }
}
const props = defineProps<Props>()

const timeAgo = useTimeAgo(
  new Date(props.viewer.started_viewing_timestamp * 1000)
)
</script>
