import ChooseAgency from '@/views/auth/ChooseAgency.vue'
import UserProfile from '@/views/auth/UserProfile.vue'

export default [
  {
    name: 'choose-agency',
    path: '/choose-agency',
    component: ChooseAgency,
    meta: {
      agencyRequired: false,
      title: 'Choose Agency',
      legacy: true,
    },
  },
  {
    name: 'user-profile',
    path: '/profile',
    component: UserProfile,
    meta: {
      agencyRequired: false,
      title: 'Profile',
      legacy: true,
    },
  },
]
