<template>
  <Component
    :is="props.tag"
    class="text-base"
    :class="{
      [`text-base--weight-${weight}`]: props.weight,
      [`text-base--size-${size}`]: props.size,
      [`text-base--colour-${type}`]: props.type,
      'text-base--uppercase': props.uppercase,
      [`text-base--line-height-${lineHeight}`]: props.lineHeight,
    }"
  >
    <slot />
  </Component>
</template>

<script setup lang="ts">
import type {
  WeightsType,
  SizesType,
  TypesType,
  LineHeightsType,
} from '../constants'

interface Props {
  tag?: string
  size?: SizesType
  weight?: WeightsType
  uppercase?: boolean
  type?: TypesType
  lineHeight?: LineHeightsType
}

const props = withDefaults(defineProps<Props>(), {
  tag: 'div',
  size: 'base',
  weight: 'regular',
  lineHeight: 'regular',
  uppercase: false,
  type: 'regular',
})
</script>

<style lang="scss" scoped>
.text-base {
  &--colour {
    &-primary {
      color: var(--el-text-color-primary);
    }

    &-secondary {
      color: var(--el-text-color-secondary);
    }

    &-regular {
      color: var(--el-text-color-regular);
    }
  }

  &--weight {
    &-bold {
      font-weight: 700;
    }

    &-semi-bold {
      font-weight: 600;
    }

    &-medium {
      font-weight: 500;
    }

    &-regular {
      font-weight: 400;
    }
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--line-height {
    &-compact {
      line-height: 1;
    }

    &-regular {
      line-height: 1.3;
    }

    &-large {
      line-height: 1.5;
    }
  }

  &--size {
    &-huge {
      font-family: var(--el-font-family-heading);
      font-size: 36px;
    }

    &-extra-large {
      font-family: var(--el-font-family-heading);
      font-size: 28px;
    }

    &-large {
      font-size: 18px;
    }

    &-medium {
      font-size: 16px;
    }

    &-base {
      font-size: 14px;
    }

    &-small {
      font-size: 12px;
    }
  }
}
</style>
