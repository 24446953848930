import type { ApiListParams, ApiListSearchParams } from '@/@types/ApiResponse'
import type { AgencyName } from '@/@types/Config'
import type { AddPatchOperation, PatchOperation } from '@/@types/JSONPatch'
import type {
  Customer,
  CustomerAddress,
  CustomerNote,
  CustomerWithAddress,
} from '@/domain/Customer'
import { CustomerFlag } from '@/domain/CustomerFlag'
import ErrorResponseFactory from '@/domain/Error/ErrorResponseFactory'

import ApiService from './ApiService'
import CustomerFlagParser from './parsers/CustomerFlagParser'
import CustomersParser from './parsers/CustomersParser'
import { PaginatedResponse } from './parsers/PaginatedParser'

const CUSTOMERS_PATH = '/users'

export default class CustomersService {
  public static async getCustomerWithAddress(
    customerId: string
  ): Promise<CustomerWithAddress> {
    try {
      const customerRequest = ApiService.get({
        path: `${CUSTOMERS_PATH}/${customerId}`,
        parse: CustomersParser.parse,
      })

      const [customer, address = {}] = await Promise.all([
        customerRequest,
        CustomersService.getAddress(customerId),
      ])

      return { ...customer, address }
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to get customer with addresses',
        originalError: error,
        tags: ['s-user'],
      })

      throw errorClass
    }
  }

  public static async getAddresses(
    customerId: string
  ): Promise<CustomerAddress[]> {
    try {
      const response = await ApiService.get({
        path: `${CUSTOMERS_PATH}/${customerId}/addresses`,
        parse: CustomersParser.parsePaginatedAddressResponse,
      })

      return response.items
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to get addresses for customer',
        originalError: error,
        tags: ['s-user'],
      })

      throw errorClass
    }
  }

  public static async getAddress(customerId: string): Promise<CustomerAddress> {
    try {
      const response = await CustomersService.getAddresses(customerId)
      return response[0]
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to get address for customer',
        originalError: error,
        tags: ['s-user'],
      })

      throw errorClass
    }
  }

  public static async getCustomerNotes(
    customerId: string,
    params: { searchParams: ApiListParams }
  ): Promise<PaginatedResponse<CustomerNote>> {
    const { searchParams } = params

    try {
      return await ApiService.get({
        path: `${CUSTOMERS_PATH}/${customerId}/notes`,
        params: {
          searchParams: {
            page_number: 1,
            per_page: 5,
            ...searchParams,
          },
        },
        parse: CustomersParser.parsePaginatedNoteResponse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to get customer notes',
        originalError: error,
        tags: ['s-user'],
      })

      throw errorClass
    }
  }

  public static async createCustomerNote(
    customerId: string,
    params: { data: Partial<CustomerNote> }
  ): Promise<CustomerNote> {
    const { data } = params

    try {
      return await ApiService.post({
        path: `${CUSTOMERS_PATH}/${customerId}/notes`,
        params: {
          json: data,
        },
        parse: CustomersParser.parseNote,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to get address for customer',
        originalError: error,
        tags: ['s-user'],
      })

      throw errorClass
    }
  }

  public static async DO_NOT_USE_getCustomer(
    customerId: string
  ): Promise<Customer> {
    try {
      return await ApiService.get({
        path: `${CUSTOMERS_PATH}/${customerId}`,
        parse: CustomersParser.parse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to get customer',
        originalError: error,
        tags: ['s-user'],
      })

      throw errorClass
    }
  }

  public static async getCustomerByEmail(
    email: string
  ): Promise<Maybe<Customer>> {
    try {
      const customerResponse = await ApiService.get({
        path: CUSTOMERS_PATH,
        params: {
          searchParams: {
            email,
          },
        },
        parse: CustomersParser.parsePaginatedResponse,
      })

      return customerResponse.items[0]
    } catch (error: any) {
      console.error(`Unable to get user: ${email}: ${error}`)
      return
    }
  }

  public static async checkEmailExists(email: string): Promise<Maybe<Boolean>> {
    try {
      return await ApiService.get({
        path: `${CUSTOMERS_PATH}/email-exists`,
        params: {
          searchParams: {
            email,
          },
        },
        parse: (data: { exists: boolean }) => data.exists,
      })
    } catch (error: any) {
      console.error(`Unable to check user email: ${email}: ${error}`)
      return
    }
  }

  public static async create(params: {
    data: Partial<Customer>
  }): Promise<Customer> {
    const { data } = params

    try {
      return await ApiService.post({
        path: CUSTOMERS_PATH,
        params: {
          json: data,
        },
        parse: CustomersParser.parse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to create the customer',
        originalError: error,
        tags: ['s-user'],
      })

      throw errorClass
    }
  }

  public static async patch(
    customerId: string,
    params: { patches: PatchOperation[] }
  ): Promise<Customer> {
    const { patches } = params

    try {
      return await ApiService.patch({
        path: `${CUSTOMERS_PATH}/${customerId}`,
        params: {
          json: patches,
        },
        parse: CustomersParser.parse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to patch the customer',
        originalError: error,
        tags: ['s-user'],
      })

      throw errorClass
    }
  }

  public static async update(
    customerId: string,
    params: { data: Partial<Customer> }
  ): Promise<Customer> {
    const { data } = params

    const patches = Object.entries(data).map(
      ([field, value]) =>
        ({
          op: 'add',
          path: `/${field}`,
          value,
        } as AddPatchOperation)
    )

    try {
      return await CustomersService.patch(customerId, { patches })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to patch the customer',
        originalError: error,
        tags: ['s-user'],
      })

      throw errorClass
    }
  }

  public static async addAddress(
    customerId: string,
    params: { address: Partial<CustomerAddress> }
  ): Promise<CustomerAddress> {
    const { address } = params

    try {
      return await ApiService.post({
        path: `${CUSTOMERS_PATH}/${customerId}/addresses`,
        params: {
          json: address,
        },
        parse: CustomersParser.parseAddress,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to add address for customer',
        originalError: error,
        tags: ['s-user'],
      })

      throw errorClass
    }
  }

  public static async sendPasswordResetEmail(
    customerId: string
  ): Promise<void> {
    try {
      await ApiService.post({
        path: `${CUSTOMERS_PATH}/${customerId}/action/send-reset-password-email`,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to send password reset email',
        originalError: error,
        tags: ['s-user'],
      })

      throw errorClass
    }
  }

  public static async syncToIBSuite(customerId: string): Promise<void> {
    try {
      await ApiService.post({
        path: `${CUSTOMERS_PATH}/${customerId}/action/sync-to-ibsuite`,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Error syncing with IBSuite',
        originalError: error,
        tags: ['s-user'],
      })

      throw errorClass
    }
  }

  public static async search(
    searchParams?: ApiListSearchParams
  ): Promise<PaginatedResponse<Customer>> {
    try {
      return await ApiService.get({
        path: `${CUSTOMERS_PATH}/action/search`,
        params: {
          searchParams: {
            q: searchParams?.q ?? '',
            page_number: searchParams?.page_number ?? 1,
            per_page: searchParams?.per_page ?? 50,
            include_address: true,
          },
        },
        parse: CustomersParser.parsePaginatedResponse,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to search for customer',
        originalError: error,
        tags: ['s-user'],
      })

      throw errorClass
    }
  }

  public static async patchCustomerAddress(params: {
    addressId: string
    patches: PatchOperation[]
  }): Promise<CustomerAddress> {
    try {
      const { addressId, patches } = params

      return await ApiService.patch({
        path: `${CUSTOMERS_PATH}/addresses/${addressId}`,
        params: {
          json: patches,
        },
        parse: CustomersParser.parseAddress,
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to patch the address for customer',
        originalError: error,
        tags: ['s-user'],
      })

      throw errorClass
    }
  }

  public static async getFlags(params: {
    userUuid: string
  }): Promise<CustomerFlag[]> {
    return ApiService.get({
      path: `${CUSTOMERS_PATH}/sensitive/${params.userUuid}`,

      parse: CustomerFlagParser.parse,
    })
  }

  public static async updateFlags(params: {
    userUuid: string
    flags: CustomerFlag[]
  }): Promise<void> {
    const json = {
      vulnerable: params.flags.includes('VULNERABLE') ? true : false,
    }

    await ApiService.post({
      path: `${CUSTOMERS_PATH}/sensitive/${params.userUuid}/flags`,
      params: {
        json,
      },
    })
  }

  public static async unsubscribe(
    agency: AgencyName,
    email?: string,
    customer?: string
  ): Promise<void> {
    if (!email && !customer) {
      throw new Error('Email or customer are required')
    }

    try {
      await ApiService.post({
        path: `/unsubscribe`,
        params: {
          json: {
            agency,
            email,
            customer,
          },
        },
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable unsubscribe',
        originalError: error,
        tags: ['s-customer'],
      })
      throw errorClass
    }
  }

  public static async resetMfa(
    customerId: string,
    factor_type: string = 'phone'
  ): Promise<void> {
    try {
      await ApiService.post({
        path: `${CUSTOMERS_PATH}/${customerId}/action/reset-mfa`,
        params: {
          searchParams: { factor_type },
        },
      })
    } catch (error) {
      const errorClass = await ErrorResponseFactory.instantiateErrorClass({
        title: 'Unable to reset MFA',
        originalError: error,
        tags: ['s-user'],
      })

      throw errorClass
    }
  }
}
