import Formatters from '../lib/formaters'
import { i18n as _ } from '../lib/i18n'
import ErrorWidget from '../widgets/helpers/Error.vue'
import HelpWidget from '../widgets/helpers/Help.vue'
import LabelWidget from '../widgets/helpers/Label.vue'
import ReadonlyWidget from '../widgets/helpers/Readonly.vue'

/**
 * WidgetMixin
 * Used when creating widgets to provide a common interface
 */
export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
    formatter: {
      type: Object,
      required: false,
      default: null,
    },
    copyable: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['callback'],
  components: {
    LabelWidget,
    ErrorWidget,
    HelpWidget,
    ReadonlyWidget,
  },
  data() {
    return {
      errors: null,
      value: null,
    }
  },
  created() {
    this.init()
    this.field.widget = this
  },
  computed: {
    isCopyable() {
      /*
        Note: This is a computed property to make it reactive
        to changes to this.copyable. However, in the case that
        there is an overide, this will always be applied.
      */
      if (this.field.options.hasOwnProperty('copyable')) {
        return this.field.options.copyable
      }

      return this.copyable
    },

    isReadOnly() {
      /*
        Note: This is a computed property to make it reactive
        to changes to this.readonly. However, in the case that
        there is an overide, this will always be applied.
      */
      if (this.field.options.hasOwnProperty('readonly')) {
        return this.field.options.readonly
      }

      return this.readonly
    },

    getFieldValue() {
      if (this.field.value === null) {
        return null
      }

      if (this.formatter) {
        return this.getFormattedValue(this.formatter, this.field.value)
      } else if (this.field.options.hasOwnProperty('format')) {
        const fieldFormat = this.field.options.format

        return this.getFormattedValue(fieldFormat, this.field.value)
      }

      return this.field.value
    },

    hasErrors() {
      return this.errors && this.errors.length > 0
    },

    qaLocatorPrefix() {
      /*
        Check if parent element(usually <form>)
        has "data-qa-locator-prefix" attribute
      */
      let parent = this.$parent

      while (parent && !parent.$attrs['data-qa-locator-prefix']) {
        parent = parent.$parent
      }

      return parent ? parent.$attrs['data-qa-locator-prefix'] : null
    },

    qaLocator() {
      let qaLocator = null

      // Check if we have "attribs.qalocator" in options
      if (this.field.attribs.hasOwnProperty('qalocator')) {
        qaLocator = this.field.attribs.qalocator
      }

      // Check if we have QA locator prefix on a parent element/form
      if (this.qaLocatorPrefix) {
        qaLocator = `${this.qaLocatorPrefix}-${qaLocator || this.field.id}`
      }

      return qaLocator
    },

    getPlaceholder() {
      if (this.field.options.placeholder) {
        return _(this, this.field.options.placeholder)
      }

      return ''
    },
  },
  methods: {
    init() {
      this.value = this.field.value
    },

    getValue() {
      return this.value
    },

    getFormattedValue(formatter, value) {
      if (typeof formatter === 'string') {
        if (Formatters.hasOwnProperty(formatter)) {
          return Formatters[formatter](value)
        }
      } else {
        const formatType = formatter.type
        if (Formatters.hasOwnProperty(formatType)) {
          return Formatters[formatType](this.field.value, formatter)
        }
      }
      return value
    },

    setValue(value) {
      this.value = value
    },

    _getFieldClass() {
      if (this.field.attribs.hasOwnProperty('class')) {
        return `mutt-field ${this.field.attribs.class}`
      }

      return 'mutt-field'
    },

    getFieldClass() {
      return this._getFieldClass()
    },

    _getFieldWrapperClass() {
      let output = 'mutt-field-wrapper'

      if (this.field.options.hasOwnProperty('wrapperClass')) {
        output = `${output} ${this.field.options.wrapperClass}`
      }

      if (this.hasErrors) {
        return `${output} ${this.getErrorWrapperClass()}`
      }

      return output
    },

    getFieldWrapperClass() {
      return this._getFieldWrapperClass()
    },

    getErrorClass() {
      return 'mutt-error'
    },

    getErrorWrapperClass() {
      return 'mutt-error-wrapper'
    },

    getFieldId() {
      return this.field.options.usePathId ? this.field.id : this.field.name
    },

    refreshErrorState(errors) {
      if (errors.length > 0) {
        this.errors = errors
      }
    },

    submitCallback() {
      if (this.field.validate()) {
        this.$emit('callback', {
          key: this.field.name,
          value: this.field.value,
          action: 'submit',
          validated: true,
        })
      } else {
        this.$emit('callback', {
          key: this.field.name,
          value: this.field.value,
          action: 'submit',
          validated: false,
        })
      }
    },
  },
}
