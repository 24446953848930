<template>
  <div v-if="field" :class="getFieldWrapperClass()">
    <label-widget :field="field" :field-id="getFieldId()" />
    <readonly-widget
      v-if="isReadOnly"
      :field="field"
      :copyable="isCopyable"
      :value="field.value | formatDate"
    />
    <span class="mutt-field-wrapper-dateofbirth">
      <input
        v-if="!isReadOnly && !hideDay"
        ref="day"
        v-model="day"
        class="mutt-dateinput mutt-dateinput--day mutt-field"
        :required="required"
        type="tel"
        pattern="^(0?[1-9]|[12]\d|3[01])$"
        placeholder="DD"
        @keyup="jumper($event, 'day')"
        @keypress.enter.prevent="submitCallback"
        @click="checkJump('day')"
        @focus="checkJump('day')"
      />
      <input
        v-if="!isReadOnly"
        ref="month"
        v-model="month"
        class="mutt-dateinput mutt-dateinput--month mutt-field"
        :required="required"
        type="tel"
        pattern="^(0?[1-9]|1[012])$"
        placeholder="MM"
        @keyup="jumper($event, 'month')"
        @keypress.enter.prevent="submitCallback"
        @click="checkJump('month')"
        @focus="checkJump('month')"
      />
      <input
        v-if="!isReadOnly"
        ref="year"
        v-model="year"
        class="mutt-dateinput mutt-dateinput--year mutt-field"
        :required="required"
        type="number"
        :min="getMinYears()"
        :max="getMaxYears()"
        placeholder="YYYY"
        @keyup="jumper($event, 'year')"
        @keypress.enter.prevent="submitCallback"
        @click="checkJump('year')"
        @focus="checkJump('year')"
      />
      <input
        v-if="!isReadOnly"
        v-show="showTime"
        ref="hour"
        v-model="hour"
        class="mutt-dateinput mutt-dateinput--hour mutt-field"
        :required="required"
        type="tel"
        pattern="[01]?\d|2[0-3]"
        placeholder="HH"
        @keyup="jumper($event, 'hour')"
        @keypress.enter.prevent="submitCallback"
        @click="checkJump('hour')"
        @focus="checkJump('hour')"
      />
      <input
        v-if="!isReadOnly"
        v-show="showTime"
        ref="minute"
        v-model="minute"
        class="mutt-dateinput mutt-dateinput--minute mutt-field"
        :required="required"
        type="tel"
        pattern="[0-5]?\d"
        placeholder="MM"
        @keyup="jumper($event, 'minute')"
        @keypress.enter.prevent="submitCallback"
        @click="checkJump('minute')"
        @focus="checkJump('minute')"
      />
      <input
        v-if="!isReadOnly"
        v-show="showTime"
        ref="second"
        v-model="second"
        class="mutt-dateinput mutt-dateinput--second mutt-field"
        :required="required"
        type="tel"
        pattern="[0-5]?\d"
        placeholder="SS"
        @keyup="jumper($event, 'second')"
        @keypress.enter.prevent="submitCallback"
        @click="checkJump('second')"
        @focus="checkJump('second')"
      />
    </span>
    <help-widget :field="field" />
    <error-widget
      v-if="!isReadOnly"
      :field="field"
      :errors="errors"
      :error-class="getErrorClass()"
    />
  </div>
</template>

<script>
import MuttVue from '@mutt/widgets-vue'
import moment from 'moment'

import { MomentDateTimeValidator } from './validators/Validators.js'

export default {
  name: 'MuttDatetimeinput',
  for: 'datetimeinput',
  filters: {
    formatDate(value) {
      return moment.utc(value).format('h:mm:ss, MMMM Do YYYY')
    },
  },
  mixins: [MuttVue.mixin],
  data() {
    return {
      errors: null,
      value: null,
      initalised: false,

      date: null,
      day: null,
      month: null,
      year: null,
      hour: null,
      minute: null,
      second: null,

      hideDay: false,
      min: null,
      max: null,
      required: null,

      jump: true,
    }
  },
  computed: {
    showTime() {
      if (this.field.options.hasOwnProperty('showTime')) {
        return this.field.options.showTime
      }
      return true
    },
  },
  watch: {
    second(newVal, oldVal) {
      if (this.initalised) {
        setTimeout(() => {
          this.buildValue()
        }, 1000)
      }
    },
    minute(newVal, oldVal) {
      if (this.initalised) {
        setTimeout(() => {
          this.buildValue()
        }, 1000)
      }
    },
    hour(newVal, oldVal) {
      if (this.initalised) {
        setTimeout(() => {
          this.buildValue()
        }, 1000)
      }
    },
    day(newVal, oldVal) {
      if (this.initalised) {
        setTimeout(() => {
          this.buildValue()
        }, 1000)
      }
    },
    month(newVal, oldVal) {
      if (this.initalised) {
        setTimeout(() => {
          this.buildValue()
        }, 1000)
      }
    },
    year(newVal, oldVal) {
      if (this.initalised) {
        this.buildValue()
      }
    },
  },
  mounted() {
    // build min date value from options string
    // ISO8601 e.g 1970-01-01T00:00:00Z
    if (this.field.options.min) {
      // Check for ISO-8601 durantion string - i.e P1Y
      if (this.field.options.min.startsWith('P')) {
        this.min = moment.utc().add(moment.duration(this.field.options.min))
      } else {
        this.min = moment.utc(this.field.options.min)
      }
    }

    // edge case for 18 and above
    if (this.field.options.max === 'adultonly') {
      this.max = moment.utc().add(moment.duration('P18Y'))
    } else if (this.field.options.max) {
      // Check for ISO-8601 durantion string - i.e P1Y
      if (this.field.options.max.startsWith('P')) {
        this.max = moment.utc().add(moment.duration(this.field.options.max))
      } else {
        this.max = moment.utc(this.field.options.max)
      }
    }

    const messages = this.field.options.messages || null

    // check if the day field is set to be hidden for this field
    if (this.field.options.hideDay) this.hideDay = true

    this.field.validators.push(
      new MomentDateTimeValidator({
        min: this.min,
        max: this.max,
        messages,
        required: this.required,
      })
    )

    let initialValue = null

    if (this.field.value) {
      initialValue = moment.utc(this.field.value)
    } else if (this.field.options.default) {
      initialValue = moment.utc(this.field.options.default)
    }

    if (initialValue) {
      this.second = initialValue.second()
      this.minute = initialValue.minute()
      this.hour = initialValue.hour()
      this.day = initialValue.date()
      this.month = initialValue.month() + 1
      this.year = initialValue.year()
      this.field.value = initialValue
    }

    this.initalised = true
  },
  created() {
    if (this.field.options.required === false) {
      this.required = false
    } else {
      // The field is required if the options value is missing, undefined, null, or true.
      // This maintains the legacy behaviour of this field, which defaulted to being required.
      this.required = true
    }
  },
  methods: {
    getFieldClass() {
      return 'mutt-field mutt-field-choice'
    },
    getFieldWrapperClass() {
      if (this.hasErrors) {
        return `mutt-field-wrapper ${this.getErrorWrapperClass()}`
      }
      return 'mutt-field-wrapper'
    },
    zeroPad(value) {
      if (
        (value || value === 0) && // a value could be 0 which is falsy
        value.toString().length === 1
      ) {
        value = `0${value}`
      }
      return value
    },
    buildValue() {
      // If the day field is hidden set the day value to '01'
      // so that the date will validate successfully
      if (this.hideDay) {
        this.day = '01'
      }

      // YY can be a valid but ambiguious date
      // Check we have the right length
      let yearValidLength = false

      if (this.year) {
        if (this.year.toString().length > 3) {
          yearValidLength = true
        }
      }

      const reset = () => {
        this.field.refreshValidationState(false)
        this.field.value = null
      }

      if (
        yearValidLength &&
        this.day &&
        this.month &&
        (this.hour || this.hour === 0) && // hour can be 0 which is falsy
        (this.minute || this.minute === 0) && // minute can be 0 which is falsy
        (this.second || this.second === 0) // second can be 0 which is falsy
      ) {
        const date = `${this.year}-${this.zeroPad(this.month)}-${this.zeroPad(
          this.day
        )}`
        const time = `${this.zeroPad(this.hour)}:${this.zeroPad(
          this.minute
        )}:${this.zeroPad(this.second)}`
        const value = moment.utc(`${date}T${time}Z`)

        if (value.isValid()) {
          this.field.value = value
          this.field.validate()
        } else {
          reset()
        }
      } else {
        reset()
      }
    },
    restrictDates(dateElement, dateVal) {
      switch (dateElement) {
        case 'second':
        case 'minute':
          if (dateVal > 59) {
            this[dateElement] = '59'
          }
          break
        case 'hour':
          if (dateVal > 23) {
            this.hour = '23'
          }
          break
        case 'day':
          if (dateVal > 31) {
            this.day = '31'
          } else if (dateVal === '00') {
            this.day = '01'
          }
          break
        case 'month':
          if (dateVal > 12) {
            this.month = '12'
          } else if (dateVal === '00') {
            this.month = '01'
          }
          break
        default:
          break
      }
    },
    jumper(event, type) {
      const value = this[type]
      const $el = this.$refs[type]
      const $nextElementSibling = $el.nextElementSibling

      if (value) {
        this.restrictDates(type, value)

        if (
          $el &&
          $nextElementSibling &&
          this.jump &&
          // Day/month can be 0 prefixed or not so we should check length
          // as well as validity otherwise entering '10' would cause
          // the field jump to happen
          value.length > 1 &&
          $el.checkValidity()
        ) {
          $nextElementSibling.focus()
        }
      }
    },
    checkJump(type) {
      // We need to disable the jump if people edit or use a non-standard
      // order....
      if (this[type]) {
        this.jump = false
      }
    },
    focus() {
      this.$nextTick().then(() => {
        // if the day field is set to hidden then focus the
        // cursor onto the month input
        if (this.hideDay) {
          this.$refs.month.focus()
        } else {
          this.$refs.day.focus()
        }
      })
    },
    resetInputs() {
      this.year = null
      this.month = null
      this.day = null
      this.hour = null
      this.minute = null
      this.second = null

      this.jump = true
    },
    getMinYears() {
      if (this.min) {
        return this.min.year()
      }
      return moment.utc().add(moment.duration('P-120Y')).year()
    },
    getMaxYears() {
      if (this.max) {
        return this.max.year()
      }
      return moment.utc().add(moment.duration('P120Y')).year()
    },
    isNotZero(value) {
      return value !== '0'
    },
    refresh() {
      this.initalised = false

      const value = moment.isMoment(this.field.value)
        ? this.field.value
        : moment.utc(this.field.value)

      if (value.isValid()) {
        this.second = value.second()
        this.minute = value.minute()
        this.hour = value.hour()
        this.day = value.date()
        this.month = value.month() + 1
        this.year = value.year()
      } else {
        this.resetInputs()
      }

      this.initalised = true
    },
  },
}
</script>
