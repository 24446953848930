import type { App, Plugin } from 'vue'

import showError from '@/lib/showError'

const ErrorPlugin: Plugin = {
  install(app: App) {
    app.config.globalProperties.$error = showError
  },
}

export default ErrorPlugin
