<template>
  <copyable-text
    v-if="copyable"
    :text="value"
    :success-message="`${field.label} copied`"
  >
    <span class="mutt-readonly" v-html="value"></span>
  </copyable-text>
  <span v-else class="mutt-readonly" v-html="value"></span>
</template>

<script>
import CopyableText from '@/components/CopyableText'

export default {
  name: 'readonly-widget',
  components: {
    CopyableText,
  },
  props: ['copyable', 'field', 'value'],
}
</script>
